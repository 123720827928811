import { useState } from "react";
import Modal from "react-modal";
import { Scrollbar, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import 'swiper/css';
import './slider.css'
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import './lightbox.css';

const SliderComponent = ({ imagePaths }) => {
  const images = imagePaths;
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  function extractFileName(path) {
    const parts = path.split('/');
    const fileName = parts[parts.length - 1].split('.')[0];
    return fileName;
  }

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };

  console.log(images);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  return (
    <>
      <Swiper
        slidesPerView={2}
        navigation={{
          prevEl: ".custom-prev",
          nextEl: ".custom-next",
        }}
        modules={[Scrollbar, Navigation,]}
        spaceBetween={10}
        scrollbar={{ hide: true }}
        breakpoints={{
          768: {
            slidesPerView: 4,
          },
        }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              className="slider-img cursor-pointer"
              src={image.path}
              alt={image.alt}
              onClick={() => openLightbox(index)}
            />
          </SwiperSlide>
        ))}


      </Swiper>

      <Modal
        isOpen={lightboxIsOpen}
        onRequestClose={closeLightbox}
        contentLabel="Image Lightbox"
        className="lightbox-modal"
        overlayClassName="lightbox-overlay"
      >
        <div className="lightbox-content">
          <button className="close-button" onClick={closeLightbox}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
              <rect x="1.80762" y="0.979183" width="30" height="2" transform="rotate(45 1.80762 0.979183)" fill="#D9D9D9" />
              <rect x="22.6675" y="3.45406" width="30" height="2" transform="rotate(135 22.6675 3.45406)" fill="#D9D9D9" />
            </svg>
          </button>
          <div className="lightbox-slider">
            <Swiper
              initialSlide={currentImageIndex}
              modules={[Navigation,]}
              navigation={{
                prevEl: ".custom-prev2",
                nextEl: ".custom-next2",
              }}
              onSlideChange={({ isBeginning, isEnd }) => {
                setIsBeginning(isBeginning);
                setIsEnd(isEnd);
                console.log('slide change');
              }}
            >
              {images.map((image, index) => (
                <SwiperSlide key={index}>
                  <figure>
                    <img
                      className="lightbox-img"
                      src={image.path}
                      alt={image.alt}
                    />
                    {image.title && (
                        <figcaption>
                          {image.title}
                        </figcaption>
                      )}
                  </figure>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="custom-navigation custom-navigation2">
              <div className={`custom-prev custom-prev2 p-2 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                <ArrowLeftIcon className="text-white w-6 h-6" />
              </div>
              <div className={`custom-next custom-next2 p-2 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                <ArrowRightIcon className="text-white w-6 h-6" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="custom-navigation ">
        <div className={`custom-prev custom-prev2 p-2 rounded-full bg-lgray`}>
          <ArrowLeftIcon className="text-white w-4 h-4" />
        </div>
        <div className={`custom-next custom-next2 p-2 rounded-full bg-lgray`}>
          <ArrowRightIcon className="text-white w-4 h-4" />
        </div>
      </div>
    </>
  );
};

export default SliderComponent;