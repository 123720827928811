// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#post {
    color: white;
}

#post p{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

#post a{
    text-decoration: underline;
    font-weight: 600;
}

#post figcaption {
    margin-top: 10px;
    font-size: 11px;
    text-align: center;
    color: rgb(160, 160, 160);
}

#post img {
    /* aspect-ratio: 16/9; */
    object-fit: cover;
    width: 100%;
}


#post video {
    /* aspect-ratio: 16/9; */
    object-fit: cover;
    width: 100% !important;
}

#post .contant-wrapper {
    margin-top: 40px;
}

#post .contant-text-acsent {
    background-color: #2A2A2A;
    padding: 20px;
    border-left: 2px solid white;
}

#post .contant-title {
    font-weight: 700;
    font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/postpage/postpage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,wBAAwB;IACxB,iBAAiB;IACjB,WAAW;AACf;;;AAGA;IACI,wBAAwB;IACxB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":["#post {\r\n    color: white;\r\n}\r\n\r\n#post p{\r\n    margin-top: 10px;\r\n    margin-bottom: 10px;\r\n    font-size: 16px;\r\n}\r\n\r\n#post a{\r\n    text-decoration: underline;\r\n    font-weight: 600;\r\n}\r\n\r\n#post figcaption {\r\n    margin-top: 10px;\r\n    font-size: 11px;\r\n    text-align: center;\r\n    color: rgb(160, 160, 160);\r\n}\r\n\r\n#post img {\r\n    /* aspect-ratio: 16/9; */\r\n    object-fit: cover;\r\n    width: 100%;\r\n}\r\n\r\n\r\n#post video {\r\n    /* aspect-ratio: 16/9; */\r\n    object-fit: cover;\r\n    width: 100% !important;\r\n}\r\n\r\n#post .contant-wrapper {\r\n    margin-top: 40px;\r\n}\r\n\r\n#post .contant-text-acsent {\r\n    background-color: #2A2A2A;\r\n    padding: 20px;\r\n    border-left: 2px solid white;\r\n}\r\n\r\n#post .contant-title {\r\n    font-weight: 700;\r\n    font-size: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
