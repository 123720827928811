import LineDot from "../../components/line-dot/LineDot";
import Footer from "../../container/footer/Footer";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { Helmet } from "react-helmet";
import 'swiper/css';
import 'swiper/css/navigation';
import './team.css'
import viktor from './team/viktor800.jpg'
import andrii from './team/andrii800.jpg'
import serhii from './team/serhii800.jpg'
import daria from './team/daria800.jpg'
import anna from './team/anna800.jpg'
import kseniia from './team/kseniia800.jpg'
import arsenii from './team/arsenii800.jpg'
import maksym from './team/maksym800.jpg'
import oleksii from './team/oleksii800.jpg'
import petro from './team/petro800.jpg'
import lolita from './team/lolita800.jpg'
import roman from './team/roman800.jpg'
import vladimir from './team/vladimir800.jpg'
import maria from './team/maria800.jpg'
import angelina from './team/angelina800.jpg'
import mykola from './team/mykola800.jpg'
import vitaliy from './team/vitaliy800.jpg'
import Action from "../../components/action/Action";


function Team() {
  
    const team_core = [
        {
            img: viktor,
            name: 'Viktor Kvachan',
            position: 'CEO, Founder <br/> in business 25+ years, in farm automation 10+ years, chemist with degree',
            link: 'https://www.linkedin.com/in/viktorkvachan/'
        },
        {
            img: andrii,
            name: 'Andrii Chelobitchykov',
            position: 'Chief Engineer Officer <br/> in business 25+ years, production & execution',
            link: 'https://www.linkedin.com/in/andrii-chelobitchykov-b139bb246/'
        },
        {
            img: serhii,
            name: 'Serhii Chelobitchykov',
            position: 'Chief Product Designer <br/> in design 5+ years, architect background, 3D modeling & prototyping, testing of equipment',
            link: 'https://www.linkedin.com/in/serhiichelobitchykov/'
        },
        {
            img: daria,
            name: 'Daria Pylypas',
            position: 'Chief Мarketing Officer <br/> 6+ years in marketing & project management',
            link: 'https://www.linkedin.com/in/pylypas-daria/'
        }
    ]

    const team = [
        {
            img: anna,
            name: 'Anna Kvachan',
            position: 'Management Team',
        },
        {
            img: kseniia,
            name: 'Kseniia Kvachan',
            position: 'Management Team',
        },
        {
            img: arsenii,
            name: 'Arsenii Kvachan',
            position: 'Software Team',
        },
        {
            img: maksym,
            name: 'Maksym Skomorokha',
            position: 'Software Team',
        },
        {
            img: oleksii,
            name: 'Oleksii Fedchishyn',
            position: 'Software Team',
        },
        {
            img: petro,
            name: 'Petro Voloshanivskyi',
            position: 'Engineering Team',
        },
        {
            img: lolita,
            name: 'Lolita Oriekhovska',
            position: 'Marketing Team',
        },
        {
            img: roman,
            name: 'Roman Saenko',
            position: 'Management Team',
        },
        {
            img: vladimir,
            name: 'Vladimir Krondiak',
            position: 'Management Team',
        },
        {
            img: maria,
            name: 'Mariia Maison',
            position: 'Software Team',
        },
        {
            img: angelina,
            name: 'Angelina Malashenko',
            position: 'Science Team',
        },
        {
            img: vitaliy,
            name: 'Vitaliy Osovskyy',
            position: 'Science Team',
        },
        {
            img: mykola,
            name: 'Mykola Paraian',
            position: 'Engineering Team',
        }
    ]

    const sortedTeam = [...team].sort((a, b) => a.position.localeCompare(b.position));

    return (
        <>
            <Helmet>
                <title>EHOSS | Team</title>
            </Helmet>
            <section>
                <div className="fixed h-full z-[-9999]">
                    <LineDot />
                </div>
                <div className="container">
                    <div className="pt-24">
                        <Breadcrumbs
                            items={[
                                { label: 'People', url: '#' },
                                { label: 'Team', url: '/team' }
                            ]}
                        />

                    </div>
                    <h2 className="text-[32px] mt-12 font-semibold mb-10 text-white">
                        Team core:
                    </h2>

                    <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-12 border-b border-lgray pb-12">
                        {team_core.map((member, index) => (
                            <div key={index} className="bg-gray rounded-xl overflow-hidden">
                                <img src={member.img} alt={member.name} />
                                <div className="p-4 flex flex-col">
                                    <div className="md:h-24">
                                        <h4 className="text-white font-semibold md:text-[20px]">{member.name}</h4>
                                        <p className="text-white mt-2 text-[14px] opacity-60" dangerouslySetInnerHTML={{ __html: member.position }}></p>

                                    </div>
                                    <a target="_blank"  className="mt-4 block cursor-pointer" href={member.link}>
                                        <svg width="24" height="24" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.2548 37.2837C29.5038 37.2837 37.8123 28.971 37.8123 18.7168C37.8123 8.46258 29.5038 0.149902 19.2548 0.149902C9.00576 0.149902 0.697266 8.46258 0.697266 18.7168C0.697266 28.971 9.00576 37.2837 19.2548 37.2837Z" fill="white" />
                                            <path d="M20.6858 16.1836C20.9909 15.8398 21.2411 15.4932 21.5545 15.1989C22.5168 14.2911 23.655 13.8345 24.9856 13.8455C25.7169 13.851 26.4372 13.9033 27.141 14.1068C28.7521 14.5717 29.6869 15.683 30.135 17.2563C30.4704 18.4364 30.5309 19.6494 30.5336 20.8652C30.5391 23.4288 30.5254 25.9952 30.5336 28.5588C30.5336 28.7981 30.4677 28.8613 30.2312 28.8613C28.9116 28.8503 27.5892 28.8503 26.2695 28.8613C26.0358 28.8613 25.9864 28.7926 25.9864 28.5725C25.9946 26.1327 25.9946 23.6929 25.9864 21.2503C25.9864 20.6396 25.9451 20.0262 25.7747 19.4321C25.4585 18.3373 24.675 17.779 23.5285 17.8395C21.9614 17.922 21.1476 18.6977 20.9497 20.2903C20.903 20.6699 20.8782 21.0522 20.881 21.4346C20.881 23.8084 20.881 26.1822 20.8865 28.556C20.8865 28.7926 20.8287 28.8613 20.5868 28.8613C19.2561 28.8503 17.9255 28.8503 16.5949 28.8613C16.3804 28.8613 16.3199 28.8063 16.3199 28.589C16.3254 23.8909 16.3254 19.19 16.3199 14.4919C16.3199 14.2581 16.3969 14.2059 16.6169 14.2059C17.8788 14.2141 19.1434 14.2169 20.4053 14.2059C20.639 14.2059 20.6968 14.2801 20.694 14.5002C20.6803 15.0613 20.6885 15.6225 20.6885 16.1863L20.6858 16.1836Z" fill="#242424" />
                                            <path d="M13.4493 21.5527C13.4493 23.877 13.4466 26.2013 13.4548 28.5256C13.4548 28.7842 13.3916 28.8612 13.1249 28.8585C11.8053 28.8447 10.4856 28.8475 9.16323 28.8585C8.95154 28.8585 8.88831 28.8062 8.88831 28.5889C8.8938 23.8825 8.8938 19.1762 8.88831 14.4671C8.88831 14.2718 8.93504 14.203 9.14123 14.203C10.4801 14.2113 11.819 14.214 13.1579 14.203C13.4164 14.203 13.4493 14.2993 13.4493 14.5221C13.4438 16.8656 13.4466 19.2092 13.4466 21.5527H13.4493Z" fill="#242424" />
                                            <path d="M13.8132 9.54069C13.8132 11.0013 12.631 12.1896 11.1739 12.1896C9.73604 12.1896 8.54287 11.0013 8.53737 9.5627C8.53187 8.11035 9.72505 6.91382 11.1794 6.91382C12.6228 6.91382 13.8104 8.09935 13.8132 9.53794V9.54069Z" fill="#242424" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                    <h2 className="text-[24px] mt-12 font-semibold mb-10 text-white">
                        Team:
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
                        {sortedTeam.map((member, index) => (
                            <div key={index} className="bg-gray rounded-xl overflow-hidden">
                                <img className="md:h-[320px] object-cover" src={member.img} alt={member.name} />
                                <div className="p-4">
                                    <h4 className="text-white font-semibold md:text-[20px]">{member.name}</h4>
                                    <p className="text-white text-[12px] opacity-60">{member.position}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="mt-32 hidden md:block">
                <div className="container">
                    <Action />
                </div>
            </section>
            <Footer />
        </>

    );
}

export default Team;
