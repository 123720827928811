// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#app-page canvas:nth-child(1){
    display: none !important;
}

#app-page {
    z-index: -1;
    pointer-events: none;
    position: fixed;
    top: 0;
   
}

.section {
    height: 100vh;
    /* background-color: transparent; */
}

.fp-right.onmain ul li:nth-child(1) {
    pointer-events: none !important;
}

.fp-right.onmain ul li:nth-child(2) {
    pointer-events: none !important;
}


.fp-right.onmain ul li:nth-child(3) {
    pointer-events: none !important;
}


.fp-right.onmain ul li:nth-child(4) {
    pointer-events: none !important;
}

.main-btn-home {
    font-size: 12px !important;
}

@media (max-width: 290px) {
    .main-btn-home {
        font-size: 12px !important;
        padding-right: 20px;
        background-image: none !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/main/main.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,eAAe;IACf,MAAM;;AAEV;;AAEA;IACI,aAAa;IACb,mCAAmC;AACvC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,+BAA+B;AACnC;;;AAGA;IACI,+BAA+B;AACnC;;;AAGA;IACI,+BAA+B;AACnC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI;QACI,0BAA0B;QAC1B,mBAAmB;QACnB,iCAAiC;IACrC;AACJ","sourcesContent":["#app-page canvas:nth-child(1){\r\n    display: none !important;\r\n}\r\n\r\n#app-page {\r\n    z-index: -1;\r\n    pointer-events: none;\r\n    position: fixed;\r\n    top: 0;\r\n   \r\n}\r\n\r\n.section {\r\n    height: 100vh;\r\n    /* background-color: transparent; */\r\n}\r\n\r\n.fp-right.onmain ul li:nth-child(1) {\r\n    pointer-events: none !important;\r\n}\r\n\r\n.fp-right.onmain ul li:nth-child(2) {\r\n    pointer-events: none !important;\r\n}\r\n\r\n\r\n.fp-right.onmain ul li:nth-child(3) {\r\n    pointer-events: none !important;\r\n}\r\n\r\n\r\n.fp-right.onmain ul li:nth-child(4) {\r\n    pointer-events: none !important;\r\n}\r\n\r\n.main-btn-home {\r\n    font-size: 12px !important;\r\n}\r\n\r\n@media (max-width: 290px) {\r\n    .main-btn-home {\r\n        font-size: 12px !important;\r\n        padding-right: 20px;\r\n        background-image: none !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
