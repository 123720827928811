import LineDot from "../../components/line-dot/LineDot";
import Footer from "../../container/footer/Footer";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import poster from './slider/poster.png'
import slide1 from './slider/1.mp4'
import slide2 from './slider/2.jpg'
import slide3 from './slider/3.jpg'
import slide4 from './slider/4.jpg'
// import slide3 from './slider/2.png'
// import slide4 from './slider/2.png'

import 'swiper/css';
import 'swiper/css/navigation';
import './researchanddevelopment.css'

function ResearchAndDevelopment() {
  const [slidesPerView, setSlidesPerView] = useState(calculateSlidesPerView());
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setSlidesPerView(calculateSlidesPerView());
  };

  function calculateSlidesPerView() {
    if (window.innerWidth < 768) {
      return 1;
    } else if (window.innerWidth < 1280) {
      return 2.2;
    } else {
      return 3.2;
    }
  }

  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>EHOSS | Laboratory and R&D</title>
        <meta name="keywords" content="biomass, manure, reusable technologies, forage quality" />
        <meta name="robots" content="index, follow" />
        <meta name="description" content="where ideas turn into reality" />
        <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
      </Helmet>
      <section id="br-section" className="overflow-hidden">
        <div className="fixed h-[100vh] w-24 z-[-9999]">
          <LineDot />
        </div>

        <div className="container">

          <div className="mt-24">
            <Breadcrumbs
              items={[
                { label: 'Company', url: '#' },
                { label: 'EHOSS', url: '#' },
                { label: 'Laboratory and R&D', url: '/laboratory-and-r-and-d' },
              ]}
            />
            <div className="flex md:flex-row justify-between items-center mb-4 mt-8 md:mb-10">
              <h2 className="text-[32px] font-semibold text-white">
                Laboratory and R&D
              </h2>

            </div>

            {/* <div
                            className="flex gap-2 text-white items-center mt-4 md:mb-0 mb-4 md:mt-16 cursor-pointer"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <p className="text-[13px]">Scroll right</p>
                            <ArrowRightIcon className={`w-5 h-5 transition-transform duration-300 ${isHovered ? 'transform translate-x-2' : ''}`} />
                        </div> */}
          </div>
          
          <div className='w-full cursor-grab hidden md:block relative slider-shadow'>
            <Swiper
              modules={[Navigation, Mousewheel]}
              spaceBetween={50}
              slidesPerView={slidesPerView}
              className={`sw1 ${isBeginning ? '' : 'sw2'}`}
              mousewheel={{ enabled: false }}
              navigation={{
                nextEl: '.custom-r',
                prevEl: '.custom-l'
              }}
              onSlideChange={({ isBeginning, isEnd, swiper }) => {
                setIsBeginning(isBeginning);
                setIsEnd(isEnd);
              }}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                    <video title='EHOSS microbiologist is working in the laboratory' poster={poster} controls className="w-full h-full object-cover" src={slide1} alt="woman is working in the laboratory" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>
                    Own Laboratory
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>
                  <div id='start-line' className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    EHOSS laboratory conducts experiments, tests, and analyses to support our research and development. The laboratory is equipped with various tools, equipment and technologies needed to carry out specialized research and testing activities.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img title="EHOSS laboratory microscope screen with some microelements" className="w-full h-full object-cover" src={slide2} alt="laboratory microscope screen with some microelements" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    Microbiological and Biochemical Research

                  </h3>
                  <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    Our laboratory is particularly focused on analysing the biomass, which is formed in the process of farms and stables functioning, for our reusable technologies. For our animal care line products, we analyse the environment in which animals live and the products they consume.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="w-full h-full object-cover" title="EHOSS engineer is installing sensors in a stable" src={slide3} alt="a man installs sensors under the ceiling" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    First Tests
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>
                  <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    Aside from laboratory research, our R&D department is also engaged in the project’s test stalls. At our partner’s stables, we conduct our first prototype tests.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="w-full h-full object-cover" title="EHOSS's first prototype for preparing hay for horses" src={slide4} alt="hay in equipment for preparing it for horses" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    IoT solutions
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>
                  <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    We integrate technological sensors and analysers into our products, providing end-users with information about various indicators, such as the biomass quality and readiness to be transported for biomass line of products, and water and hay quality, gas and dust levels in stalls for animal care line products. In all our products we provide IoT technologies.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className='flex mt-8 z-20 items-center justify-center gap-4 mt-2'>
              <div className={`custom-l cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                <ArrowLeftIcon />
              </div>
              <div className={`custom-r cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                <ArrowRightIcon />
              </div>
            </div>
          </div>
          <div className='w-full cursor-grab flex flex-col gap-16 md:hidden relative slider-shadow'>

                <div className=' text-white  bg-gray p-4 rounded-xl'>
                  <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                    <video title='EHOSS microbiologist is working in the laboratory' poster={poster} controls className="w-full h-full object-cover" src={slide1} alt="woman is working in the laboratory" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>
                    Own Laboratory
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>

                  <p className='text-[14px]'>
                    EHOSS laboratory conducts experiments, tests, and analyses to support our research and development. The laboratory is equipped with various tools, equipment and technologies needed to carry out specialized research and testing activities.
                  </p>
                </div>
  
                <div className=' text-white  bg-gray p-4 rounded-xl'>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img title="EHOSS laboratory microscope screen with some microelements" className="w-full h-full object-cover" src={slide2} alt="laboratory microscope screen with some microelements" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    Microbiological and Biochemical Research

                  </h3>
  
                  <p className='text-[14px]'>
                    Our laboratory is particularly focused on analysing the biomass, which is formed in the process of farms and stables functioning, for our reusable technologies. For our animal care line products, we analyse the environment in which animals live and the products they consume.
                  </p>
                </div>
    
                <div className=' text-white  bg-gray p-4 rounded-xl'>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="w-full h-full object-cover" title="EHOSS engineer is installing sensors in a stable" src={slide3} alt="a man installs sensors under the ceiling" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    First Tests
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>

                  <p className='text-[14px]'>
                    Aside from laboratory research, our R&D department is also engaged in the project’s test stalls. At our partner’s stables, we conduct our first prototype tests.
                  </p>
                </div>

                <div className=' text-white  bg-gray p-4 rounded-xl'>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="w-full h-full object-cover" title="EHOSS's first prototype for preparing hay for horses" src={slide4} alt="hay in equipment for preparing it for horses" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    IoT solutions
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>
                  <p className='text-[14px]'>
                    We integrate technological sensors and analysers into our products, providing end-users with information about various indicators, such as the biomass quality and readiness to be transported for biomass line of products, and water and hay quality, gas and dust levels in stalls for animal care line products. In all our products we provide IoT technologies.
                  </p>
                </div>
   
            
          </div>
        </div>
      </section>
      <Footer />
    </>

  );
}

export default ResearchAndDevelopment;
