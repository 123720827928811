import React from 'react';
import LineDot from '../../components/line-dot/LineDot';
import Footer from '../../container/footer/Footer';
import './ecosystem.css'
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { Helmet } from "react-helmet";

import img1 from './images/img1.png'
import img2 from './images/img2.png'
import img3 from './images/img3.png'

import next1 from './images/next1.png'
import next2 from './images/next2.png'
import next3 from './images/next3.png'
import next4 from './images/next4.png'



function Ecosystem() {

    function openRequestModal() {
        const modal = document.getElementById('request-modal');
        modal.classList.remove('hidden');
        modal.classList.add('flex');
    }

    function openOrderModal() {
        const modal = document.getElementById('order-modal');
        modal.classList.remove('hidden');
        modal.classList.add('flex');
    }


    return (
        <>
            <Helmet>
                <title>EHOSS | Ecosystem</title>
            </Helmet>
            <section>
                <div className='fixed -z-10 h-full'>
                    <LineDot />
                </div>

                <div className='container '>
                    <div className='pt-24 text-white'>
                        <Breadcrumbs
                            items={[
                                { label: 'Ecosystem', url: '/ecosystem' },
                            ]}
                        />
                        <h2 className='text-[32px]  font-bold uppercase mb-4 mt-12'>
                            Ecosystem
                        </h2>
                        <div>
                            <h3 className='md:text-[30px] text-[24px] mb-4 font-medium uppercase'>
                                Biomass TECHNOLOGY:
                            </h3>
                        </div>
                    </div>
                    <a href='/reusing-platform' id='card' className='bg-gray transition hover:shadow-2xl overflow-hidden relative flex md:flex-row flex-col justify-between rounded-xl'>
                        <div className='flex order-2 md:order-none bg-gray z-10 flex-col justify-between p-4 md:p-10 '>
                            <div className='text-white  md:h-[300px]'>
                                <h2 className='text-[24px] uppercase font-medium mb-4'>
                                    Reusing Platform
                                </h2>
                                <p className='text-[14px] max-w-[400px] md:text-[16px] md:opacity-100 opacity-35'>
                                    Solves manure disposal questions, makes a profit, supports sustainable development and climate action.
                                </p>
                            </div>
                            {/* <div className='flex items-center mt-4 gap-4'>
                                <button
                                    onClick={(event) => {
                                        event.preventDefault(); 
                                        openRequestModal();
                                    }}
                                    className='main-btn relative z-50'>
                                    Preorder
                                </button>
                                <a className='text-white underline font-medium' href='/reusing-platform'>
                                    Read more
                                </a>
                            </div> */}
                        </div>
                        <div className='card1 order-1 md:order-none overflow-hidden -z-1 md:w-1/2'>
                            <img className='w-full h-full object-cover transition' src={img1} />
                        </div>
                    </a>
                    <div className='pt-12 text-white'>
                        <div>
                            <h3 className='text-[30px] mb-4 font-medium uppercase'>
                                ANIMAL CARE:
                            </h3>
                        </div>
                    </div>
                    <div className='grid md:grid-cols-2 gap-4'>
                        <a href='/forage-platform' id='card' className='bg-gray transition hover:shadow-2xl overflow-hidden relative flex flex-col md:flex-row justify-between rounded-xl'>
                            <div className='flex order-2 md:order-none flex-col justify-between p-4 md:p-10'>
                                <div className='text-white max-w-[400px] md:h-[300px]'>
                                    <h2 className='text-[24px] uppercase font-medium mb-4'>
                                        Forage Platform
                                    </h2>
                                    <p className='text-[14px] md:text-[16px] md:opacity-100 opacity-35'>
                                        Using this technology, an entire hay bale can be prepared for consumption in three steps: cutting, cleaning, and portioning. Equipment is located in a place in the stables that is accessible for a transport to deliver large hay bales.
                                    </p>
                                </div>
                                {/* <div className='flex items-center mt-4 gap-4'>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault(); 
                                            openRequestModal();
                                        }}
                                        className='main-btn'>
                                        Preorder
                                    </button>
                                    <a className='text-white underline font-medium' href='/forage-platform'>
                                        Read more
                                    </a>
                                </div> */}
                            </div>
                            <div className='card1 order-1 md:order-none overflow-hidden -z-1 md:w-1/2'>
                                <img className='w-full object-cover transition h-full' src={img2} />
                            </div>
                        </a>
                        <a href='/modular-stable' id='card' className='bg-gray transition hover:shadow-2xl overflow-hidden relative flex md:flex-row flex-col justify-between rounded-xl'>
                            <div className='flex order-2 md:order-none flex-col justify-between p-4 md:p-10'>
                                <div className='text-white max-w-[400px] md:h-[300px]'>
                                    <h2 className='text-[24px] uppercase font-medium mb-4'>
                                        Modular Stable
                                    </h2>
                                    <p className='text-[14px] md:text-[16px] md:opacity-100 opacity-35'>
                                        Transportable, prefab & mobile modular stable, that can be used to expand stables, temporary stables at exhibitions or events and any other situation where you need to build fast stable.
                                    </p>
                                </div>
                                {/* <div className='flex items-center mt-4 gap-4'>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            openRequestModal();
                                        }}
                                        className='main-btn'>
                                        Buy
                                    </button>
                                    <a className='text-white underline font-medium' href='/modular-stable'>
                                        Read more
                                    </a>
                                </div> */}
                            </div>
                            <div className='card1 order-1 md:order-none overflow-hidden -z-1 md:w-1/2'>
                                <img className='w-full object-cover transition h-full' src={img3} />
                            </div>
                        </a>
                    </div>
                    <div className='pt-12 text-white'>
                        <div>
                            <h3 className='text-[24px] mb-4 font-medium uppercase'>
                                concept:
                            </h3>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>

                        <div className='bg-gray rounded-xl overflow-hidden flex flex-col md:flex-row items-center'>
                            <img className='md:w-[20%] w-full h-[160px] max-h-[160px] object-cover transition' src={next1} />
                            <div className='p-5 text-white flex flex-col gap-2'>
                                <h4 className='uppercase text-[18px] text-white font-semibold'>
                                    one-horse platform
                                </h4>
                                <p className='text-[14px] opacity-35'>
                                    Prepares hay individually according to individual feeding programs for one horse in three stages: cutting, cleaning, portioning. Equipment is installed in the stall.
                                </p>
                                <a className='text-white text-[14px underline font-medium' href='/one-horse-platform'>
                                    Read more
                                </a>
                            </div>
                        </div>

                        <div className='bg-gray rounded-xl overflow-hidden flex flex-col md:flex-row items-center'>
                            <img className='md:w-[20%] w-full h-[160px] max-h-[230px] object-cover transition' src={next2} />
                            <div className='p-5 text-white flex flex-col gap-2'>
                                <h4 className='uppercase text-[18px] text-white font-semibold'>
                                    grain platform
                                </h4>
                                <p className='text-[14px] opacity-35'>
                                    Prepares a portion of various products for consumption, such as concentrated feeds, cereals, muesli, vitamin supplements and other mixes. Basically, all possible types of forage but hay.
                                </p>
                                <a className='text-white text-[14px underline font-medium' href='/grain-platform'>
                                    Read more
                                </a>
                            </div>
                        </div>

                        <div className='bg-gray rounded-xl overflow-hidden flex flex-col md:flex-row items-center'>

                            <img className='md:w-[20%] w-full h-[160px] max-h-[230px] m-0 p-0 object-cover transition' src={next3} />
                            <div className='p-5 text-white flex flex-col gap-2'>
                                <h4 className='uppercase text-[18px] text-white font-semibold'>
                                    MONITORING PLATFORM
                                </h4>
                                <p className='text-[14px] opacity-35'>
                                    Allows you to monitor stalls, trailers and other closed places where the horse stays, the environmental indicators of which are important to monitor.
                                </p>
                                <a className='text-white text-[14px underline font-medium' href='/monitoring-platform'>
                                    Read more
                                </a>
                            </div>
                        </div>

                        <div className='bg-gray rounded-xl overflow-hidden flex flex-col md:flex-row items-center'>
                            <img className='md:w-[20%] w-full h-[160px] max-h-[230px] object-cover transition' src={next4} />
                            <div className='p-5 text-white flex flex-col gap-2'>
                                <h4 className='uppercase text-[18px] text-white font-semibold'>
                                    ENVIRONMENT PLATFORM
                                </h4>
                                <p className='text-[14px] opacity-35'>
                                    Disinfects the stable, has an anti-mosquito effect and saturates with vitamin D.
                                </p>
                                <a className='text-white text-[14px underline font-medium' href='/soon'>
                                    Read more
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <LineDot />
                <div className='container'>

                </div>
            </section>
            <Footer />
        </>
    );
}

export default Ecosystem;