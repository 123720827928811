import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./postswidget.css";
import postsData from "../../data/postsData";
import { ArrowUpRightIcon, ClockIcon, HashtagIcon } from "@heroicons/react/24/outline";
// import loader from './../../assets/images/loader.gif'

const PostsWidget = ({ filter, currentPost, firstPosts }) => {
  console.log(firstPosts);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(filter.filter || null);
  const [visiblePosts, setVisiblePosts] = useState(3);
  const [isLoading, setIsLoading] = useState(true);
  const [shuffledPosts, setShuffledPosts] = useState([]);

  useEffect(() => {
    // Shuffle posts when the component mounts or when selectedCategory changes
    shufflePosts();
  }, [selectedCategory]);

  const shufflePosts = () => {
    const shuffled = [...postsData].sort(() => Math.random() - 0.5);
    setShuffledPosts(shuffled);
  };

  const handlePostClick = (postLink) => {
    navigate(`/${postLink}`);
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const filteredPosts = selectedCategory
    ? shuffledPosts.filter((post) => post.category === selectedCategory && post.id !== currentPost)
    : shuffledPosts.filter((post) => post.id !== currentPost);

  let postsToShow = [...filteredPosts];

  if (firstPosts && firstPosts.length > 0) {
    const firstPostsObj = firstPosts
      .map((id) => shuffledPosts.find((post) => post.id === id))
      .filter((post) => post); // Удаляем undefined значения, если пост не найден
    const remainingPosts = filteredPosts.filter((post) => !firstPosts.includes(post.id));
    postsToShow = [...firstPostsObj, ...remainingPosts].slice(0, visiblePosts);
  } else {
    postsToShow = filteredPosts.slice(0, visiblePosts);
  }

  return (
    <>
      <div id="blog" className="container">
        <div>
          <h2 className="text-white font-medium text-[29px] mt-12 md:mt-32 mb-6">
            You may like:
          </h2>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-10">
          {postsToShow.map((post) => (
            <div
              className="flex flex-col gap-4 md:gap-0 cursor-pointer"
              key={post.id}
              onClick={() =>
                handlePostClick(post.title.replace(/ /g, "-")
                  .replace(/:/g, "") // Удаляем двоеточия
                  .replace(/[-–—]+/g, "-") // Заменяем последовательности тире на одно тире
                  .replace(/[^a-zA-Z0-9- ]/g, "") // Удаляем специальные символы, оставляя только буквы, цифры, тире и пробелы
                  .replace(/ /g, "-")
                  .toLowerCase())
              }
            >
              <img
                className="w-full object-cover h-[200px] rounded-xl"
                src={post.image}
                alt={post.image}
                onLoad={handleImageLoad}
                loading="lazy"
              />
              <div className="flex flex-col gap-2 text-white p-4">
                <div className="flex gap-3">
                  <p className="bg-white flex text-[12px] text-black p-[4px] rounded-lg">
                    <HashtagIcon className="h-4 w-4 text-red" />
                    {post.category.replace(/ /g, "").toLowerCase()}
                  </p>
                  <p className="flex items-center gap-1 text-[12px]">
                    <ClockIcon className="h-4 w-4" />
                    {post.date}
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <h4 className="text-[18px] font-semibold">{post.title}</h4>
                  <p className="text-[#DCDCDC] text-[12px]">
                    {post.minidesckr}
                  </p>
                  <a
                    className="font-bold text-[12px] flex items-center gap-1"
                    href=""
                    onClick={() =>
                      handlePostClick(post.title.replace(/ /g, "-").toLowerCase())
                    }>
                    Read more
                    <ArrowUpRightIcon className="h-4 w-4" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PostsWidget;