
import React from 'react';
import { useRef, useState, useCallback } from 'react';
import { PaperClipIcon } from '@heroicons/react/24/outline';
// import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom";


function Career() {

    // const [token, setToken] = useState("");
    // const site_key = '6LcrcsQpAAAAAPbSXQaw1Q68ugp4ECEdR9dVesij'
    // const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    // const setTokenFunc = (getToken) => {
    //     setToken(getToken);
    //     console.log('Captcha true');
    // };

    let close = useRef(null)
    const location = useLocation();
    
    const [error, setError] = useState(false);
    const [fileName, setFileName] = useState("");
    const fileInputRef = useRef(null);

    function CloseCareerModal() {
        close.current.classList.add('hidden')
    }

    let username = useRef(null);
    let email = useRef(null);
    let message = useRef(null);

    const [isChecked, setIsChecked] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleFileChange = useCallback((event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        }
    }, []); // Ensure that the dependency array is empty if not using any external variables


    const handleFileUploadClick = () => {
        fileInputRef.current.click();
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const usernameValue = username.current.value;
        const emailValue = email.current.value;
        const messageValue = message.current.value;

        if (!usernameValue || !emailValue || !messageValue) {
            console.error('Error: One or more fields are empty');
            setError(true);
            return;
        } else {
            try {
                const formData = new FormData(); // Создание объекта FormData для отправки данных формы

                formData.append("name", username.current.value);
                formData.append("mail", email.current.value);
                formData.append("description", message.current.value);
                formData.append("resume", fileInputRef.current.files[0]);
                formData.append("url",  `https://ehoss.com${location.pathname}`); // Добавление файла в FormData

                const response = await fetch('https://form.ehoss.com/summary', {
                    method: 'POST',
                    mode: 'cors',
                    body: formData
                });

                if (response.ok) {
                    setIsSent(true);
                    username.current.value = "";
                    email.current.value = "";
                    message.current.value = "";
                    setFileName(""); // Очистка имени файла после успешной отправки

                    setError(false);
                } else {
                    // Обработка ошибок при отправке
                    // setRefreshReCaptcha(!refreshReCaptcha);

                    console.error('Failed to send data');
                }
            } catch (error) {
                console.error('Failed to send data:', error);
            }
        }
    };

    // function onSubmit(token) {
    //     document.getElementById("career-form").submit();
    // }


    return (
        <>
            <div ref={close} id="career-modal" tabIndex="-1" aria-hidden="true" className="hidden bg-[#000000] z-[9999] bg-opacity-40 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 h-[100vh] max-h-full">
                <div className="relative p-4 w-full max-w-md max-h-full">

                    <div className="relative bg-gray rounded-lg shadow dark:bg-gray-700">

                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">

                                Submit your application
                            </h3>
                            <button onClick={CloseCareerModal} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="white" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4 md:p-5">
                            <form id="career-form" className="space-y-4" action="#">

                                <div>
                                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your name</label>
                                    <input ref={username} type="name" name="name" id="name" className="bg-gray border border-gray-300 text-white  text-sm rounded-lg focus:red focus:border-red block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                                </div>
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                    <input ref={email} type="email" name="email" id="email" className="bg-gray border border-gray-300 text-white  text-sm rounded-lg focus:red focus:border-red block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                                </div>
                                <div onClick={handleFileUploadClick} id='file-upload' className='hover:border-red cursor-pointer rounded-lg flex justify-center items-center gap-2 p-2.5 text-white text-center border-dashed border border-white'>
                                    <PaperClipIcon className='w-6 h-6' />


                                    <input ref={fileInputRef} type="file" file="file" id="file" className="hidden" onChange={handleFileChange} required />
                                    {/* Отображение имени файла, если файл выбран */}
                                    {fileName ? (
                                        <div className="flex items-center">
                                            <span>{fileName}</span>
                                            <button type="button" onClick={() => setFileName("")} className="ml-2 text-xs font-semibold underline text-red">Change</button>
                                        </div>
                                    ) :
                                        <div>
                                            <p>Attach a file with your resume</p>
                                            <p className='text-[11px] opacity-70 font-medium'>.doc, .docx, .txt, .rtf, .odt, .pdf</p>
                                        </div>
                                    }
                                </div>
                                <div>
                                    <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
                                    <textarea ref={message} type="text" name="text" id="text" className="bg-gray border h-24 border-gray-300 text-white text-sm rounded-lg focus:red focus:border-red block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500  dark:placeholder-gray-400 dark:text-white" required />
                                </div>


                                {/* <div className="flex justify-between">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required />
                                        </div>
                                        <label htmlFor="remember" className="ms-2 text-sm font-medium text-white">Remember me</label>
                                    </div>
                                    <a href="#" className="text-sm text-white hover:underline">Lost Password?</a>
                                </div> */}

                                <button
                                    onClick={handleSubmit}
                                    type="submit"
                                    className={`g-recaptcha w-full text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${!isChecked ? 'opacity-30' : ''} ${isSent ? 'bg-[#1B391A] pointer-events-none text-white' : 'bg-white'}`}
                                    disabled={!isChecked}
                                >
                                    {isSent ? 'Sent!' : 'Send'}
                                </button>
                                <p className={`text-red text-[14px] ${error ? 'block' : 'hidden'}`}>Please fill in the required fields</p>
                                <div className="flex justify-between">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input
                                                id="remember"
                                                type="checkbox"
                                                value=""
                                                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                                required
                                                onChange={handleCheckboxChange} // Обработчик изменения галочки
                                            />
                                        </div>
                                        <label htmlFor="remember" className="ms-2 text-[12px] font-medium text-white">
                                            I agree to the terms of the <a className="font-bold underline" href="/privacy-policy">Privacy Policy</a> and allow the processing of my personal data on legal grounds.
                                        </label>
                                    </div>
                                </div>
                               
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Career;