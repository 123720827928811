import LineDot from "../../components/line-dot/LineDot";
import Footer from "../../container/footer/Footer";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { Helmet } from "react-helmet";
import { useEffect, useState } from 'react';
import { MapPinIcon } from '@heroicons/react/24/outline';

function Careers() {
    const [jobs, setJobs] = useState([]);

    function openCareerModal() {
        const modal = document.getElementById('career-modal');
        modal.classList.remove('hidden');
        modal.classList.add('flex');
    }

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = async () => {
        const response = await fetch('https://form.ehoss.com/api/vacancies');
        const data = await response.json();
        setJobs(data);
        console.log(data);
    };


    return (
        <>
            <Helmet>
                <title>EHOSS | Careers</title>
            </Helmet>
            <section className="overflow-hidden">
                <div className='z-[-10] md:z-10 fixed left-0 w-3 h-full'>
                    <LineDot />
                </div>
                <div className='container'>
                    <div className='privacy md:w-1/3 md:h-[100vh]  pt-24 text-white'>
                        <div className='flex flex-col  items-start gap-4'>
                            <Breadcrumbs
                                items={[
                                    { label: 'Company', url: '#' },
                                    { label: 'People', url: '#' },
                                    { label: 'Careers', url: '/careers' }
                                ]}
                            />
                            <h2 className='text-[36px] mt-12 font-bold'>
                                Careers at EHOSS
                            </h2>
                            <p className='text-[14px]'>
                                Join our team, even if your position is not mentioned on our vacancy list.
                                <br /><br />
                                We are constantly looking for talented people with a passion for action and innovation in the equestrian industry.
                            </p>
                            <div className="grid md:grid-cols-1 gap-4 mt-6">
                                {/* <div onClick={openCareerModal} className='bg-gray p-4 rounded-xl flex flex-col cursor-pointer gap-2 border border-gray hover:border-red'>
                                    <p className='font-semibold text-[18px] uppercase'>microelectronics programmer</p>
                                    <p className=' opacity-50 text-[14px]'>permanent employment</p>
                                    <a className=' underline flex gap-2 items-center font-semibold mt-6' target='_blank'>
                                        Submit your application
                                    </a>
                                </div>
                                <div onClick={openCareerModal} className='bg-gray p-4 rounded-xl flex flex-col cursor-pointer gap-2 border border-gray hover:border-red'>
                                    <p className='font-semibold text-[18px] uppercase'>equine veterinarian</p>
                                    <p className=' opacity-50 text-[14px]'>permanent employment, Slovakia</p>
                                    <a className=' underline flex gap-2 items-center font-semibold mt-6' target='_blank'>
                                        Submit your application
                                    </a>
                                </div>
                                <div onClick={openCareerModal} className='bg-gray p-4 rounded-xl flex flex-col cursor-pointer gap-2 border border-gray hover:border-red'>
                                    <p className='font-semibold text-[18px] uppercase'>engineer</p>
                                    <p className=' opacity-50 text-[14px]'>permanent employment, Slovakia</p>
                                    <a className=' underline flex gap-2 items-center font-semibold mt-6' target='_blank'>
                                        Submit your application
                                    </a>
                                </div> */}
                                {jobs.length > 0 ? 
                                (jobs.map((job) => (


                                    <div id={'job.id'} key={job.id} className='bg-gray p-4 rounded-xl flex flex-col cursor-pointer gap-2 border border-gray'>
                                        <div className="flex justify-between gap-6">
                                            <p className='font-semibold text-[18px] uppercase'>{job.title}</p>
                                            <p className='flex opacity-30 text-[12px]'>
                                                {job.posted_date}
                                            </p>
                                        </div>
                                        <p className='flex text-white gap-2'>
                                            <MapPinIcon className='w-4 text-red' />
                                            {job.location}, permanent employment
                                        </p>
                                        {/* <p className=' opacity-50 text-[14px]'></p> */}

                                        <div className="flex justify-between items-center mt-6">
                                            <a onClick={openCareerModal} className='main-btn text-black' target='_blank'>
                                                Send CV
                                            </a>
                                            <a href={`/careers/vacancy/${job._id}`} className='underline flex gap-2 items-center font-semibold'>
                                                More
                                            </a>
                                        </div>

                                    </div>


                                ))) : (
                                    <p className='text-center text-gray-500'>No job vacancies available :(</p>
                                )}

                            </div>
                        </div>
                    </div>
                    {/* <img className='w-2/3 hidden md:block absolute right-[-400px] opacity-30 bottom-0' src={schemas} /> */}
                </div>


            </section >
            <Footer />
        </>

    );
}

export default Careers;
