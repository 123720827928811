import React from 'react';
import LineDot from '../../components/line-dot/LineDot';
import Footer from '../../container/footer/Footer';
import { EnvelopeIcon, MapIcon } from '@heroicons/react/24/outline';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { Helmet } from "react-helmet";

import './contacts.css';

import soc1 from './../../container/footer/social/Crunchbase.svg'
import soc2 from './../../container/footer/social/academicons_researchgate-square.svg'
import soc3 from './../../container/footer/social/basil_instagram-solid.svg'
import soc4 from './../../container/footer/social/ic_outline-telegram.svg'
import soc5 from './../../container/footer/social/icon-park-solid_twitter.svg'
import soc6 from './../../container/footer/social/mdi_linkedin.svg'
import soc7 from './../../container/footer/social/mingcute_youtube-fill.svg'
import soc8 from './../../container/footer/social/ph_tiktok-logo-fill.svg'
import soc9 from './../../container/footer/social/ri_facebook-box-fill.svg'

function Contacts() {

    return (
        <>
            <Helmet>
                <title>EHOSS | Contact Information</title>
            </Helmet>
            <section>
                <div className='z-[-10] fixed left-0 w-3 h-full'>
                    <LineDot />
                </div>
                <div className='container'>
                    <div className=''>
                        <div className='text-white md:w-1/2 pt-24 mb-12 z-20 md:h-[100vh]'>
                            <Breadcrumbs
                                items={[
                                    { label: 'Company', url: '#' },
                                    { label: 'Contacts', url: '/contacts' },
                                ]}
                            />
                            <h2 className="font-semibold mt-12 text-2xl md:text-4xl">
                                Contact Information
                            </h2>
                            <div>
                                <div className='mt-6'>
                                    <p className='mb-4 text-[14px]'>
                                        EHOSS s.r.o.
                                        ‌<br />
                                        Legal address: Vajnorska 10595/98B, Bratislava, 831 04, Slovakia  ‌<br />
                                        ID/ICO 51115492  ‌<br />
                                        TAX ID/ DIC DPH SK2120619776
                                    </p>
                                    <a target='_blank' className='flex gap-2 items-center text-white hover:text-red' href='https://www.google.com/maps/place/EHOSS/@48.0840707,16.9166227,74214m/data=!3m1!1e3!4m15!1m8!3m7!1s0x476c8fcbd80be01d:0xbb86eab89d6c9ea!2sEHOSS!8m2!3d48.1217267!4d17.0911317!10e1!16s%2Fg%2F11shf0qhvg!3m5!1s0x476c8fcbd80be01d:0xbb86eab89d6c9ea!8m2!3d48.1217267!4d17.0911317!16s%2Fg%2F11shf0qhvg?entry=ttu'>
                                        <div>
                                            <MapIcon className='h-6 w-6 text-red' />
                                        </div>
                                        <p className='text-[14px]'>
                                            areal Hydronika Nova, Hala M1, EHOSS, Gogolova 326/18, 851 01 Bratislava Petrzalka, Slovakia
                                        </p>
                                    </a>
                                    <a className='flex gap-2 items-center text-white hover:text-red' href='mailto:welcome@ehoss.com'>
                                        <div>
                                            <EnvelopeIcon className='h-6 w-6 text-red' />
                                        </div>
                                        <p className='text-[14px]'>
                                            welcome@ehoss.com
                                        </p>
                                    </a>
                                </div>
                                <div className='mt-6 flex flex-col items-start'>
                                    <p className='font-semibold'>
                                        Our products are available throughout the EU and Great Britain. You can place an order on our website in the ‘Platforms’ section:
                                    </p>
                                    <a className='main-btn mt-4 w-auto text-black' href='/ecosystem'>
                                        Go to ecosystem
                                    </a>
                                </div>
                                <div className='mt-6'>
                                    <p className='font-semibold'>
                                        If you still have questions, please write to us by mail or on social networks.
                                    </p>
                                    <div className='flex md:order-3 mt-12 gap-1 items-center'>
                                        <a href='https://www.crunchbase.com/organization/ehoss' target='_blank'>
                                            <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc1}></img>
                                        </a>
                                        <a href='https://www.researchgate.net/publication/361401127_EHOSS_Trailer_Platform_was_implemented_by_EHOSS_to_impact_horse_stress' target='_blank'>
                                            <img className='w-[26px] transition hover:scale-110 h-[26px] object-contain' src={soc2}></img>
                                        </a>
                                        <a href='https://www.instagram.com/ehossofficial/' target='_blank'>
                                            <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc3}></img>
                                        </a>
                                        <a href='https://t.me/ehossofficial' target='_blank'>
                                            <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc4}></img>
                                        </a>
                                        <a href='https://twitter.com/ehossofficial/' target='_blank'>
                                            <img className='w-[26px] transition hover:scale-110 h-[26px] object-contain' src={soc5}></img>
                                        </a>
                                        <a href='https://www.linkedin.com/company/ehoss/' target='_blank'>
                                            <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc6}></img>
                                        </a>
                                        <a href='https://www.youtube.com/@ehoss' target='_blank'>
                                            <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc7}></img>
                                        </a>
                                        <a href='https://www.tiktok.com/@ehossofficial' target='_blank'>
                                            <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc8}></img>
                                        </a>
                                        <a href='https://www.facebook.com/ehossofficial/' target='_blank'>
                                            <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc9}></img>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='map md:absolute md:w-1/2 right-0 top-0 h-full'>
                            <iframe className='' src="https://snazzymaps.com/embed/587502" width="100%" height="100%"></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Contacts;