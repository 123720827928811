import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import img1 from './1.png';
import img2 from './2.png';
import img3 from './3.png';
import LineDot from '../../components/line-dot/LineDot';
import Line from '../../components/line/Line';
import Footer from '../../container/footer/Footer';
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './forageplatform.css'

import icon1 from './icons/icon1.svg'
import icon2 from './icons/icon2.svg'
import icon3 from './icons/icon3.svg'

function ForagePlatfrom() {

  const [slidesPerView, setSlidesPerView] = useState(calculateSlidesPerView());
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setSlidesPerView(calculateSlidesPerView());
  };

  function calculateSlidesPerView() {
    if (window.innerWidth < 768) {
      return 1;
    } else if (window.innerWidth < 1280) {
      return 3;
    } else {
      return 4;
    }
  }

  const location = useLocation();

  function openRequestModal() {
    const modal = document.getElementById('request-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }

  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(180);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const intervalDuration = 35;
  const totalImages = 330;
  const isAnimatingRef = useRef(false);

  const loadImages = useCallback(async () => {

    const slideFolder = window.innerWidth < 830 ? 'slides830' : 'slides1920';

    const loadedImages = await Promise.all(
      Array.from({ length: totalImages }, (_, index) => {
        const paddedIndex = index.toString().padStart(4, '0');
        return `./${slideFolder}/${paddedIndex}.jpg`;
      }).map(async (src, index) => {
        const img = new Image();
        img.src = src;

        await new Promise((resolve) => {
          img.onload = () => {
            const percentage = ((index + 1) / totalImages) * 100;
            // Проверяем, что значение процентов больше или равно текущему значению
            if (percentage >= loadingPercentage) {
              setLoadingPercentage(percentage);
            }
            resolve();
          };
        });

        return img;
      })
    );

    setLoadingPercentage(100);
    return loadedImages;
  }, [totalImages]);

  useEffect(() => {
    const fetchData = async () => {
      const loadedImages = await loadImages();
      setImages(loadedImages);
    };

    fetchData();
  }, [loadImages]);

  const moveToSlide = useCallback(
    (targetIndex) => {
      if (isAnimatingRef.current) {
        return;
      }

      isAnimatingRef.current = true;

      const start = currentIndex;
      const step = targetIndex > start ? 1 : -1;

      // Создаем массив индексов, пропуская значения от 100 до 200
      const indexesToAnimate = [];
      for (let i = start; step > 0 ? i <= targetIndex : i >= targetIndex; i += step) {
        if (i < 30 || i > 140) { // Пропускаем кадры с 100 до 200
          indexesToAnimate.push(i);
        }
      }

      animateIndexes(indexesToAnimate);
    },
    [currentIndex]
  );

  const animateIndexes = useCallback(
    (indexesToAnimate) => {
      let currentIndexIndex = 0;
      const intervalId = setInterval(() => {
        const currentIndex = indexesToAnimate[currentIndexIndex];
        setCurrentIndex(currentIndex);

        if (currentIndex === indexesToAnimate[indexesToAnimate.length - 1]) {
          clearInterval(intervalId);
          isAnimatingRef.current = false;
        } else {
          currentIndexIndex += 1;
        }
      }, intervalDuration);
    },
    [intervalDuration]
  );

  moveToSlide(295);

  return (
    <>
      <ReactFullpage
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={1000}
        navigation
        anchors={
          [
            '',
            'for-whole-bale',
            'for-clean-hay',
            'benefits',
            'for-horses-installation',
            'footer'
          ]
        }
        render={({ state, fullpageApi }) => {
          return (
            <div>
              <div className='fixed w-full h-full z-[-10]'>
                {images.length > 0 &&
                  <img
                    className='absolute inset-0 w-full h-[100vh] object-cover'
                    src={images[currentIndex].src}
                    alt={`Slide ${currentIndex + 1}`}
                  />
                }
              </div>

              <ReactFullpage.Wrapper>
                <Helmet>
                  <title>EHOSS | Clean hay from dust and disinfected from bacteria</title>
                  <meta name="description" content="Prepare hay bale for horse consumption in several stages: chopp hay, cleaning from dust and other impurities with steam, moistening and disinfecting hay." />
                  <meta name="keywords" content="equine hay steamer, hay streamer, horse hay quality, chopped hay for horses, chopped hay, chopped hay feeder, no waste hay feeder, dust free hay for horses, save space in the stable" />
                  <meta name="robots" content="index, follow" />
                  <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
                </Helmet>
                <div className='section'>
                  <div className='z-[-10] fixed left-0 w-3 h-full'>
                    <LineDot />
                  </div>

                  <div className='container w-full items-center'>

                    <div className='h-[100vh] flex flex-col pt-24 w-full'>

                      <Breadcrumbs
                        items={[
                          { label: 'Ecosystem', url: '/ecosystem' },
                          { label: 'Animal Care', url: '/ecosystem' },
                          { label: 'Forage Plarform', url: '/forage-platform' },
                        ]}
                      />
                      <div className='flex w-ful h-full'>
                        <div className='flex max-w-[500px] flex-col justify-end w-full'>
                          <div className='mb-6'>
                            <h1 className='text-white uppercase mt-12 font-medium md:text-[38px]'>
                              FORAGE PLATFORM
                            </h1>
                            <p className='text-white mt-4'>
                              Clean hay from dust and disinfected from bacteria
                            </p>
                          </div>
                          <div className='hidden md:flex md:flex-row md:mt-16 flex-col md:w-auto w-full md:justify-start justify-center gap-4 mb-16'>
                            <button onClick={openRequestModal} className='main-btn text-start whitespace-nowrap cursor-pointer'>
                              Leave a request
                            </button>
                            <a onClick={() => fullpageApi.moveTo(2)} className='main-btn text-start whitespace-nowrap two cursor-pointer'>
                              learn more
                            </a>
                          </div>
                        </div>
                      </div>


                    </div>
                    {/* <div className='w-full md:pt-0 flex justify-end pt-20 order-1 md:order-2'>
                      <img className='w-full max-w-3xl' src={img1}></img>
                    </div> */}
                  </div>
                </div>


                <div className='section'>
                  <div className='z-[-10] fixed left-0 w-3 h-full'>
                    <LineDot />
                  </div>
                  <div className='container w-full items-center'>
                    <div className='h-[100vh] flex flex-col pt-24 w-full'>
                      <div className='flex w-full'>
                        <div className='flex max-w-[500px] flex-col justify-start w-full'>

                          {/* 
                            <h1 className='text-white mt-12 font-medium text-[24px] md:text-[38px]'>
                              FORAGE PLATFORM
                            </h1>
                             */}
                          <div className='text-white mt-12 mb-12'>
                            <h3 className='font-medium'>
                              With the help of the platform equipment, the hay goes through the following stages of preparation:
                            </h3>
                            <ul className='flex list flex-col gap-3 mt-6'>
                              <li className='text-[14px]'>
                                Raw hay in bales is loaded into the equipment and chopped into 7-12 cm pieces. (It is also possible to change the settings and choose the preferable length.)
                              </li>
                              <li className='text-[14px]'>
                                The hay is cleaned from dust and other impurities with steam, and moistened. The moisture level can be adjusted individually.
                              </li>
                              <li className='text-[14px]'>
                                Harmful microorganisms, such as bacteria, viruses, mold, fungi, yeast, spores etc., are neutralized by a bactericidal lamp.
                              </li>
                            </ul>
                          </div>

                          <div className=' flex md:flex-row md:mt-16 flex-col md:w-auto w-full md:justify-start justify-center gap-4 mb-16'>
                            <button onClick={openRequestModal} className='main-btn text-start whitespace-nowrap cursor-pointer'>
                              Leave a request
                            </button>
                            <a onClick={() => fullpageApi.moveTo(3)} className='main-btn text-start whitespace-nowrap two cursor-pointer'>
                              learn more
                            </a>
                          </div>



                        </div>
                      </div>


                    </div>
                    <div className='absolute w-full h-full top-0 right-0 z-[-100]'>
                      <img className='w-full h-full object-cover' src={img3}></img>
                    </div>
                  </div>
                </div>



                <div className='section'>
                  <Line />
                  <div className='container flex flex-wrap-reverse md:flex-nowrap flex-col md:flex-row items-center'>
                    <div className='pb-20 md:order-1 order-2 md:pt-40 flex flex-col items-start justify-between h-[100vh]'>
                      <div className='max-w-[500px] pt-10'>
                        <div className='text-white mt-12 mb-12'>
                          <h3 className='font-medium text-[22px]'>
                            Designed to prepare hay for the whole stable:
                          </h3>
                          <div className='md:mt-0 mt-12 mb-12 md:mb-0 md:absolute w-[100%] md:top-0 md:h-[100vh] right-0 left-0 -z-10'>
                            <img className='md:w-[100%] md:h-[100vh] md:object-cover' src={img2}></img>
                          </div>
                          <ul className='flex list flex-col gap-3 mt-6'>
                            <li className='text-[14px]'>
                              Equipment is located in a place in the stables that is accessible for a transport to deliver large hay bales
                            </li>
                            <li className='text-[14px]'>
                              Bales of different sizes (up to 1,8 metres) and different shapes (round or rectangular) can be loaded into the equipment
                            </li>
                            <li className='text-[14px]'>
                              Bales are loaded into equipment that prepares hay for consumption for several animals at once
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='flex md:flex-row flex-col md:w-auto w-full justify-center gap-4 mb-16'>
                        <button onClick={openRequestModal} className='main-btn text-start whitespace-nowrap cursor-pointer'>
                          Leave a request
                        </button>
                        <a onClick={() => fullpageApi.moveTo(3)} className='main-btn text-start whitespace-nowrap two cursor-pointer'>
                          learn more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <section id="reusing-section" className="overflow-hidden section">
                  <Line />
                  <div className="container relative h-[100vh]">

                    <div className="pt-24">
                      <div className="flex md:flex-row justify-between items-center mb-4 md:mb-10">
                        <h2 className="text-[32px] font-semibold text-white">
                          Forage Platform helps with:
                        </h2>
                        {/* <div className='md:flex hidden z-20 items-center justify-center gap-4 mt-2'>
                          <div className={`custom-l cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                            <ArrowLeftIcon />
                          </div>
                          <div className={`custom-r cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                            <ArrowRightIcon />
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className='w-full hidden md:block pb-12 cursor-grab relative slider-shadow'>
                      <Swiper
                        modules={[Navigation]}
                        spaceBetween={50}
                        slidesPerView={slidesPerView}
                        className={`sw1 ${isBeginning ? '' : 'sw2'}`}
                        mousewheel={{ enabled: false }}
                        navigation={{
                          nextEl: '.custom-r',
                          prevEl: '.custom-l'
                        }}
                        onSlideChange={({ isBeginning, isEnd }) => {
                          setIsBeginning(isBeginning);
                          setIsEnd(isEnd);
                        }}
                        onSwiper={(swiper) => console.log(swiper)}
                      >
                        <SwiperSlide>
                          <div className=' text-white text-[14px] pointer-events-none'>
                            <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                              Quality
                            </h3>
                            <div id='start-line' className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>
                            <p className='text-[14px]'>
                              Reduces dust levels and amount of harmful microorganisms (mold, bacteria, etc.) due to steam treatment and bactericidal lamp.
                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>
                            <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                              Moisture level control
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>
                            <p className='text-[14px]'>
                              During steam treatment, hay is saturated with moisture. The platform allows for adjustment of the moisture level depending on the condition of hay (a higher level of moisture saturation is could be set for excessively dry hay).
                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>

                            <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                              Irrational use
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>

                            <p className='text-[14px]'>
                              It is easier for horses to chew chopped hay and to take it from a feeder or net. If the stems are chopped they don’t clung to one another, so hay doesn’t wind up on the ground. The amount of hay that is wasted is significantly reduced and savings reach up to 20% of the total volume of the bale.
                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>

                            <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                              Keeping cleaner
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4"></div>

                            <p className='text-[14px]'>
                              1) Unprocessed hay can often be dusty and this dust can be spread around the stall as the horse pulls out a portion of hay from the feeder or net.
                              2) Unchopped hay does not fit well in the horses jaws, so it falls on the ground and pollutes the stall.
                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>

                            <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                              Optimising the work of the stable staff
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>
                            <p className='text-[14px]'>
                              The equipment replaces and complements the processes done by the Stud Hands. For example, the platform distributes hay saving up to 2 hours a day on splitting the bale.
                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>

                            <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                              Hay quality and condition analysis
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>

                            <p className='text-[14px]'>
                              The platform collects data on processed hay. This makes it possible to analyse the initial condition of hay, how it has changed and what could potentially lead to it.
                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>

                            <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                              Indirect reduction of costs for veterinary treatment
                            </h3>


                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>
                            <p className='text-[14px]'>
                              The hay quality improves after steam and a bactericidal lamp treatment
                              Use of chopped hay has a positive impact on the horse's digestive system
                            </p>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                      <div className='flex z-20 items-center justify-center gap-4 mt-12'>
                        <div className={`custom-l cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                          <ArrowLeftIcon />
                        </div>
                        <div className={`custom-r cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                          <ArrowRightIcon />
                        </div>
                      </div>
                    </div>

                    <div className='w-full flex flex-col gap-16 md:hidden cursor-grab relative slider-shadow'>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                          Quality
                        </h3>
                        <p className='text-[14px]'>
                          Reduces dust levels and amount of harmful microorganisms (mold, bacteria, etc.) due to steam treatment and bactericidal lamp.
                        </p>
                      </div>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                          Moisture level control
                        </h3>
                        <p className='text-[14px]'>
                          During steam treatment, hay is saturated with moisture. The platform allows for adjustment of the moisture level depending on the condition of hay (a higher level of moisture saturation is could be set for excessively dry hay).
                        </p>
                      </div>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                          Irrational use
                        </h3>


                        <p className='text-[14px]'>
                          It is easier for horses to chew chopped hay and to take it from a feeder or net. If the stems are chopped they don’t clung to one another, so hay doesn’t wind up on the ground. The amount of hay that is wasted is significantly reduced and savings reach up to 20% of the total volume of the bale.
                        </p>
                      </div>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                          Keeping cleaner
                        </h3>
                        <p className='text-[14px]'>
                          1) Unprocessed hay can often be dusty and this dust can be spread around the stall as the horse pulls out a portion of hay from the feeder or net.
                          2) Unchopped hay does not fit well in the horses jaws, so it falls on the ground and pollutes the stall.
                        </p>
                      </div>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                          Optimising the work of the stable staff
                        </h3>
                        <p className='text-[14px]'>
                          The equipment replaces and complements the processes done by the Stud Hands. For example, the platform distributes hay saving up to 2 hours a day on splitting the bale.
                        </p>
                      </div>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                          Hay quality and condition analysis
                        </h3>
                        <p className='text-[14px]'>
                          The platform collects data on processed hay. This makes it possible to analyse the initial condition of hay, how it has changed and what could potentially lead to it.
                        </p>
                      </div>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[18px] mt-6'>
                          Indirect reduction of costs for veterinary treatment
                        </h3>
                        <p className='text-[14px]'>
                          The hay quality improves after steam and a bactericidal lamp treatment
                          Use of chopped hay has a positive impact on the horse's digestive system
                        </p>
                      </div>
                    </div>
                  </div>
                </section>



                <div className='section'>
                  <Line />
                  <div className='container h-[100vh] '>
                    <div className='pt-32 grid  md:gap-8 gap-16 md:grid-cols-3 text-white'>
                      <div>
                        <div className='mb-4 text-white p-2 w-12 h-12 rounded-full bg-lgray'>
                          <img src={icon1} />
                        </div>
                        <h3 className='font-semibold text-[22px] mb-2'>
                          Easy to maintain equipment
                        </h3>
                        <p className='mb-2'>
                          The equipment is designed the way it is easy to clean:
                        </p>
                        <ul className='list'>
                          <li>
                            the compartments where products are stored - from residues and dirt
                          </li>
                          <li>
                            the processing compartments - from the dirt that falls there after the cleaning process
                          </li>
                          <li>
                            packing /serving compartments - from product residues
                          </li>
                        </ul>


                      </div>
                      <div>
                        <div className='mb-4 flex justify-center items-center text-white p-2 w-12 h-12 rounded-full bg-lgray'>
                          <img src={icon2} />
                        </div>
                        <h3 className='font-semibold text-[22px] mb-2'>
                          Installation:
                        </h3>
                        <p>
                          Installation of the equipment is carried out by the EHOSS specialists or our regional partners. If the needed specialists are at your stable, we will consult you and help to install the equipment in a remote format.
                        </p>
                        <br />
                        <p>
                          To install the platform, you need a power connection, access to water and tools to install our products.
                        </p>
                      </div>
                      <div>
                        <div className='mb-4 flex justify-center items-center text-white p-2 w-12 h-12 rounded-full bg-lgray'>
                          <img src={icon3} />
                        </div>
                        <h3 className='font-semibold text-[22px] mb-2'>
                          Data analysis
                        </h3>
                        <p>
                          All the elements of the feed platform collect and process data. The owner can access it using an interactive monitor that is placed directly in the room where the equipment is installed, or via a mobile application/ personal account.
                        </p>
                        <br />
                        <p>
                          Forage Platform can work independently or in conjunction with other platforms of the EHOSS ecosystem.
                        </p>
                      </div>
                    </div>
                    <div className='md:mt-32 mt-16 pb-16'>
                      <div className='flex justify-center gap-4'>
                        <button onClick={openRequestModal} className='main-btn text-start whitespace-nowrap cursor-pointer'>
                          Leave a request
                        </button>
                        {/* <a href='#' className='main-btn two'>
                          learn more
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </ReactFullpage.Wrapper>
            </div>
          );
        }}
      />
    </>

  );
}

export default ForagePlatfrom;