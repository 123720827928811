// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horse {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 500px;
    animation: horseMove 1s;
    transition: 0.3s;
}

@keyframes horseMove {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}`, "",{"version":3,"sources":["webpack://./src/admin/login/adminLogin.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI;QACI,2BAA2B;IAC/B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".horse {\r\n    position: absolute;\r\n    right: 0;\r\n    bottom: 0;\r\n    width: 500px;\r\n    animation: horseMove 1s;\r\n    transition: 0.3s;\r\n}\r\n\r\n@keyframes horseMove {\r\n    0% {\r\n        transform: translateX(100%);\r\n    }\r\n    100% {\r\n        transform: translateX(0%);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
