import LineDot from "../../components/line-dot/LineDot";
import Footer from "../../container/footer/Footer";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import logo from './../../assets/images//logo.svg'
import histiry_one from './../../assets/images/about/history.png'
import histiry_two from './../../assets/images/about/history2.png'
import { Helmet } from "react-helmet";

import poster1 from './poster1.png'
import poster2 from './poster2.png'
import poster3 from './poster4.png'
import poster4 from './poster3.png'

import demo from './demostand.png'

import 'swiper/css';
import 'swiper/css/navigation';
import './about.css'

function About() {
    const [isHovered, setIsHovered] = useState(false);
    const [slidesPerView, setSlidesPerView] = useState(calculateSlidesPerView());
    const [isEnd, setIsEnd] = useState(false);
    const [isBeginning, setIsBeginning] = useState(true);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        setSlidesPerView(calculateSlidesPerView());
    };

    function calculateSlidesPerView() {
        if (window.innerWidth < 768) {
            return 1;
        } else if (window.innerWidth < 1280) {
            return 2.2;
        } else {
            return 3.2;
        }
    }

    function openCooperationModal() {
        const modal = document.getElementById('cooperation-modal');
        modal.classList.remove('hidden');
        modal.classList.add('flex');
    }

    return (
        <>
            <Helmet>
                <title>EHOSS | About copmany</title>
            </Helmet>
            <section className="overflow-hidden">
                <div className="fixed h-[100vh] w-24 z-[-9999]">
                    <LineDot />
                </div>
                <div className="container">
                    <div className="mt-24">
                        <Breadcrumbs
                            items={[
                                { label: 'Company', url: '#' },
                                { label: 'EHOSS', url: '#' },
                                { label: 'About', url: '/about' }
                            ]}
                        />
                        <div className="flex md:flex-row justify-between items-center mb-4 mt-8 md:mb-10">
                            <h2 className="text-[32px] font-semibold text-white">
                                About copmany
                            </h2>

                        </div>

                        {/* <div
                            className="flex gap-2 text-white items-center mt-4 md:mb-0 mb-4 md:mt-16 cursor-pointer"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <p className="text-[13px]">Scroll right</p>
                            <ArrowRightIcon className={`w-5 h-5 transition-transform duration-300 ${isHovered ? 'transform translate-x-2' : ''}`} />
                        </div> */}
                    </div>

                    <div className='w-full hidden md:block cursor-grab relative slider-shadow'>
                        <Swiper
                            modules={[Navigation, Mousewheel]}
                            spaceBetween={50}
                            slidesPerView={slidesPerView}
                            className={`${isBeginning ? '' : 'sw2'} ${isEnd ? '' : 'sw1'}`}
                            mousewheel={{ enabled: false }}
                            navigation={{
                                nextEl: '.custom-r',
                                prevEl: '.custom-l'
                            }}
                            onSlideChange={({ isBeginning, isEnd }) => {
                                setIsBeginning(isBeginning);
                                setIsEnd(isEnd);
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            <SwiperSlide>
                                <div className=' text-white  '>
                                    <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                                        <img className="w-[170px]" src={logo} alt="Main EHOSS logo" />
                                    </div>
                                    <h3 className='font-bold mb-2 text-[22px] mt-6'>
                                        Our name
                                    </h3>
                                    <div id='start-line' className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                                    </div>
                                    <p className='text-[14px]'>
                                        Our name, EHOSS, isn't an acronym, it's a reflection of our gentle attitude towards horses combined with the latest achievements of humankind.
                                        <br /> <br />
                                        "E" stands for ecology and technology, and “HOSS” is another way to say "horse" or address a friend. We believe in a compassionate approach to working with horses and use technology to enhance their well-being and performance.
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className=' text-white  '>
                                    <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                        <img className="object-cover w-full h-full" src={histiry_one} alt="Main EHOSS logo" />
                                    </div>
                                    <h3 className='font-bold mb-2 text-[22px] mt-6'>
                                        Our history
                                    </h3>
                                    <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                                    </div>
                                    <p className='text-[14px]'>
                                        The EHOSS company was established in Ukraine in December of 2021, with the primary objective of improving the living conditions of horses and simplifying the process of equine care.
                                        <br /> <br />

                                        We built a team, established an enterprise, set up a laboratory, and constructed a stable for experimental implementation three months before the full-scale war broke out in Ukraine. When active hostilities started we were forced to relocate to the neighboring country, Slovakia in May 2022.

                                        <br /> <br />
                                        In the following months we created a team, established a production facility and laboratory in Bratislava. We also got a purebred Andalusian horse from Spain for our EHOSS project, who now lives in our experimenta l stable near the capital of Slovakia.

                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className=' text-white  '>
                                    <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                        <img className="w-full" src={histiry_two} alt="Main EHOSS logo" />
                                    </div>
                                    <h3 className='font-bold mb-2 text-[22px] mt-6'>
                                        Our history 2022
                                    </h3>
                                    <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                                    </div>
                                    <p className='text-[14px]'>
                                        By the end of 2022, our team installed the first prototype of the Monitoring Platform within the stable. This system monitors the environment the horse lives in and tracks all the events. In 2022, the EHOSS project was acknowledged as one of the most innovative projects in the agricultural sector in Slovakia. In May of the same year, we presented our product prototypes at the Danube Equestrian Festival.
                                        <br /> <br />
                                        A few months later, the project obtained its first customer from a reputable stable in Austria, who expressed interest in piloting the implementation of all EHOSS platforms.
                                        <br /> <br />
                                        Stay updated with the latest developments of the EHOSS project by following our Blog and Social media.
                                        <br /> <br />
                                        To be continued.
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className=' text-white  '>
                                    <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                        <video poster={poster1} controls className="w-full h-full object-cover" src="https://ehoss.com/videos/video-about.mp4" alt="Main EHOSS logo" />
                                    </div>

                                    <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                                        Founder
                                    </h3>
                                    <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                                    </div>
                                    <p className='text-[14px]'>
                                        "For thousands of years, horses have been loyal companions to humans, aiding us in various aspects of life. Without their contributions, the advancements we have achieved as a society may not have been possible. It is now our turn to express our gratitude to these magnificent animals.
                                        <br /> <br />
                                        I have been dreaming for years of making a positive impact on the lives of horses across the globe. What started as a childhood fantasy has turned into real solutions.
                                        <br /> <br />
                                        During the most difficult time for my country, Ukraine, dreams came true in the EHOSS project."
                                        <br /> <br />
                                        Viktor Kvachan, Founder of EHOSS
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className=' text-white  '>
                                    <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                        <video poster={poster2} controls className="w-full h-full object-cover" src="https://ehoss.com/videos/product.mov" alt="Main EHOSS logo" />
                                    </div>


                                    <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                                        Product
                                    </h3>
                                    <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                                    </div>
                                    <p className='text-[14px]'>
                                        We conducted a thorough analysis of the global equestrian industry, studying everything from equine food products to the working conditions of their caregivers. Using this research as a foundation, we understood which fields of the industry can benefit from modernisation through the implementation of engineering solutions and technologies. Based on the results of this analysis we created innovative engineering and technological platforms. Our platforms can be divided into two categories: one designed for professionals, and another for those for whom horses are more of a hobby.
                                        <br /> <br />
                                        Our platforms aim to positively impact the horse's overall well-being, including their health and psychological state. Additionally, our products will systematise and automate the processes involved in caring for horses, benefitting stable staff, owners, and the horses themselves.
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className=' text-white  '>
                                    <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                        <video poster={poster3} controls className="w-full h-full object-cover" src="https://ehoss.com/videos/procuctions-eng.cbc801c1c60ce66435cb.mp4" alt="Main EHOSS logo" />
                                    </div>
                                    <h3 className='font-bold mb-2  text-[22px] mt-6'>
                                        Production
                                    </h3>
                                    <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                                    </div>
                                    <p className='text-[14px]'>
                                        EHOSS has opted to create its own production facility. Today, it is an area of more than a thousand square metres, where we have placed modern modelling and product manufacturing equipment.
                                        <br /> <br />
                                        In addition to that we set up a laboratory, a design and prototyping department, and a research stable. In total, we have invested more than one million euros in the project since May 2022.
                                        <br /> <br />
                                        Our vision of the future is constantly evolving alongside the future itself, and we are proud to introduce horse IoT innovation. We are always pushing ourselves to new heights and have ambitious plans for the future. We look forward to continuing our work and making further advancements. See you soon!
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className=' text-white  '>
                                    <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                        <img className="object-cover w-full h-full" src={demo} alt="Main EHOSS logo" />
                                    </div>


                                    <h3 className='font-bold mb-2 text-[22px] mt-6'>
                                        Demonstration stable
                                    </h3>
                                    <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                                    </div>
                                    <p className='text-[14px]'>
                                        At EHOSS, we test all our products at our stable, what allows us to conduct in-depth research and analysis of the impact of our technologies on both horses and human working conditions in real-world settings.
                                        <br /> <br />
                                        In addition to our own stable, we work with professional athletes and stables who own dozens of horses, and who are the first to test the efficacy of our products. Therefore, if you are a professional athlete or the owner of a modern stable, please do not hesitate to contact us for special terms of use of our products. Further details can be found in the <button onClick={openCooperationModal} className="underline font-medium">Cooperation Requests</button> section.
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className=' text-white  '>
                                    <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                        <video poster={poster4} controls className="w-full h-full object-cover" src="https://ehoss.com/videos/linkedin.mp4" alt="Main EHOSS logo" />
                                    </div>

                                    <h3 className='font-bold mb-2 text-[22px] mt-6'>
                                        Product versions for trailers
                                    </h3>
                                    <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                                    </div>
                                    <p className='text-[14px]'>
                                        In today's world, horses frequently participate in
                                        sports events that may take place in various cities
                                        and countries. That necessitates their transportation
                                        from one location to another, sometimes for multiple
                                        times each month. These journeys, which can last more
                                        than ten hours, can be extremely stressful for the
                                        animals. To create products that facilitate stress-free
                                        transportation <a target="_blank" href="https://www.researchgate.net/publication/361401127_EHOSS_Trailer_Platform_was_implemented_by_EHOSS_to_impact_horse_stress" className="font-medium underline">we conduct research on the conditions
                                            of horse travel</a>, and adapt our products to meet the
                                        specific needs of horses during transportation, ensuring
                                        that they are comfortable and secure throughout their
                                        journey.
                                    </p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        <div className='flex mt-4 z-20 items-center justify-center gap-4 mt-2'>
                            <div className={`custom-l cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                                <ArrowLeftIcon />
                            </div>
                            <div className={`custom-r cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                                <ArrowRightIcon />
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex flex-col gap-16 md:hidden cursor-grab relative slider-shadow'>
                        <div className=' text-white bg-gray p-4 rounded-xl'>
                            <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                                <img className="w-[170px]" src={logo} alt="Main EHOSS logo" />
                            </div>
                            <h3 className='font-bold mb-2 text-[22px] mt-6'>
                                Our name
                            </h3>
                            <p className='text-[14px]'>
                                Our name, EHOSS, isn't an acronym, it's a reflection of our gentle attitude towards horses combined with the latest achievements of humankind.
                                <br /> <br />
                                "E" stands for ecology and technology, and “HOSS” is another way to say "horse" or address a friend. We believe in a compassionate approach to working with horses and use technology to enhance their well-being and performance.
                            </p>
                        </div>
                        <div className=' text-white bg-gray p-4 rounded-xl'>
                            <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                <img className="object-cover w-full h-full" src={histiry_one} alt="Main EHOSS logo" />
                            </div>
                            <h3 className='font-bold mb-2 text-[22px] mt-6'>
                                Our history
                            </h3>
                            <p className='text-[14px]'>
                                The EHOSS company was established in Ukraine in December of 2021, with the primary objective of improving the living conditions of horses and simplifying the process of equine care.
                                <br /> <br />

                                We built a team, established an enterprise, set up a laboratory, and constructed a stable for experimental implementation three months before the full-scale war broke out in Ukraine. When active hostilities started we were forced to relocate to the neighboring country, Slovakia in May 2022.

                                <br /> <br />
                                In the following months we created a team, established a production facility and laboratory in Bratislava. We also got a purebred Andalusian horse from Spain for our EHOSS project, who now lives in our experimenta l stable near the capital of Slovakia.

                            </p>
                        </div>
                        <div className=' text-white bg-gray p-4 rounded-xl'>
                            <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                <img className="w-full" src={histiry_two} alt="Main EHOSS logo" />
                            </div>
                            <h3 className='font-bold mb-2 text-[22px] mt-6'>
                                Our history 2022
                            </h3>
          
                            <p className='text-[14px]'>
                                By the end of 2022, our team installed the first prototype of the Monitoring Platform within the stable. This system monitors the environment the horse lives in and tracks all the events. In 2022, the EHOSS project was acknowledged as one of the most innovative projects in the agricultural sector in Slovakia. In May of the same year, we presented our product prototypes at the Danube Equestrian Festival.
                                <br /> <br />
                                A few months later, the project obtained its first customer from a reputable stable in Austria, who expressed interest in piloting the implementation of all EHOSS platforms.
                                <br /> <br />
                                Stay updated with the latest developments of the EHOSS project by following our Blog and Social media.
                                <br /> <br />
                                To be continued.
                            </p>
                        </div>
                        <div className=' text-white bg-gray p-4 rounded-xl'>
                            <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                <video poster={poster1} controls className="w-full h-full object-cover" src="https://ehoss.com/videos/video-about.mp4" alt="Main EHOSS logo" />
                            </div>

                            <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                                Founder
                            </h3>
                            <p className='text-[14px]'>
                                "For thousands of years, horses have been loyal companions to humans, aiding us in various aspects of life. Without their contributions, the advancements we have achieved as a society may not have been possible. It is now our turn to express our gratitude to these magnificent animals.
                                <br /> <br />
                                I have been dreaming for years of making a positive impact on the lives of horses across the globe. What started as a childhood fantasy has turned into real solutions.
                                <br /> <br />
                                During the most difficult time for my country, Ukraine, dreams came true in the EHOSS project."
                                <br /> <br />
                                Viktor Kvachan, Founder of EHOSS
                            </p>
                        </div>
                        <div className=' text-white bg-gray p-4 rounded-xl'>
                            <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                <video poster={poster2} controls className="w-full h-full object-cover" src="https://ehoss.com/videos/product.mov" alt="Main EHOSS logo" />
                            </div>


                            <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                                Product
                            </h3>
                            <p className='text-[14px]'>
                                We conducted a thorough analysis of the global equestrian industry, studying everything from equine food products to the working conditions of their caregivers. Using this research as a foundation, we understood which fields of the industry can benefit from modernisation through the implementation of engineering solutions and technologies. Based on the results of this analysis we created innovative engineering and technological platforms. Our platforms can be divided into two categories: one designed for professionals, and another for those for whom horses are more of a hobby.
                                <br /> <br />
                                Our platforms aim to positively impact the horse's overall well-being, including their health and psychological state. Additionally, our products will systematise and automate the processes involved in caring for horses, benefitting stable staff, owners, and the horses themselves.
                            </p>
                        </div>
                        <div className=' text-white bg-gray p-4 rounded-xl'>
                            <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                <video poster={poster3} controls className="w-full h-full object-cover" src="https://ehoss.com/videos/procuctions-eng.cbc801c1c60ce66435cb.mp4" alt="Main EHOSS logo" />
                            </div>
                            <h3 className='font-bold mb-2  text-[22px] mt-6'>
                                Production
                            </h3>
                            <p className='text-[14px]'>
                                EHOSS has opted to create its own production facility. Today, it is an area of more than a thousand square metres, where we have placed modern modelling and product manufacturing equipment.
                                <br /> <br />
                                In addition to that we set up a laboratory, a design and prototyping department, and a research stable. In total, we have invested more than one million euros in the project since May 2022.
                                <br /> <br />
                                Our vision of the future is constantly evolving alongside the future itself, and we are proud to introduce horse IoT innovation. We are always pushing ourselves to new heights and have ambitious plans for the future. We look forward to continuing our work and making further advancements. See you soon!
                            </p>
                        </div>
                        <div className=' text-white bg-gray p-4 rounded-xl'>
                            <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                <img className="object-cover w-full h-full" src={demo} alt="Main EHOSS logo" />
                            </div>
                            <h3 className='font-bold mb-2 text-[22px] mt-6'>
                                Demonstration stable
                            </h3>
                            <p className='text-[14px]'>
                                At EHOSS, we test all our products at our stable, what allows us to conduct in-depth research and analysis of the impact of our technologies on both horses and human working conditions in real-world settings.
                                <br /> <br />
                                In addition to our own stable, we work with professional athletes and stables who own dozens of horses, and who are the first to test the efficacy of our products. Therefore, if you are a professional athlete or the owner of a modern stable, please do not hesitate to contact us for special terms of use of our products. Further details can be found in the <button onClick={openCooperationModal} className="underline font-medium">Cooperation Requests</button> section.
                            </p>
                        </div>
                        <div className=' text-white bg-gray p-4 rounded-xl'>
                            <div className="bg-gray overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                                <video poster={poster4} controls className="w-full h-full object-cover" src="https://ehoss.com/videos/linkedin.mp4" alt="Main EHOSS logo" />
                            </div>
                            <h3 className='font-bold mb-2 text-[22px] mt-6'>
                                Product versions for trailers
                            </h3>
                            <p className='text-[14px]'>
                                In today's world, horses frequently participate in
                                sports events that may take place in various cities
                                and countries. That necessitates their transportation
                                from one location to another, sometimes for multiple
                                times each month. These journeys, which can last more
                                than ten hours, can be extremely stressful for the
                                animals. To create products that facilitate stress-free
                                transportation <a target="_blank" href="https://www.researchgate.net/publication/361401127_EHOSS_Trailer_Platform_was_implemented_by_EHOSS_to_impact_horse_stress" className="font-medium underline">we conduct research on the conditions
                                    of horse travel</a>, and adapt our products to meet the
                                specific needs of horses during transportation, ensuring
                                that they are comfortable and secure throughout their
                                journey.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    );
}

export default About;
