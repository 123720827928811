import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import slide1 from './images/gif_1.png'
import slide2 from './images/gif_2.png'
import slide3 from './images/gif_3.png'
import slide4 from './images/gif_4.png'
import slide5 from './images/gif_5.png'

import LineDot from '../../components/line-dot/LineDot';
import Line from '../../components/line/Line';
import Footer from '../../container/footer/Footer';
import './modularstable.css'

import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


function ModularStable() {

  const location = useLocation();

  function openRequestModal() {
    const modal = document.getElementById('request-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }

  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [slide1, slide2, slide3, slide4, slide5];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % slides.length);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function openOrderModal() {
    const modal = document.getElementById('order-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }

  return (
    <>
      <ReactFullpage
        // Опции FullPage.js
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={1000}
        navigation
        anchors={
          [
            'modular-stables-for-horses',
            'modular-temporary-stables-for-horses',
            'footer'
          ]
        }
        render={({ state, fullpageApi }) => {
          return (
            <div>
              <ReactFullpage.Wrapper>
                <Helmet>
                  <title>EHOSS | Modular Stable</title>
                  <meta name="robots" content="index, follow" />
                  <meta name="keywords" content="portable horse stalls, modular horse stalls, Portable Horse Shelter" />
                  <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
                </Helmet>
                <div className='section'>
                  <LineDot />
                  <div className='container flex flex-wrap-reverse md:flex-nowrap flex-col md:flex-row items-center'>
                    <div className='md:order-1 order-2 pt-24 flex flex-col items-start justify-between gap-8 h-[100vh]'>
                      <div>
                        <Breadcrumbs
                          items={[
                            { label: 'Ecosystem', url: '/ecosystem' },
                            { label: 'Animal Care', url: '/ecosystem' },
                            { label: 'Modular Stable', url: '/modular-stable' },
                          ]}
                        />
                      </div>
                      <div className='flex flex-col items-start gap-10'>

                        <div>
                          <h1 className='text-white font-medium text-[38px] uppercase'>
                            MODULAR STABLES
                          </h1>
                          <p className='text-white'>
                            Transportable, prefab & mobile
                          </p>
                        </div>
                        <div className='flex md:flex-row flex-col md:w-auto w-full justify-center gap-4 pb-16'>
                          <button onClick={openOrderModal} className='main-btn text-start whitespace-nowrap cursor-pointer'>
                            Buy
                          </button>
                          <a onClick={() => fullpageApi.moveTo(2)} className='main-btn text-start whitespace-nowrap two cursor-pointer'>
                            learn more
                          </a>
                        </div>
                      </div>

                      <div className='mb-0 absolute w-[100%] top-0 h-[100vh] right-0 left-0 -z-20'>
                        <img alt='Modular stable for horses' title='Temporary Portable Short-Term Modular Stable for horses' className='w-[100%] rounded-none h-[100vh] object-cover' src='https://static.ehoss.com/assets/images/modular-stable/modularstableforhorses.jpg'></img>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='section'>
                  <Line />
                  <div className='container gap-10 flex flex-wrap-reverse md:flex-nowrap flex-col md:flex-row items-center justify-between'>
                    <div className='md:order-1 md:w-1/2 order-2 md:pt-40 flex flex-col items-start justify-between md:h-[100vh]'>
                      <div className=''>
                        <div className='text-white mt-12 mb-12'>
                          <h3 className='font-medium'>
                            Key features:
                          </h3>
                          <ul className='flex list flex-col gap-3 mt-2 mb-4'>
                            <li className='text-[14px]'>
                              High quality materials and robust construction
                            </li>
                            <li className='text-[14px]'>
                              Quick assembly compact modular stables for any events and places
                            </li>
                            <li className='text-[14px]'>
                              Packed in one compact container
                            </li>
                            <li className='text-[14px]'>
                              The install takes up to 30 minutes for one modular stable
                            </li>
                            <li className='text-[14px]'>
                              An unlimited number of modular stables can be installed together
                            </li>
                          </ul>

                          <h3 className='font-medium mt-4'>
                            Can be used:
                          </h3>
                          <ul className='flex list flex-col gap-3 mt-2'>
                            <li className='text-[14px]'>
                              For temporarily horse keeping on the road (in case of necessary stops on the road, you can stop anywhere where there is enough place to install a modular stable)
                            </li>
                            <li className='text-[14px]'>
                              As a resting place for a horse at exhibitions, competitions and other event
                            </li>
                            <li className='text-[14px]'>
                              For stables expansion
                            </li>
                          </ul>
                          <p className='mt-4'>
                            Delivery cost and time are calculated individually taking into consideration the customer's geographical location.
                          </p>
                        </div>
                      </div>
                      <div className='flex md:flex-row flex-col w-full justify-start gap-4 pb-16'>
                        <button onClick={openOrderModal} className='main-btn text-start whitespace-nowrap cursor-pointer'>
                          Buy
                        </button>
                      </div>
                    </div>
                    <div className='md:order-2 md:w-1/2 order-1 mt-24'>
                      <img className='rounded-xl shadow-xl' src={slides[currentSlide]} alt={`Slide ${currentSlide + 1}`} ></img>
                    </div>
                  </div>
                </div>
                <Footer />
              </ReactFullpage.Wrapper>
            </div>
          );
        }}
      />
    </>

  );
}

export default ModularStable;
