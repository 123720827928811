import ReactFullpage from '@fullpage/react-fullpage';
import LineDot from '../../../components/line-dot/LineDot';
import Footer from '../../../container/footer/Footer';
import './equestrianfacilities.css'
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

import Line from '../../../components/line/Line';
import card1 from './../../audience/images/bg2.png'
import card2 from './../../audience/images/bg3.png'
import card3 from './../../audience/images/bg4.png'
import card4 from './../../audience/images/bg5.png'
import card5 from './../../audience/images/bg6.png'
import card7 from './../../audience/images/bg1.png'

function EquestrianFacilities() {
    const location = useLocation();
    return (
        <>
            <ReactFullpage
                licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
                scrollingSpeed={1000}
                navigation
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <Helmet>
                                <title>EHOSS | Equestrian Facilities</title>
                                <meta name="robots" content="index, follow" />
                                <meta name="keywords" content="Equine welfare, Horse Welfare, Equine Health Plan, feeding analysis software, equine management nutrition health & exercise" />
                                <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
                            </Helmet>
                            <section className='section audience3'>
                                <LineDot />
                                <div className='container'>
                                    <div className='pt-24 flex md:flex-row  flex-col gap-8 h-[100vh]'>
                                        <div className=' text-white '>
                                            <div className='flex flex-col items-start gap-4'>
                                                <Breadcrumbs
                                                    items={[
                                                        { label: 'Who Needs?', url: '/' },
                                                        { label: 'For Animal Owners', url: '/audience' },
                                                        { label: 'Equestrian Facilities', url: '/equestrian-facilities' },
                                                    ]}
                                                />
                                                <h2 className='text-[36px] mt-32 font-medium'>
                                                    FOR THE EQUESTRIAN FACILITIES OWNERS
                                                </h2>
                                                <div className='grid md:grid-cols-2 gap-10'>
                                                    <div>
                                                        <p className='mt-6 font-medium mb-4'>EHOSS Platforms can help the owners of the equestrian facilities with:</p>
                                                        <ul className='list flex flex-col gap-4 *:text-[14px] mb-6'>
                                                            <li>
                                                                <strong>Purity: </strong>
                                                                1) Storing products inside, in the equipment, prevents pests and other animals from accessing them; 2) preparation of products for consumption and packaging portions in separate containers inside the equipment reduces spillage of products; 3) the premises are disinfected with a bactericidal lamp.
                                                            </li>
                                                            <li>
                                                                <strong>Optimise work of the stable staff: </strong>
                                                                the platform significantly saves time the Stud Hands spend on preparing hay for consumption (the bales just have to be loaded into the equipment), and on preparing concentrated feed (products are pre-loaded into tanks, the platform forms individual portions using a program that can be controlled from a computer or phone)
                                                            </li>
                                                            <li>
                                                                <strong>Save money: </strong>
                                                                up to 20% of money is saved by reducing hay wasting
                                                            </li>
                                                            <li>
                                                                <strong>Improve the quality of nutrition: </strong>
                                                                improve hay quality by purifying, disinfecting, cutting and moisturising it according to the parameters set.
                                                            </li>
                                                            <li>
                                                                <strong>Increase the level of care: </strong>
                                                                1) the monitoring system monitors the parameters inside and outside the stable; 2) the feed distribution process becomes less time consuming due to automatic processing / preparation and dividing into portions
                                                            </li>
                                                            <li>
                                                                <strong>Analysis: </strong>
                                                                collecting data over a period of time helps to identify potential risks (if the hay quality changes it may be a sign of improper storage or of the problems on the suppliers side)
                                                            </li>

                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <p className='mt-6 font-medium mb-4'>Advantages:</p>
                                                        <ul className='list flex flex-col gap-4 *:text-[14px] mb-6'>
                                                            <li>
                                                                Bales of different shapes can be easily loaded into the equipment.
                                                            </li>
                                                            <li>
                                                                Improved sanitation and cleanliness.
                                                            </li>
                                                            <li>
                                                                Monitor temperature, humidity, and air quality inside
                                                            </li>
                                                            <li>
                                                                The equipment is easy to use and maintain
                                                            </li>
                                                            <li>
                                                                The possibility to supplement / expand the platform system due to the modularity of the equipment
                                                            </li>
                                                            <li>
                                                                Convenient access to any part of the EHOSS ecosystem through a personal account or application.
                                                            </li>
                                                            <li>
                                                                Integration with the facility's CCTV monitoring system.
                                                            </li>
                                                        </ul>
                                                        <p className='mt-6 font-medium mb-4'>
                                                            The EHOSS Platforms ecosystem is an IoT solution. The platforms simplify and automate routine processes, enhance security and control, and enable you to analyse the collected data using your phone or computer. The platforms provide solutions for both single stalls and entire stables.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className='section overflow-hidden'>
                <Line />
                <div className='container flex gap-6 flex-col md:flex-row md:items-center md:justify-between'>
                  <div className=' w-full md:h-[100vh] pt-24 text-white'>
                    <div>
                      <h2 className='text-[36px] font-medium uppercase'>
                        more about who needs
                      </h2>
                    </div>
                    <div className='w-full mt-12'>
                      <div className='grid gap-2 grid-cols-2 md:grid-cols-6 w-full'>
                        {/* =========== card 1 ============= */}
                        <a href='/farmers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>Farmers</h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card7} />
                        </a>
                        {/* =========== card 2 ============= */}
                        <a href='/horse-owners' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Horse Owners
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card1} />
                        </a>
                        {/* =========== card 3 ============= */}
                        <a href='/equestrian-facilities' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Equestrian Facilities
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card2} />
                        </a>
                        {/* =========== card 4 ============= */}
                        <a href='/equine-producers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Equine Product Producers
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card3} />
                        </a>
                        {/* =========== card 5 ============= */}
                        <a href='/veterinary' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] mt-4 font-semibold'>
                              Veterinary
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card4} />
                        </a>
                        {/* =========== card 6 ============= */}
                        <a href='/equestrians' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] mt-4 font-semibold'>
                              Equestrians
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card5} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                            <Footer />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </>

    );
}

export default EquestrianFacilities;