// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../audience/images/bg3.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audience3 {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat:  no-repeat;
    background-size: cover;
    z-index: -2;
}

.audience3::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Задайте здесь желаемый цвет и уровень прозрачности */
    z-index: -1;
}

.audience3 .cont-for-red-l {
    z-index: 2 !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/who-needs/equestrian-facilities/equestrianfacilities.css"],"names":[],"mappings":"AAAA;IACI,yDAAsD;IACtD,6BAA6B;IAC7B,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,uDAAuD;IAC7F,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".audience3 {\r\n    background-image: url(./../../audience/images/bg3.png);\r\n    background-repeat:  no-repeat;\r\n    background-size: cover;\r\n    z-index: -2;\r\n}\r\n\r\n.audience3::after {\r\n    content: \"\";\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background-color: rgba(0, 0, 0, 0.5); /* Задайте здесь желаемый цвет и уровень прозрачности */\r\n    z-index: -1;\r\n}\r\n\r\n.audience3 .cont-for-red-l {\r\n    z-index: 2 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
