// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.post-content a{
  text-decoration: underline;
  color:white;
  font-weight: 600;
}

#post img {
  width: 100% !important;
}

#post blockquote {
  background-color: #2A2A2A;
  padding: 20px;
  border-left: 2px solid white;
}`, "",{"version":3,"sources":["webpack://./src/blog/blog.css"],"names":[],"mappings":";AACA;EACE,0BAA0B;EAC1B,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,4BAA4B;AAC9B","sourcesContent":["\r\n.post-content a{\r\n  text-decoration: underline;\r\n  color:white;\r\n  font-weight: 600;\r\n}\r\n\r\n#post img {\r\n  width: 100% !important;\r\n}\r\n\r\n#post blockquote {\r\n  background-color: #2A2A2A;\r\n  padding: 20px;\r\n  border-left: 2px solid white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
