import React, { useState } from 'react';
import './shareButtons.css'
import { useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";


import share from './../../container/footer/social/circum_share.svg'
import fs from './../../container/footer/social/ri_facebook-box-fill.svg'
import tw from './../../container/footer/social/icon-park-solid_twitter.svg'
import link from './../../container/footer/social/mdi_linkedin.svg'
import ws from './../../container/footer/social/ws.svg'

const ShareButtons = () => {
    const location = useLocation();
    const url = window.location.origin + location.pathname;
  
    const [copied, setCopied] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const handleCopy = () => {
      navigator.clipboard.writeText(url)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000); // Сбросить статус копирования через 3 секунды
          setTimeout(() => setIsActive(false), 3000); // Сбросить статус активности через 3 секунды

        })
        .catch(error => {
          console.error("Failed to copy link: ", error);
        });
    };

  return (
    <div>
      <div className="share-wrapper">
        <div style={{marginRight: '20px'}}>
            <img className={`share-icon copy-link ${isActive ? "active" : ""}`} src={share} onClick={handleCopy} alt=''/>
        </div>
        <FacebookShareButton url={url}>
            <img className="share-icon" src={fs} alt=''/>
        </FacebookShareButton>
        <TwitterShareButton url={url}>
            <img className="share-icon" src={tw} alt=''/>
        </TwitterShareButton>
        <LinkedinShareButton url={url}>
            <img className="share-icon" src={link} alt=''/>
        </LinkedinShareButton>
        <WhatsappShareButton url={url}>
            <img className="share-icon" src={ws} alt=''/>
        </WhatsappShareButton>
      </div>
      {copied && (
        <div className={`done ${isActive ? "active" : ""}`}>
          <p>
            Link copied to clipboard!
          </p>
        </div>
      )}
    </div>
  );
};

export default ShareButtons;