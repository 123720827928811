import post7slide1 from './../assets/images/post7/slider/1.jpg'
import post7slide2 from './../assets/images/post7/slider/2.jpg'
import post7slide3 from './../assets/images/post7/slider/3.jpg'
import post7slide4 from './../assets/images/post7/slider/4.jpg'

import post3slide1 from './../assets/images/post3/slider/EHOSS Alessandra Reich equestrian jumping show.jpg'
import post3slide2 from './../assets/images/post3/slider/EHOSS Alisa Danilova Bratislava.jpg'
import post3slide3 from './../assets/images/post3/slider/EHOSS Alisa Danilova Danube Festival.jpg'
import post3slide4 from './../assets/images/post3/slider/EHOSS Alisa Danilova Ukraine.jpg'
import post3slide5 from './../assets/images/post3/slider/EHOSS Alisa Danilova equestrian jumping show.jpg'
import post3slide6 from './../assets/images/post3/slider/EHOSS Alisa Danilova equestrian.jpg'
import post3slide7 from './../assets/images/post3/slider/EHOSS Alisa Danilova jumping show Slovakia.jpg'
import post3slide8 from './../assets/images/post3/slider/EHOSS Alisa Danilova jumping show.jpg'
import post3slide9 from './../assets/images/post3/slider/EHOSS Bronislav Chudyba.jpg'
import post3slide10 from './../assets/images/post3/slider/EHOSS Commandant Geoff Curran Danube Festival.jpg'
import post3slide11 from './../assets/images/post3/slider/EHOSS Commandant Geoff Curran equestrian jumping show.jpg'
import post3slide12 from './../assets/images/post3/slider/EHOSS Commandant Geoff Curran equestrian.jpg'
import post3slide13 from './../assets/images/post3/slider/EHOSS Commandant Geoff Curran jumping show.jpg'
import post3slide14 from './../assets/images/post3/slider/EHOSS Daniel Ouzky equestrian jumping show.jpg'
import post3slide15 from './../assets/images/post3/slider/EHOSS Daniel Ouzky equestrian.jpg'
import post3slide16 from './../assets/images/post3/slider/EHOSS Daniel Ouzky jumping show Bratislava.jpg'
import post3slide17 from './../assets/images/post3/slider/EHOSS Daniel Ouzky jumping show.jpg'
import post3slide18 from './../assets/images/post3/slider/EHOSS Dominika Droppova jumping show.jpg'
import post3slide19 from './../assets/images/post3/slider/EHOSS Emma Augier De Moussac equestrian.jpg'
import post3slide20 from './../assets/images/post3/slider/EHOSS Emma Augier De Moussac jumping show.jpg'
import post3slide21 from './../assets/images/post3/slider/EHOSS Harry Allen equestrian jumping show.jpg'
import post3slide22 from './../assets/images/post3/slider/EHOSS Harry Allen equestrian.jpg'
import post3slide23 from './../assets/images/post3/slider/EHOSS Harry Allen jumping show.jpg'
import post3slide24 from './../assets/images/post3/slider/EHOSS Katharina Rhomberg Danube Festival.jpg'
import post3slide25 from './../assets/images/post3/slider/EHOSS Katharina Rhomberg equestrian jumping show Bratislava.jpg'
import post3slide26 from './../assets/images/post3/slider/EHOSS Katharina Rhomberg equestrian jumping show.jpg'
import post3slide27 from './../assets/images/post3/slider/EHOSS Katharina Rhomberg equestrian.jpg'
import post3slide28 from './../assets/images/post3/slider/EHOSS Katharina Rhomberg jumping show.jpg'
import post3slide29 from './../assets/images/post3/slider/EHOSS Lena Binder equestrian.jpg'
import post3slide30 from './../assets/images/post3/slider/EHOSS Lena Binder jumping show.jpg'
import post3slide31 from './../assets/images/post3/slider/EHOSS Longines EEF Series jumping show Bratislava Slovakia.jpg'
import post3slide32 from './../assets/images/post3/slider/EHOSS Longines EEF Series jumping show Bratislava.jpg'
import post3slide33 from './../assets/images/post3/slider/EHOSS Longines EEF Series jumping show.jpg'
import post3slide34 from './../assets/images/post3/slider/EHOSS Marek Klus equestrian jumping show.jpg'
import post3slide35 from './../assets/images/post3/slider/EHOSS Marek Klus jumping show.jpg'
import post3slide36 from './../assets/images/post3/slider/EHOSS Marianne Schindele equestrian jumping show.jpg'
import post3slide37 from './../assets/images/post3/slider/EHOSS Marie Sebesta equestrian jumping show.jpg'
import post3slide38 from './../assets/images/post3/slider/EHOSS Marvin Jüngel equestrian jumping show.jpg'
import post3slide39 from './../assets/images/post3/slider/EHOSS Marvin Jüngel jumping show.jpg'
import post3slide40 from './../assets/images/post3/slider/EHOSS Mohamed El Naggar equestrian jumping show.jpg'
import post3slide41 from './../assets/images/post3/slider/EHOSS Mohamed El Naggar equestrian.jpg'
import post3slide42 from './../assets/images/post3/slider/EHOSS Mohamed El Naggar jumping show.jpg'
import post3slide43 from './../assets/images/post3/slider/EHOSS Patricia Kardun equestrian.jpg'
import post3slide44 from './../assets/images/post3/slider/EHOSS Peter Mackovjak equestrian jumping show.jpg'
import post3slide45 from './../assets/images/post3/slider/EHOSS Peter Mackovjak jumping show.jpg'
import post3slide46 from './../assets/images/post3/slider/EHOSS Shady Samir jumping show.jpg'
import post3slide47 from './../assets/images/post3/slider/EHOSS Tamás Dömse equestrian jumping show.jpg'
import post3slide48 from './../assets/images/post3/slider/EHOSS Tamás Dömse equestrian.jpg'
import post3slide49 from './../assets/images/post3/slider/EHOSS Tamás Dömse jumping show.jpg'
import post3slide50 from './../assets/images/post3/slider/EHOSS at equestrian jumping show(1).jpg'
import post3slide51 from './../assets/images/post3/slider/EHOSS at equestrian jumping show.jpg'
import post3slide52 from './../assets/images/post3/slider/EHOSS at equestrian show Bratislava.jpg'
import post3slide53 from './../assets/images/post3/slider/EHOSS equestrian Danube Festival Slovakia.jpg'
import post3slide54 from './../assets/images/post3/slider/EHOSS equestrian Danube Festival.jpg'
import post3slide55 from './../assets/images/post3/slider/EHOSS equestrian jumping show Danube Festival.jpg'
import post3slide56 from './../assets/images/post3/slider/EHOSS equestrian show Slovakia.jpg'
import post3slide57 from './../assets/images/post3/slider/EHOSS jumping show Bratislava Slovakia.jpg'
import post3slide58 from './../assets/images/post3/slider/EHOSS jumping show Bratislava.jpg'

import post9slide1 from './../assets/images/post9/slider/CHE_9800F.jpg'
import post9slide2 from './../assets/images/post9/slider/CHE_9778F.jpg'
import post9slide3 from './../assets/images/post9/slider/CHE_9784F.jpg'
import post9slide4 from './../assets/images/post9/slider/CHE_9741F.jpg'
import post9slide5 from './../assets/images/post9/slider/CHE_9774F.jpg'
import post9slide6 from './../assets/images/post9/slider/CHE_9767f.jpg'
import post9slide7 from './../assets/images/post9/slider/CHE_9804F.jpg'

import post9slide8 from './../assets/images/post9/slider/CHE_9703F.jpg'
import post9slide9 from './../assets/images/post9/slider/CHE_9697F.jpg'

import post17slide1 from './../assets/images/post17/CAT_5_EHOSS_Slovakia_pages-to-jpg-0001.jpg'


export const slider17 = [
    {
        'path': post17slide1,
        'alt': '',
        'figcaption': "",
    },
]

export const slider16 = [
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2383.jpg',
        'alt': '',
        'figcaption': "",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2385.jpg',
        'alt': '',
        'figcaption': "",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2386.jpg',
        'alt': '',
        'figcaption': "",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2387.jpg',
        'alt': '',
        'figcaption': "",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2388.jpg',
        'alt': '',
        'figcaption': "",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2389.jpg',
        'alt': '',
        'figcaption': "",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2391.jpg',
        'alt': '',
        'figcaption': "",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2392.jpg',
        'alt': '',
        'figcaption': "",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2393.jpg',
        'alt': '',
        'figcaption': "",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2396.jpg',
        'alt': '',
        'figcaption': "",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2397.jpg',
        'alt': '',
        'figcaption': "",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2401.jpg',
        'alt': '',
        'figcaption': "",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post16/slider/small_IMG_2404.jpg',
        'alt': '',
        'figcaption': "",
    }
]

export const slider14 = [
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/1small_EHOS6002_LR.jpg',
        'alt': 'people speaking on a booth',
        'figcaption': "EHOSS' booth at Reflect Festival",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/2small_EHOS6016_LR.jpg',
        'alt': 'people speaking on an event',
        'figcaption': 'Viktor Kvachan, Alexander Lipski and Marko Malak',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/3small_EHOS6377_LR.jpg',
        'alt': 'a girl making pitching on a stage',
        'figcaption': "EHOSS' pitch at Reflect Festival",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/4small_EHOS6446_LR.jpg',
        'alt': "judges of startups' pitchings on stage",
        'figcaption': "judges of startups' pitchings on stage",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6467_LR.jpg',
        'alt': "judge of startups' pitchings on stage",
        'figcaption': 'Karel Zheng (Investor, Kaya VC)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6458_LR.jpg',
        'alt': "judges of startups' pitchings on stage",
        'figcaption': 'Bindi Karia (Venture Partner, Molten Ventures) and Numan Numan (Founder & General Partner, 212)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6443_LR.jpg',
        'alt': "startups' pitchings on stage",
        'figcaption': 'Michal Csonga (Partner, Zero One Hundred)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6421_LR.jpg',
        'alt': "judges of startups' pitchings on stage",
        'figcaption': 'Dana Labin, Bindi Karia and Numan Numan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6418_LR.jpg',
        'alt': "judges of startups' pitchings on stage",
        'figcaption': 'Dana Labin, Bindi Karia and Numan Numan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6417_LR.jpg',
        'alt': "judges of startups' pitchings on stage",
        'figcaption': 'Dana Labin, Bindi Karia and Numan Numan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6415_LR.jpg',
        'alt': "judges of startups' pitchings on stage",
        'figcaption': 'Dana Labin, Bindi Karia, Numan Numan and Karel Zheng',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6399_LR.jpg',
        'alt': "judges of startups' pitchings on stage",
        'figcaption': 'Dana Labin, Bindi Karia, Numan Numan and Karel Zheng',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6398_LR.jpg',
        'alt': 'Numan Numan (Founder & General Partner, 212)',
        'figcaption': 'Numan Numan (Founder & General Partner, 212)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6393_LR.jpg',
        'alt': "judges of startups' pitchings on stage",
        'figcaption': "EHOSS' pitching",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6391_LR.jpg',
        'alt': 'pitching of an startup on stage',
        'figcaption': 'Daria Pylypas (EHOSS) and Michal Csonga (Partner, Zero One Hundred)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6388_LR.jpg',
        'alt': 'startup pitch on stage',
        'figcaption': "EHOSS' pitching",
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6372_LR.jpg',
        'alt': 'startup pitch on stage',
        'figcaption': 'Daria Pylypas (EHOSS)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6360_LR.jpg',
        'alt': "judge of startups' pitchings on stage",
        'figcaption': 'Dana Labin (Partner, Leo Capital)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6359_LR.jpg',
        'alt': "startups' pitchings on stage",
        'figcaption': 'Michal Csonga (Partner, Zero One Hundred)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6346_LR.jpg',
        'alt': 'pitching organization backstage',
        'figcaption': 'George Koskinas (Reflect Festival)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6135_LR.jpg',
        'alt': 'man portrait',
        'figcaption': 'Viktor Kvachan (EHOSS)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6130_LR.jpg',
        'alt': '2 men talking',
        'figcaption': 'Viktor Kvachan (EHOSS) and Martin Bezak (Ambassador of the Slovak Republic to the Republic of Cyprus)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6096_LR.jpg',
        'alt': '2 men talking',
        'figcaption': 'Viktor Kvachan (EHOSS) and Martin Bezak (Ambassador of the Slovak Republic to the Republic of Cyprus)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6092_LR.jpg',
        'alt': 'one man is showing to another feedstock from manure',
        'figcaption': 'Viktor Kvachan (EHOSS) and Martin Bezak (Ambassador of the Slovak Republic to the Republic of Cyprus)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6084_LR.jpg',
        'alt': 'people speaking near boothes',
        'figcaption': 'Viktor Kvachan, Alexander Lipski and festival participant',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS5983_LR.jpg',
        'alt': 'people on the background of a booth',
        'figcaption': 'Viktor Kvachan, Marko Malak and other festival participants',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6802_LR.jpg',
        'alt': 'man and 2 women standing near the exhibition booth',
        'figcaption': 'Viktor Kvachan, Emese Pancsa and Daria Pylypas',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6796_LR.jpg',
        'alt': 'man and 2 women standing near the exhibition booth',
        'figcaption': 'Viktor Kvachan, Emese Pancsa and Daria Pylypas',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6790_LR.jpg',
        'alt': 'man and 2 women standing near the exhibition booth',
        'figcaption': 'Viktor Kvachan, Emese Pancsa and Daria Pylypas',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6788_LR.jpg',
        'alt': 'man and 2 women standing near the exhibition booth',
        'figcaption': 'Emese Pancsa (Compocity)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS6503_LR.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS5919_LR.jpg',
        'alt': 'speaker on a conference',
        'figcaption': 'Demetrios Zoppos (33East)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS5917_LR.jpg',
        'alt': 'speaker on a conference',
        'figcaption': 'Andreas Michaelides (Innoport VC)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post14/slider/small_EHOS5914_LR.jpg',
        'alt': 'speaker on a conference',
        'figcaption': 'David Höhl (Interface Capital)',
    },
]

export const slider13 = [
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5664_LR.jpg',
        'alt': 'man and three women standing on a meeting',
        'figcaption': 'Christina Doritou, Joanna Ellina, Daria Pylypas and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5643_LR.jpg',
        'alt': '2 men are standing on a meeting',
        'figcaption': 'and Viktor Kvachan and Angelos Gregoriades',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5617_LR.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5849_LR.jpg',
        'alt': 'man in profile',
        'figcaption': 'Boris Chrenko',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5635_LR.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5618_LR.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5636_LR.jpg',
        'alt': '2 men are standing on a meeting',
        'figcaption': 'and Viktor Kvachan and Angelos Gregoriades',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5872_LR.jpg',
        'alt': 'man smiles and shakes hands with someone',
        'figcaption': 'Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5857_LR.jpg',
        'alt': 'man and woman are laughing',
        'figcaption': 'Eleana Antoniou and František Vráb',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5855_LR.jpg',
        'alt': 'man and woman are talking',
        'figcaption': 'Eleana Antoniou and František Vráb',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5626_LR.jpg',
        'alt': 'man smiles ',
        'figcaption': 'Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5847_LR.jpg',
        'alt': 'people are talking',
        'figcaption': 'Horska Dominika, Boris Chrenko, Marieta Horský Gundová',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5842_LR.jpg',
        'alt': 'man in profile',
        'figcaption': 'Marko Malak',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5838_LR.jpg',
        'alt': 'people are on a meeting',
        'figcaption': 'František Vráb',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5822_LR.jpg',
        'alt': 'man and woman speaking',
        'figcaption': 'Marieta Horský Gundová',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5812_LR.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5810_LR.jpg',
        'alt': 'man and two women sitting and listening to one man standing',
        'figcaption': 'Annita Stylianidou, Daria Pylypas, Costas Spyrides and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5807_LR.jpg',
        'alt': 'man and two women sitting and listening to one man standing',
        'figcaption': 'Annita Stylianidou, Daria Pylypas, Costas Spyrides and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5797_LR.jpg',
        'alt': 'portrait of a woman',
        'figcaption': 'Annita Stylianidou',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5794_LR.jpg',
        'alt': 'portrait of a man',
        'figcaption': 'Costas Spyrides',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5779_LR.jpg',
        'alt': 'women are talking',
        'figcaption': 'Slovak Business Mission to Cyprus',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5777_LR.jpg',
        'alt': 'woman is smiling',
        'figcaption': 'Slovak Business Mission to Cyprus',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5763_LR.jpg',
        'alt': 'man is sitting in chair on a meeting',
        'figcaption': 'Slovak Business Mission to Cyprus',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5761_LR.jpg',
        'alt': '2 women are on a meeting',
        'figcaption': 'Daria Pylypas and Eleana Antoniou',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5753_LR.jpg',
        'alt': '2 men are on a meeting',
        'figcaption': 'Viktor Kvachan and Egon Zorad',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5740_LR.jpg',
        'alt': 'portrait of a man',
        'figcaption': 'Marko Malak',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5734_LR.jpg',
        'alt': 'portrait of a laughing woman',
        'figcaption': 'Annita Stylianidou',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5727_LR.jpg',
        'alt': 'woman laughing',
        'figcaption': 'Annita Stylianidou, Costas Spyrides and Marko Malak',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5720_LR.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5715_LR.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5725_LR.jpg',
        'alt': 'man in profile',
        'figcaption': 'Egon Zorad',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5697_LR.jpg',
        'alt': 'man and three women standing on a meeting',
        'figcaption': 'Christina Doritou, Joanna Ellina, Daria Pylypas and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5693_LR.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5688_LR.jpg',
        'alt': 'people on a meeting',
        'figcaption': 'Annita Stylianidou, Costas Spyrides and Marko Malak',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5685_LR.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post13/slider/small_EHOS5678_LR.jpg',
        'alt': '',
        'figcaption': '',
    },

]

export const slider11 = [
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00001.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00002.jpg',
        'alt': 'Woman talks while sitting at a conference table',
        'figcaption': 'Shipra Sharma',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00004.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00005.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00006.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00007.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00008.jpg',
        'alt': '',
        'figcaption': '',
    }, 
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00009.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00010.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00011.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00012.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00013.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00014.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00015.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00016.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00017.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00018.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00019.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00020.jpg',
        'alt': 'Woman is having a conversation outside',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00021.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00022.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00023.jpg',
        'alt': 'Man speaking on the phone outside',
        'figcaption': 'Darko Stojmenovski Ilich',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00024.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00025.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00026.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00027.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00028.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00029.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00032.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00033.jpg',
        'alt': 'Woman speaking at a conference',
        'figcaption': 'Natalie Maabdi',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00034.jpg',
        'alt': 'Man sitting on a conference',
        'figcaption': 'Egbert von Mentzingen',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00035.jpg',
        'alt': 'Man listening to a speech',
        'figcaption': 'Frantisek Vráb',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00037.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00038.jpg',
        'alt': 'Woman speaking at a conference',
        'figcaption': 'Natalie Maabdi',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00041.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00042.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00043.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00044.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00046.jpg',
        'alt': 'Czech flag on a conference table',
        'figcaption': 'CzechTrade table',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00047.jpg',
        'alt': 'Hungary flag on a conference table',
        'figcaption': 'HEPA table (Hungarian Export Promotion Agency)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00048.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00049.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00051.jpg',
        'alt': 'Woman speaking at a conference',
        'figcaption': 'Julie Geoffrey',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00052.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00053.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00054.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00055.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00056.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00057.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00058.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00059.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00060.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00061.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00062.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00063.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00064.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00065.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00066.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00067.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00068.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00069.jpg',
        'alt': 'Women are having a discussion',
        'figcaption': 'Julie Geoffrey',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00070.jpg',
        'alt': 'Woman listening to a man',
        'figcaption': 'Julie Havlova (CzechTrade) and Viktor Kvachan (EHOSS)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00070.jpg',
        'alt': 'Man and woman having a discussion',
        'figcaption': 'Viktor Kvachan (EHOSS) and Julie Havlova (CzechTrade)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00073.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00075.jpg',
        'alt': 'Business card in a woman`s hands',
        'figcaption': 'EHOSS business card and product samples',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00076.jpg',
        'alt': 'A woman listening to a man',
        'figcaption': 'Julie Havlova, CzechTrade',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00077.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00078.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00079.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00080.jpg',
        'alt': 'eople sitting at a conference table',
        'figcaption': 'Ingrid Antalkova, Daria Pylypas and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00081.jpg',
        'alt': 'Man and woman sitting at a conference table',
        'figcaption': 'Daria Pylypas and Viktor Kvachan ',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00083.jpg',
        'alt': 'Two men sitting at a conference table',
        'figcaption': 'Tomás Hudec (International Tenders)',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00085.jpg',
        'alt': 'Woman smilig sitting at a conference table',
        'figcaption': 'Ingrid Antalkova',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00087.jpg',
        'alt': 'Two men and a woman are standing in front of the flags of EU',
        'figcaption': 'EHOSS team: Serhii Chelobitchykov, Daria Pylypas and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00090.jpg',
        'alt': 'People on a pannel discussion',
        'figcaption': 'Darko Stojmenovski Ilich, Shipra Sharma, Selenge Lkhagva and Monica Bota',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00091.jpg',
        'alt': 'Women on a pannel discussion',
        'figcaption': 'Selenge Lkhagva and Monica Bota',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00092.jpg',
        'alt': 'People on a pannel discussion',
        'figcaption': 'Darko Stojmenovski Ilich and Shipra Sharma',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00094.jpg',
        'alt': 'Woman explains at a meeting',
        'figcaption': 'Daria Pylypas',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00095.jpg',
        'alt': 'Man smells product sample at a conference table',
        'figcaption': 'Egbert von Mentzingen',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00096.jpg',
        'alt': 'Man smilig sitting at a conference table',
        'figcaption': 'Egbert von Mentzingen',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00098.jpg',
        'alt': 'wo men and a woman talking sitting at a conference table',
        'figcaption': 'Egbert von Mentzingen, Viktor Kvachan and Daria Pylypas',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00100.jpg',
        'alt': 'Two men and a woman talking sitting at a conference table',
        'figcaption': 'Egbert von Mentzingen, Viktor Kvachan and Daria Pylypas',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00102.jpg',
        'alt': 'Man smiling sitting at a conference table',
        'figcaption': 'Egbert von Mentzingen',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00104.jpg',
        'alt': 'Man',
        'figcaption': 'Frantisek Vráb',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00107.jpg',
        'alt': 'Two men talking',
        'figcaption': 'Jaroslav Korienek and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00108.jpg',
        'alt': 'Мan and woman are talking',
        'figcaption': 'Julie Geoffrey and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00110.jpg',
        'alt': 'Мan and woman are talking',
        'figcaption': 'Julie Geoffrey and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00111.jpg',
        'alt': 'Мan and woman are talking',
        'figcaption': 'Julie Geoffrey and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00112.jpg',
        'alt': 'Мan and woman are talking',
        'figcaption': 'Julie Geoffrey and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00113.jpg',
        'alt': 'Мan and woman are talking',
        'figcaption': 'Gabriela Veenstra Skorkovska and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00114.jpg',
        'alt': 'Мan and woman are talking',
        'figcaption': 'Gabriela Veenstra Skorkovska and Viktor Kvachan',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00115.jpg',
        'alt': '',
        'figcaption': '',
    },
    {
        'path': 'https://static.ehoss.com/assets/blog/post11/slider/ehoss_00116.jpg',
        'alt': 'Мan and woman are looking at the phone',
        'figcaption': 'Gabriela Veenstra Skorkovska and Viktor Kvachan',
    },
];

export const slider3 = [
    {
        'path': post3slide1,
        'alt': 'Woman on horse front view on show jumping arena in Bratislava.',
        'figcaption': '1st place at Danube Equestrian Festival 2023: Alessandra Reich – Oeli R (AUT).',
    },
    {
        'path': post3slide2,
        'alt': 'Woman on running horse in side view on show jumping arena in Bratislava.',
        'figcaption': 'Alisa Danilova (UA) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide3,
        'alt': 'Woman on a horse jumping over an obstacle in front view.',
        'figcaption': 'Alisa Danilova (UA) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide4,
        'alt': 'Woman on a horse in front of obstacle prepare to jump, in front view.',
        'figcaption': 'Alisa Danilova (UA) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide5,
        'alt': 'Woman on a horse in begin of jump, front view."',
        'figcaption': 'Alisa Danilova (UA) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide6,
        'alt': 'Woman on a horse jumping over an obstacle in front view.',
        'figcaption': 'Alisa Danilova (UA) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide7,
        'alt': 'Woman on a horse finish the jump, side view.',
        'figcaption': 'Alisa Danilova (UA) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide8,
        'alt': 'Woman on a horse at jumping show CSIO Longines EEF.',
        'figcaption': 'Alisa Danilova (UA) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide9,
        'alt': 'Man on a running horse in front view on show jumping arena in Bratislava.',
        'figcaption': 'Bronislav Chudyba – Herriet T (SVK) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide10,
        'alt': 'Man on a horse jumping over an obstacle in side view.',
        'figcaption': 'Commandant Geoff Curran – Derrycusch (IRL) at Danube Equestrian Festival, Bratislava, ',
    },
    {
        'path': post3slide11,
        'alt': 'Man on a running horse strokes its neck on a show jumping arena, side view.',
        'figcaption': 'Commandant Geoff Curran – Derrycusch (IRL) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide12,
        'alt': 'Man on a running horse strokes its neck on a show jumping arena, front view.',
        'figcaption': 'Commandant Geoff Curran – Derrycusch (IRL) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide13,
        'alt': 'Man on a horse finishes the jump with an obstacle.',
        'figcaption': 'Commandant Geoff Curran – Derrycusch (IRL) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide14,
        'alt': 'Riding man portrait.',
        'figcaption': 'Daniel Ouzky (SVK) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide15,
        'alt': 'Man on a running horse on show jumping arena in Bratislava.',
        'figcaption': 'Daniel Ouzky (SVK) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide16,
        'alt': 'Man on a running horse on show jumping arena in front view.',
        'figcaption': 'Daniel Ouzky (SVK) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide17,
        'alt': 'Man on a running horse on show jumping arena',
        'figcaption': 'Daniel Ouzky (SVK) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide18,
        'alt': 'Woman on a horse standing on show jumping arena.',
        'figcaption': 'Dominika Droppova (SVK) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide19,
        'alt': 'Woman on a running horse on show jumping arena.',
        'figcaption': 'Emma Augier De Moussac (CZE) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide20,
        'alt': 'Woman on a jumping horse on show jumping arena.',
        'figcaption': 'Emma Augier De Moussac (CZE) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide21,
        'alt': 'Man on a jumping horse on show jumping arena.',
        'figcaption': 'Harry Allen – Calculatus (IRL) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide22,
        'alt': 'Man on a jumping horse on show jumping arena, front view.',
        'figcaption': 'Harry Allen – Calculatus (IRL) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide23,
        'alt': 'Man on a jumping horse on show jumping arena, side view.',
        'figcaption': 'Harry Allen – Calculatus (IRL) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide24,
        'alt': 'Woman on a jumping horse on show jumping arena.',
        'figcaption': 'Katharina Rhomberg (AUT) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide25,
        'alt': 'Side view ofwoman on a horse on show jumping arena.',
        'figcaption': 'Katharina Rhomberg (AUT) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide26,
        'alt': 'Woman on a jumping horse on show jumping arena.',
        'figcaption': 'Katharina Rhomberg (AUT) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide27,
        'alt': 'Portrait of horse with riding woman.',
        'figcaption': 'Katharina Rhomberg (AUT) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide28,
        'alt': 'Woman on a jumping horse on show jumping arena.',
        'figcaption': 'Katharina Rhomberg (AUT) at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide29,
        'alt': 'Woman on a jumping horse on show jumping arena.',
        'figcaption': 'Lena Binder at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide30,
        'alt': 'Woman on a jumping horse on show jumping arena.',
        'figcaption': 'Lena Binder at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide31,
        'alt': 'Portrait of horse with riding woman.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide32,
        'alt': 'Woman on horse in jump.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide33,
        'alt': 'Woman on a horse strokes its neck on a show jumping arena.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide34,
        'alt': 'Man on a running horse touching his hat on show jumping arena.',
        'figcaption': 'Marek Klus at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide35,
        'alt': 'Man on a lumping horse on show jumping arena',
        'figcaption': 'Marek Klus at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide36,
        'alt': 'Portrait of woman on horse.',
        'figcaption': 'Marianne Schindele at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide37,
        'alt': 'Woman riding a horse.',
        'figcaption': 'Marie Sebesta at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide38,
        'alt': 'Man on a jumping horse.',
        'figcaption': 'Marvin Jüngel at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide39,
        'alt': 'Man on a jumping horse in fly.',
        'figcaption': 'Marvin Jüngel at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide40,
        'alt': 'Man on a horse finishing the jump.',
        'figcaption': 'Mohamed El Naggar at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide41,
        'alt': 'Rain on show jumping arena.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide42,
        'alt': 'Man on a jumping horse.',
        'figcaption': 'Mohamed El Naggar at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide43,
        'alt': 'Woman on a jumping horse.',
        'figcaption': 'Patricia Kardun at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide44,
        'alt': 'Man on a jumping horse.',
        'figcaption': 'Peter Mackovjak at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide45,
        'alt': 'Man on a jumping horse.',
        'figcaption': 'Peter Mackovjak at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide46,
        'alt': 'Man on a jumping horse.',
        'figcaption': 'Shady Samir at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide47,
        'alt': 'Man on a jumping horse in fly.',
        'figcaption': 'Tamás Dömse at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide48,
        'alt': 'Man on a jumping horse in fly.',
        'figcaption': 'Tamás Dömse at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide49,
        'alt': 'Man on a jumping horse in fly.',
        'figcaption': 'Tamás Dömse at Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide50,
        'alt': 'Man on a jumping horse in fly.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide51,
        'alt': 'Man on a running horse.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide52,
        'alt': 'Woman on a horse ready to jump.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide53,
        'alt': 'Woman on a horse on show jumping arena.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide54,
        'alt': 'Man on a jumping horse.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide55,
        'alt': 'Man on a horse on show jumping arena.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide56,
        'alt': 'Man on a horse strokes its neck on a show jumping arena.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide57,
        'alt': 'Man on a running horse.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
    {
        'path': post3slide58,
        'alt': 'Woman on a horse in a beginning of a jump.',
        'figcaption': 'Danube Equestrian Festival, Bratislava, Slovakia 2023.',
    },
];

export const slider7 = [
    {
        'path': post7slide1,
        'alt': 'the stand at trade show with two women chatting and one man working',
        'figcaption': 'EHOSS stand at BETA International trade show at NAEC Stoneleigh.',
    },
    {
        'path': post7slide2,
        'alt': 'the stand at trade show with 3 persons inside',
        'figcaption': 'EHOSS stand at BETA International trade show at NAEC Stoneleigh.',
    },
    {
        'path': post7slide3,
        'alt': 'a person speak at public with a presentation behind',
        'figcaption': 'Viktor Kvachan presents EHOSS at BETA Seminar Theater.',
    },
    {
        'path': post7slide4,
        'alt': 'team in front of a stand at trade show',
        'figcaption': 'EHOSS team at BETA International trade show at NAEC Stoneleigh.',
    },
]

export const slider9 = [
    {
        'path': post9slide1,
        'alt': 'Man is sitting at the table with others not in focus and having a chat with paper in hands.',
        'figcaption': 'Mr. Tomás Kral from Oberbank AG.',
    },
    {
        'path': post9slide2,
        'alt': 'Woman is sitting at the table with others that are not in focus at a conference.',
        'figcaption': 'Ms. Hana Mozolová from Oberbank AG.',
    },
    {
        'path': post9slide3,
        'alt': 'Man is sitting at the table with another man that is not in focus at a conference.',
        'figcaption': 'Mr. Peter Gramblicka from Oberbank AG.',
    },
    {
        'path': post9slide4,
        'alt': 'Man and woman are sitting opposite each other at the table at a conference.',
        'figcaption': ' Ms. Anna Balaj Vaclavova from Československá obchodní banka and Mr. Viktor Kvachan from EHOSS.',
    },
    {
        'path': post9slide5,
        'alt': 'Two women in red and black are sitting opposite a man in costume at the table at a conference.',
        'figcaption': 'Ms. Miroslava Remenarova and Ms. Katarina Stulajterova from British Chamber of Commerce in SR with Mr. Viktor Kvachan from EHOSS.',
    },
    {
        'path': post9slide6,
        'alt': 'Two women in red and black are smiling and sitting opposite a man in costume at the table at a conference.',
        'figcaption': ' Ms. Miroslava Remenarova and Ms. Katarina Stulajterova from British Chamber of Commerce in SR with Mr. Viktor Kvachan from EHOSS.',
    },
    {
        'path': post9slide7,
        'alt': 'Woman is smiling to the camera and sitting at the table with a man facing other direction at a conference.',
        'figcaption': 'Ms. Dana Höller-Lipkova from Austrian Business Agency with Mr. Viktor Kvachan from EHOSS',
    },
    {
        'path': post9slide8,
        'alt': 'Three men are standing at a conference.',
        'figcaption': 'Mr. Mario Gržinić and Mr. Jozef Jojo Krška from Chorvátsko - Slovenská Obchodná Komora with Mr. Viktor Kvachan from EHOSS.',
    },
    {
        'path': post9slide9,
        'alt': 'Two men are standing at a conference.',
        'figcaption': 'Mr. Mario Gržinić and Mr. Jozef Jojo Krška from Chorvátsko - Slovenská Obchodná Komora.',
    },
]
