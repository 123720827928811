import './sitemap.css'
import LineDot from '../../components/line-dot/LineDot';
import sitemapIcon from './sitemap.svg'
function Sitemap() {
  return (
    <>
      <div className='section overflow-auto h-[100vh]'>
        <LineDot />
        <div className='container '>
          <div className=' mt-32 text-white'>
            <h2 className="font-semibold flex items-center gap-4 mb-12 text-2xl md:text-4xl">
              <img className='w-12 h-12' src={sitemapIcon} />
              Sitemap

            </h2>
            <div className='grid grid-cols-1 md:grid-cols-3 mb-12'>
              <div>
                <h3 className='text-[20px]'>Заглавие</h3>
                <ul className='*:pl-4 flex flex-col gap-2 mt-4'>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className='text-[20px]'>Заглавие</h3>
                <ul className='*:pl-4 flex flex-col gap-2 mt-4'>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className='text-[20px]'>Заглавие</h3>
                <ul className='*:pl-4 flex flex-col gap-2 mt-4'>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-3 mb-12'>
              <div>
                <h3 className='text-[20px]'>Заглавие</h3>
                <ul className='*:pl-4 flex flex-col gap-2 mt-4'>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className='text-[20px]'>Заглавие</h3>
                <ul className='*:pl-4 flex flex-col gap-2 mt-4'>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className='text-[20px]'>Заглавие</h3>
                <ul className='*:pl-4 flex flex-col gap-2 mt-4'>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-3 mb-12'>
              <div>
                <h3 className='text-[20px]'>Заглавие</h3>
                <ul className='*:pl-4 flex flex-col gap-2 mt-4'>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className='text-[20px]'>Заглавие</h3>
                <ul className='*:pl-4 flex flex-col gap-2 mt-4'>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className='text-[20px]'>Заглавие</h3>
                <ul className='*:pl-4 flex flex-col gap-2 mt-4'>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-3 mb-12'>
              <div>
                <h3 className='text-[20px]'>Заглавие</h3>
                <ul className='*:pl-4 flex flex-col gap-2 mt-4'>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className='text-[20px]'>Заглавие</h3>
                <ul className='*:pl-4 flex flex-col gap-2 mt-4'>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className='text-[20px]'>Заглавие</h3>
                <ul className='*:pl-4 flex flex-col gap-2 mt-4'>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                  <li>
                    <a href='#'>Пункт списка</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default Sitemap;