// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#blog li.active {
    border-radius: 12px;
    background-color: white;
    color: black !important;

}

#blog li svg path {
    fill: white;
}

#blog li.active svg path {
    fill: red !important;
}


#blog li.active a {
   color: black !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/PostsWidget/postswidget.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;;AAE3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB;;;AAGA;GACG,uBAAuB;AAC1B","sourcesContent":["#blog li.active {\r\n    border-radius: 12px;\r\n    background-color: white;\r\n    color: black !important;\r\n\r\n}\r\n\r\n#blog li svg path {\r\n    fill: white;\r\n}\r\n\r\n#blog li.active svg path {\r\n    fill: red !important;\r\n}\r\n\r\n\r\n#blog li.active a {\r\n   color: black !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
