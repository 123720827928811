// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-icon {
    width: 24px !important;
    height: 24px !important;

    cursor: pointer;
}

.share-wrapper {
    display: flex;
    align-items: center;
}

.done {
    position: fixed;
    /* transform: translateX(120%); */
    right: 20px;
    bottom: 20px;
    padding: 20px;
    background-color: rgb(19, 148, 69);
    border-radius: 12px;
    transition: 0.3s;

    z-index: 9999;
}

.done p {
    margin: 0px;
    font-family: Raleway;
    font-size: 13px;
}

.done.active {
 
    transform: translate(0);
}`, "",{"version":3,"sources":["webpack://./src/components/share-buttons/shareButtons.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,uBAAuB;;IAEvB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iCAAiC;IACjC,WAAW;IACX,YAAY;IACZ,aAAa;IACb,kCAAkC;IAClC,mBAAmB;IACnB,gBAAgB;;IAEhB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,eAAe;AACnB;;AAEA;;IAEI,uBAAuB;AAC3B","sourcesContent":[".share-icon {\r\n    width: 24px !important;\r\n    height: 24px !important;\r\n\r\n    cursor: pointer;\r\n}\r\n\r\n.share-wrapper {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.done {\r\n    position: fixed;\r\n    /* transform: translateX(120%); */\r\n    right: 20px;\r\n    bottom: 20px;\r\n    padding: 20px;\r\n    background-color: rgb(19, 148, 69);\r\n    border-radius: 12px;\r\n    transition: 0.3s;\r\n\r\n    z-index: 9999;\r\n}\r\n\r\n.done p {\r\n    margin: 0px;\r\n    font-family: Raleway;\r\n    font-size: 13px;\r\n}\r\n\r\n.done.active {\r\n \r\n    transform: translate(0);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
