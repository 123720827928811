import ReactFullpage from '@fullpage/react-fullpage';
import LineDot from '../../../components/line-dot/LineDot';
import Footer from '../../../container/footer/Footer';
import './equineproducers.css'
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

import Line from '../../../components/line/Line';
import card1 from './../../audience/images/bg2.png'
import card2 from './../../audience/images/bg3.png'
import card3 from './../../audience/images/bg4.png'
import card4 from './../../audience/images/bg5.png'
import card5 from './../../audience/images/bg6.png'
import card7 from './../../audience/images/bg1.png'


function EquineProducers() {

    const location = useLocation();

    function openCooperationModal() {
        const modal = document.getElementById('cooperation-modal');
        modal.classList.remove('hidden');
        modal.classList.add('flex');
    }

    return (
        <>
            <ReactFullpage
                licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
                scrollingSpeed={1000}
                navigation
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <Helmet>
                                <title>EHOSS | Equine Product Producers</title>
                                <meta name="robots" content="index, follow" />
                                <meta name="keywords" content="Equine welfare, Horse Welfare, Equine Health Plan, feeding analysis software, equine management nutrition health & exercise" />
                                <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
                            </Helmet>
                            <section className='section audience4'>
                                <LineDot />
                                <div className='container'>
                                    <div className='pt-24 flex md:flex-row  flex-col gap-8 h-[100vh]'>
                                        <div className=' text-white '>
                                            <div className='flex flex-col items-start gap-4'>
                                                <Breadcrumbs
                                                    items={[
                                                        { label: 'Who Needs?', url: '/' },
                                                        { label: 'For Animal Owners', url: '/audience' },
                                                        { label: 'Equine Product Producers', url: '/equine-producers' },
                                                    ]}
                                                />
                                                <h2 className='text-[36px] mt-12 font-medium'>
                                                    FOR THE ENTREPRENEURS AND PRODUCERS OF THE EQUINE PRODUCTS

                                                </h2>
                                                <div className='grid md:grid-cols-2 gap-10'>
                                                    <div>
                                                        <p className='mt-6 mb-4'>
                                                            We would like to offer the manufacturers of the horse care products the opportunity to cooperate with us and expand their business. We use our expertise in engineering and creating technological solutions to create platforms that improve horses living conditions and make it easier and faster for the owners and stable staff to take care of them.
                                                            <br /><br />
                                                            Our <a className='underline font-medium cursor-pointer' href='/monitoring-platform'>Feed platform </a> allows programing the horse nutrition according to its individual needs. We form a database of manufacturers and recommendations of how to use their products, taking into account the age, status and other characteristics of the horse. <strong>In this way, the horse owner will have the opportunity to:</strong>
                                                        </p>
                                                        <ul className='list flex flex-col gap-4 *:text-[14px] mb-6'>
                                                            <li>
                                                                Choose a product that their horse needs
                                                            </li>
                                                            <li>
                                                                Get a description of this product and recommendations for its use from the manufacturer
                                                            </li>
                                                            <li>
                                                                Introduce the product into the horse diet using personal account or mobile app. This can be done in accordance with the recommendations provided in the description or based on your own preferences.
                                                            </li>
                                                        </ul>
                                                        <p className='mt-4'>
                                                            To explore partnership opportunities and have your product featured in our database, please contact us at <a className='underline font-medium' href='mailto:partner@ehoss.com'>partner@ehoss.com</a> or complete the <button onClick={openCooperationModal} className='underline font-medium'>feedback form</button>.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className='mt-6 font-medium mb-4'>If you produce feed. Cooperation with us will allow you to:</p>
                                                        <ul className='list flex flex-col gap-4 *:text-[14px] mb-6'>
                                                            <li>
                                                                Enter the market, be competitive and stand out due to the advanced technologies of our <a className='underline font-medium cursor-pointer' href='/monitoring-platform'>Feed platform </a>. We will add your product and recommendations for its use to our platform database. This will help your regular customers to quickly adjust their pet's usual diet, and will allow the new customers to see your offers in our database and read your recommendations.
                                                            </li>
                                                            <li>
                                                                Store products more properly. The products can be stored for a longer time because they are loaded into special hermetic containers that are equipped with bactericidal lamps. Thus, your customers will receive high-quality products that meet their expectations.
                                                            </li>
                                                            <li>
                                                                Receive a request for replenishment of stocks automatically and on time. Our equipment, equipped with sensors, signals to the owners that the platform runs out of products and offers to replenish the stocks.
                                                            </li>
                                                            <li>
                                                                An easy and fast usage of all the advantages of our partnership through special programs. You can assign a QR code to your product in our system. After scanning, your product can be immediately added to the horse's diet via the mobile application.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='section audience4'>
                                <Line />
                                <div className='container'>
                                    <div className='pt-32 flex md:flex-row  flex-col gap-8 h-[100vh]'>
                                        <div className=' text-white '>
                                            <div className='flex flex-col items-start gap-4'>
                                                <div className='grid pb-16 md:grid-cols-2 gap-10'>
                                                    <div>
                                                        <p className='mt-6 font-medium mb-4'>
                                                            If you supply hay
                                                        </p>
                                                        <p className='mt-2'>
                                                            To start a successful cooperation, we encourage you to describe the advantages of your hay, recommendations for its proper storage and other important information that horse owners can find in the EHOSS database / ecosystem. Our equipment is designed to facilitate the horse feeding process for horse owners and other caretakers.
                                                        </p>
                                                        <p className='mt-6 font-medium mb-4'>
                                                            If you produce other goods or products for the equine industry
                                                        </p>
                                                        <p className='mt-2'>
                                                            We will be glad to explore potential collaboration opportunities with you. Please contact us at <a className='underline font-medium' href='mailto:partner@ehoss.com'>partner@ehoss.com</a> or complete the <button onClick={openCooperationModal} className='underline font-medium'>feedback form</button>.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className='mt-6 font-medium mb-4'>
                                                            Data analysis
                                                        </p>
                                                        <p className='mt-2'>
                                                            We offer cooperation in your product analysis which is based on de-identified information from owners (who have given their consent). In particular, we will be able to analyse your product based on consumption statistics in the database and feedback from real users over a certain period of time.
                                                        </p>
                                                        <p className='mt-2'>
                                                            In addition, the site uses the Google Review service, the mechanisms of which help to get real, identified reviews from users and raise the position of your product in search queries.
                                                        </p>
                                                        <p className='mt-2'>
                                                            If your goal is to improve horse products and develop the equine industry, we are open for cooperation. Do not hesitate to write to us at <a className='underline font-medium' href='mailto:partner@ehoss.com'>partner@ehoss.com</a> or fill out the <button onClick={openCooperationModal} className='underline font-medium'>feedback form</button>.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className='section overflow-hidden'>
                                <Line />
                                <div className='container flex gap-6 flex-col md:flex-row md:items-center md:justify-between'>
                                    <div className=' w-full md:h-[100vh] pt-24 text-white'>
                                        <div>
                                            <h2 className='text-[36px] font-medium uppercase'>
                                                more about who needs
                                            </h2>
                                        </div>
                                        <div className='w-full mt-12'>
                                            <div className='grid gap-2 grid-cols-2 md:grid-cols-6 w-full'>
                                                {/* =========== card 1 ============= */}
                                                <a href='/farmers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                                                    <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                                                        <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                                                            </svg>
                                                        </div>
                                                        <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>Farmers</h3>
                                                    </div>
                                                    <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card7} />
                                                </a>
                                                {/* =========== card 2 ============= */}
                                                <a href='/horse-owners' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                                                    <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                                                        <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                                                            </svg>
                                                        </div>
                                                        <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                                                            Horse Owners
                                                        </h3>
                                                    </div>
                                                    <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card1} />
                                                </a>
                                                {/* =========== card 3 ============= */}
                                                <a href='/equestrian-facilities' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                                                    <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                                                        <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                                                            </svg>
                                                        </div>
                                                        <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                                                            Equestrian Facilities
                                                        </h3>
                                                    </div>
                                                    <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card2} />
                                                </a>
                                                {/* =========== card 4 ============= */}
                                                <a href='/equine-producers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                                                    <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                                                        <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                                                            </svg>
                                                        </div>
                                                        <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                                                            Equine Product Producers
                                                        </h3>
                                                    </div>
                                                    <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card3} />
                                                </a>
                                                {/* =========== card 5 ============= */}
                                                <a href='/veterinary' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                                                    <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                                                        <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                                                            </svg>
                                                        </div>
                                                        <h3 className='text-white text-[16px] mt-4 font-semibold'>
                                                            Veterinary
                                                        </h3>
                                                    </div>
                                                    <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card4} />
                                                </a>
                                                {/* =========== card 6 ============= */}
                                                <a href='/equestrians' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                                                    <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                                                        <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                                                            </svg>
                                                        </div>
                                                        <h3 className='text-white text-[16px] mt-4 font-semibold'>
                                                            Equestrians
                                                        </h3>
                                                    </div>
                                                    <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card5} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </>

    );
}

export default EquineProducers;