import img from './not-found.svg'
import LineDot from '../../components/line-dot/LineDot';
import Footer from '../../container/footer/Footer';

function NotFound() {
    return (
        <section>
            <LineDot/>
            <div className='container'>
                <div className='flex flex-col gap-4 h-[100vh] justify-center items-center'>
                    <img className='max-w-[500px]' src={img} alt='Not Found Text' />
                    <p className='uppercase text-white text-[32px]'>This page does not exist or has been deleted</p>
                    <a className='main-btn' href='/'>Home</a>
                </div>
            </div>
            <Footer/>
        </section>
    );
}

export default NotFound;
