import ReactFullpage from '@fullpage/react-fullpage';
import LineDot from '../../components/line-dot/LineDot';
import { DocumentIcon } from '@heroicons/react/24/outline';
import Footer from '../../container/footer/Footer';
import schemas from './../../assets/images/platforms-schemes/seno.svg'
import SalesAndRefundPDF from './../../assets/files/terms_and_conditions_of_purchase_and_sale_agreemen.pdf'
import SalesAndRefundPDF_file2 from './../../assets/files/sales_and_refund_policy.pdf'
import { Helmet } from "react-helmet";

function SalesAndRefund() {
  return (
    <>
      <Helmet>
        <title>EHOSS | Sales and Refunds</title>
      </Helmet>
      <ReactFullpage
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={1000}
        navigation
        render={({ state, fullpageApi }) => {
          return (
            <div>
              <ReactFullpage.Wrapper>
                <div className='section overflow-hidden'>
                  <LineDot />
                  <div className='container'>
                    <div className='privacy md:w-1/2 h-[100vh] pt-32 text-white'>
                      <div className='flex flex-col  items-start gap-4'>
                        <h2 className='text-[24px] md:text-[36px] font-bold'>
                          Sales and Refunds
                        </h2>
                        <p className='text-[14px]'>
                          We want your experience with EHOSS to be nothing but good, even if in some aspects we did not achieve the results you hoped for. In order for all the terms of our cooperation to be clear and transparent, please familiarize yourself with the Purchase Agreement, the Terms and Conditions of the Purchase Agreement, as well as the Sales and Refund Policy.
                        </p>
                        <div>
                          <div className='bg-gray p-4 rounded-xl flex flex-col gap-2 mt-6'>
                            <p className='font-semibold text-[18px]'>Terms and conditions of the Purchase Agreement</p>
                            <p className=' opacity-50 text-[12px]'>Updated February 7, 2023</p>
                            {/* <p className='text-[14px]'>This document describes how EHOSS collects, uses, and shares your personal data.</p> */}
                            <a href={SalesAndRefundPDF} className=' underline flex gap-2 items-center font-semibold mt-6' target='_blank'>
                              View the PDF file
                              <DocumentIcon className='w-4 h-4' />
                            </a>
                          </div>
                          <div className='bg-gray p-4 rounded-xl flex flex-col gap-2 mt-6'>
                            <p className='font-semibold text-[18px]'>Sales and Refund Policy</p>
                            <p className=' opacity-50 text-[12px]'>Updated February 7, 2023</p>
                            {/* <p className='text-[14px]'>This document describes how EHOSS collects, uses, and shares your personal data.</p> */}
                            <a href={SalesAndRefundPDF_file2} className=' underline flex gap-2 items-center font-semibold mt-6' target='_blank'>
                              View the PDF file
                              <DocumentIcon className='w-4 h-4' />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className='w-2/3 absolute right-[-400px] opacity-30 bottom-0' src={schemas} />
                </div>
                <Footer />
              </ReactFullpage.Wrapper>
            </div>
          );
        }}
      />
    </>

  );
}

export default SalesAndRefund;