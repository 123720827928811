import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import img1 from './1.png';
import img2 from './2.png';
import LineDot from '../../components/line-dot/LineDot';
import Line from '../../components/line/Line';
import Footer from '../../container/footer/Footer';
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './monitoringplatform.css'
import horse from '../../assets/icons/HORSE.svg';
import icon2 from './icons/icon2.svg'


function MonitoringPlatform() {

  const [slidesPerView, setSlidesPerView] = useState(calculateSlidesPerView());
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setSlidesPerView(calculateSlidesPerView());
  };

  function calculateSlidesPerView() {
    if (window.innerWidth < 768) {
      return 1;
    } else if (window.innerWidth < 1280) {
      return 1;
    } else {
      return 1;
    }
  }

  const location = useLocation();

  const [activeTab, setActiveTab] = useState('title1');

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  function openRequestModal() {
    const modal = document.getElementById('request-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }


  return (
    <>
      <ReactFullpage
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={1000}
        navigation
        anchors={
          [
            'monitoring-for-horse-stables-and-trailers',
            'monitoring-for-horse-stable-functions',
            'monitoring-for-horse-stable-installation',
            'footer'
          ]
        }
        render={({ state, fullpageApi }) => {
          return (
            <div>
              <ReactFullpage.Wrapper>
                <Helmet>
                  <title>EHOSS | Monitoring for Horse's Stables and Trailers</title>
                  <meta name="keywords" content="Installing an Equine Monitoring System for Your Stables, Horse Monitoring Camera, installing an equine security system for your stables, security system for a horse barn, Installing an Equine Monitoring System for Your Stables, Horse Monitoring Camera, installing an equine security system for your stables, security system for a horse barn, stable equine monitor" />
                  <meta name="robots" content="index, follow" />
                  <meta name="description" content="The monitoring platform allows you to monitor stalls, analyze data and process all indicators using cameras and sensors in real-time." />
                  <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
                </Helmet>
                <div className='section'>
                  <div className='z-[-10] md:z-10 fixed left-0 w-3 h-full'>
                    <LineDot />
                  </div>
                  <div className='container flex flex-wrap-reverse md:flex-nowrap flex-col md:flex-row items-center'>
                    <div className='md:order-1 order-2 pt-24 flex flex-col items-start justify-between h-[100vh]'>
                      <div>
                        <Breadcrumbs
                            items={[
                              { label: 'Ecosystem', url: '/ecosystem' },
                              { label: 'Animal Care', url: '/ecosystem' },
                              { label: 'Monitoring Platform', url: '/monitoring-platform' },
                            ]}
                          />
                      </div>
                      <div className='max-w-[600px]'>
                        <h1 className='text-white font-medium text-[38px]'>
                          Monitoring Platform
                        </h1>
                        <div className='text-white mt-12 mb-12'>
                          <div className='mb-0 absolute w-[100%] top-0 h-[100vh] right-0 left-0 -z-20'>
                            <img className='w-[100%] rounded-none h-[100vh] object-cover' src={img1}></img>
                          </div>
                        </div>
                        <div className='flex md:flex-row flex-col md:w-auto w-full justify-center gap-4 mb-16'>
                        <button onClick={openRequestModal} className='main-btn text-start whitespace-nowrap cursor-pointer'>
                          Leave a request
                        </button>
                        <a onClick={() => fullpageApi.moveTo(2)} className='main-btn text-start whitespace-nowrap two cursor-pointer'>
                          learn more
                        </a>
                      </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className='section bg-[#010101]'>

                  <div className='fixed z-10 h-[100vh]'>
                    <Line />
                  </div>

                  <div className='container  flex flex-wrap-reverse md:flex-nowrap flex-col md:flex-row items-center'>
                    <div className='md:order-2 order-2 md:pt-20 flex flex-col items-start justify-between'>
                      <div className='max-w-[700px]'>
                        <h2 className='text-white mb-4 font-medium text-[24px] md:text-[38px]'>
                          Functions
                        </h2>
                        <div className='flex justify-center'>
                          <div className='w-[300px] md:w-full cursor-grab'>
                            <Swiper
                              modules={[Navigation]}
                              spaceBetween={50}
                              autoplay
                              slidesPerView={slidesPerView}
                              mousewheel={{ enabled: true }}
                              navigation={{
                                nextEl: '.custom-r',
                                prevEl: '.custom-l'
                              }}
                              onSlideChange={({ isBeginning, isEnd }) => {
                                setIsBeginning(isBeginning);
                                setIsEnd(isEnd);
                              }}
                              onSwiper={(swiper) => console.log(swiper)}
                            >
                              <SwiperSlide>
                                <div className='bg-gray p-6 text-white rounded-xl mb-6'>
                                  {/* <div className='custom-l cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray'>
                                <QuestionMarkCircleIcon/>
                              </div> */}
                                  <h3 className='font-bold mb-2 mt-2 text-[18px]'>
                                    Monitoring of the environment
                                  </h3>
                                  <p className='text-[14px]'>
                                    According to the following parameters: humidity and precipitation, wind, outside temperature, atmospheric pressure, air quality.
                                  </p>
                                </div>
                              </SwiperSlide>
                              <SwiperSlide>
                                <div className='bg-gray p-6 text-white rounded-xl'>
                                  <h3 className='font-bold mb-4 text-[18px]'>
                                    Stall monitoring
                                  </h3>
                                  <p className='text-[14px]'>
                                    Video cameras with artificial intelligence technology identify people and record events in the stall.
                                  </p>
                                </div>
                              </SwiperSlide>
                              <SwiperSlide>
                                <div className='bg-gray p-6 text-white rounded-xl'>
                                  <h3 className='font-bold mb-4 text-[18px]'>
                                    Monitoring of the stall environment
                                  </h3>
                                  <p className='text-[14px]'>
                                    According to the following parameters: CO2 level, temperature, humidity, air quality, noise level.
                                  </p>
                                </div>
                              </SwiperSlide>
                            </Swiper>
                          </div>
                          
                        </div>
                        <div className='flex mb-12 items-center justify-center gap-4 mt-2'>
                            <div className={`custom-l cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                              <ArrowLeftIcon />
                            </div>
                            <div className={`custom-r cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                              <ArrowRightIcon />
                            </div>
                          </div>

                        <p className='text-white mt-4'>
                          The monitoring platform allows you to monitor stalls, trailers and other closed places where the horse stays, the environmental indicators of which are important to monitor.
                        </p>
                        <div className='text-white mb-6'>
                          <h3 className='font-semibold mt-6'>
                            There are two versions of the monitoring platform:
                          </h3>
                          <ul className='flex list flex-col gap-3 mt-6'>
                            <li className='text-[14px] flex items-center gap-2'>
                              for the whole stable
                              <div className='flex items-center gap-2'>
                                <img className='w-4 h-4' src={horse} />
                                <img className='w-4 h-4' src={horse} />
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </li>
                            <li className='text-[14px] flex items-center gap-2'>
                              for a single stall
                              <div className='flex items-center gap-2'>
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <p className='text-white mb-16'>
                          The platform analyzes data and processes all indicators using cameras and sensors in real-time. Some of the information is available on an interactive monitor located in the equipment room, while the complete information is displayed in the user's personal account and mobile application.
                          <br /><br />
                          Additionally, the monitoring platform works independently or in conjunction with other platforms of the EHOSS ecosystem.
                        </p>
                      </div>
                    </div>
                    <div className='w-full md:h-[100vh] mb-10 md:mb-0 mt-24 md:mt-0 md:mt-0 flex justify-start order-1 md:order-1'>
                      <img className='w-full max-w-3xl rounded-xl' src={img2}></img>
                    </div>
                  </div>
                </div>

                <div className='section image3'>
                  <div className='fixed z-10 h-[100vh]'>
                    <Line />
                  </div>
                  <div className='container h-[100vh] '>
                    <div className='pt-32 grid md:gap-8 gap-16 md:grid-cols-3 text-white'>

                      <div>
                        <div className='mb-4 flex justify-center items-center text-white p-2 w-12 h-12 rounded-full bg-lgray'>
                          <img src={icon2} />
                        </div>
                        <h3 className='font-semibold text-[22px] mb-2'>
                          Installation:
                        </h3>
                        <p>
                          Depending on the configuration, users can choose to install the equipment themselves, following the accompanying documentation and video materials or, if the configuration includes equipment that requires specialized knowledge, installation can be performed by EHOSS company specialists or by our partners.
                        </p>
                        <br />
                        <p>
                          The main requirement for installation is the ability to connect to the power grid and access to tools needed for installation.
                        </p>
                        <br />
                        <p>
                          The platform product kit includes all the necessary materials for easy installation.
                        </p>
                        <div className='md:mt-32 mt-16 pb-16'>
                          <div>
                            <button onClick={openRequestModal} className='main-btn text-black'>
                              Leave a request
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </ReactFullpage.Wrapper>
            </div>
          );
        }}
      />
    </>

  );
}

export default MonitoringPlatform;