import ReactFullpage from '@fullpage/react-fullpage';
import LineDot from '../../components/line-dot/LineDot';
import { DocumentIcon } from '@heroicons/react/24/outline';
import './privacypolicy.css'
import Footer from '../../container/footer/Footer';
import schemas from './../../assets/images/platforms-schemes/seno.svg'
import privacyPDF from './../../assets/files/privacy_policy.pdf'
import { Helmet } from "react-helmet";

function PrivacyPolicy() {

  function openRequestModal() {
    const modal = document.getElementById('contact-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }

  return (
    <>
        <Helmet>
        <title>EHOSS | Privacy Policy</title>
      </Helmet>
      <ReactFullpage
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={1000}
        navigation
        render={({ state, fullpageApi }) => {
          return (
            <div>
              <ReactFullpage.Wrapper>
                <div className='section overflow-hidden'>
                  <LineDot />
                  <div className='container'>
                    <div className='privacy md:w-1/2 h-[100vh] pt-32 text-white'>
                      <div className='flex flex-col items-start gap-4'>
                        <h2 className='text-[24px] md:text-[36px] font-bold'>
                          Privacy Policy EHOSS
                        </h2>
                        <p className='text-[14px]'>
                          EHOSS cares about your privacy: the protection of
                          personal data is important to us.
                          Read our Privacy Policy for a clear explanation of
                          how we collect, use, share and store your information.
                        </p>
                        <p className='text-[14px]'>
                          "To access and manage your EHOSS data, go to the ‘Manage my
                          EHOSS Account’ page. To make any amendments or send general
                          inquiries, please contact us via the <button className='underline font-medium' onClick={openRequestModal}>feedback form</button>.
                        </p>
                        <div className='bg-gray p-4 rounded-xl flex flex-col gap-2 mt-6'>
                          <p className='font-semibold text-[18px]'>Privacy Policy</p>
                          <p className=' opacity-50 text-[12px]'>Updated February 7, 2023</p>
                          <p className='text-[14px]'>This document describes how EHOSS collects, uses, and shares your personal data.</p>
                          <a href={privacyPDF} className=' underline flex gap-2 items-center font-semibold mt-6' target='_blank'>
                            View the PDF file
                            <DocumentIcon className='w-4 h-4' />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className='w-2/3 absolute right-[-400px] opacity-30 bottom-0' src={schemas} />
                </div>
                <Footer />
              </ReactFullpage.Wrapper>
            </div>
          );
        }}
      />
    </>

  );
}

export default PrivacyPolicy;