import logo from "../../assets/images/logo.svg";
import horse from './../../assets/images/horse.png'

function Registration() {
    return (
        <section className="overflow-hidden">
            <img className="horse" src={horse} />
            <div className="grid grid-cols-2 h-[100vh]">
                <div className=" flex justify-center items-center">
                    <div className="relative p-4 w-full max-w-md max-h-full">

                        <div className="relative rounded-lg dark:bg-gray-700">

                            <div className="flex items-center justify-between p-4 md:p-5 rounded-t">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Sign up
                                </h3>
                            </div>
                            <div className="bg-white rounded-xl p-4 text-center m-5 text-black font-bold">
                                <p>
                                    GOOGLE
                                </p>

                            </div>
                            <div className="p-4 md:p-5">
                                <form className="space-y-4" action="#">
                                    <div>
                                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your name</label>
                                        <input type="name" name="name" id="name" className="bg-gray border border-gray-300 text-white  text-sm rounded-lg focus:red focus:border-red block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Viktor" required />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                        <input type="email" name="email" id="email" className="bg-gray border border-gray-300 text-white  text-sm rounded-lg focus:red focus:border-red block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required />
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
                                        <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray border border-gray-300 text-white text-sm rounded-lg focus:red focus:border-red block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                                    </div>
                                    {/* <div className="flex justify-between">
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required />
                                            </div>
                                            <label htmlFor="remember" className="ms-2 text-sm font-medium text-white">Remember me</label>
                                        </div>
                                        <a href="#" className="text-sm text-white hover:underline">Lost Password?</a>
                                    </div> */}
                                    <button type="submit" className="w-full  text-red bg-white  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Login to your account</button>
                                    <div className="text-sm font-medium text-white">
                                        Not registered? <a href="#" className="text-red hover:underline">Create account</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </div>
        </section>
    );
}

export default Registration;
