// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("gothampro_light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("gothampro_black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("gothampro.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("gothampro_bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("gothampro_medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Gotham Pro';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
    font-weight: 500;
    font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,4CAA+B;IAC/B,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,4CAA+B;IAC/B,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,4CAAyB;IACzB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,4CAA8B;IAC9B,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,4CAAgC;IAChC,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["@font-face {\r\n    font-family: 'Gotham Pro';\r\n    src: url('gothampro_light.ttf');\r\n    font-weight: 300;\r\n    font-style: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Gotham Pro';\r\n    src: url('gothampro_black.ttf');\r\n    font-weight: 900;\r\n    font-style: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Gotham Pro';\r\n    src: url('gothampro.ttf');\r\n    font-weight: normal;\r\n    font-style: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Gotham Pro';\r\n    src: url('gothampro_bold.ttf');\r\n    font-weight: bold;\r\n    font-style: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Gotham Pro';\r\n    src: url('gothampro_medium.ttf');\r\n    font-weight: 500;\r\n    font-style: normal;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
