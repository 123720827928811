import './breadcrumbs.css';

function Breadcrumbs({ items }) {
  return (
    <nav aria-label="breadcrumbs">
      <ol className="breadcrumbs-list text-white flex items-center">
        <div className='flex breadcrumb-item breadcrumb-item-home'>
          <li className='text-[12px] md:text-[14px]'>
            <a href='/'>
              <svg  className='md:w-5 w-4 h-4 homeicon md:h-5' viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.833984 8.00005L7.54948 1.2838C7.87948 0.954553 8.41348 0.954553 8.74273 1.2838L15.459 8.00005M2.52148 6.31255V13.9063C2.52148 14.3721 2.89948 14.7501 3.36523 14.7501H6.45898V11.0938C6.45898 10.6281 6.83698 10.2501 7.30273 10.2501H8.99023C9.45598 10.2501 9.83398 10.6281 9.83398 11.0938V14.7501H12.9277C13.3935 14.7501 13.7715 14.3721 13.7715 13.9063V6.31255M5.33398 14.7501H11.5215" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </a>
          </li>
          <svg className='md:h-6 h-4 w-4 md:w-6 transition-[0.3] cursor-pointer -rotate-90' xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
          </svg>
        </div>

        {items.map((item, index) => (
          <div className='flex breadcrumb-item'>
            <li className='text-[12px] md:text-[16px]' key={index}>
            <a href={item.url}>{item.label.length > 5 && window.innerWidth < 320 ? `${item.label.substring(0, 5)}...` : item.label}</a>
            </li>
            <svg className='md:h-6 h-4 w-4 md:w-6 transition-[0.3] cursor-pointer -rotate-90' xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
            </svg>
          </div>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;