// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#card:hover img {
    transform: scale(1.05);
    transition: 0.3s;
}
.card1 {
    position: relative;
}
.card1::before {
    content: "";
    width: 100px;
    height: 100%;
    background: linear-gradient(to right, #2A2A2A, transparent);
    position: absolute;
    z-index: 22;
}

@media (max-width: 830px) {
    .card1::before {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/ecosystem/ecosystem.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;AACpB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,2DAA2D;IAC3D,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["#card:hover img {\r\n    transform: scale(1.05);\r\n    transition: 0.3s;\r\n}\r\n.card1 {\r\n    position: relative;\r\n}\r\n.card1::before {\r\n    content: \"\";\r\n    width: 100px;\r\n    height: 100%;\r\n    background: linear-gradient(to right, #2A2A2A, transparent);\r\n    position: absolute;\r\n    z-index: 22;\r\n}\r\n\r\n@media (max-width: 830px) {\r\n    .card1::before {\r\n        display: none;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
