// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 830px) {
    .home {
        width: auto !important;
    }
    
}

.custom-line-blog {
    position: fixed !important;
    z-index: -1 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/blog/blog.css"],"names":[],"mappings":"AAAA;IACI;QACI,sBAAsB;IAC1B;;AAEJ;;AAEA;IACI,0BAA0B;IAC1B,sBAAsB;AAC1B","sourcesContent":["@media (max-width: 830px) {\r\n    .home {\r\n        width: auto !important;\r\n    }\r\n    \r\n}\r\n\r\n.custom-line-blog {\r\n    position: fixed !important;\r\n    z-index: -1 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
