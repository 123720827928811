import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/images/logo.svg";
import horse from './../../assets/images/horse.png';
import './adminLogin.css';

function AdminLogin() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        const response = await fetch('https://form.ehoss.com/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        });

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem('token', data.token); // Сохраняем токен
            navigate('/panel'); // Перенаправляем на страницу admin-profile
        } else {
            // Обработка ошибок
            console.error('Login failed');
        }
    };



    return (
        <section className="overflow-hidden">
            <div className='container'>
                <img className="horse" src={horse} alt="horse"/>
                <div className='flex h-[100vh] justify-center items-center'>
                    <form className="bg-gray p-6 rounded-2xl items-center flex flex-col gap-4" onSubmit={handleLogin}>
                        <div className="flex gap-2">
                            <img className="w-[140px] mb-4" src={logo} alt="logo"/><span className="text-[20px] uppercase text-white">panel</span>
                        </div>
                        <div>
                            <p className="text-white mb-2">Login</p>
                            <input
                                className="bg-lgray border-2 border-[#A6A6A6] w-[250px] rounded-l p-2 text-white"
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div>
                            <p className="text-white mb-2">Password</p>
                            <input
                                className="bg-lgray border-2 border-[#A6A6A6] w-[250px] rounded-l p-2 text-white"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="w-full mt-5">
                            <button className="main-btn" type="submit">Log in</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default AdminLogin;