import React, { useEffect } from 'react';
import LineDot from '../../components/line-dot/LineDot';
import logo from './../../assets/images/logo.svg'
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

import tg from './icons/tg.svg'
import mail from './icons/mail.svg'
import adress from './icons/adress.svg'

import website from './icons/website.svg'
import linked from './icons/in.svg'
import inst from './icons/inst.svg'
import fb from './icons/fb.svg'
import rg from './icons/rg.svg'
import cb from './icons/cb.svg'
import tw from './icons/x.svg'
import tt from './icons/tt.svg'
import yt from './icons/yt.svg'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import './welcome.css'

function Welcome() {
    useEffect(() => {
        // Добавляем класс hidden к хедеру при монтировке компонента
        const header = document.querySelector('header');
        header.classList.add('hidden');

        // Удаляем класс hidden из хедера при размонтировке компонента
        return () => {
            header.classList.remove('hidden');
        };
    }, []); // Пустой массив зависимостей означает, что эффект будет выполняться только один раз при монтировке компонента


    const social = [
        {
            img: website,
            text: 'WebSite',
            link: 'https://ehoss.com/',
        },
        {
            img: linked,
            text: 'LinkedIn',
            link: 'https://www.linkedin.com/company/ehoss/',
        },
        {
            img: inst,
            text: 'Instagram',
            link: 'https://www.instagram.com/ehossofficial/',
        },
        {
            img: fb,
            text: 'Facebook',
            link: 'https://www.facebook.com/ehossofficial/',
        },
        {
            img: rg,
            text: 'Researchgate',
            link: 'https://www.researchgate.net/publication/361401127_EHOSS_Trailer_Platform_was_implemented_by_EHOSS_to_impact_horse_stress',
        },
        {
            img: cb,
            text: 'crunchbase',
            link: 'https://www.crunchbase.com/organization/ehoss',
        },
        {
            img: tw,
            text: 'X (Twitter)',
            link: 'https://twitter.com/ehossofficial/',
        },
        {
            img: tt,
            text: 'TikTok',
            link: 'https://www.tiktok.com/@ehossofficial',
        },
        {
            img: yt,
            text: 'YouTube',
            link: 'https://www.youtube.com/@ehoss',
        }
    ]

    const contacts = [
        {
            img: tg,
            text: 'Telegram',
            link: 'https://t.me/ehossofficial',
        },
        {
            img: mail,
            text: 'welcome@ehoss.com',
            link: 'mailto:welcome@ehoss.com',
        },
        {
            img: adress,
            text: 'areal Hydronika Nova, Hala M1, EHOSS, Gogolova 326/18, 85202 Bratislava Petrzalka, Slovakia',
            link: 'https://www.google.com/maps/place/EHOSS/@48.0840707,16.9166227,41512m/data=!3m1!1e3!4m7!3m6!1s0x476c8fcbd80be01d:0xbb86eab89d6c9ea!8m2!3d48.1223371!4d17.0922652!10e1!16s%2Fg%2F11shf0qhvg?entry=ttu',
        }
    ]


    return (
        <section>
            <div className='h-full -z-1 fixed'>
                <LineDot />
            </div>
            
            <div className='container'>
                <div className='flex md:flex-row flex-col pt-10 gap-4 items-center justify-between'>
                    <a href='https://ehoss.com/welcome'>
                        <img src={logo} className='w-40' alt='Main Logo'></img>
                    </a>
                    
                    <h1 className='md:text-[20px] text-[14px] mb-0 md:text-start text-center md:mb-0 text-white'>
                        SCIENCE AND TECHNOLOGY TO <br className='block md:hidden' /> IMPROVE THE LIVES <br/>
                        OF ANIMALS AND THE PEOPLE <br className='block md:hidden' /> AROUND THEM
                    </h1>
                </div>

                <div id='welcome-slider' className='pb-5'>
                    <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={{
                            nextEl: '.custom-r',
                            prevEl: '.custom-l'
                        }}
                        pagination={{
                            clickable: true // Разрешаем нажимать на пагинацию для перехода к слайдам
                        }}
                        autoplay={{
                            delay: 4500, 
                            disableOnInteraction: false 
                        }}
                        // onSlideChange={() => console.log('slide change')}
                        // onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>
                            <a href='https://ehoss.com/reusing-platform' id='slide1' className='bg-gray block mb-6 md:mb-10 mt-10 md:h-[200px] rounded-xl relative'>
                                <div className='md:ml-[40%] p-4 md:pt-10'>
                                    <h3 className='text-white md:text-[32px] md:pt-0 pt-24 font-medium mb-2 uppercase'>
                                        Monitize manure,  <br className='block md:hidden' />
                                        preserve the planet
                                    </h3>
                                    <p className='text-white opacity-50 md:text-[16px] mb-0 md:mb-4 text-[14px]'>
                                        Reusing Platform
                                        
                                    </p>
                                    <a
                                        className="font-bold text-[12px] flex items-center gap-1 text-white mt-2"
                                        href="">
                                        Read more
                                        <ArrowUpRightIcon className="h-4 w-4" />
                                    </a>
                                </div>
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a href='https://ehoss.com/forage-platform' id='slide2' className='bg-gray block mb-6 md:mb-10 mt-10 md:h-[200px] rounded-xl relative'>
                                <div className='md:ml-[40%] p-4 md:pt-10'>
                                    <h3 className='text-white md:text-[32px] md:pt-0 pt-24 font-medium mb-2 uppercase'>
                                       Feed your horse better hay
                                    </h3>
                                    <p className='text-white opacity-50 md:text-[16px] mb-8 md:mb-4 text-[14px]'>
                                        Forage Platfotm
                                    </p>
                                    <a
                                        className="font-bold text-[12px] flex items-center gap-1 text-white mt-2"
                                        href="">
                                        Read more
                                        <ArrowUpRightIcon className="h-4 w-4" />
                                    </a>
                                </div>
                            </a>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div>
                    <h3 className='font-semibold md:text-start text-center text-white mb-4 text-[24px]'>
                        GET IN TOUCH
                    </h3>
                </div>
                <div className='grid grid-cols-3 md:gap-4 pl-4 md:pl-0 pr-4 md:pr-0'>
                    {social.map((item, index) => (
                        <a key={index} href={item.link} target='_blank' className="md:bg-gray flex gap-4 p-4 items-center md:justify-start justify-center rounded-xl transition-all overflow-hidden md:border md:border-transparent hover:border-red">
                            <img className='w-10' src={item.img} alt={`EHOSS ${item.text}`} />
                            <p className='text-white md:block hidden font-medium uppercase'>
                                {item.text}
                            </p>
                        </a>
                    ))}
                </div>

                <div className='grid welcome-grid-2 pb-10 border-t border-gray mt-4 pt-4 grid-cols-3 gap-4 pl-4 md:pl-0 pr-4 md:pr-0'>
                    {contacts.map((item, index) => (
                        <a key={index} href={item.link} className="md:bg-gray flex gap-4 p-4 md:justify-start items-center justify-center rounded-xl transition-all overflow-hidden md:border md:border-transparent hover:border-red">
                            <img src={item.img} alt={`EHOSS ${item.text}`} />
                            <p className='text-white md:block hidden font-medium uppercase'>
                                {item.text}
                            </p>
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Welcome;