import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';
import LineDot from '../components/line-dot/LineDot';
import Breadcrumbs from '../components/breadcrumbs/Breadcrumbs';
import { ClockIcon, HashtagIcon, TagIcon } from '@heroicons/react/24/outline';
import Footer from '../container/footer/Footer';

function BlogMain() {
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        async function fetchPosts() {
            try {
                const response = await fetch('https://form.ehoss.com/api/posts');
                const data = await response.json();
                setPosts(data);

                // Extract unique categories from the posts
                const uniqueCategories = [...new Set(data.map(post => post.category))];
                setCategories(uniqueCategories);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        }

        fetchPosts();
    }, []);

    useEffect(() => {
        // Check if there is a category in the URL
        const params = new URLSearchParams(location.search);
        const category = params.get('category');
        if (category) {
            setSelectedCategory(category.replace(/-/g, ' ')); // Replace hyphens with spaces
        } else {
            setSelectedCategory('');
        }
    }, [location.search]);

    useEffect(() => {
        // Filter posts based on the selected category
        if (selectedCategory) {
            setFilteredPosts(posts.filter(post => post.category === selectedCategory));
        } else {
            setFilteredPosts(posts);
        }
    }, [selectedCategory, posts]);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        // Update the URL with the selected category, replacing spaces with hyphens
        const formattedCategory = category.replace(/ /g, '-'); // Replace spaces with hyphens
        navigate(`?category=${formattedCategory}`);
    };

    const [visiblePosts, setVisiblePosts] = useState(4); // изначально отображаем 8 постов

    // Функция для загрузки дополнительных постов
    const loadMorePosts = () => {
        setVisiblePosts(prevVisiblePosts => prevVisiblePosts + 4); // увеличиваем количество видимых постов на 8
    };

    return (
        <>
            <div className="blog-container container">
                <Helmet>
                    <title>EHOSS | Blog</title>
                </Helmet>
                <div className="fixed z-[-10] left-0 w-24 h-[100vh]">
                    <LineDot />
                </div>
                <div className='pt-24'>
                    {/* Sidebar for categories */}
                    <div>
                        <Breadcrumbs
                            items={[
                                { label: 'Company', url: '#' },
                                { label: 'Blog', url: '/blog' }
                            ]}
                        />
                        <h2 className="text-[48px] font-semibold text-white uppercase mt-12 mb-10">Blog</h2>
                    </div>
                    <div className="mb-8">
                        <ul className="text-white flex gap-4">
                            <li
                                className={`cursor-pointer flex gap-1 items-center px-4 rounded-xl border py-2 border-white ${!selectedCategory ? 'text-red bg-white' : ''}`}
                                onClick={() => handleCategoryClick('')}
                            >
                                <HashtagIcon className='w-4 h-4' />
                                All
                            </li>
                            {categories.map((category, index) => (
                                <li
                                    key={index}
                                    className={`cursor-pointer  flex gap-1 items-center px-4 rounded-xl border py-2 border-white ${selectedCategory === category ? 'text-red bg-white' : ''}`}
                                    onClick={() => handleCategoryClick(category)}
                                >
                                    <HashtagIcon className='w-4 h-4' />
                                    {category}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* List of posts */}
                    <div className="grid gap-4 md:grid-cols-2">
                        {filteredPosts
                            .sort((a, b) => new Date(b.date) - new Date(a.date))
                            .slice(0, visiblePosts) // отображаем только видимые посты
                            .map(post => (
                                <a href={`blog/${post.link}`} key={post._id} className="post-item md:flex items-center gap-4 py-4">
                                    <img className='bg-gray w-full md:w-1/2 h-[250px] object-cover rounded-xl mb-4' src={post.image} alt={post.title} />
                                    <div className='flex flex-col md:w-1/2'>
                                        <div className='flex mb-2 gap-4 items-center'>
                                            <div className='flex gap-1 bg-white p-1 px-2 rounded-xl'>
                                                <TagIcon className='text-red w-4' />
                                                <p className='text-black font-semibold text-[12px]'>{post.category}</p>
                                            </div>
                                            <div className='flex gap-1 items-center'>
                                                <ClockIcon className='text-white w-4' />
                                                <p className='text-white text-[12px]'>{post.date}</p>
                                            </div>
                                        </div>
                                        <h2 className="text-[18px] text-white font-semibold">
                                            {post.title}
                                        </h2>
                                        <p className="text-gray-600 text-[13px] text-white opacity-70 mt-2">{post.minidesckr}</p>
                                    </div>

                                </a>
                            ))}
                    </div>

                    {/* Кнопка для загрузки дополнительных постов */}
                    {visiblePosts < filteredPosts.length && (
                        <div className="flex justify-center mt-6">
                            <button onClick={loadMorePosts} className="bg-white text-red py-2 px-4 rounded">
                                Load more
                            </button>
                        </div>
                    )}
                </div>




            </div>
            <Footer />
        </>

    );
}

export default BlogMain;
