// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map::after{
    content: '';
    width: 300px;
    pointer-events: none;
    top: 0;
    position: absolute;
    height: 100%;
    background: linear-gradient(90deg, #1d1d1d, transparent);
    z-index: 1;
}

@media (max-width: 830px) {
    .map::after{
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/contacts/contacts.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,MAAM;IACN,kBAAkB;IAClB,YAAY;IACZ,wDAAwD;IACxD,UAAU;AACd;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".map::after{\r\n    content: '';\r\n    width: 300px;\r\n    pointer-events: none;\r\n    top: 0;\r\n    position: absolute;\r\n    height: 100%;\r\n    background: linear-gradient(90deg, #1d1d1d, transparent);\r\n    z-index: 1;\r\n}\r\n\r\n@media (max-width: 830px) {\r\n    .map::after{\r\n        display: none;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
