// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./3.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eskiz {
    background-position: right bottom;
    background-size: 50%;
    background-repeat: no-repeat;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: red !important;
}

.swiper-pagination-bullet {
    background-color: gray;
    width: 6px !important;
    padding: 3px;
    height: 6px !important;
}

.swiper-pagination {
    transform: translateY(10px);
}

#forage-2 {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    z-index: -1;
    background-size: cover;
    pointer-events: none;
    background-position: center;
}

@media (max-width: 670px) {
    #forage-2 {
        background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
        z-index: -1;
        background-size: cover;
        background-position: 600px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/forage-platform/forageplatform.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,oBAAoB;IACpB,4BAA4B;AAChC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,yDAA8B;IAC9B,WAAW;IACX,sBAAsB;IACtB,oBAAoB;IACpB,2BAA2B;AAC/B;;AAEA;IACI;QACI,yDAA8B;QAC9B,WAAW;QACX,sBAAsB;QACtB,0BAA0B;IAC9B;AACJ","sourcesContent":[".eskiz {\r\n    background-position: right bottom;\r\n    background-size: 50%;\r\n    background-repeat: no-repeat;\r\n}\r\n\r\n.swiper-pagination-bullet.swiper-pagination-bullet-active {\r\n    background-color: red !important;\r\n}\r\n\r\n.swiper-pagination-bullet {\r\n    background-color: gray;\r\n    width: 6px !important;\r\n    padding: 3px;\r\n    height: 6px !important;\r\n}\r\n\r\n.swiper-pagination {\r\n    transform: translateY(10px);\r\n}\r\n\r\n#forage-2 {\r\n    background-image: url(./3.png);\r\n    z-index: -1;\r\n    background-size: cover;\r\n    pointer-events: none;\r\n    background-position: center;\r\n}\r\n\r\n@media (max-width: 670px) {\r\n    #forage-2 {\r\n        background-image: url(./3.png);\r\n        z-index: -1;\r\n        background-size: cover;\r\n        background-position: 600px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
