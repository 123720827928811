import React, { useEffect, useState } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import LineDot from '../../components/line-dot/LineDot';
import Line from '../../components/line/Line';
import Footer from '../../container/footer/Footer';
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import icon1 from './icons/icon1.svg'
import icon2 from './icons/icon2.svg'
import icon3 from './icons/icon3.svg'

function GrainPlatform() {

  const [slidesPerView, setSlidesPerView] = useState(calculateSlidesPerView());
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setSlidesPerView(calculateSlidesPerView());
  };

  function calculateSlidesPerView() {
    if (window.innerWidth < 768) {
      return 1;
    } else if (window.innerWidth < 1280) {
      return 3;
    } else {
      return 4;
    }
  }

  const location = useLocation();

  const [activeTab, setActiveTab] = useState('title1');

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  function openRequestModal() {
    const modal = document.getElementById('request-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }


  return (
    <>
      <ReactFullpage
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={1000}
        navigation
        anchors={
          [
            'grain-platform-prepares-horse-food',
            'grain-platform-versions',
            'grain-platform-benefits',
            'grain-platforms-for-horses-installation',
            'footer'
          ]
        }
        render={({ state, fullpageApi }) => {
          return (
            <div>
              <ReactFullpage.Wrapper>
                <Helmet>
                  <title>EHOSS | Grain and Supplement Horse Feeding Management in One Place</title>
                  <meta name="keywords" content="gain horse feed, horse grain, horse feed supplements" />
                  <meta name="robots" content="index, follow" />
                  <meta name="description" content="Equine grains & concentrates feed preparation and storage in one piece of equipment. You will need just to load and program!" />
                  <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
                </Helmet>
                <div className='section'>
                  <div className='z-[-10] md:z-10 fixed left-0 w-3 h-full'>
                    <LineDot />
                  </div>
                  <div className='container flex justify-between flex-wrap-reverse md:flex-nowrap flex-col md:flex-row items-start'>
                    <div className='flex pt-24 w-full md:w-auto'>
                      <Breadcrumbs
                        items={[
                          { label: 'Ecosystem', url: '/ecosystem' },
                          { label: 'Animal Care', url: '/ecosystem' },
                          { label: 'Grain Platform', url: '/grain-platform' },
                        ]}
                      />
                    </div>
                    <div className='pb-20 md:order-1 order-2 md:pt-24 flex flex-col items-start justify-between h-[100vh]'>
                      <div className='max-w-[600px]'>
                        <h1 className='text-white mt-12 font-medium text-[38px]'>
                          Grain Platform
                        </h1>
                        <p className='text-white'>
                          Under development, release planned for the end of 2025
                        </p>
                        <div className='text-white mt-12 mb-12'>
                          <div className='md:mt-0 mt-12 mb-12 md:mb-0 md:absolute w-[100%] md:top-0 md:h-[100vh] right-0 left-0 -z-10'>
                            <img title='EHOSS Grain Platform is an equipment for horse feed mixing' alt='Equipment for horse feed mixing' className='md:w-[100%] rounded-xl md:rounded-none md:h-[100vh] md:object-cover' src='https://static.ehoss.com/assets/images/grain-platform/equipmentforhorsefeed.png'></img>
                          </div>
                          <p>
                            The platform equipment prepares a portion of various products for consumption, such as concentrated feeds, cereals, muesli, vitamin supplements and other mixes. Basically, all possible types of forage but hay. <br />
                            Depending on the diet of your horse, all the necessary products are loaded into the special tanks that inside of the platform and are then mixed at recommended proportions.
                          </p>
                        </div>
                      </div>
                      <div className='flex md:flex-row flex-col md:w-auto w-full justify-center gap-4 mb-16'>
                        <button onClick={openRequestModal} className='main-btn text-start whitespace-nowrap cursor-pointer'>
                          Leave a request
                        </button>
                        <a onClick={() => fullpageApi.moveTo(2)} className='main-btn text-start whitespace-nowrap two cursor-pointer'>
                          learn more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='section'>
                  <Line />
                  <div className='container flex flex-wrap-reverse md:flex-nowrap flex-col md:flex-row items-center'>
                    <div className='pb-20 pt-24 m:pt-0 md:order-1 order-2 md:pt-32 flex flex-col items-start justify-between h-[100vh]'>
                      <div className='max-w-[700px]'>
                        <div className='text-white mb-12'>
                          <h3 className='text-white font-medium text-[24px] md:text-[38px]'>
                            Grain Platform available in two versions:
                          </h3>
                          <div className='md:mt-0 mt-12 mb-12 md:mb-0 md:absolute w-[100%] md:top-0 md:h-[100vh] right-0 left-0 -z-10'>
                            <img title='EHOSS Grain Platform is an equipment for horse feed mixing' alt='Equipment for horse feed mixing' className='md:w-[100%] md:h-[100vh] rounded-xl md:rounded-none md:object-cover' src='https://static.ehoss.com/assets/images/grain-platform/equipmentforhorsefeed2.png'></img>
                          </div>
                          <div className='grid mt-4 mb-4 grid-cols-1 md:grid-cols-2 gap-2 p-1 bg-gray rounded-xl'>
                            <div
                              id='title1'
                              className={`text-center flex gap-1 items-center justify-center cursor-pointer p-3  rounded-xl bg-lgray ${activeTab === 'title1' ? 'bg-white text-black' : ''}`}
                              onClick={() => handleClick('title1')}
                            >
                              For a single stall
                              <svg width="16" height="16" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.47247 2.676C7.92917 3.1602 8.34525 3.77854 8.7361 4.50029C9.12696 5.22203 8.83066 5.64474 8.83066 5.64474C8.83066 5.64474 9.36091 7.43828 10.4319 8.61907C10.4319 8.61907 10.593 8.74832 10.7114 8.86221C10.8627 9.00684 10.6701 9.31077 10.7548 9.91514C10.8466 10.5698 9.97381 11.0959 9.72585 11.1511C9.54724 11.1909 9.41415 11.1448 9.1907 11.054C9.04711 11.2161 8.49795 11.1854 8.26539 11.1274C7.87384 11.0309 7.98591 10.5335 7.89695 10.3735C7.59365 9.82501 6.86307 9.25557 5.79067 8.79444C4.11307 8.51077 3.77055 7.47741 3.89663 6.4657C3.89663 6.4657 3.3895 7.52073 4.17401 8.37453C3.71101 8.39968 3.2452 9.72649 3.04627 10.4468L0.240234 5.22623C0.240234 5.22623 2.20642 2.05696 6.12059 2.23583C6.12059 2.23583 7.19229 2.12823 7.9733 0.780457C8.34945 0.131374 8.1176 1.94797 7.47107 2.67531L7.47247 2.676Z" fill={activeTab === 'title1' ? 'black' : 'white'} />
                              </svg>
                            </div>
                            <div
                              id='title2'
                              className={`text-center flex gap-1 items-center justify-center cursor-pointer p-3  rounded-xl bg-lgray ${activeTab === 'title2' ? 'bg-white text-black' : ''}`}
                              onClick={() => handleClick('title2')}
                            >
                              For the whole stable
                              <svg width="16" height="16" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.47247 2.676C7.92917 3.1602 8.34525 3.77854 8.7361 4.50029C9.12696 5.22203 8.83066 5.64474 8.83066 5.64474C8.83066 5.64474 9.36091 7.43828 10.4319 8.61907C10.4319 8.61907 10.593 8.74832 10.7114 8.86221C10.8627 9.00684 10.6701 9.31077 10.7548 9.91514C10.8466 10.5698 9.97381 11.0959 9.72585 11.1511C9.54724 11.1909 9.41415 11.1448 9.1907 11.054C9.04711 11.2161 8.49795 11.1854 8.26539 11.1274C7.87384 11.0309 7.98591 10.5335 7.89695 10.3735C7.59365 9.82501 6.86307 9.25557 5.79067 8.79444C4.11307 8.51077 3.77055 7.47741 3.89663 6.4657C3.89663 6.4657 3.3895 7.52073 4.17401 8.37453C3.71101 8.39968 3.2452 9.72649 3.04627 10.4468L0.240234 5.22623C0.240234 5.22623 2.20642 2.05696 6.12059 2.23583C6.12059 2.23583 7.19229 2.12823 7.9733 0.780457C8.34945 0.131374 8.1176 1.94797 7.47107 2.67531L7.47247 2.676Z" fill={activeTab === 'title2' ? 'black' : 'white'} />
                              </svg>
                              <svg width="16" height="16" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.47247 2.676C7.92917 3.1602 8.34525 3.77854 8.7361 4.50029C9.12696 5.22203 8.83066 5.64474 8.83066 5.64474C8.83066 5.64474 9.36091 7.43828 10.4319 8.61907C10.4319 8.61907 10.593 8.74832 10.7114 8.86221C10.8627 9.00684 10.6701 9.31077 10.7548 9.91514C10.8466 10.5698 9.97381 11.0959 9.72585 11.1511C9.54724 11.1909 9.41415 11.1448 9.1907 11.054C9.04711 11.2161 8.49795 11.1854 8.26539 11.1274C7.87384 11.0309 7.98591 10.5335 7.89695 10.3735C7.59365 9.82501 6.86307 9.25557 5.79067 8.79444C4.11307 8.51077 3.77055 7.47741 3.89663 6.4657C3.89663 6.4657 3.3895 7.52073 4.17401 8.37453C3.71101 8.39968 3.2452 9.72649 3.04627 10.4468L0.240234 5.22623C0.240234 5.22623 2.20642 2.05696 6.12059 2.23583C6.12059 2.23583 7.19229 2.12823 7.9733 0.780457C8.34945 0.131374 8.1176 1.94797 7.47107 2.67531L7.47247 2.676Z" fill={activeTab === 'title2' ? 'black' : 'white'} />
                              </svg>
                              <svg width="16" height="16" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.47247 2.676C7.92917 3.1602 8.34525 3.77854 8.7361 4.50029C9.12696 5.22203 8.83066 5.64474 8.83066 5.64474C8.83066 5.64474 9.36091 7.43828 10.4319 8.61907C10.4319 8.61907 10.593 8.74832 10.7114 8.86221C10.8627 9.00684 10.6701 9.31077 10.7548 9.91514C10.8466 10.5698 9.97381 11.0959 9.72585 11.1511C9.54724 11.1909 9.41415 11.1448 9.1907 11.054C9.04711 11.2161 8.49795 11.1854 8.26539 11.1274C7.87384 11.0309 7.98591 10.5335 7.89695 10.3735C7.59365 9.82501 6.86307 9.25557 5.79067 8.79444C4.11307 8.51077 3.77055 7.47741 3.89663 6.4657C3.89663 6.4657 3.3895 7.52073 4.17401 8.37453C3.71101 8.39968 3.2452 9.72649 3.04627 10.4468L0.240234 5.22623C0.240234 5.22623 2.20642 2.05696 6.12059 2.23583C6.12059 2.23583 7.19229 2.12823 7.9733 0.780457C8.34945 0.131374 8.1176 1.94797 7.47107 2.67531L7.47247 2.676Z" fill={activeTab === 'title2' ? 'black' : 'white'} />
                              </svg>
                            </div>
                          </div>
                          <div>
                            <div id='list1' className={activeTab === 'title1' ? 'block' : 'hidden'}>
                              <div>
                                <p className='text-[14px]'>
                                  A fixed or modular app-controlled platform system, used for one horse
                                </p>
                                <h3 className='mt-2 mb-2 font-medium '>Characteristics of the platform equipment for a single stall:</h3>
                                <ul className='list flex flex-col gap-1 text-[13px]'>
                                  <li>
                                    <h4>
                                      the platform is installed inside the horse's stall
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      products are loaded into tanks within the platform equipment
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      the platform makes portions from the available products,
                                      taking into account the specifications of the horse's diet
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      ready-to-eat portions are served to the horse
                                      at a specific time set in the application
                                    </h4>
                                  </li>

                                  <li>
                                    <h4>
                                      drinking water is supplied to a separate compartment
                                    </h4>
                                  </li>
                                </ul>
                              </div>

                              <div>
                                <h3 className='mt-2 mb-2'>App allows you to:</h3>
                                <ul className='list flex flex-col gap-1 text-[13px]'>
                                  <li>
                                    <h4>
                                      monitor the amount of water and the total amount of the products consumed
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      check the composition and dosage of individual products included in the portion
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      monitor the individual preparation of each meal
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      record feeding times and frequency
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      monitor platform fullness
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      collect and analyse data
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      receive online veterinary and nutritional support, as well as management of the horse's diet
                                    </h4>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div id='list2' className={activeTab === 'title2' ? 'block' : 'hidden'}>
                              <div>
                                <p className='text-[14px]'>
                                  A modular, app-controlled platform system used for more than one horse
                                </p>
                                <h3 className='mt-2 font-medium mb-2'>Characteristics of the platform equipment for the whole stable:</h3>
                                <ul className='list flex flex-col gap-1 text-[13px]'>
                                  <li>
                                    <h4>
                                      the equipment is installed within the stable
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      the products are loaded into closed tanks which are connected to the platform equipment
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      the platform automatically makes individual portions for each horse, considering their specific dietary requirements
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      the portions are dispensed into separate containers, ready to be distributed to each horse's stall
                                    </h4>
                                  </li>
                                </ul>
                              </div>
                              <div>
                                <h3 className='mt-2 mb-2'>App allows you to:</h3>
                                <ul className='list flex flex-col gap-1 text-[13px]'>
                                  <li>
                                    <h4>
                                      track the total amount of the products consumed
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      check the composition of the feed and proportion of different products
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      monitor preparation of every feed portion for every horse
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      monitor how often the feed is served
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      check how full is the platform
                                    </h4>
                                  </li>

                                  <li>
                                    <h4>
                                      store and analyse data
                                    </h4>
                                  </li>
                                  <li>
                                    <h4>
                                      receive online veterinary and nutritional support and help with your horse's diet
                                    </h4>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex md:flex-row flex-col md:w-auto w-full justify-center gap-4 pb-16'>
                        <button onClick={openRequestModal} className='main-btn text-start whitespace-nowrap cursor-pointer'>
                          Leave a request
                        </button>
                        <a onClick={() => fullpageApi.moveTo(3)} className='main-btn text-start whitespace-nowrap two cursor-pointer'>
                          learn more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>




                <section id="reusing-section" className="overflow-hidden section">
                  <Line />
                  <div className="container relative h-[100vh]">

                    <div className="pt-24">
                      <div className="flex md:flex-row justify-between items-center mb-4 md:mb-10">
                        <h2 className="text-[32px] font-semibold text-white">
                          One-Horse Platform helps with:
                        </h2>
                        {/* <div className='md:flex hidden z-20 items-center justify-center gap-4 mt-2'>
                          <div className={`custom-l cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                            <ArrowLeftIcon />
                          </div>
                          <div className={`custom-r cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                            <ArrowRightIcon />
                          </div>
                        </div> */}
                      </div>
                    </div>



                    <div className='w-full hidden md:block pb-12 cursor-grab relative slider-shadow'>
                      <Swiper
                        modules={[Navigation]}
                        spaceBetween={50}
                        slidesPerView={slidesPerView}
                        className={`sw1 ${isBeginning ? '' : 'sw2'}`}
                        mousewheel={{ enabled: false }}
                        navigation={{
                          nextEl: '.custom-r',
                          prevEl: '.custom-l'
                        }}
                        onSlideChange={({ isBeginning, isEnd }) => {
                          setIsBeginning(isBeginning);
                          setIsEnd(isEnd);
                        }}
                        onSwiper={(swiper) => console.log(swiper)}
                      >
                        <SwiperSlide>
                          <div className=' text-white text-[14px] pointer-events-none'>
                            <h3 className='font-bold mb-2 text-[16px] md:text-[14px] mt-6'>
                              With an individual control of the horse's diet
                            </h3>
                            <div id='start-line' className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>
                            <p className='text-[14px]'>
                              Especially if a horse has a special diet
                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>
                            <h3 className='font-bold mb-2 text-[16px] md:text-[14px] mt-6'>
                              To monitor the amount of the feed consumed
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>
                            <p className='text-[14px]'>
                              The platform records the total amount of products that were loaded and processed by the equipment. This information, as well as how it changes, is available on a personal account or via mobile app
                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>
                            <h3 className='font-bold mb-2 text-[16px] md:text-[14px] mt-6'>
                              To spend less time on feeding
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>
                            <p className='text-[14px]'>
                              At large stables it may take a long time for a horse to be given its feed. If the horse is on a special diet, the Stud Hands have to spend time preparing individual portions, which is time consuming
                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>
                            <h3 className='font-bold mb-2 text-[16px] md:text-[14px] mt-6'>
                              Secure storage
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4"></div>
                            <p className='text-[14px]'>
                              Products are stored in closed tanks,  so rodents and other stable animals can’t reach it
                            </p>


                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>
                            <h3 className='font-bold mb-2 text-[16px] md:text-[14px] mt-6'>
                              Rehabilitation
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>

                            <p className='text-[14px]'>
                              The platform allows for easy adaptation of nutrition programs based on the different events (such as rehabilitation after injuries, competitions, transportation, etc.) in the horse's life
                            </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>
                            <h3 className='font-bold mb-2 text-[16px] md:text-[14px] mt-6'>
                              Potentially reduce the cost of veterinary treatment
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4"></div>
                            <p className='text-[14px]'>
                              By maintaining better control over the horse's diet and feed consumption
                            </p>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                      <div className='flex z-20 items-center justify-center gap-4 mt-12'>
                        <div className={`custom-l cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                          <ArrowLeftIcon />
                        </div>
                        <div className={`custom-r cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                          <ArrowRightIcon />
                        </div>
                      </div>
                    </div>

                    <div className='w-full flex flex-col gap-16 md:hidden cursor-grab relative slider-shadow'>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[14px] mt-6'>
                          With an individual control of the horse's diet
                        </h3>
                        <p className='text-[14px]'>
                          Especially if a horse has a special diet
                        </p>
                      </div>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[14px] mt-6'>
                          To monitor the amount of the feed consumed
                        </h3>
                        <p className='text-[14px]'>
                          The platform records the total amount of products that were loaded and processed by the equipment. This information, as well as how it changes, is available on a personal account or via mobile app
                        </p>
                      </div>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                      <h3 className='font-bold mb-2 text-[16px] md:text-[14px] mt-6'>
                              To spend less time on feeding
                            </h3>
                            <p className='text-[14px]'>
                              At large stables it may take a long time for a horse to be given its feed. If the horse is on a special diet, the Stud Hands have to spend time preparing individual portions, which is time consuming
                            </p>
                      </div>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                      <h3 className='font-bold mb-2 text-[16px] md:text-[14px] mt-6'>
                              Secure storage
                            </h3>
                            <p className='text-[14px]'>
                              Products are stored in closed tanks,  so rodents and other stable animals can’t reach it
                            </p>
                      </div>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                      <h3 className='font-bold mb-2 text-[16px] md:text-[14px] mt-6'>
                              Rehabilitation
                            </h3>

                            <p className='text-[14px]'>
                              The platform allows for easy adaptation of nutrition programs based on the different events (such as rehabilitation after injuries, competitions, transportation, etc.) in the horse's life
                            </p>
                      </div>
                      <div className=' text-white bg-gray p-4 rounded-xl'>
                      <h3 className='font-bold mb-2 text-[16px] md:text-[14px] mt-6'>
                              Potentially reduce the cost of veterinary treatment
                            </h3>
                            <p className='text-[14px]'>
                              By maintaining better control over the horse's diet and feed consumption
                            </p>
                      </div>
                    </div>
                  </div>
                </section>





                <div className='section'>
                  <Line />
                  <div className='container h-[100vh] '>
                    <div className='pt-32 grid  md:gap-8 gap-16 md:grid-cols-3 text-white'>
                      <div>
                        <div className='mb-4 text-white p-2 w-12 h-12 rounded-full bg-lgray'>
                          <img src={icon1} />
                        </div>
                        <h3 className='font-semibold text-[22px] mb-2'>
                          Easy to maintain equipment
                        </h3>
                        <p className='mb-2'>
                          The equipment is designed the way it is easy to clean:
                        </p>
                        <ul className='list'>
                          <li>
                            the compartments where products are stored - from residues and dirt
                          </li>
                          <li>
                            the processing compartments - from the dirt that falls there after the cleaning process
                          </li>
                          <li>
                            packing /serving compartments - from product residues
                          </li>
                        </ul>


                      </div>
                      <div>
                        <div className='mb-4 flex justify-center items-center text-white p-2 w-12 h-12 rounded-full bg-lgray'>
                          <img src={icon2} />
                        </div>
                        <h3 className='font-semibold text-[22px] mb-2'>
                          Installation:
                        </h3>
                        <p>
                          Installation of the equipment is carried out by the EHOSS specialists or our regional partners. If the needed specialists are at your stable, we will consult you and help to install the equipment in a remote format.
                        </p>
                        <br />
                        <p>
                          To install the platform, you need a power connection, access to water and tools to install our products.
                        </p>
                      </div>
                      <div>
                        <div className='mb-4 flex justify-center items-center text-white p-2 w-12 h-12 rounded-full bg-lgray'>
                          <img src={icon3} />
                        </div>
                        <h3 className='font-semibold text-[22px] mb-2'>
                          Data analysis
                        </h3>
                        <p>
                          All the elements of the feed platform collect and process data. The owner can access it using an interactive monitor that is placed directly in the room where the equipment is installed, or via a mobile application/ personal account.
                        </p>
                        <br />
                        <p>
                          Grain Platform Platform can work independently or in conjunction with other platforms of the EHOSS ecosystem.
                        </p>
                      </div>
                    </div>
                    <div className='md:mt-32 mt-16 pb-16'>
                      <div className='flex justify-center gap-4'>
                        <button onClick={openRequestModal} className='main-btn'>
                          Leave a request
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </ReactFullpage.Wrapper>
            </div>
          );
        }}
      />
    </>

  );
}

export default GrainPlatform;