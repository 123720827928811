function Line() {
    return (
        <div className="cont-for-red-l -z-0">
            <div className="line-1 central-page-line"></div>
        </div>
    );
}

export default Line;



