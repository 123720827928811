import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/breadcrumbs/Breadcrumbs';
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import SliderComponent from '../components/slider/slider';
import { ClockIcon, HashtagIcon } from '@heroicons/react/24/outline';
import './blog.css'
import img from './mail-img.svg'
import LineDot from '../components/line-dot/LineDot';
import Footer from '../container/footer/Footer';
import ShareButtons from '../components/share-buttons/ShareButtons';

function PostPageMain() {
    // Используем параметр 'link' из URL вместо 'id'
    const { link } = useParams();
    const [post, setPost] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

   
    
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        async function fetchPosts() {
            try {
                const response = await fetch('https://form.ehoss.com/api/posts');
                const data = await response.json();

                // Extract unique categories from the posts
                const uniqueCategories = [...new Set(data.map(post => post.category))];
                setCategories(uniqueCategories);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        }

        fetchPosts();
    }, []);


    useEffect(() => {
        async function fetchPost() {
            try {
                // Запрос к API для получения поста по 'link'
                const response = await fetch(`https://form.ehoss.com/api/posts-link/${link}`);
                const data = await response.json();
                setPost(data);
            } catch (error) {
                console.error('Error fetching post:', error);
            }
        }

        fetchPost();
    }, [link]); // Обновляем зависимость на 'link'




    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        // Update the URL with the selected category, replacing spaces with hyphens
        const formattedCategory = category.replace(/ /g, '-'); // Replace spaces with hyphens
        window.location = `https://ehoss.com/blog-test?category=${formattedCategory}`;
    };


    if (!post) return <p>Loading...</p>;

    return (
        <>

            <Helmet>
                <title>EHOSS | {post.title}</title>
                <meta property="og:type" content="article" />
                <meta property="og:title" content={post.title} />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content={post.seo_keywords} />
                <meta name="description" content={post.seo_deskr} />
                <meta property="og:image" content={`https://ehoss.com${post.image}`} />
            </Helmet>
            <div className="fixed z-[-10] left-0 w-24 h-[100vh]">
                <LineDot />
            </div>
            <div className="container">
                <div className='pt-24'>
                    <Breadcrumbs
                        items={[
                            { label: 'Company', url: '#' },
                            { label: 'Blog', url: '/blog' },
                            { label: post.category, url: `/blog-test?category=${post.category.replace(/\s+/g, '-')}` },
                            { label: post.title.length > 14 ? post.title.substring(0, 14) + '...' : post.title, url: '#' }
                        ]}
                    />
                </div>
                <div className='flex md:flex-row flex-col gap-10 pt-16 '>



                    <div id='post' className='md:w-3/4 md:order-1 order-2'>


                        <h1 className="text-4xl font-bold text-white mb-4">{post.title}</h1>

                        <div className='flex justify-between items-center'>
                            <div className='flex gap-2'>
                                <p className="text-gray-600 flex gap-1 text-red bg-white rounded-xl px-4 py-2 justify-center items-center mb-4"><HashtagIcon className='w-4 h-4' /> {post.category}</p>
                                <p className="text-gray-600 flex gap-1 justify-center items-center mb-4"><ClockIcon className='w-4 h-4' />{post.date}</p>
                            </div>
                            <div>
                                <ShareButtons />
                            </div>
                        </div>
                        {post.image && (
                            <img src={post.image} alt={post.title} className="my-4" />
                        )}
                        <div className='post-content text-white' dangerouslySetInnerHTML={{ __html: post.content }} />

                        <div className="slider mt-16">
                            {post.slider.length > 0 && (
                                <SliderComponent imagePaths={post.slider} />
                            )}
                        </div>
                    </div>
                    <div className='md:w-1/4 md:pt-0 md:sticky h-auto md:h-[480px] md:top-[120px] md:order-2 order-1'>
                        <div className="mb-8">
                            <ul className="text-white flex flex-wrap gap-4">
                                <li
                                    className={`cursor-pointer flex gap-1 items-center px-4 rounded-xl border py-2 border-white ${!selectedCategory ? 'text-red bg-white' : ''}`}
                                    onClick={() => handleCategoryClick('')}
                                >
                                    <HashtagIcon className='w-4 h-4' />
                                    All
                                </li>
                                {categories.map((category, index) => (
                                    <li
                                        key={index}
                                        className={`cursor-pointer  flex gap-1 items-center px-4 rounded-xl border py-2 border-white ${selectedCategory === category ? 'text-red bg-white' : ''}`}
                                        onClick={() => handleCategoryClick(category)}
                                    >
                                        <HashtagIcon className='w-4 h-4' />
                                        {category}
                                    </li>
                                ))}
                            </ul>
                            <div className='bg-gray mt-8 rounded-lg hidden md:block'>
                                <div className='p-4'>
                                    <h3 className='font-semibold mb-10 leading-[120%] text-[32px] text-white'>
                                        Have an idea? <br />
                                        Lets get in touch
                                    </h3>
                                    <div className='flex gap-4 items-center '>
                                        <a href='/contacts' className='main-btn'>Contacts</a>
                                    </div>
                                </div>
                                <div className='flex w-full justify-end'>
                                    <img alt='' src={img} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>

    );
}

export default PostPageMain;