
import 'react-quill/dist/quill.snow.css';
import logo from "../../assets/images/logo.svg";
import { ArchiveBoxIcon, ArrowLeftEndOnRectangleIcon, ArrowUpLeftIcon, ArrowUpTrayIcon, BriefcaseIcon, ChatBubbleLeftIcon, HomeIcon, LanguageIcon, ListBulletIcon, PencilSquareIcon, PresentationChartBarIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

function Sidebar(nav) {
    const [activeIndex, setActiveIndex] = useState(nav);

    useEffect(() => {
        setActiveIndex(nav.nav)
        console.log(nav);
    }, []);



    const logout = () => {
        localStorage.removeItem('token');
        console.log('Logged out');
        window.location = '/admin-login'
    };

    return (
        <div className="bg-[#1d1d1d] w-[260px] p-5 flex m-5 rounded-xl flex-col justify-between">
            {/* <img src={logo} className='w-32' /> */}
            <ul className='nav flex gap-2 flex-col text-white'>
                <li className={`p-2 rounded-lg cursor-pointer hover:bg-[#24242449] flex gap-2 ${activeIndex === 0 ? 'bg-white text-black hover:bg-white' : ''}`}>
                    <HomeIcon className='w-6 h-6' />
                    Main
                </li>
                <li onClick={() => {
                    window.location = '/panel'
                }} className={`p-2 rounded-lg cursor-pointer hover:bg-[#24242449] flex gap-2 ${activeIndex === 1 ? 'bg-white text-black hover:bg-white' : ''}`}>
                    <BriefcaseIcon className='w-6 h-6' />
                    Vacancies
                </li>
                <li onClick={() => {
                    window.location = '/panel/uploads'
                }}
                    className={`p-2 rounded-lg cursor-pointer hover:bg-[#24242449] flex gap-2 ${activeIndex === 2 ? 'bg-white text-black hover:bg-white' : ''}`}>
                    <ArrowUpTrayIcon className='w-6 h-6' />
                    Uploads
                </li>
                <hr className='bg-white mt-2 mb-2'/>
                <li onClick={() => {
                    window.location = '/panel/posts'
                }} className={`p-2 rounded-lg cursor-pointer  hover:bg-[#24242449] flex gap-2 ${activeIndex === 3 ? 'bg-white text-black hover:bg-white' : ''}`}>
                    <PencilSquareIcon className='w-6 h-6' />
                    Add Post
                </li>

                <li onClick={() => {
                    window.location = '/panel/posts/list'
                }} className={`p-2 rounded-lg cursor-pointer hover:bg-[#24242449] flex gap-2 ${activeIndex === 6 ? 'bg-white text-black hover:bg-white' : ''}`}>
                    <ArchiveBoxIcon className='w-6 h-6' />
                    Posts
                </li>
                <hr className='bg-white mt-2 mb-2'/>
                <li className='p-2 pointer-events-none opacity-30 rounded-lg cursor-pointer hover:bg-[#242424] flex gap-2'>
                    <LanguageIcon className='w-6 h-6' />
                    Translate
                </li>

                <li onClick={() => {
                    window.location = '/panel/seo'
                }}
                className={`p-2 rounded-lg cursor-pointer hover:bg-[#24242449] flex gap-2 ${activeIndex === 5 ? 'bg-white text-black hover:bg-white' : ''}`}>

                    <PresentationChartBarIcon className='w-6 h-6' />
                    SEO
                </li>

                {/* <li onClick={() => {
                    window.location = '/panel/applications'
                }}
                className={`p-2 pointer-events-none rounded-lg cursor-pointer hover:bg-[#24242449] flex gap-2 ${activeIndex === 5 ? 'bg-white text-black hover:bg-white' : ''}`}>

                    <ListBulletIcon className='w-6 h-6' />
                    Applications
                </li> */}

            </ul>
            <ul className='nav flex gap-2 flex-col text-white'>
                <li onClick={logout} className='p-2 bg-white text-red mt-16 rounded-lg cursor-pointer hover:bg-[#242424] flex gap-2'>
                    <ArrowLeftEndOnRectangleIcon className='w-6 h-6' />
                    Выход
                </li>
            </ul>
        </div>
    );
}

export default Sidebar;