import ReactFullpage from '@fullpage/react-fullpage';
import LineDot from '../../../components/line-dot/LineDot';
import Footer from '../../../container/footer/Footer';
import './veterinary.css'
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

import Line from '../../../components/line/Line';
import card1 from './../../audience/images/bg2.png'
import card2 from './../../audience/images/bg3.png'
import card3 from './../../audience/images/bg4.png'
import card4 from './../../audience/images/bg5.png'
import card5 from './../../audience/images/bg6.png'
import card7 from './../../audience/images/bg1.png'

function Veterinary() {
  const location = useLocation();
  
  function openCooperationModal() {
    const modal = document.getElementById('cooperation-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }

  return (
    <>
      <ReactFullpage
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={1000}
        navigation
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <Helmet>
                <title>EHOSS | Veterinary</title>
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Equine welfare, Horse Welfare, Equine Health Plan, feeding analysis software, equine management nutrition health & exercise" />
                <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
              </Helmet>
              <section className='section audience5'>
              <LineDot />
                <div className='container'>
                  <div className='pt-24 flex md:flex-row flex-col gap-8 h-[100vh]'>
                    <div className='md:w-1/2 text-white'>
                      <div className='flex flex-col  items-start gap-4'>
                        <Breadcrumbs
                            items={[
                            { label: 'Who Needs?', url: '/' },
                            { label: 'For Animal Owners', url: '/audience' },
                            { label: 'Veterinary', url: '/veterinary' },
                            ]}
                        />
                        <h2 className='text-[36px] mt-12 font-medium'>
                          FOR THE EQUINE VETS AND NUTRITIONISTS
                        </h2>
                        <p className='text-[14px]'>
                          We want to make communication between horse owners and veterinarians or other horse care professionals easier and more convenient. In order for the animal to receive proper individual care and not be neglected due to erroneous and untimely decisions, we want to make the necessary medical and preventive examinations of animals regular (without delays) and correct (due to a professional approach). Self-diagnosis is as dangerous as self-medication and may be a reason for further disease development.
                        </p>
                        <p className='text-[14px] font-bold'>
                          In our app, you can choose the needed specialist. They will provide guidance and online consultations on the following issues:
                        </p>
                        <ul className='list flex flex-col gap-4 *:text-[14px] mb-6'>
                          <li>
                            Remote check up
                          </li>
                          <li>
                            Choosing the correct/optimal nutrition plan according to the horse's living conditions
                          </li>
                          <li>
                            Preparation for certain events (competitions, transportation, etc.)
                          </li>
                          <li>
                            Rehabilitation after injuries
                          </li>
                        </ul>
                        <p className='font-bold'>
                          If your goal is to improve horse welfare and develop the equine industry, we are open for cooperation. Contact us at <a className='underline font-medium' href='mailto:partner@ehoss.com'>partner@ehoss.com</a> or fill out the <button onClick={openCooperationModal} className='underline font-medium'>feedback form</button>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className='section overflow-hidden'>
                                <Line />
                                <div className='container flex gap-6 flex-col md:flex-row md:items-center md:justify-between'>
                                    <div className=' w-full md:h-[100vh] pt-24 text-white'>
                                        <div>
                                            <h2 className='text-[36px] font-medium uppercase'>
                                                more about who needs
                                            </h2>
                                        </div>
                                        <div className='w-full mt-12'>
                                            <div className='grid gap-2 grid-cols-2 md:grid-cols-6 w-full'>
                                                {/* =========== card 1 ============= */}
                                                <a href='/farmers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                                                    <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                                                        <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                                                            </svg>
                                                        </div>
                                                        <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>Farmers</h3>
                                                    </div>
                                                    <img alt='Veterynary' className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card7} />
                                                </a>
                                                {/* =========== card 2 ============= */}
                                                <a href='/horse-owners' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                                                    <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                                                        <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                                                            </svg>
                                                        </div>
                                                        <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                                                            Horse Owners
                                                        </h3>
                                                    </div>
                                                    <img alt='Veterynary'  className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card1} />
                                                </a>
                                                {/* =========== card 3 ============= */}
                                                <a href='/equestrian-facilities' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                                                    <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                                                        <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                                                            </svg>
                                                        </div>
                                                        <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                                                            Equestrian Facilities
                                                        </h3>
                                                    </div>
                                                    <img alt='Veterynary'  className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card2} />
                                                </a>
                                                {/* =========== card 4 ============= */}
                                                <a href='/equine-producers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                                                    <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                                                        <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                                                            </svg>
                                                        </div>
                                                        <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                                                            Equine Product Producers
                                                        </h3>
                                                    </div>
                                                    <img alt='Veterynary'  className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card3} />
                                                </a>
                                                {/* =========== card 5 ============= */}
                                                <a href='/veterinary' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                                                    <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                                                        <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                                                            </svg>
                                                        </div>
                                                        <h3 className='text-white text-[16px] mt-4 font-semibold'>
                                                            Veterinary
                                                        </h3>
                                                    </div>
                                                    <img alt='Veterynary'  className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card4} />
                                                </a>
                                                {/* =========== card 6 ============= */}
                                                <a href='/equestrians' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                                                    <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                                                        <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                                                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                                                            </svg>
                                                        </div>
                                                        <h3 className='text-white text-[16px] mt-4 font-semibold'>
                                                            Equestrians
                                                        </h3>
                                                    </div>
                                                    <img alt='Veterynary'  className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card5} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
              <Footer />
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </>

  );
}

export default Veterinary;