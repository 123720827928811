import { useEffect, useState, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';
import Sidebar from '../sidebar/sidebar';
import { CheckCircleIcon, ArrowUpTrayIcon, PauseCircleIcon, ClipboardDocumentCheckIcon, TrashIcon, PlayCircleIcon } from '@heroicons/react/24/outline';

function AdminProfileUploads() {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isCopied, setIsCopied] = useState(false);



    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
            window.location = '/admin-login'
            console.log('Token is invalid or expired');
        }
    }, []);

    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
            }

            try {
                const response = await fetch('https://form.ehoss.com/api/upload', {
                    method: 'POST',
                    body: formData,
                    mode: 'no-cors'
                });

                if (response.ok) {
                    const result = await response.json();
                    console.log('Files uploaded successfully:', result);
                    window.location.reload()
                } else {
                    console.error('File upload failed');
                }
            } catch (error) {
                console.error('Error uploading files:', error);
            }
        }
    };

    const [uploads, setUploads] = useState([]);

    useEffect(() => {
        async function fetchFiles() {
            try {
                const response = await fetch('https://form.ehoss.com/api/files');
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                const data = await response.json();
                setUploads(data);
            } catch (error) {
                console.error('Ошибка при загрузке файлов:', error);
            }
        }

        fetchFiles();
    }, []);


    const handleDelete = async (fileId) => {
        try {
            const response = await fetch(`https://form.ehoss.com/api/files/${fileId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setUploads(uploads.filter(file => file._id !== fileId));
                console.log('Файл успешно удален');
            } else {
                console.error('Ошибка при удалении файла:', response.statusText);
            }
        } catch (error) {
            console.error('Ошибка при удалении файла:', error);
        }
    };

    const handleCopyToClipboard = async (file) => {
        try {
            const filePath = `https://form.ehoss.com/uploads/${file.file_name}`;
            await navigator.clipboard.writeText(filePath);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        } catch (err) {
            console.error('Ошибка копирования в буфер обмена:', err);
        }
    };


    const [playingVideoId, setPlayingVideoId] = useState(null);
    const videoRefs = useRef({});

    const handleVideoToggle = (videoId) => {
        const currentVideo = videoRefs.current[videoId];

        if (currentVideo) {
            if (playingVideoId === videoId) {
                currentVideo.pause();
                setPlayingVideoId(null);
            } else {

                if (playingVideoId && videoRefs.current[playingVideoId]) {
                    videoRefs.current[playingVideoId].pause();
                }
                currentVideo.play();
                setPlayingVideoId(videoId);
            }
        }
    };
    return (
        <div className="flex overflow-hidden h-screen bg-[#242424]">
            <Sidebar nav={2} />
            <main className="flex flex-col w-full h-[100vh] p-8">

                <div className='mb-8 flex justify-between items-center'>
                    <div>
                        <h2 className='text-white font-bold text-[32px]'>
                            Uploads
                        </h2>
                        <p className='text-white mt-2 opacity-30'> Files: {uploads.length}</p>
                    </div>
                    <div className='flex items-center gap-4'>
                        <p className='text-white'>Add new</p>
                        <div className='bg-white p-2 rounded-full' onClick={handleButtonClick}>
                            <ArrowUpTrayIcon className='bg-white text-red w-6' />
                        </div>
                        <input
                            type='file'
                            name='files'
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            multiple
                            onChange={handleFileChange}
                        />
                    </div>

                </div>

                <div className="grid grid-cols-5 gap-4 overflow-auto">
                {uploads
    .sort((a, b) => new Date(b.upload_date) - new Date(a.upload_date)) // Сортируем файлы по дате загрузки
    .map((file) => (
        <div key={file._id} className="relative h-[250px] w-full border border-gray-200 rounded-lg overflow-hidden shadow-lg group">
            {file.file_path.toLowerCase().endsWith('.jpg') || file.file_path.toLowerCase().endsWith('.svg') || file.file_path.toLowerCase().endsWith('.jpeg') || file.file_path.toLowerCase().endsWith('.png') ? (
                <img className="object-cover h-full w-full" src={`https://form.ehoss.com/uploads/${file.file_name}`} alt={file.file_name} />
            ) : file.file_path.toLowerCase().endsWith('.mov') || file.file_path.toLowerCase().endsWith('.mp4') ? (
                <video
                    className="object-cover h-full w-full"
                    ref={(el) => (videoRefs.current[file._id] = el)}
                    src={`https://form.ehoss.com/uploads/${file.file_name}`}
                    controls={false}
                >
                    Your browser does not support the video tag.
                </video>
            ) : (
                <div className="w-full h-48 flex items-center aspect-square object-cover justify-center bg-gray-200">
                    <span className="text-gray-600">{file.file_name}</span>
                </div>
            )}

            <div className="absolute inset-0 bg-red bg-opacity-50 flex flex-col items-center justify-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <p className="mb-2 text-[10px] absolute bottom-0 left-0 p-2 w-full">Имя файла: {file.file_name}</p>
                <button
                    className={`absolute right-4 top-[70px] text-red w-12 h-12 px-3 py-1 rounded-full transition ${isCopied ? 'bg-green-500' : 'bg-white'}`}
                    onClick={() => handleCopyToClipboard(file)}
                >
                    {isCopied ? (
                        <CheckCircleIcon className='w-6 h-6 text-white' />
                    ) : (
                        <ClipboardDocumentCheckIcon className='w-6 h-6' />
                    )}
                </button>
                <button
                    className="bg-red absolute right-4 top-4 text-white w-12 h-12 px-3 py-1 rounded-full transition"
                    onClick={() => handleDelete(file._id)}
                >
                    <TrashIcon className='w-6 h-6' />
                </button>
                {file.file_path.toLowerCase().endsWith('.mov') || file.file_path.toLowerCase().endsWith('.mp4') ? (
                    <button
                        className="bg-white absolute right-4 top-[130px] text-red w-12 h-12 px-3 py-1 rounded-full transition"
                        onClick={() => handleVideoToggle(file._id)}
                    >
                        {playingVideoId === file._id ? (
                            <PauseCircleIcon className='w-6 h-6' />
                        ) : (
                            <PlayCircleIcon className='w-6 h-6' />
                        )}
                    </button>
                ) : null}
            </div>
        </div>
    ))}
                </div>


            </main>
        </div>
    );
}

export default AdminProfileUploads;