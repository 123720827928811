// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#about-section::before {
    content: '';
    width: 100%;
    position: absolute;
    height: 5px;
    top: 572px !important;
    background: linear-gradient(09deg, #2A2A2A, #F20000);

    z-index: -9999;
}

#about-section {
    position: relative;
}

@media (max-width: 630px) {
    #about-section::before {
        content: '';
        width: 100%;
        position: absolute;
        height: 5px;
        top: 463px !important;
        background: linear-gradient(09deg, #2A2A2A, #F20000);
    
        z-index: -9999;
    }
}


#about-section::after{
    content: '';
    width: 700px;
    right: 0;
    top: 0;
    position: absolute;
    height: 100%;
    background: linear-gradient(-90deg, #1d1d1d, transparent);
    /* background-color: #F20000; */
    z-index: 1;
}


@media (max-width: 630px) {
    #about-section::after {
        width: 70px;
    }   
}

/* .slider-shadow::before {
    content: '';
    width: 100px;
    position: absolute;
    height: 100%;
    right: 0;
    z-index: 2;
    background: linear-gradient(90deg, transparent, #1d1d1d);
} */
`, "",{"version":3,"sources":["webpack://./src/pages/about/about.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,qBAAqB;IACrB,oDAAoD;;IAEpD,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,WAAW;QACX,WAAW;QACX,kBAAkB;QAClB,WAAW;QACX,qBAAqB;QACrB,oDAAoD;;QAEpD,cAAc;IAClB;AACJ;;;AAGA;IACI,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,MAAM;IACN,kBAAkB;IAClB,YAAY;IACZ,yDAAyD;IACzD,+BAA+B;IAC/B,UAAU;AACd;;;AAGA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;;;;;;;;GAQG","sourcesContent":["#about-section::before {\r\n    content: '';\r\n    width: 100%;\r\n    position: absolute;\r\n    height: 5px;\r\n    top: 572px !important;\r\n    background: linear-gradient(09deg, #2A2A2A, #F20000);\r\n\r\n    z-index: -9999;\r\n}\r\n\r\n#about-section {\r\n    position: relative;\r\n}\r\n\r\n@media (max-width: 630px) {\r\n    #about-section::before {\r\n        content: '';\r\n        width: 100%;\r\n        position: absolute;\r\n        height: 5px;\r\n        top: 463px !important;\r\n        background: linear-gradient(09deg, #2A2A2A, #F20000);\r\n    \r\n        z-index: -9999;\r\n    }\r\n}\r\n\r\n\r\n#about-section::after{\r\n    content: '';\r\n    width: 700px;\r\n    right: 0;\r\n    top: 0;\r\n    position: absolute;\r\n    height: 100%;\r\n    background: linear-gradient(-90deg, #1d1d1d, transparent);\r\n    /* background-color: #F20000; */\r\n    z-index: 1;\r\n}\r\n\r\n\r\n@media (max-width: 630px) {\r\n    #about-section::after {\r\n        width: 70px;\r\n    }   \r\n}\r\n\r\n/* .slider-shadow::before {\r\n    content: '';\r\n    width: 100px;\r\n    position: absolute;\r\n    height: 100%;\r\n    right: 0;\r\n    z-index: 2;\r\n    background: linear-gradient(90deg, transparent, #1d1d1d);\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
