// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-img {
    height: 200px !important;
    border-radius: 12px;
}

.swiper-scrollbar {
    background: #FFF !important;
}

.swiper-scrollbar-drag {
    background: #F20000 !important;
}

.custom-navigation {
    margin-top: 20px;
    display: flex;
    gap: 40px;
    justify-content: center;
}

.custom-navigation svg {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.custom-navigation2 {
    position: fixed !important;
    bottom: 20px;
    left: 50%;
    right: 50%;

}
@media (min-width: 1420px) {

    .slider-img {
        height: 337px !important;
        border-radius: 12px;
    }
}
@media (min-width: 1620px) {

    .slider-img {
        height: 337px !important;
        border-radius: 12px;
    }
}



@media (min-width: 2200px) {

    .slider-img {
        height: 437px !important;
        border-radius: 12px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/slider/slider.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,0BAA0B;IAC1B,YAAY;IACZ,SAAS;IACT,UAAU;;AAEd;AACA;;IAEI;QACI,wBAAwB;QACxB,mBAAmB;IACvB;AACJ;AACA;;IAEI;QACI,wBAAwB;QACxB,mBAAmB;IACvB;AACJ;;;;AAIA;;IAEI;QACI,wBAAwB;QACxB,mBAAmB;IACvB;AACJ","sourcesContent":[".slider-img {\r\n    height: 200px !important;\r\n    border-radius: 12px;\r\n}\r\n\r\n.swiper-scrollbar {\r\n    background: #FFF !important;\r\n}\r\n\r\n.swiper-scrollbar-drag {\r\n    background: #F20000 !important;\r\n}\r\n\r\n.custom-navigation {\r\n    margin-top: 20px;\r\n    display: flex;\r\n    gap: 40px;\r\n    justify-content: center;\r\n}\r\n\r\n.custom-navigation svg {\r\n    width: 40px;\r\n    height: 40px;\r\n    cursor: pointer;\r\n}\r\n\r\n.custom-navigation2 {\r\n    position: fixed !important;\r\n    bottom: 20px;\r\n    left: 50%;\r\n    right: 50%;\r\n\r\n}\r\n@media (min-width: 1420px) {\r\n\r\n    .slider-img {\r\n        height: 337px !important;\r\n        border-radius: 12px;\r\n    }\r\n}\r\n@media (min-width: 1620px) {\r\n\r\n    .slider-img {\r\n        height: 337px !important;\r\n        border-radius: 12px;\r\n    }\r\n}\r\n\r\n\r\n\r\n@media (min-width: 2200px) {\r\n\r\n    .slider-img {\r\n        height: 437px !important;\r\n        border-radius: 12px;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
