import React from "react";
import { useState } from "react";
import "./posts.css";
import { useNavigate, Link } from "react-router-dom";
import postsData from "../../data/postsData";
import { ArrowDownLeftIcon, ArrowTrendingUpIcon, ArrowUpRightIcon, ClockIcon, HashtagIcon } from "@heroicons/react/24/outline";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
// import loader from './../../assets/images/loader.gif'

const Posts = ({ filter }) => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(filter.filter || null);
  const [visiblePosts, setVisiblePosts] = useState(4);

  const categories = Array.from(
    new Set(postsData.map((post) => post.category))
  );

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setVisiblePosts(4); // Сбрасываем видимое количество постов при смене категории
  };

  const handleShowMore = () => {
    setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 4); // Увеличиваем видимое количество постов на 4
  };

  const filteredPosts = selectedCategory
    ? postsData.filter((post) => post.category === selectedCategory)
    : postsData;

  const postsToShow = filteredPosts.slice(0, visiblePosts);

  // const handlePostClick = (postLink) => {
  //   navigate(`/${postLink}`);
  //   scrollToTop();
  // };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };


  // console.log(selectedCategory);
  return (
    <>
      <div id="blog" className="pt-24">
        <div className="">
          <Breadcrumbs
            items={[
              { label: 'Company', url: '#' },
              { label: 'Blog', url: '/blog' },
              {
                label: selectedCategory !== null ? selectedCategory : null,
                url: selectedCategory !== null ? `/${selectedCategory.replace(/\s+/g, '-').toLowerCase()}` : null
              },
            ].filter(item => item.label !== null)}
          />
          <h2 className="text-[48px] font-semibold text-white uppercase mt-12 mb-10">Blog</h2>
          <ul className="flex-wrap flex gap-4 *:rounded-xl *:border-[1px] *:border-white">
            <li
              className={selectedCategory === null ? "active" : ""}
            >
              <Link onClick={() => handleCategorySelect(null)} className="pl-3 pb-2 pt-2 pr-3 flex items-center gap-2 text-[16px] font-medium text-white" to="/blog">
                <svg className="w-4" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M8.68441 0.169046C9.3056 0.324342 9.68328 0.953807 9.52798 1.57499L9.01859 3.61255H12.4254L13.0753 1.01261C13.2306 0.391428 13.8601 0.0137487 14.4813 0.169046C15.1025 0.324342 15.4802 0.953807 15.3249 1.57499L14.8155 3.61255H17.6782C18.3185 3.61255 18.8376 4.13162 18.8376 4.77193C18.8376 5.41223 18.3185 5.9313 17.6782 5.9313H14.2358L13.0764 10.5688H15.3595C15.9998 10.5688 16.5188 11.0879 16.5188 11.7282C16.5188 12.3685 15.9998 12.8876 15.3595 12.8876H12.4967L11.8467 15.4875C11.6914 16.1087 11.062 16.4864 10.4408 16.3311C9.8196 16.1758 9.44192 15.5463 9.59721 14.9251L10.1066 12.8876H6.69984L6.04986 15.4875C5.89456 16.1087 5.2651 16.4864 4.64391 16.3311C4.02272 16.1758 3.64504 15.5463 3.80034 14.9251L4.30973 12.8876H1.44697C0.806667 12.8876 0.287598 12.3685 0.287598 11.7282C0.287598 11.0879 0.806667 10.5688 1.44697 10.5688H4.88942L6.04879 5.9313H3.76572C3.12542 5.9313 2.60635 5.41223 2.60635 4.77193C2.60635 4.13162 3.12542 3.61255 3.76572 3.61255H6.62848L7.27846 1.01261C7.43376 0.391428 8.06323 0.0137487 8.68441 0.169046ZM8.4389 5.9313L7.27953 10.5688H10.6863L11.8457 5.9313H8.4389Z" fill="#D21C1B" />
                </svg>
                All
              </Link>
            </li>
            {categories.map((category) => (
              <li
                key={category}
                className={selectedCategory === category ? "active" : ""}
              >
                <Link onClick={() => handleCategorySelect(category)} className="pl-3 pb-2 pt-2 pr-3 flex text-nowrap	gap-2 items-center text-white" to={`/${category.replace(/ /g, "-").toLowerCase()}`}>
                  <svg className="w-4" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.68441 0.169046C9.3056 0.324342 9.68328 0.953807 9.52798 1.57499L9.01859 3.61255H12.4254L13.0753 1.01261C13.2306 0.391428 13.8601 0.0137487 14.4813 0.169046C15.1025 0.324342 15.4802 0.953807 15.3249 1.57499L14.8155 3.61255H17.6782C18.3185 3.61255 18.8376 4.13162 18.8376 4.77193C18.8376 5.41223 18.3185 5.9313 17.6782 5.9313H14.2358L13.0764 10.5688H15.3595C15.9998 10.5688 16.5188 11.0879 16.5188 11.7282C16.5188 12.3685 15.9998 12.8876 15.3595 12.8876H12.4967L11.8467 15.4875C11.6914 16.1087 11.062 16.4864 10.4408 16.3311C9.8196 16.1758 9.44192 15.5463 9.59721 14.9251L10.1066 12.8876H6.69984L6.04986 15.4875C5.89456 16.1087 5.2651 16.4864 4.64391 16.3311C4.02272 16.1758 3.64504 15.5463 3.80034 14.9251L4.30973 12.8876H1.44697C0.806667 12.8876 0.287598 12.3685 0.287598 11.7282C0.287598 11.0879 0.806667 10.5688 1.44697 10.5688H4.88942L6.04879 5.9313H3.76572C3.12542 5.9313 2.60635 5.41223 2.60635 4.77193C2.60635 4.13162 3.12542 3.61255 3.76572 3.61255H6.62848L7.27846 1.01261C7.43376 0.391428 8.06323 0.0137487 8.68441 0.169046ZM8.4389 5.9313L7.27953 10.5688H10.6863L11.8457 5.9313H8.4389Z" fill="#D21C1B" />
                  </svg>
                  {category}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 mt-16 gap-4">
          {postsToShow.map((post) => (
            <a
              className="flex flex-col md:flex-row gap-4 md:gap-0 items-center cursor-pointer"
              key={post.id}
              href={`/${post.title.replace(/ /g, "-")
                  .replace(/:/g, "") // Удаляем двоеточия
                  .replace(/[-–—]+/g, "-") // Заменяем последовательности тире на одно тире
                  .replace(/[^a-zA-Z0-9- ]/g, "") // Удаляем специальные символы, оставляя только буквы, цифры, тире и пробелы
                  .replace(/ /g, "-") 
                  .toLowerCase()
                }`}
              onClick={() =>
                // handlePostClick()
                scrollToTop()
              }
            >
              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50">
                  {/* Здесь можно использовать любой компонент или стили для отображения индикатора загрузки */}
                  <div className="loader text-white">Loading...</div>
                </div>
              )}
              <img
                className="w-full md:w-1/3 object-cover h-[200px] rounded-xl"
                src={post.image}
                alt={post.image}
                onLoad={handleImageLoad}
                loading="lazy"
              />
              <div className="flex flex-col w-full gap-2 md:w-2/3 text-white p-4">
                <div className="flex items-center gap-3">
                  <p className="bg-white flex text-[12px] text-black p-[4px] rounded-lg">
                    <HashtagIcon className="h-4 w-4 text-red" />
                    {post.category.replace(/ /g, "").toLowerCase()}
                  </p>
                  <p className="flex items-center gap-1 text-[12px]">
                    <ClockIcon className="h-4 w-4" />
                    {post.date}
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <h4 className="text-[18px] font-semibold">{post.title}</h4>
                  <p className=" text-[#DCDCDC] text-[12px]">
                    {post.minidesckr}
                  </p>
                  <a
                    className="font-bold text-[12px] flex items-center gap-1"
                    href={`/${post.title.replace(/ /g, "-")
                      .replace(/:/g, "") // Удаляем двоеточия
                      .replace(/[-–—]+/g, "-") // Заменяем последовательности тире на одно тире
                      .replace(/[^a-zA-Z0-9- ]/g, "") // Удаляем специальные символы, оставляя только буквы, цифры, тире и пробелы
                      .replace(/ /g, "-") 
                      .toLowerCase()
                    }`}
                    onClick={() =>
                      // handlePostClick()
                      scrollToTop()
                    }>
                    Read more
                    <ArrowUpRightIcon className="h-4 w-4" />
                  </a>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
      {visiblePosts < filteredPosts.length && (
        <div className="m-16 flex justify-center">
          <button className="main-btn" onClick={handleShowMore}>Load more</button>
        </div>
      )}
    </>
  );
};

export default Posts;
