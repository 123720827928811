import img from './soon.svg'
import LineDot from '../../components/line-dot/LineDot';
import Footer from '../../container/footer/Footer';

function Soon() {
    return (
        <section>
            <LineDot />
            <div className='container'>
                <div className='flex flex-col gap-4 h-[100vh] justify-center items-center'>
                    <img className='max-w-[500px]' src={img} alt='Not Found Text' />
                    <p className='uppercase text-white text-center text-[32px]'>THIS PAGE IS UNDER CONSTRUCTION</p>
                    <a className='main-btn' href='/'>Home</a>
                </div>
            </div>
            <Footer />
        </section>
    );
}

export default Soon;
