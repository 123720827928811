// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb-item-home:hover svg:first-child path{
    stroke: red;

}

.homeicon path{
    fill: none !important;
}

.breadcrumb-item-home:first-child svg path{
    transition: 0.2s;
    
}
.breadcrumb-item {
    transition: 0.2s;
}
.breadcrumb-item:hover{
    color: red;
}

.breadcrumb-item:last-child svg{
    display: none !important;
}

.breadcrumb-item a {
    text-decoration: none !important;
}

`, "",{"version":3,"sources":["webpack://./src/components/breadcrumbs/breadcrumbs.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;AAEf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;;AAEpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,UAAU;AACd;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".breadcrumb-item-home:hover svg:first-child path{\r\n    stroke: red;\r\n\r\n}\r\n\r\n.homeicon path{\r\n    fill: none !important;\r\n}\r\n\r\n.breadcrumb-item-home:first-child svg path{\r\n    transition: 0.2s;\r\n    \r\n}\r\n.breadcrumb-item {\r\n    transition: 0.2s;\r\n}\r\n.breadcrumb-item:hover{\r\n    color: red;\r\n}\r\n\r\n.breadcrumb-item:last-child svg{\r\n    display: none !important;\r\n}\r\n\r\n.breadcrumb-item a {\r\n    text-decoration: none !important;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
