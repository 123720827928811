import ReactFullpage from '@fullpage/react-fullpage';
import LineDot from '../../components/line-dot/LineDot';
import { DocumentIcon } from '@heroicons/react/24/outline';
import Footer from '../../container/footer/Footer';
import schemas from './../../assets/images/platforms-schemes/seno.svg'
import termsOfUsePDF from './../../assets/files/terms_of_use.pdf'
import { Helmet } from "react-helmet";

function TermsOfUse() {
  return (
    <>
         <Helmet>
        <title>EHOSS | Terms of Use</title>
      </Helmet>
      <ReactFullpage
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={1000}
        navigation
        render={({ state, fullpageApi }) => {
          return (
            <div>
              <ReactFullpage.Wrapper>
                <div className='section overflow-hidden'>
                  <LineDot />
                  <div className='container'>
                    <div className='privacy md:w-1/2 h-[100vh] pt-32 text-white'>
                      <div className='flex flex-col  items-start gap-4'>
                        <h2 className='text-[24px] md:text-[36px] font-bold'>
                          Terms of Use
                        </h2>
                        <p className='text-[14px]'>
                          Please read these Terms of Use of the EHOSS website and 
                          our Privacy Policy carefully before using the services 
                          offered by EHOSS s.r.o. (hereinafter - "EHOSS", "we", "us"). 
                          These Terms of Use ("Terms") apply to the EHOSS website 
                          located at www.ehoss.com and all linked sites linked by EHOSS, 
                          its subsidiaries and affiliates from <a className='underline font-semibold' href='https://www.ehoss.com'>
                            www.ehoss.com
                          </a> , 
                          (hereinafter - "Site"). These Terms constitute a 
                          legally binding agreement between EHOSS and the 
                          User (hereinafter also referred to as "you", "your"), 
                          which governs your use of the Site and the services 
                          available through the Site and the EHOSS mobile application 
                          (hereinafter referred to as the "Application").
                        </p>
                        <p className='text-[14px]'>
                          By using this Site, you agree to these Terms of Use. If you do 
                          not agree, do not use the site.
                        </p>
                        <div className='bg-gray p-4 rounded-xl flex flex-col gap-2 mt-6'>
                          <p className='font-semibold text-[18px]'>Terms of use of the EHOSS website</p>
                          <p className=' opacity-50 text-[12px]'>Updated February 7, 2023</p>
                          {/* <p className='text-[14px]'>This document describes how EHOSS collects, uses, and shares your personal data.</p> */}
                          <a href={termsOfUsePDF} className=' underline flex gap-2 items-center font-semibold mt-6' target='_blank'>
                            View the PDF file
                            <DocumentIcon className='w-4 h-4' />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className='w-2/3 absolute right-[-400px] opacity-30 bottom-0' src={schemas} />
                </div>
                <Footer />
              </ReactFullpage.Wrapper>
            </div>
          );
        }}
      />
    </>

  );
}

export default TermsOfUse;