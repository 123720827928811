import { useRef } from "react";

function CookiesSetting() {
    let modal = useRef(null);

    function closeCookieSetting(){
        modal.current.classList.add('hidden')
        modal.current.classList.remove('flex')
    }
    return (
        <>
            <div ref={modal} id="cookiesetting-modal" data-modal-backdrop="static" tabindex="-1" aria-hidden="true" class="overflow-hidden hidden w-full flex justify-center items-center  h-[100vh] bg-[#000000] z-[9999] bg-opacity-40  fixed top-0 right-0 left-0">
                <div class="p-4 w-full max-w-2xl">
                    <div class=" bg-gray rounded-lg shadow dark:bg-gray-700">
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                Cookie Preferences
                            </h3>
                            <button onClick={closeCookieSetting} type="button" class="text-white bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="static-modal">
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div class="p-4 md:p-5 space-y-4 h-[360px] overflow-auto">
                            <div>
                                <h3 className="text-white text-xl font-bold mb-4">
                                    Your Privacy
                                </h3>
                                <p class="text-white leading-relaxed text-[14px]">
                                    We use cookies to help you browse more effectively and perform specific functions.
                                    <br/><br/>
                                    Cookies, in simple words, are the website's memory of some of your actions that you won't need to repeat in the future. Cookies also collect overall user browsing experience to help us improve our product (Analytics, Marketing, Advertising cookies). We use third-party cookies that help us analyze how you use our website, save your preferences, and deliver relevant content for you.
                                    <br/><br/>
                                    Some cookies functionally work for our website and do not involve your personal data (Essential cookies). The cookies under the category of 'Essential' are stored on your browser since they are crucial in allowing the basic features of the website to work properly.
                                    <br/><br/>
                                    We will only keep these cookies on your browser with your explicit consent. You have the option to enable or disable certain or all of these cookies, although disabling some may impact your browsing experience.
                                </p>
                            </div>

                            <div>
                                <h3 className="text-white text-xl font-bold mb-4">
                                    Essential Cookies
                                </h3>
                                <p class="text-white leading-relaxed text-[14px]">
                                    Essential cookies are used to provide proper function of the website, such as secure log-in and consent preference adjustments. These cookies do not store any personally identifiable information.
                                    <br/><br/>
                                    Applicable cookies: Deskpro, Stripe, Sentry, Consent Manager
                                </p>
                            </div>

                            <div>
                                <div className="flex justify-between items-center mb-4">
                                <h3 className="text-white text-xl font-bold">
                                        Essential Cookies
                                    </h3>
                                    <label htmlFor="1" class="inline-flex items-center cursor-pointer">
                                        <input id="1" name="1" type="checkbox" value="" class="sr-only peer" />
                                        <div class="relative w-11 h-6 bg-lgray peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-lgray dark:peer-focus:ring-red rounded-full peer dark:bg-lgray peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red"></div>
                                 
                                    </label>
                                </div>
                                <p class="text-white leading-relaxed text-[14px]">
                                    Essential cookies are used to provide proper function of the website, such as secure log-in and consent preference adjustments. These cookies do not store any personally identifiable information.
                                    <br/><br/>
                                    Applicable cookies: Deskpro, Stripe, Sentry, Consent Manager
                                </p>
                            </div>

                            <div>
                                <div className="flex justify-between items-center mb-4">
                                <h3 className="text-white text-xl font-bold">
                                        Essential Cookies
                                    </h3>
                                    <label htmlFor="2" class="inline-flex items-center cursor-pointer">
                                        <input id="2" name="2" type="checkbox" value="" class="sr-only peer" />
                                        <div class="relative w-11 h-6 bg-lgray  peer-focus:ring-lgray dark:peer-focus:ring-red rounded-full peer dark:bg-lgray peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red"></div>
                                 
                                    </label>
                                </div>
                                <p class="text-white leading-relaxed text-[14px]">
                                    Essential cookies are used to provide proper function of the website, such as secure log-in and consent preference adjustments. These cookies do not store any personally identifiable information.
                                    <br/><br/>
                                    Applicable cookies: Deskpro, Stripe, Sentry, Consent Manager
                                </p>
                            </div>

 

                        </div>

                        <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="static-modal" type="button" class="text-white bg-red  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center ">I accept</button>
                            <button data-modal-hide="static-modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200  focus:z-10 focus:ring-4">Decline</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CookiesSetting;