import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import Sidebar from '../sidebar/sidebar';
import { XMarkIcon,  CodeBracketIcon, TrashIcon } from '@heroicons/react/24/outline';
import img_bg from './images/image 77.png'

function AdminProfileSEO() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
            // Редирект на страницу логина или другая обработка неавторизованного доступа
            window.location = '/admin-login'
            console.log('Token is invalid or expired');
        }
    }, []);

    // Инициализация состояния для хранения скриптов
    const [scripts, setScripts] = useState([]);



    useEffect(() => {
        // Функция для получения скриптов
        const fetchScripts = async () => {
            try {
                const response = await fetch('https://form.ehoss.com/api/scripts', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setScripts(data);
            } catch (error) {
                console.error('Error fetching scripts:', error);
            }
        };

        fetchScripts();
    }, []); // Пустой массив зависимостей означает, что этот эффект выполнится один раз при монтировании компонента




    const [scriptContent, setScriptContent] = useState('');
    const [error, setError] = useState('');

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(''); // Очистить ошибки перед отправкой

        try {
            const response = await fetch('https://form.ehoss.com/api/scripts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ scriptContent })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Перезагрузить страницу при успешной отправке
            window.location.reload();
        } catch (error) {
            setError('Failed to submit the script. Please try again.');
        }
    };

    const deleteOneScript = async (scriptId) => {
        try {
            const response = await fetch(`https://form.ehoss.com/api/scripts/${scriptId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            // Здесь можно обновить состояние или выполнить другие действия после успешного удаления
            console.log('Script deleted successfully');
            window.location.reload(); // Перезагрузить страницу после успешного удаления
        } catch (error) {
            console.error('Failed to delete script:', error);
            alert('Failed to delete script. Please try again.'); // Отобразить сообщение об ошибке
        }
    };

    return (
        <div className="flex overflow-hidden h-screen bg-[#242424]">
            <Sidebar nav={5} />

            {isModalOpen && (
                <div className='fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-20'>
                    <div className='flex flex-col gap-4 bg-[#1d1d1d] p-8 rounded-xl shadow-2xl relative'>
                        <button
                            onClick={handleCloseModal}
                            className='absolute top-4 right-4 text-white'
                        >
                            <XMarkIcon className='w-6 h-6' />
                        </button>
                        <h4 className='text-white font-semibold'>
                            Add new code
                        </h4>
                        <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
                            <input
                                type='text'
                                value={scriptContent}
                                onChange={(e) => setScriptContent(e.target.value)}
                                className='p-2 rounded'
                                placeholder='Enter code here'
                                required
                            />
                            <button type='submit' className='bg-white text-black px-4 py-2 rounded'>
                                Send
                            </button>
                            {error && <p className='text-red-500'>{error}</p>}
                        </form>
                    </div>
                </div>
            )}

            <main className="flex flex-col w-full h-[100vh] p-8">

                <div className='mb-8 flex justify-between items-center'>
                    <div>
                        <h2 className='text-white font-bold text-[32px]'>
                            HEAD Сайта
                        </h2>
                        <p className='text-white mt-2 opacity-30'>
                            „Всем нравится прекрасная лошадь, но почему-то совершенно нет желающих ею стать.“ —  Аврелий Августин
                        </p>
                    </div>
                    <div className='flex items-center gap-4'>
                        <p className='text-white'>
                            Add new
                        </p>
                        <div
                            onClick={() => setIsModalOpen(true)} // Открыть модальное окно при клике
                            className='bg-white p-2 rounded-full cursor-pointer'
                        >
                            <CodeBracketIcon className='bg-white text-red w-6' />
                        </div>
                    </div>

                </div>
                <main className="flex">
                    <div className="mb-6 w-1/2">
                        <p className=''>
                            {scripts.map((script) => (
                                <div id={'script.id'} key={script.id} className="mb-2 p-4 text-white rounded-xl bg-[#1d1d1d] font-semibold">
                                    <div className='flex justify-between items-center mb-4'>
                                        <div className='font-normal text-[14px] opacity-40'>
                                            {script._id}
                                        </div>
                                        <div onClick={() => deleteOneScript(script._id)} className='font-normal cursor-pointer hover:scale-105 text-[14px]'>
                                            <TrashIcon className='w-6 text-red' />
                                        </div>

                                    </div>
                                    <p className=''>
                                        {script.scriptContent}

                                    </p>

                                </div>
                            ))}
                        </p>

                    </div>
                    <div className='w-1/2 overflow-auto h-[100vh] relative'>
                        <div className='mt-24 ml-48'>
                            <p className='text-[#AAAD81] font-semibold'>
                                {`<head>`}
                            </p>
                            <p className='ml-12'>
                                {scripts.map((script) => (
                                    <div id={'script.id'} key={script.id} className="mb-2 text-white font-semibold">

                                        <p className=''>
                                            {script.scriptContent}
                                        </p>

                                    </div>
                                ))}
                            </p>
                            <p className='text-[#AAAD81]  font-semibold'>
                                {`</head>`}
                            </p>
                        </div>

                        <img src={img_bg} className='w-full absolute top-0 left-0' />
                    </div>
                </main>

            </main>
        </div>
    );
}

export default AdminProfileSEO;