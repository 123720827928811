import LineDot from "../../../components/line-dot/LineDot";
import Footer from "../../../container/footer/Footer";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Helmet } from "react-helmet";

import logo1 from './slider/1.png'
import logo2 from './slider/2.png'
import logo3 from './slider/3.png'
import logo4 from './slider/4.png'
import logo5 from './slider/5.png'

import logo6 from './slider/TranS4MErs.png'
import logo7 from './slider/STU.png'
import logo8 from './slider/csob.png'
import logo9 from './slider/enterprise-europe-network.png'
import logo10 from './slider/BIC-Bratislava.png'
import logo11 from './slider/alfaMapLogo.png'
import logo12 from './slider/spu.png'

import 'swiper/css';
import 'swiper/css/navigation';
import './businessrelationships.css'

function BusinessRelationships() {
  const [slidesPerView, setSlidesPerView] = useState(calculateSlidesPerView());
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setSlidesPerView(calculateSlidesPerView());
  };

  function calculateSlidesPerView() {
    if (window.innerWidth < 768) {
      return 1;
    } else if (window.innerWidth < 1280) {
      return 2.2;
    } else {
      return 3.2;
    }
  }


  return (
    <>
      <Helmet>
        <title>EHOSS | Business Relationships</title>
      </Helmet>
      <section id="br-section" className="overflow-hidden">
        <div className="fixed h-[100vh] w-24 z-[-9999]">
          <LineDot />
        </div>

        <div className="container">

          <div className="mt-24">
            <Breadcrumbs
              items={[
                { label: 'Cooperation', url: '#' },
                { label: 'Business Relationships', url: '/business-relationships' },
              ]}
            />
            <div className="flex md:flex-row justify-between items-center mb-4 mt-8 md:mb-10">
              <h2 className="text-[32px] font-semibold text-white">
                Business Relationships
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="grid md:grid-cols-3 gap-16">
            {/* Сard 1 */}
            <div className=' text-white'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo1} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 text-[22px] mt-6'>
                <a className="hover:text-red" target="_blank" href="https://www.sario.sk/">
                  Slovak Investment and
                  Trade Development
                  Agency (SARIO)
                </a>
              </h3>
              <p className='text-[14px]'>
                is the investment promotion state agency of the Slovak Republic under the authority of the Ministry of Economy of the Slovak Republic.
              </p>
            </div>
            {/* Сard 2 */}
            <div className=' text-white'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo2} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 text-[22px] mt-6'>
                <a className="hover:text-red" target="_blank" href="https://www.exporteri.sk/">
                  Council of Slovak Exporters
                  {/* <p className="opacity-0 pointer-events-none">1</p> */}
                </a>
              </h3>
              <p className='text-[14px]'>
                is an association comprised of private entities and a business platform open for all Slovak businesses that are already exporting or intending to access foreign markets. The organisation aims to link exporters and develop connections with governmental bodies, international organizations, financial institutions, and banks.
              </p>
            </div>
            {/* Сard 3 */}
            <div className=' text-white'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo3} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 text-[22px] mt-6'>
                <a className="hover:text-red" target="_blank" href="https://eximbanka.sk">
                  EXIMBANKA SR
                  {/* <p className="opacity-0 pointer-events-none">1</p>
                  <p className="opacity-0 md:hidden pointer-events-none">1</p> */}
                </a>
              </h3>

              <p className='text-[14px]'>
                is giving support in trade insurance, trade finance and other forms of export in the form of loans, guarantees and credit insurance to enable companies to export products and services to other countries.
              </p>

            </div>
            {/* Сard 4 */}
            <div className=' text-white'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo4} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                <a className="hover:text-red" target="_blank" href="https://www.chsok.sk/">
                  Chorvátsko – Slovenská Obchodná Komora
                  {/*                 
                  <p className="opacity-0 md:hidden pointer-events-none">1</p> */}
                </a>
              </h3>

              <p className='text-[14px]'>
                is an international business organization established to support and develop relations between Croatia and Slovakia. The organization mission is to provide services and information for members engaged in trade or investment in the two countries.
              </p>

            </div>
            {/* Сard 5 */}
            <div className=' text-white'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo5} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                <a className="hover:text-red" target="_blank" href="https://slovakia.mfa.gov.ua/sk">
                  Embassy of Ukraine in the Slovak Republic
                  <p className="opacity-0 md:hidden pointer-events-none">1</p>
                </a>
              </h3>
              <p className='text-[14px]'>
                As EHOSS was born in Ukraine and all core team are Ukrainians, we are supporting initiatives of Embassy of Ukraine in the Slovak Republic and are grateful for opportunities for possible cooperation.
              </p>
            </div>
            {/* Сard 6 */}
            <div className=' text-white'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo6} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                <a className="hover:text-red" target="_blank" href="https://trans4mers.eu/">
                  TranS4MErs
                </a>
              </h3>
            </div>
            {/* Сard 7 */}
            <div className=' text-white'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo7} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                <a className="hover:text-red" target="_blank" href="https://www.stuba.sk/">
                  STU
                  <p className="opacity-0 md:hidden pointer-events-none">1</p>
                </a>
              </h3>
            </div>
            {/* Сard 8 */}
            <div className=' text-white'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo8} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                <a className="hover:text-red" target="_blank" href="https://www.csob.sk/">
                  ČSOB
                </a>
              </h3>
            </div>

            {/* Сard 9 */}
            <div className=' text-white'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo9} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                <a className="hover:text-red" target="_blank" href="https://een.ec.europa.eu/">
                  Enterprise Europe Network    
                </a>
              </h3>
            </div>

            {/* Сard 10*/}
            <div className=' text-white'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo10} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                <a className="hover:text-red" target="_blank" href="https://www.bic.sk/">
                BIC-Bratislava
                  
                </a>
              </h3>
            </div>

            {/* Сard 11 */}
            <div className=' text-white'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-20" src={logo11} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                <a className="hover:text-red" target="_blank" href="https://alfaep.eu/">
                  ALFA
                
                </a>
              </h3>
            </div>
            
            {/* Сard 12 */}
            <div className=' text-white'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo12} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 mt-2 text-[22px] mt-6'>
                <a className="hover:text-red" target="_blank" href="https://www.uniag.sk/">
                  Slovak University of Agriculture in Nitra
                </a>
              </h3>
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </>

  );
}

export default BusinessRelationships;
