import './footer.scss';
import LineDot from '../../components/line-dot/LineDot';
import cow from '../../assets/icons/COW.svg';
import horse from '../../assets/icons/HORSE.svg';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useState, useEffect } from 'react';

import soc1 from './social/Crunchbase.svg'
import soc2 from './social/academicons_researchgate-square.svg'
import soc3 from './social/basil_instagram-solid.svg'
import soc4 from './social/ic_outline-telegram.svg'
import soc5 from './social/icon-park-solid_twitter.svg'
import soc6 from './social/mdi_linkedin.svg'
import soc7 from './social/mingcute_youtube-fill.svg'
import soc8 from './social/ph_tiktok-logo-fill.svg'
import soc9 from './social/ri_facebook-box-fill.svg'

function Footer() {

  const [isExpandedMainLink, setIsExpandedMainLink] = useState(true);
  const [isExpandedMainLink2, setIsExpandedMainLink2] = useState(true);
  const [isExpandedMainLink3, setIsExpandedMainLink3] = useState(true);

  const [isExpanded, setIsExpanded] = useState(true);
  const [isExpanded2, setIsExpanded2] = useState(true);
  const [isExpanded3, setIsExpanded3] = useState(true);
  const [isExpanded4, setIsExpanded4] = useState(true);
  const [isExpanded5, setIsExpanded5] = useState(true);
  const [isExpanded6, setIsExpanded6] = useState(true);
  const [isExpanded7, setIsExpanded7] = useState(true);
  const [isExpanded8, setIsExpanded8] = useState(true);
  const [isExpanded9, setIsExpanded9] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight <= 830) {
        setIsExpanded(false);
        setIsExpanded2(false);
        setIsExpanded3(false);
        setIsExpanded4(false);
        setIsExpanded5(false);
        setIsExpanded6(false);
        setIsExpanded7(false);
        setIsExpanded8(false);
        setIsExpanded9(false);
      }
      if (window.innerHeight > 830) {
        setIsExpanded(true);
        setIsExpanded2(true);
        setIsExpanded3(true);
        setIsExpanded4(true);
        setIsExpanded5(true);
        setIsExpanded6(true);
        setIsExpanded7(true);
        setIsExpanded8(true);
        setIsExpanded9(true);



      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight >= 830) {
        const cols = document.querySelectorAll('#col');
        if (cols.length > 0) {
          cols.forEach(col => {
            col.click();
          });
        }
      } else {
        setIsExpandedMainLink(true);
        setIsExpandedMainLink2(true);
        setIsExpandedMainLink3(true);
      }
    };

    handleResize(); // Вызываем функцию при монтировании компонента

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function openCooperationModal() {
    const modal = document.getElementById('cooperation-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }

  function openLoginModal() {
    const modal = document.getElementById('login-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }


  function openCareerModal() {
    const modal = document.getElementById('career-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }

  function openGetPrModal() {
    const modal = document.getElementById('getpr-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }



  const handleClick = () => {
    if (window.innerWidth < 830) {
      setIsExpandedMainLink(!isExpandedMainLink);
    }
  };

  const handleClick2 = () => {
    if (window.innerWidth < 830) {
      setIsExpandedMainLink2(!isExpandedMainLink2);
    }
  };

  const handleClick3 = () => {
    if (window.innerWidth < 830) {
      setIsExpandedMainLink3(!isExpandedMainLink3);
    }
  };


  return (
    <div className='section text-white bg-[#1d1d1d]'>
      <LineDot />
      <div className='container'>
        <footer className='footer '>
          <div className='w-full h-[100vh] flex flex-col justify-between pt-24'>
            <div className='grid md:grid-cols-4 relative z-10'>
              {/* ========== COL 1 START ========= */}
              <div className='bg-gray p-4 rounded-xl overflow-hidden md:h-auto'>
                <div className={`overflow-hidden transition-[0.3] ${isExpandedMainLink ? 'h-auto' : 'h-7'}`}>
                  <div id='col' onClick={handleClick} className='flex items-center justify-between'>
                    <h3 className='font-bold hover:text-red text-[20px]'>
                      <a href='/ecosystem'>
                        ECOSYSTEM
                      </a>
                    </h3>
                    <svg className={`h-6 md:hidden w-6 transition-[0.3] cursor-pointer ${isExpandedMainLink ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                    </svg>
                  </div>

                  <div>
                    <h4 className='mb-3 mt-4 font-semibold text-[16px]'>BIOMASS TECHNOLOGY</h4>
                    <div>
                      <ul className='flex flex-col gap-2'>
                        <li className='text-[14px] flex items-center justify-between'>
                          <a className='w-[120px] md:w-auto' href='/reusing-platform'>
                          Reusing Platform
                          </a>
                          <div className='flex gap-2'>
                                <img className='w-4 h-4' src={cow} />
                                <img className='w-4 h-4' src={horse} />
                              </div>
                        </li>
                        {/* <li className='text-[14px] text-lgray'>
                          <a href='/soon'>
                            Hay Management Technology
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div>
                    <div className={`overflow-hidden transition-[0.3] ${isExpanded3 ? 'h-auto' : 'h-10'}`}>
                      <div className='flex gap-8 items-center justify-between'>
                        <h4 className='mb-3 mt-4 font-semibold text-[16px]'>
                          <a href='#'>ANIMAL CARE</a>
                        </h4>
                        <svg onClick={() => setIsExpanded3(!isExpanded3)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded3 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                          <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                        </svg>
                      </div>

                      <div>
                        <ul className='flex flex-col gap-2'>
                          {/* <li className='text-[14px]'>
                            <p className='font-semibold'>
                              Preorder:
                            </p>
                          </li> */}
                          {/* <li className='text-[14px]'>
                            <a className='flex justify-between  items-center' href='/reusing-platform'>
                              Reusing Platform
                              <div className='flex gap-2'>
                                <img className='w-4 h-4' src={cow} />
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li> */}
                          <li className='text-[14px]'>
                            <a className='flex justify-between items-center' href='/forage-platform'>
                              Forage Platform
                              <div className='flex gap-2'>
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li>
                          <li className='text-[14px]'>
                            <a className='flex justify-between items-center' href='/modular-stable'>
                              Modular Stable
                              <div className='flex gap-2'>
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className='mt-6'>
                        <ul className='flex flex-col gap-2'>
                          <li className='text-[14px]'>
                            <parent className='font-semibold'>
                              Concept:
                            </parent>
                          </li>
                          <li className='text-[14px]'>
                            <a className='flex justify-between items-center' href='/grain-platform'>
                              Grain Platform
                              <div className='flex gap-2'>
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li>
                          <li className='text-[14px]'>
                            <a className='flex justify-between items-center' href='/one-horse-platform'>
                              One Horse Platform
                              <div className='flex gap-2'>
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li>
                          <li className='text-[14px]'>
                            <a className='flex justify-between items-center' href='/monitoring-platform'>
                              Monitoring Platform
                              <div className='flex gap-2'>
                                <img className='w-4 h-4' src={cow} />
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li>
                          <li className='text-[14px] text-lgray'>
                            <a className='flex justify-between items-center' href='/soon'>
                              Enviroment Platform
                              <div className='flex gap-2'>
                                <img className='w-4 h-4' src={cow} />
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      
                    </div>


                    <div className='mt-6'>
                      <h3 className='font-semibold text-[16px] hover:text-red'>
                        <a href='https://map.ehoss.com' target='_blank'>E-MAP PLATFORM</a>
                      </h3>
                      <h3 className='font-semibold text-[16px] text-lgray'>
                        <a href='/soon' target='_blank'>BIOMASS PLATFORM</a>
                      </h3>
                    </div>
                    <div className='mt-6'>
                      <ul className='flex flex-col gap-2'>
                        <li className='text-[14px]'>
                          <a className='text-lgray pointer-events-none' href='#'>
                            Get PDF Catalog
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='text-lgray pointer-events-none' href='#'>
                            Sign up for Demonstration
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
              {/* ========== COL 2 START ========= */}
              <div className='p-4 rounded-xl overflow-hidden md:h-auto'>
                <div className={`overflow-hidden transition-[0.3] ${isExpandedMainLink2 ? 'h-auto' : 'h-7'}`}>
                  <div id='col' onClick={handleClick2} className='flex items-center justify-between'>
                    <h3 className='font-bold text-[20px]'>WHO NEEDS?</h3>
                    <svg className={`h-6 md:hidden w-6 transition-[0.3] cursor-pointer ${isExpandedMainLink2 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                    </svg>
                  </div>


                  <div className={`overflow-hidden transition-[0.3] ${isExpanded ? 'h-auto' : 'h-10'}`}>
                    <div className='flex gap-8 items-center justify-between'>
                      <h4 className='mb-3 mt-4 font-semibold text-[16px] 1'>
                        <a href='/audience'>FOR ANIMAL OWNERS</a>
                      </h4>
                      <svg onClick={() => setIsExpanded(!isExpanded)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                      </svg>
                    </div>

                    <div>
                      <ul className='flex flex-col gap-2'>
                        <li className='text-[14px]'>
                          <a className='' href='/farmers'>
                            Farmers
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a href='/horse-owners'>
                            Horse Owners
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a href='/equestrian-facilities'>
                            Equestrian Facilities
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a href='/equine-producers'>
                            Equine Product Producers
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a href='/veterinary'>
                            Veterinary
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a href='/equestrians'>
                            Equestrians
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <div className={`overflow-hidden transition-[0.3] ${isExpanded2 ? 'h-auto' : 'h-10'}`}>
                      <div className='flex gap-8 items-center justify-between'>
                        <h4 className='mb-3 mt-4 font-semibold text-[16px] 1'>FOR BUSINESS</h4>
                        <svg onClick={() => setIsExpanded2(!isExpanded2)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded2 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                          <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                        </svg>
                      </div>
                      <div>
                        <ul className='flex flex-col gap-2'>
                          <li className='text-[14px] text-lgray'>
                            <a className='' href='/soon'>
                              Biogas Plant
                            </a>
                          </li>
                          <li className='text-[14px] text-lgray'>
                            <a className='' href='/soon'>
                              Agricultural Company
                            </a>
                          </li>
                          <li className='text-[14px] text-lgray'>
                            <a className='' href='/soon'>
                              Pellet Manufacturer
                            </a>
                          </li>
                          <li className='text-[14px] text-lgray'>
                            <a className='' href='/soon'>
                              Vertical Farming
                            </a>
                          </li>
                          <li className='text-[14px] text-lgray'>
                            <a className='' href='/soon'>
                              Insulation Manufacturer
                            </a>
                          </li>
                          <li className='text-[14px] text-lgray'>
                            <a className='' href='/soon'>
                              Substrate Manufacturer
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ========== COL 3 START ========= */}
              <div className='p-4 rounded-xl overflow-hidden md:h-auto'>
                <div className={`overflow-hidden transition-[0.3] ${isExpandedMainLink3 ? 'h-auto' : 'h-7'}`}>
                  <div id='col' onClick={handleClick3} className='flex items-center justify-between'>
                    <h3 className='font-bold text-[20px]'>COMPANY</h3>
                    <svg className={`h-6 md:hidden w-6 transition-[0.3] cursor-pointer ${isExpandedMainLink3 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                    </svg>
                  </div>
                  <div className={`overflow-hidden transition-[0.3] ${isExpanded5 ? 'h-auto' : 'h-10'}`}>
                    <div className='flex gap-8 items-center justify-between'>
                      <h4 className='mb-3 mt-4 font-semibold text-[16px] 1'>EHOSS</h4>
                      <svg onClick={() => setIsExpanded5(!isExpanded5)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded5 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                      </svg>
                    </div>
                    <div>
                      <ul className='flex flex-col gap-2 '>
                        <li className='text-[14px]'>
                          <a className='capitalize	' href='/about'>
                            About company
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a href='/laboratory-and-r-and-d'>
                            Laboratory and R&D
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='' href='/esg-and-sustainability'>
                            ESG and Sustainability
                          </a>
                        </li>
                        {/* <li className='text-[14px]'>
                          <a className='' href='/ethics-and-compilance'>
                            Ethics & Compliance
                          </a>
                        </li> */}
                        <li className='text-[14px]'>
                          <a className='' href='/grants-and-awards'>
                            Grants and Awards
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={`overflow-hidden transition-[0.3] ${isExpanded6 ? 'h-auto' : 'h-10'}`}>
                    <div className='flex gap-8 items-center justify-between'>
                      <h4 className='mb-3 hover:text-red mt-4 font-semibold text-[16px] 1'>
                        <a href='/blog'>BLOG</a>
                      </h4>
                      <svg onClick={() => setIsExpanded6(!isExpanded6)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded6 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                      </svg>
                    </div>
                    <div>
                      <ul className='flex flex-col gap-2'>
                        <li className='text-[14px]'>
                          <a href='/press-about-us'>
                            Media
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a href='/events'>
                            Events
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a href='/articles'>
                            Articles
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={`overflow-hidden transition-[0.3] ${isExpanded4 ? 'h-auto' : 'h-10'}`}>
                    <div className='flex gap-8 items-center justify-between'>
                      <h4 className='mb-3 mt-4 font-semibold text-[16px] 1'>
                        PEOPLE
                      </h4>
                      <svg onClick={() => setIsExpanded4(!isExpanded4)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded4 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                      </svg>
                    </div>

                    <div>
                      <ul className='flex flex-col gap-2'>
                        <li className='text-[14px]'>
                          <a className='' href='/team'>
                            Team
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='text-lgray' href='/soon'>
                            Board
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='' href='/expert-advisors'>
                            Expert Advisors
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='text-lgray' href='/soon'>
                            Ambassadors
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='' href='/careers'>
                            Career
                          </a>
                        </li>
                        <li className='text-[14px] capitalize	'>
                          <button onClick={openCareerModal} className='underline capitalize	 hover:text-red'>
                            Job application
                          </button>
                        </li>
                        <li className='text-[14px]'>
                          <a className='underline capitalize	 text-lgray' href='/soon'>
                            Internship Application
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* ========== COL 4 START ========= */}
              <div className='md:p-4 px-4 pt-0 overflow-hidden md:h-auto'>
                <div>
                  <h3 className='font-bold hidden md:block text-transparent pointer-events-none text-[20px]'>MARKET</h3>
                  <div className={`overflow-hidden transition-[0.3] ${isExpanded7 ? 'h-auto' : 'h-10'}`}>
                    {/* <div className='flex gap-8 items-center justify-between border-b border-gray mb-4'> */}
                    <div className='flex gap-8 items-center justify-between'>
                      <h4 className='mb-3 mt-4 md:font-semibold md:text-[16px] font-bold text-[20px]'>COOPERATION</h4>
                      <svg onClick={() => setIsExpanded7(!isExpanded7)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded7 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                      </svg>
                    </div>
                    <div>
                      <ul className='flex flex-col gap-2'>
                        <li className='text-[14px]'>
                          <a className='text-lgray' href='/soon'>
                            Distributors
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='text-lgray' href='/soon'>
                            Partners
                          </a>
                        </li>
                        <li className='text-[14px] capitalize	'>
                          <a className='' href='/business-relationships'>
                            Business Relationships
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='text-lgray' href='/soon'>
                            Educational Establishments
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='underline' onClick={openCooperationModal} href='#'>
                            Cooperation Request
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={`overflow-hidden mt-4 md:mt-0 transition-[0.3] ${isExpanded8 ? 'h-auto' : 'h-10'}`}>
                    <div className='flex gap-8 items-center justify-between'>
                      <h4 className='mb-3 mt-4 md:font-semibold md:text-[16px] font-bold text-[20px]'>FOR INVESTORS</h4>
                      <svg onClick={() => setIsExpanded8(!isExpanded8)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded8 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                      </svg>
                    </div>
                    <div>
                      <ul className='flex flex-col gap-2'>
                        <li className='text-[14px]'>
                          <p onClick={openGetPrModal} className='underline hover:text-red cursor-pointer'>
                            Get Presentation
                          </p>
                        </li>
                        <li className='text-[14px]'>
                          <a className='underline text-lgray pointer-events-none' href='#'>
                            Reports
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='text-lgray' href='/soon'>
                            Investor Application
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='underline text-lgray pointer-events-none' href='#'>
                            Get Invest Deck
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={`overflow-hidden mt-4 md:mt-0 transition-[0.3] ${isExpanded9 ? 'h-auto' : 'h-10'}`}>
                    <div className='flex gap-8 items-center justify-between'>
                      <h4 className='mb-3 mt-4 flex gap-2 items-center md:font-semibold md:text-[16px] font-bold text-[20px]'>
                        ACCOUNT
                        <UserCircleIcon className='w-6 h-6' />
                      </h4>
                      <svg onClick={() => setIsExpanded9(!isExpanded9)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded9 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                      </svg>
                    </div>

                    <div>
                      <ul className='flex flex-col gap-2'>
                        <li className='text-[14px] capitalize	'>
                          <a onClick={openLoginModal} className='text-lgray pointer-events-none' href='#'>
                            Authorised login
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='text-lgray' href='/soon'>
                            Support
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='text-lgray' href='/soon'>
                            Documentation
                          </a>
                        </li>
                        <li className='text-[14px]'>
                          <a className='text-lgray' href='/soon'>
                            Downloads
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={`overflow-hidden mt-4 md:mt-0 transition-[0.3]`}>
                    <div className='flex gap-8 items-center justify-between'>
                      <h4 className='mb-3 mt-4 md:font-semibold md:text-[16px] font-bold text-[20px]'>
                        <a className='hover:text-red' href='/contacts'>
                          CONTACTS
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-12 pb-6 flex flex-col md:flex-row text-center md:text-start justify-between items-center'>
              <ul className='flex gap-4 order-1 md:order-1 md:mb-0 flex-col md:flex-row  *:text-[#979797] *:text-[14px]'>
                <li>
                  <a href='/privacy-policy'>
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href='/terms-of-use'>
                    Terms of Use
                  </a>
                </li>
                <li>
                  <a href='/sales-and-refund'>
                    Sales and Refunds
                  </a>
                </li>
                <li>
                  <a href='/soon'>
                    Site Map
                  </a>
                </li>
                <li>
                  <a className='pointer-events-none text-lgray' href='#'>
                    Cookie Preferences
                  </a>
                </li>
              </ul>
              <span className='text-[#979797] md:mb-0 mb-12 order-3 md:order-2 text-[12px]'>
                COPYRIGHT © 2024 EHOSS ALL RIGHTS RESERVED
              </span>
              <div className='flex order-2 md:order-3 mt-16 mb-6 md:m-0 justify-center gap-1 items-center'>
                <a href='https://www.crunchbase.com/organization/ehoss' target='_blank'>
                  <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc1}></img>
                </a>
                <a href='https://www.researchgate.net/publication/361401127_EHOSS_Trailer_Platform_was_implemented_by_EHOSS_to_impact_horse_stress' target='_blank'>
                  <img className='w-[26px] transition hover:scale-110 h-[26px] object-contain' src={soc2}></img>
                </a>
                <a href='https://www.instagram.com/ehossofficial/' target='_blank'>
                  <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc3}></img>
                </a>
                <a href='https://t.me/ehossofficial' target='_blank'>
                  <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc4}></img>
                </a>
                <a href='https://twitter.com/ehossofficial/' target='_blank'>
                  <img className='w-[26px] transition hover:scale-110 h-[26px] object-contain' src={soc5}></img>
                </a>
                <a href='https://www.linkedin.com/company/ehoss/' target='_blank'>
                  <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc6}></img>
                </a>
                <a href='https://www.youtube.com/@ehoss' target='_blank'>
                  <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc7}></img>
                </a>
                <a href='https://www.tiktok.com/@ehossofficial' target='_blank'>
                  <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc8}></img>
                </a>
                <a href='https://www.facebook.com/ehossofficial/' target='_blank'>
                  <img className='w-[28px] transition hover:scale-110 h-[28px] object-contain' src={soc9}></img>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Footer;
