import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import './main.css';
import logo from './../../assets/images/logo.svg';
import bg_img from './0000.jpg';
import bg_imgm from './0000-m.jpg';
import gsap from 'gsap';
import Footer from '../../container/footer/Footer';
import Line from '../../components/line/Line';
import LineDot from '../../components/line-dot/LineDot';
import { Link } from 'react-router-dom';

function Main() {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const intervalDuration = 35;
  const totalImages = 330;
  const isAnimatingRef = useRef(false);
  const startScreen = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const checkVisitStatus = () => {
      const visited = localStorage.getItem('visited');
      if (visited) {
        setIsFirstVisit(false);
        console.log("User has visited the site before.");
      } else {
        console.log("User is visiting the site for the first time.");
      }
    };

    checkVisitStatus();
  }, []);

  const bgimgwidth = window.innerWidth < 830 ? bg_imgm : bg_img;

  const loadImages = useCallback(async () => {
    const slideFolder = window.innerWidth < 830 ? 'slides830' : 'slides1920';

    const loadedImages = await Promise.all(
      Array.from({ length: totalImages }, (_, index) => {
        const paddedIndex = index.toString().padStart(4, '0');
        return `./${slideFolder}/${paddedIndex}.jpg`;
      }).map(async (src, index) => {
        const img = new Image();
        img.src = src;

        await new Promise((resolve) => {
          img.onload = () => {
            const percentage = ((index + 1) / totalImages) * 100;
            if (percentage >= loadingPercentage) {
              setLoadingPercentage(percentage);
            }
            resolve();
          };
        });

        return img;
      })
    );

    setLoadingPercentage(100);
    localStorage.setItem('visited', 'true');
    return loadedImages;
  }, [totalImages, loadingPercentage]);

  useEffect(() => {
    const fetchData = async () => {
      const loadedImages = await loadImages();
      setImages(loadedImages);
    };

    fetchData();
  }, [loadImages]);

  useEffect(() => {
    if (!isFirstVisit && loadingPercentage === 100 && buttonRef.current) {
      buttonRef.current.click();
    }
  }, [isFirstVisit, loadingPercentage]);

  const moveToSlide = useCallback(
    (targetIndex) => {
      if (isAnimatingRef.current) {
        return;
      }

      isAnimatingRef.current = true;

      const start = currentIndex;
      const step = targetIndex > start ? 1 : -1;
      const indexesToAnimate = Array.from({ length: Math.abs(targetIndex - start) + 1 }, (_, index) => start + index * step);

      animateIndexes(indexesToAnimate);
    },
    [currentIndex]
  );

  const animateIndexes = useCallback(
    (indexesToAnimate) => {
      let currentIndex = indexesToAnimate[0];
      const intervalId = setInterval(() => {
        setCurrentIndex(currentIndex);

        if (currentIndex === indexesToAnimate[indexesToAnimate.length - 1]) {
          clearInterval(intervalId);
          isAnimatingRef.current = false;
        } else {
          currentIndex += indexesToAnimate[0] < indexesToAnimate[indexesToAnimate.length - 1] ? 1 : -1;
        }
      }, intervalDuration);
    },
    [intervalDuration]
  );

  function HideStartScreen() {
    if (startScreen.current) {
      startScreen.current.classList.add('hidden');
    }

    if (animateRef.current) {
      gsap.fromTo(
        animateRef.current,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1.3 }
      );
    }
  }

  const animateRef = useRef(null);
  const animateRef2 = useRef(null);
  const animateRef2button = useRef(null);
  const animateRef3 = useRef(null);
  const animateRef4 = useRef(null);

  useEffect(() => {
    let nav = document.querySelector('#fp-nav');
    nav.classList.add('onmain');
    return () => {
      nav.classList.remove('onmain');
    };
  }, []);

  return (
    <>
      <div
        ref={startScreen}
        className='flex overflow-hidden transition-all flex-col items-center justify-end loading-indicator fixed z-[9999] w-full h-full bg-black'>
        
        <div className='flex flex-col items-center'>
          <img className='max-w-[160px] mb-6 mt-12 md:mt-44' src={logo} alt='Ehoss main logo' />
          <h1 className='text-white leading-[130%] uppercase font-medium text-[24px] md:text-[36px] text-center'>
            Advancing Biomass Technologies
          </h1>
          <p className='text-white mt-2 max-w-2xl text-center text-[12px] md:text-[14px]'>
            RND manufacturer of engineering equipment for farms and stables. We design equipment for the automatisation of feeding and animal care processes, recycle animal manure and supply feedstock to consumers.
          </p>
        </div>
        
        <button
          ref={buttonRef}
          onClick={() => {
            if (loadingPercentage === 100) {
              HideStartScreen();
              setTimeout(() => {
                moveToSlide(70);
              }, 300)
            }
          }}
          className='cursor-pointer mt-4 mb-10 overflow-hidden border-red border-2 w-[200px] relative rounded-[16px]'>
          <h1 className='text-white absolute w-full mt-2 mb-2 font-medium text-[20px] text-center'>
            {loadingPercentage === 100 ? 'Enter website' : `${loadingPercentage.toFixed()}%`}
          </h1>
          <div className='bg h-12 z-0 bg-red' style={{ width: `${loadingPercentage}%` }}></div>
        </button>
        <img src={bgimgwidth} className={`absolute w-full h-full object-cover transition-[1s] z-[-1] ${loadingPercentage === 100 ? 'opacity-50' : `opacity-0`}`}/>
      </div>

      <div className='fixed w-full h-full'>
        {images.length > 0 &&
          <img
            className='absolute inset-0 w-full h-[100vh] object-cover'
            src={images[currentIndex].src}
            alt={`Slide ${currentIndex + 1}`}
          />
        }
      </div>
      <ReactFullpage
        // Опции FullPage.js
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={3300}
        navigation
        afterLoad={(origin, destination, direction) => {
          // Этот код выполнится перед началом анимации перехода
          // if (destination.index === 0) {

          // }
          // if (destination.index === 1) {

          // }
          // if (destination.index === 2) {

          // }
          // if (destination.index === 3) {

          // }
        }}
        onLeave={(origin, destination, direction) => {
          // Этот код выполнится перед началом анимации перехода
          if (destination.index === 0) {
            moveToSlide(70);
          }
          if (destination.index === 1) {
            moveToSlide(180);
          }
          if (destination.index === 2) {
            moveToSlide(295);
          }
          if (destination.index === 3) {
            moveToSlide(329);
          }
        }}
        render={({ state, fullpageApi }) => {
          return (
            <div>
              <ReactFullpage.Wrapper>
                {/* ================ slide 1 ================ */}
                <div className='section'>
                  <LineDot />
                  <div className='container'>
                    <div className='pb-6 flex justify-between gap-4 items-end h-[100vh]'>
                      <div className='flex flex-col justify-center md:items-center'>
                        <h2
                          ref={animateRef2}
                          className={`text-white md:block hidden text-[22px] md:text-[52px] uppercase text-center`}
                        >
                          Feed your horse <br className='hidden md:block'></br>
                          better hay
                        </h2>
                        <Link ref={animateRef2button} to='/forage-platform' className='main-btn main-btn-home mt-6 mb-4'>
                          Forage Platform
                        </Link>
                      </div>
                      <div className='flex flex-col justify-center md:items-center'>
                        <h2
                          ref={animateRef2}
                          className={`text-white md:block hidden  text-[22px] md:text-[52px] uppercase text-center`}
                        >
                          recycle animal  <br className='hidden md:block'></br>
                          manure
                        </h2>
                        <Link ref={animateRef2button} to='/reusing-platform' className='main-btn main-btn-home mt-6 mb-4'>
                          Reusing Platform
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ================ slide 2 ================ */}
                <div className='section'>
                  <Line />
                  <div className='container'>
                    <div className='flex gap-8 flex-col items-start justify-end h-[100vh]'>


                      <h2
                        ref={animateRef3}
                        className={`text-white block pb-10 md:pb-20 text-[16px] md:text-[64px]`}
                      >
                        MONETIZE MANURE, <br />
                        PRESERVE THE PLANET
                      </h2>
                      {/* <Link ref={animateRef2button} to='/reusing-platform?button=2' className='main-btn'>
                        Learn more
                      </Link> */}
                    </div>
                  </div>
                </div>
                {/* ================ slide 3 ================ */}
                <div className='section'>
                  <Line />
                  <div className='container'>
                    <div className='pb-6  flex gap-8 flex-col items-start justify-end h-[100vh]'>
                      <h2
                        ref={animateRef2}
                        className={`text-white md:mb-0 pb-10 md:pb-20 text-[16px] md:text-[64px]`}
                      >
                        CHOPS, DISINFECTS, CLEANS <br />
                        AND MOISTURIZES<br />
                        THE WHOLE BALE OF HAY
                      </h2>
                      {/* <Link ref={animateRef2button} to='/forage-platform' className='main-btn'>
                        Learn more
                      </Link> */}
                    </div>
                  </div>
                </div>
                <div className='section'>
                  <Line/>
                  <div className='container'>
                    <div className='pb-10 md:pb-20  flex justify-between gap-4 items-end h-[100vh]'>
                      <div className='flex md:w-1/2  md:mb-0 flex-col justify-center md:items-center'>
                      
                        <Link ref={animateRef2button} to='/forage-platform#1' className='main-btn main-btn-home  mt-6 mb-4'>
                          FORAGE PLATFORM
                        </Link>
                      </div>
                      <div className='flex md:w-1/2 md:mb-0 flex-col justify-center md:items-center'>
                      
                        <Link ref={animateRef2button} to='/reusing-platform#1' className='main-btn main-btn-home mt-6 mb-4'>
                          Reusing Platform
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ================ slide 4 ================ */}
                {/* <div className='section'>
                  <Line />
                  <div className='container'>
                    <div className='pb-20 flex gap-8 flex-col items-start justify-end h-[100vh]'>
                      <h2
                        ref={animateRef4}
                        className={`text-white text-[28px] md:text-[64px]`}
                      >
                        REUSING PLATFORM
                      </h2>
                      <a href='/reusing-platform' className='main-btn'>
                        Learn more
                      </a>
                    </div>
                  </div>
                </div> */}
                {/* ================ slide 5 ================ */}
                {/* <div className='section'>
                  <Line />
                  <div className='container'>
                    <div className='pb-20 flex gap-8 flex-col items-start justify-end h-[100vh]'>
                      <h2
                        ref={animateRef4}
                        className={`text-white text-[28px] md:text-[64px]`}
                      >
                        TRACKS THE EVENTS
                      </h2>
                      <a href='/monitoring-platform' className='main-btn'>
                        Learn more
                      </a>
                    </div>
                  </div>
                </div> */}
                {/* ================ slide 6 ================ */}
                {/* <div className='section'>
                  <Line />
                  <div className='container'>
                    <div className='pb-20 flex gap-8 flex-col items-start justify-end h-[100vh]'>
                      <h2
                        ref={animateRef4}
                        className={`text-white text-[28px] md:text-[64px]`}
                      >
                        MONITORS TEMPERATURE,<br />
                        NOISE LEVEL<br />
                        AND AIR QUALITY
                      </h2>
                      <a href='/monitoring-platform' className='main-btn'>
                        Learn more
                      </a>
                    </div>
                  </div>
                </div> */}
                <Footer />
              </ReactFullpage.Wrapper>
            </div>
          );
        }}
      />
    </>

  );
}

export default Main;