import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MapPinIcon } from '@heroicons/react/24/outline';
import LineDot from '../line-dot/LineDot';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import Footer from '../../container/footer/Footer';

function Vacancy() {
    const { id } = useParams();
    console.log(id);
    const [job, setJob] = useState(null);

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const response = await fetch(`https://form.ehoss.com/api/vacancies/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch job details');
                }
                const data = await response.json();
                setJob(data);
            } catch (error) {
                console.error('Error fetching job details:', error);
                // Обработка ошибок, если нужно
            }
        };

        fetchJob();
    }, [id]);

    if (!job) {
        return <div className='text-white mt-40'>Loading...</div>;
    }

    
    function openCareerModal() {
        const modal = document.getElementById('career-modal');
        modal.classList.remove('hidden');
        modal.classList.add('flex');
    }


    return (
        <>
            <div className='z-[-20] md:z-10 fixed left-0 w-3 h-full'>
                <LineDot />
            </div>
            <div className="job-details text-white container ">

                <div className='pt-24'>
                    <Breadcrumbs
                        items={[
                            { label: 'Company', url: '#' },
                            { label: 'People', url: '#' },
                            { label: 'Careers', url: '/careers' },
                            { label: `${job.title}`, url: '#' }
                        ]}
                    />
                    <div className='bg-gray p-4 md:p-10 mt-8 rounded-xl'>

                        <h1 className='md:text-[32px] font-medium '>{job.title}</h1>
                        <p className='opacity-30 mb-4'>{job.posted_date}</p>

                        <p className='flex gap-2 items-center'>
                            <MapPinIcon className='w-4 text-red' />

                            {job.location}
                        </p>
                        <hr className='mt-4 opacity-30' />
                        <div className='mt-4 vacancy' dangerouslySetInnerHTML={{ __html: job.description }} />
                        <div className='mt-16'>
                            <button onClick={openCareerModal} className='main-btn text-black'>
                                Send CV
                            </button>
                        </div>
                    </div>
                </div>


            </div>
            <div className='bg-black z-10 relative'>
            <Footer />
            </div>
            
        </>

    );
}

export default Vacancy;