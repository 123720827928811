import LineDot from "../../components/line-dot/LineDot";
import Footer from "../../container/footer/Footer";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel, Pagination } from 'swiper/modules';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import slide1 from './slider/1.jpg'
import slide2_1 from '../reusing-platform/slider/1.svg'
import slide2_2 from '../reusing-platform/slider/2.svg'
import slide3 from './slider/3.png'
import slide4 from './slider/4.jpg'
import slide5 from './slider/5.jpg'
import slide6 from './slider/6.jpg'
// import slide3 from './slider/2.png'
// import slide4 from './slider/2.png'

import 'swiper/css';
import 'swiper/css/navigation';
import './environment.css'

function ResearchAndDevelopment() {
  const [slidesPerView, setSlidesPerView] = useState(calculateSlidesPerView());
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  const [isEnd2, setIsEnd2] = useState(false);
  const [isBeginning2, setIsBeginning2] = useState(true);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setSlidesPerView(calculateSlidesPerView());
  };

  function calculateSlidesPerView() {
    if (window.innerWidth < 768) {
      return 1;
    } else if (window.innerWidth < 1280) {
      return 2.2;
    } else {
      return 3.2;
    }
  }

  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>EHOSS | Environmental, Social, Governance (ESG) and Sustainability</title>
        <meta name="keywords" content="SDG, sustainable farming, GHG emissions, sustainable production, ESG" />
        <meta name="robots" content="index, follow" />
        <meta name="description" content="Our sustainable production, what SDGs are we committed to, and how do our products protect from GHG emissions and provide sustainable farming." />
        <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
      </Helmet>
      <section id="br-section" className="overflow-hidden">
        <div className="fixed h-[100vh] w-24 z-[-9999]">
          <LineDot />
        </div>

        <div className="container">

          <div className="mt-24">
            <Breadcrumbs
              items={[
                { label: 'Company', url: '#' },
                { label: 'EHOSS', url: '#' },
                { label: 'ESG and Sustainability', url: '/esg-and-sustainability' },
              ]}
            />
            <div className="flex md:flex-row justify-between items-center mb-4 mt-8 md:mb-10">
              <h1 className="text-[32px] font-semibold text-white">
                Environmental, Social, Governance (ESG) and Sustainability
              </h1>

            </div>

            {/* <div
                            className="flex gap-2 text-white items-center mt-4 md:mb-0 mb-4 md:mt-16 cursor-pointer"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <p className="text-[13px]">Scroll right</p>
                            <ArrowRightIcon className={`w-5 h-5 transition-transform duration-300 ${isHovered ? 'transform translate-x-2' : ''}`} />
                        </div> */}
          </div>
          
          <div className='w-full cursor-grab hidden md:block relative slider-shadow'>
            <Swiper
              modules={[Navigation, Mousewheel]}
              spaceBetween={50}
              slidesPerView={slidesPerView}
              className={`sw1 ${isBeginning ? '' : 'sw2'}`}
              mousewheel={{ enabled: false }}
              navigation={{
                nextEl: '.custom-r',
                prevEl: '.custom-l'
              }}
              onSlideChange={({ isBeginning, isEnd, swiper }) => {
                setIsBeginning(isBeginning);
                setIsEnd(isEnd);
              }}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img title="EHOSS laboratory microscope screen with some microelements" className="w-full h-full object-cover" src={slide1} alt="laboratory microscope screen with some microelements" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>
                    Sustainable Production
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>
                  <div id='start-line' className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    We prioritize environmental sustainability and take active steps towards protecting and conserving natural resources. To ensure our equipment is eco-friendly, we use food-grade plastic for our feeding platforms, and incorporate recycled plastic into certain components. Additionally, we use low-energy devices and LED panels, which significantly reduce electricity consumption and minimize our carbon footprint. We also recognize the importance of water conservation and have implemented a closed cycle system to reuse water in our production process.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    {/* <img title="EHOSS laboratory microscope screen with some microelements" className="w-full h-full object-cover" src={slide2} alt="laboratory microscope screen with some microelements" /> */}


                    <Swiper
                      modules={[Navigation, Pagination]}
                      spaceBetween={50}
                      slidesPerView={1}
                      pagination={{
                        clickable: true
                      }}
                      // className={`sw1 ${isBeginning ? '' : 'sw2'}`}
                      mousewheel={{ enabled: false }}
                      navigation={{
                        nextEl: '.custom-r2',
                        prevEl: '.custom-l2'
                      }}
                      onSlideChange={({ isBeginning2, isEnd2, swiper }) => {
                        setIsBeginning2(isBeginning2);
                        setIsEnd2(isEnd2);
                      }}
                      onSwiper={(swiper) => console.log(swiper)}
                    >
                      <SwiperSlide>
                        <div className=' text-white  '>
                          <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                            <img title="EHOSS laboratory microscope screen with some microelements" className="w-full h-full object-contain p-5" src={slide2_1} alt="laboratory microscope screen with some microelements" />
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className=' text-white  '>
                          <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                            <img title="EHOSS laboratory microscope screen with some microelements" className="w-full h-full object-contain p-5" src={slide2_2} alt="laboratory microscope screen with some microelements" />
                          </div>
                        </div>
                      </SwiperSlide>
                      <div className='flex mt-8 absolute z-20 items-center justify-center gap-4 mt-2'>
                        <div className={`custom-l2 cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                          <ArrowLeftIcon />
                        </div>
                        <div className={`custom-r2 cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                          <ArrowRightIcon />
                        </div>
                      </div>
                    </Swiper>


                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>
                    Protecting
                    GHG emissions
                    <p className="opacity-0 pointer-events-none">1</p>
                  </h3>
                  <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    While traditional storage of manure GHG emissions occurs. In particular, these are CH4 and N2O emissions (check charts above). That contributes to climate change and global warming. EHOSS <a className="underline font-bold" href="https://ehoss.com/reusing-platform">MMT</a> reduces emissions due to better storing and optimization of logistics.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="w-full h-full object-contain" title="EHOSS engineer is installing sensors in a stable" src={slide3} alt="a man installs sensors under the ceiling" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    Commitment to
                    the SDGs
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>
                  <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    <ul className="list">
                      <li>
                        GOAL 07: Developing manure usage as feedstock that in turn influences access to alternative energy sources.
                      </li>
                      <li>
                        GOAL 09: Innovation in manure disposal that makes farming more sustainable.
                      </li>
                      <li>
                        GOAL 13: Reducing GHG emissions that occur during the traditional storage of manure and contribute to climate change and global warming. Reduction of carbon emissions due to better planning and optimization of logistics
                      </li>
                    </ul>
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="w-full h-full object-cover" title="EHOSS's first prototype for preparing hay for horses" src={slide4} alt="hay in equipment for preparing it for horses" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    Sustainable Farming
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>
                  <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    Innovation in manure disposal that makes farming more sustainable (from the side of manure utilization and using liquid fraction as organic fertiliser to replace usage of chemical ones).                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="w-full h-full object-cover" title="EHOSS's first prototype for preparing hay for horses" src={slide5} alt="hay in equipment for preparing it for horses" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    Mold &
                    Rodents Problem
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>
                  <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    EHOSS feeding platform is developed to improve the quality of the products consumed by the horse. Additionally, it is optimising the hay and fodder storage. Improper storage may cause feed spoiling, bacteria and mold growth. This can be both a cause and a consequence of the appearance of rodents. Mice and rats are often carriers of pathogens of dangerous diseases. And while poison is a common solution to eliminate rodents, it can be dangerous, and the very act of killing rodents is cruel. This approach goes against our values, so we are very happy that our platform provides another solution to the rodent problem. EHOSS equipment provides better storage of food products, rodents simply cannot access the feed.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="w-full h-full object-cover" title="EHOSS's first prototype for preparing hay for horses" src={slide6} alt="hay in equipment for preparing it for horses" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    Ethics & Compliance
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>
                  <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    EHOSS is driven by a commitment to ethical business practices and compliance with local regulations. Our partnerships are founded upon honesty and respect, while our team culture values robust communication as an essential ingredient for product quality. The set of principles of our company prioritizes:
                    <ul className="list">
                      <li>mutual respect</li>
                      <li>support</li>
                      <li>employee empowerment</li>
                    </ul>
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className='flex mt-8 z-20 items-center justify-center gap-4 mt-2'>
              <div className={`custom-l cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                <ArrowLeftIcon />
              </div>
              <div className={`custom-r cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                <ArrowRightIcon />
              </div>
            </div>
          </div>
          <div className='w-full cursor-grab flex flex-col gap-16 md:hidden relative slider-shadow'>

                <div className=' text-white bg-gray p-4 rounded-xl'>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img title="EHOSS laboratory microscope screen with some microelements" className="w-full h-full object-cover" src={slide1} alt="laboratory microscope screen with some microelements" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>
                    Sustainable Production
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>
  
                  <p className='text-[14px]'>
                    We prioritize environmental sustainability and take active steps towards protecting and conserving natural resources. To ensure our equipment is eco-friendly, we use food-grade plastic for our feeding platforms, and incorporate recycled plastic into certain components. Additionally, we use low-energy devices and LED panels, which significantly reduce electricity consumption and minimize our carbon footprint. We also recognize the importance of water conservation and have implemented a closed cycle system to reuse water in our production process.
                  </p>
                </div>
         
                <div className=' text-white bg-gray p-4 rounded-xl'>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    {/* <img title="EHOSS laboratory microscope screen with some microelements" className="w-full h-full object-cover" src={slide2} alt="laboratory microscope screen with some microelements" /> */}


                    <Swiper
                      modules={[Navigation, Pagination]}
                      spaceBetween={50}
                      slidesPerView={1}
                      pagination={{
                        clickable: true
                      }}
                      // className={`sw1 ${isBeginning ? '' : 'sw2'}`}
                      mousewheel={{ enabled: false }}
                      navigation={{
                        nextEl: '.custom-r2',
                        prevEl: '.custom-l2'
                      }}
                      onSlideChange={({ isBeginning2, isEnd2, swiper }) => {
                        setIsBeginning2(isBeginning2);
                        setIsEnd2(isEnd2);
                      }}
                      onSwiper={(swiper) => console.log(swiper)}
                    >
                      <SwiperSlide>
                        <div className=' text-white  '>
                          <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                            <img title="EHOSS laboratory microscope screen with some microelements" className="w-full h-full object-contain p-5" src={slide2_1} alt="laboratory microscope screen with some microelements" />
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className=' text-white  '>
                          <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                            <img title="EHOSS laboratory microscope screen with some microelements" className="w-full h-full object-contain p-5" src={slide2_2} alt="laboratory microscope screen with some microelements" />
                          </div>
                        </div>
                      </SwiperSlide>
                      <div className='flex mt-8 absolute z-20 items-center justify-center gap-4 mt-2'>
                        <div className={`custom-l2 cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                          <ArrowLeftIcon />
                        </div>
                        <div className={`custom-r2 cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                          <ArrowRightIcon />
                        </div>
                      </div>
                    </Swiper>


                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>
                    Protecting
                    GHG emissions
                    <p className="opacity-0 pointer-events-none">1</p>
                  </h3>

                  <p className='text-[14px]'>
                    While traditional storage of manure GHG emissions occurs. In particular, these are CH4 and N2O emissions (check charts above). That contributes to climate change and global warming. EHOSS <a className="underline font-bold" href="https://ehoss.com/reusing-platform">MMT</a> reduces emissions due to better storing and optimization of logistics.
                  </p>
                </div>
          
                <div className=' text-white bg-gray p-4 rounded-xl'>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="w-full h-full object-contain" title="EHOSS engineer is installing sensors in a stable" src={slide3} alt="a man installs sensors under the ceiling" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    Commitment to
                    the SDGs
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>
                  <p className='text-[14px]'>
                    <ul className="list">
                      <li>
                        GOAL 07: Developing manure usage as feedstock that in turn influences access to alternative energy sources.
                      </li>
                      <li>
                        GOAL 09: Innovation in manure disposal that makes farming more sustainable.
                      </li>
                      <li>
                        GOAL 13: Reducing GHG emissions that occur during the traditional storage of manure and contribute to climate change and global warming. Reduction of carbon emissions due to better planning and optimization of logistics
                      </li>
                    </ul>
                  </p>
                </div>
          
                <div className=' text-white bg-gray p-4 rounded-xl'>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="w-full h-full object-cover" title="EHOSS's first prototype for preparing hay for horses" src={slide4} alt="hay in equipment for preparing it for horses" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    Sustainable Farming
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>

                  <p className='text-[14px]'>
                    Innovation in manure disposal that makes farming more sustainable (from the side of manure utilization and using liquid fraction as organic fertiliser to replace usage of chemical ones).                  </p>
                </div>
      
                <div className=' text-white bg-gray p-4 rounded-xl'>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="w-full h-full object-cover" title="EHOSS's first prototype for preparing hay for horses" src={slide5} alt="hay in equipment for preparing it for horses" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    Mold &
                    Rodents Problem
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>
                  <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    EHOSS feeding platform is developed to improve the quality of the products consumed by the horse. Additionally, it is optimising the hay and fodder storage. Improper storage may cause feed spoiling, bacteria and mold growth. This can be both a cause and a consequence of the appearance of rodents. Mice and rats are often carriers of pathogens of dangerous diseases. And while poison is a common solution to eliminate rodents, it can be dangerous, and the very act of killing rodents is cruel. This approach goes against our values, so we are very happy that our platform provides another solution to the rodent problem. EHOSS equipment provides better storage of food products, rodents simply cannot access the feed.
                  </p>
                </div>
    
                <div className=' text-white bg-gray p-4 rounded-xl'>
                  <div className="bg-gray flex overflow-hidden justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="w-full h-full object-cover" title="EHOSS's first prototype for preparing hay for horses" src={slide6} alt="hay in equipment for preparing it for horses" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>

                    Ethics & Compliance
                    <p className="opacity-0 pointer-events-none">1</p>

                  </h3>

                  <p className='text-[14px]'>
                    EHOSS is driven by a commitment to ethical business practices and compliance with local regulations. Our partnerships are founded upon honesty and respect, while our team culture values robust communication as an essential ingredient for product quality. The set of principles of our company prioritizes:
                    <ul className="list">
                      <li>mutual respect</li>
                      <li>support</li>
                      <li>employee empowerment</li>
                    </ul>
                  </p>
                </div>
          </div>
        </div>
      </section>
      <Footer />
    </>

  );
}

export default ResearchAndDevelopment;
