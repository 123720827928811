import LineDot from "../../components/line-dot/LineDot";
import Footer from "../../container/footer/Footer";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Helmet } from "react-helmet";

import logo1 from './slider/1.png'
import logo2 from './slider/2.png'
import logo3 from './slider/3.png'
import logo4 from './slider/4.webp'
import logo5 from './slider/5.png'

import 'swiper/css';
import 'swiper/css/navigation';
import './grantsandawards.css'

function GrantsAndAwards() {
  const [slidesPerView, setSlidesPerView] = useState(calculateSlidesPerView());
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setSlidesPerView(calculateSlidesPerView());
  };

  function calculateSlidesPerView() {
    if (window.innerWidth < 768) {
      return 1;
    } else if (window.innerWidth < 1280) {
      return 2.2;
    } else {
      return 3.2;
    }
  }


  return (
    <>
      <Helmet>
        <title>EHOSS | Grants And Awards</title>
      </Helmet>
      <section id="br-section" className="overflow-hidden">
        <div className="fixed h-[100vh] w-24 z-[-9999]">
          <LineDot />
        </div>
        <div className="container">
          <div className="mt-24">
            <Breadcrumbs
              items={[
                { label: 'Company', url: '#' },
                { label: 'EHOSS', url: '#' },
                { label: 'Grants And Awards', url: '/grants-and-awards' },
              ]}
            />
            <div className="flex md:flex-row justify-between items-center mb-4 mt-8 md:mb-10">
              <h2 className="text-[32px] font-semibold text-white">
                Grants And Awards
              </h2>
            </div>
          </div>
          <div className='w-full hidden md:block cursor-grab relative slider-shadow'>
            <Swiper
              modules={[Navigation, Mousewheel]}
              spaceBetween={50}
              slidesPerView={slidesPerView}
              className={`sw1 ${isBeginning ? '' : 'sw2'}`}
              mousewheel={{ enabled: false }}
              navigation={{
                nextEl: '.custom-r',
                prevEl: '.custom-l'
              }}
              onSlideChange={({ isBeginning, isEnd, swiper }) => {
                setIsBeginning(isBeginning);
                setIsEnd(isEnd);
              }}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="p-8 max-w-[200px]" src={logo4} alt="Main EHOSS logo" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>
                    Startup World Cup
                    <p className="opacity-0 pointer-events-none">1</p>
                  </h3>
                  <div id='start-line' className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    EHOSS Reusing Platform was included in the TOP 10 Announcement 
                    of <a className="underline font-medium" target="_blank" rel="noreferrer" href="https://www.startupworldcup.io/">Startup World Cup</a> 2024 
                    in the regional final held in Limassol, Cyprus.
                  </p>
                </div>
              </SwiperSlide>
              {/* <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="p-8 max-w-[200px]" src={logo5} alt="Main EHOSS logo" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>
                    Slovakian National Winner of EEPA 2024
                    <p className="opacity-0 pointer-events-none">1</p>
                  </h3>
                  <div id='start-line' className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                  <a className="underline font-medium" target="_blank" rel="noreferrer" href="https://single-market-economy.ec.europa.eu/smes/learn-and-plan-entrepreneurship/european-enterprise-promotion-awards_en">European Enterprise Promotion Awards (EEPA)</a>
                     is 
                    an initiative by the European Commission that acknowledges 
                    and rewards excellence in promoting entrepreneurship 
                    and small business at the national, regional, and local 
                    levels. Launched in 2006, the EEPA aims to highlight 
                    successful projects and initiatives that support 
                    entrepreneurship and inspire others across Europe.
                    In 2024 EHOSS <a className="underline font-medium" target="_blank" rel="noreferrer" href="https://ehoss.com/reusing-platform">Reusing Platform</a> has placed 1st in the competition and has thus reached the EU round.

                  </p>
                </div>
              </SwiperSlide> */}
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="p-8" src={logo2} alt="Main EHOSS logo" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>
                    One of the most promising startups
                    <p className="opacity-0 pointer-events-none">1</p>
                  </h3>
                  <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    <a className="underline font-medium" target="_blank" rel="noreferrer" href="https://www.eu-startups.com/2023/05/10-slovakian-startups-to-keep-an-eye-on-in-2023-and-beyond/">EU-Startups.com</a>, the leading online magazine about startups in Europe, announced 10 Slovakian startups to watch over in 2023. EHOSS was among this listing of young and promising from Bratislava’s startup ecosystem.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="p-8" src={logo3} alt="Main EHOSS logo" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>
                    Slovakian National Winner of EEPA 2024
                    <p className="opacity-0 pointer-events-none">1</p>
                  </h3>
                  <div className="w-6 h-6 rounded-full bg-[#2A2A2A] border-red border-[6px] mb-4 mt-4">

                  </div>
                  <p className='text-[14px]'>
                    <a className="underline font-medium" target="_blank" rel="noreferrer" href="https://single-market-economy.ec.europa.eu/smes/learn-and-plan-entrepreneurship/european-enterprise-promotion-awards_en">European Enterprise Promotion Awards (EEPA)</a> is an initiative by the European Commission that acknowledges and rewards excellence in promoting entrepreneurship and small business at the national, regional, and local levels. Launched in 2006, the EEPA aims to highlight successful projects and initiatives that support entrepreneurship and inspire others across Europe.
                    In 2024 EHOSS <a className="underline font-medium" target="_blank" rel="noreferrer" href="https://ehoss.com/reusing-platform">Reusing Platform </a> has placed 1st in the competition and has thus reached the EU round.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className=' text-white  '>
                  <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                    <img className="p-8" src={logo1} alt="Main EHOSS logo" />
                  </div>
                  <h3 className='font-bold mb-2 text-[22px] mt-6'>
                    One of the most inventive farming companies and startups
                  </h3>
                  <p className='text-[14px]'>
                    Futurology.Life promote the cuting edge companies, businesses and innovations to the world. In 2022 EHOSS was included in the list of most inventive farming companies and startups from Slovakia.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className='flex mt-8 z-20 items-center justify-center gap-4 mt-2'>
              <div className={`custom-l cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                <ArrowLeftIcon />
              </div>
              <div className={`custom-r cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                <ArrowRightIcon />
              </div>
            </div>
          </div>

          <div className='w-full flex flex-col gap-16 md:hidden cursor-grab relative slider-shadow'>

            <div className=' text-white bg-gray p-4 rounded-xl'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo2} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 text-[22px] mt-6'>

              Startup World Cup
                <p className="opacity-0 pointer-events-none">1</p>

              </h3>
              <p className='text-[14px]'>
                <a className="underline font-medium" target="_blank" rel="noreferrer" href="https://www.eu-startups.com/2023/05/10-slovakian-startups-to-keep-an-eye-on-in-2023-and-beyond/">EU-Startups.com</a>, the leading online magazine about startups in Europe, announced 10 Slovakian startups to watch over in 2023. EHOSS was among this listing of young and promising from Bratislava’s startup ecosystem.
              </p>
            </div>


            {/* <div className=' text-white bg-gray p-4 rounded-xl'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo5} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 text-[22px] mt-6'>

                Slovakian National Winner of EEPA 2024
                <p className="opacity-0 pointer-events-none">1</p>

              </h3>
              <p className='text-[14px]'>
                  <a className="underline font-medium" target="_blank" rel="noreferrer" href="https://single-market-economy.ec.europa.eu/smes/learn-and-plan-entrepreneurship/european-enterprise-promotion-awards_en">European Enterprise Promotion Awards (EEPA)</a>
                     is 
                    an initiative by the European Commission that acknowledges 
                    and rewards excellence in promoting entrepreneurship 
                    and small business at the national, regional, and local 
                    levels. Launched in 2006, the EEPA aims to highlight 
                    successful projects and initiatives that support 
                    entrepreneurship and inspire others across Europe.
                    In 2024 EHOSS <a className="underline font-medium" target="_blank" rel="noreferrer" href="https://ehoss.com/reusing-platform">Reusing Platform</a> has placed 1st in the competition and has thus reached the EU round.

                  </p>
            </div> */}



            <div className=' text-white bg-gray p-4 rounded-xl'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo3} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 text-[22px] mt-6'>

              Slovakian National Winner of EEPA 2024
                <p className="opacity-0 pointer-events-none">1</p>

              </h3>
              <p className='text-[14px]'>
                    <a className="underline font-medium" target="_blank" rel="noreferrer" href="https://single-market-economy.ec.europa.eu/smes/learn-and-plan-entrepreneurship/european-enterprise-promotion-awards_en">European Enterprise Promotion Awards (EEPA)</a> is an initiative by the European Commission that acknowledges and rewards excellence in promoting entrepreneurship and small business at the national, regional, and local levels. Launched in 2006, the EEPA aims to highlight successful projects and initiatives that support entrepreneurship and inspire others across Europe.
                    In 2024 EHOSS <a className="underline font-medium" target="_blank" rel="noreferrer" href="https://ehoss.com/reusing-platform">Reusing Platform </a> has placed 1st in the competition and has thus reached the EU round.
                  </p>
            </div>
            <div className=' text-white bg-gray p-4 rounded-xl'>
              <div className="bg-gray flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                <img className="p-8" src={logo1} alt="Main EHOSS logo" />
              </div>
              <h3 className='font-bold mb-2 text-[22px] mt-6'>
                One of the most inventive farming companies and startups
              </h3>
              <p className='text-[14px]'>
                Futurology.Life promote the cuting edge companies, businesses and innovations to the world. In 2022 EHOSS was included in the list of most inventive farming companies and startups from Slovakia.
              </p>
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </>

  );
}

export default GrantsAndAwards;
