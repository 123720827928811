function LineDot() {
    return (
        <div className="cont-for-red-l custom-line2 line-z">
            <div className="line-1"></div>
            <div className="radius-1">
                <div className="radius-11"></div>
            </div>
            <div className="radius-2">
                <div className="radius-11"></div>
            </div>
            <div className="line-2"></div>
            <div className="line-3"></div>
            <div className="line-4"></div>
            <div className="radius-1">
                <div className="radius-11"></div>
            </div>
            <div className="radius-2">
                <div className="radius-11"></div>
            </div>
            <div className="line-2"></div>
            <div className="line-3"></div>
            <div className="line-1"></div>
        </div>
    );
}

export default LineDot;



