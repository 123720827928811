import ReactFullpage from '@fullpage/react-fullpage';
import LineDot from '../../components/line-dot/LineDot';
import Footer from '../../container/footer/Footer';
import './audience.css'
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import card1 from './images/bg2.png'
import card2 from './images/bg3.png'
import card3 from './images/bg4.png'
import card4 from './images/bg5.png'
import card5 from './images/bg6.png'
import card7 from './images/bg1.png'

function Audience() {
  const location = useLocation();
  return (
    <>
      <ReactFullpage
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={1000}
        navigation
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <Helmet>
                <title>EHOSS | For Animal Owners</title>
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Equine welfare, Horse Welfare, Equine Health Plan, feeding analysis software, equine management nutrition health & exercise" />
                <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
              </Helmet>
              <div className='section overflow-hidden'>
                <LineDot />
                <div className='container flex gap-6 flex-col md:flex-row md:items-center md:justify-between'>
                  <div className=' w-full md:h-[100vh] pt-24 text-white'>
                    <div className='flex flex-col  items-start gap-4'>
                      <Breadcrumbs
                        items={[
                          { label: 'Who Needs?', url: '/' },
                          { label: 'For Animal Owners', url: '/audience' },
                        ]}
                      />
                      <h2 className='md:text-[36px] text-[24px] mt-12 font-medium'>
                        NEW EQUESTRIAN TECHNOLOGIES FOR BETTER ANIMAL WELFARE
                      </h2>
                      <p className='text-[14px]'>
                        The equine industry, with its diverse aspects, shares the same fundamental elements of interaction between horses and their environment as humans. The well-being and safety of domesticated horses are crucial factors that directly impact their health, mental state, and social life. As of 2023, there are approximately 60 million domesticated horses and 100 million wild horses worldwide. However, despite the advancements in human society and technology the equine industry has not undergone significant changes. Horses still live in the stables, eat hay from nets, and some dust is still there, just as it was in old times. In this context, the quality and safety of horses lives remain largely unchanged.
                        <br /> <br />
                        EHOSS project is primarily about changing the traditional approach to horse care. We set a new milestone in feeding and caring for these animals using modern engineering and technological solutions. The solutions we create enhance well-being and safety of the horses. Overcoming the influence of the “conservative nature of the equine industry” may be a challenging task. So we’ve developed technological solutions that incorporate the synergy of various technologies and existing systems. Additionally, we have carefully considered the needs of the equine industry in today's technology-driven world.
                      </p>
                      <p className='text-[16px] font-bold'>
                        EHOSS platforms were created by people who sought to improve horses lives and make it easier to take care of them.
                      </p>
                    </div>
                    <div className='w-full mt-16'>
                      <div className='grid gap-2 grid-cols-2 md:grid-cols-6 w-full'>
                        {/* =========== card 1 ============= */}
                        <a href='/farmers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>Farmers</h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card7} />
                        </a>
                        {/* =========== card 2 ============= */}
                        <a href='/horse-owners' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Horse Owners
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card1} />
                        </a>
                        {/* =========== card 3 ============= */}
                        <a href='/equestrian-facilities' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Equestrian Facilities
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card2} />
                        </a>
                        {/* =========== card 4 ============= */}
                        <a href='/equine-producers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Equine Product Producers
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card3} />
                        </a>
                        {/* =========== card 5 ============= */}
                        <a href='/veterinary' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] mt-4 font-semibold'>
                              Veterinary
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card4} />
                        </a>
                        {/* =========== card 6 ============= */}
                        <a href='/equestrians' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] mt-4 font-semibold'>
                              Equestrians
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card5} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </>

  );
}

export default Audience;