// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footer {
  background-color: black !important;
}

.footer ul li a:hover {
  color: red !important;
}

.footer ul li {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/container/footer/footer.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":["#footer {\r\n    background-color: black !important;\r\n}\r\n\r\n.footer ul li a:hover {\r\n    color: red !important;\r\n}\r\n\r\n.footer ul li {\r\n    margin-left: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
