import './action.css';

function Action() {
    return (
        <>
            <div id='action' className='bg-gray rounded-xl'>
                <div className='w-1/3 p-10 flex flex-col justify-start items-start'>
                    <h3 className='mb-6 font-semibold text-white text-[52px] leading-[120%]'>
                        Become part of the EHOSS team
                    </h3>
                    <div className='flex gap-5 items-center'>
                        <a href='/contacts' className='main-btn'>Contacts</a>
                        <a href='/careers' className=' underline text-white'>Open vacancies</a>
                    </div>
                 
                </div>
            </div>
        </>
    );
}

export default Action;