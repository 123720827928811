// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audience-link:hover img {
    transform: scale(1.2);
    transition: 0.3;
}

.audience-link img {
    transition: 0.3s;
}

.audience-link:hover svg {
    transform: rotate(90deg);
    transition: 0.3;
}

.audience-link svg {
    transition: 0.3s;
}
`, "",{"version":3,"sources":["webpack://./src/pages/audience/audience.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".audience-link:hover img {\r\n    transform: scale(1.2);\r\n    transition: 0.3;\r\n}\r\n\r\n.audience-link img {\r\n    transition: 0.3s;\r\n}\r\n\r\n.audience-link:hover svg {\r\n    transform: rotate(90deg);\r\n    transition: 0.3;\r\n}\r\n\r\n.audience-link svg {\r\n    transition: 0.3s;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
