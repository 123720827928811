import { Link } from "react-router-dom";
import "./header.css";
import logo from "../../assets/images/logo.svg";
import { UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import cow from '../../assets/icons/COW.svg';
import horse from '../../assets/icons/HORSE.svg';
import { useState, useEffect } from 'react';
import prod from './images/all.png'
import prod1 from './images/forage.png'
import prod2 from './images/reusing.png'
import prod3 from './images/modular.png'

function Header() {

  const [isExpandedMainLink, setIsExpandedMainLink] = useState(false);
  const [isExpandedMainLink2, setIsExpandedMainLink2] = useState(false);
  const [isExpandedMainLink3, setIsExpandedMainLink3] = useState(false);
  const [isExpandedMainLink4, setIsExpandedMainLink4] = useState(false);
  const [isExpandedMainLink5, setIsExpandedMainLink5] = useState(false);

  const [openNav, setOpenNav] = useState(false);
  const [openNavPc, setOpenNavPc] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(true);
  const [hoveredImage, setHoveredImage] = useState(prod);
  const [defaultImage, setDefaultImage] = useState(prod);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [isExpanded5, setIsExpanded5] = useState(false);
  const [isExpanded6, setIsExpanded6] = useState(false);
  const [isExpanded7, setIsExpanded7] = useState(false);
  const [isExpanded8, setIsExpanded8] = useState(false);
  const [isExpanded9, setIsExpanded9] = useState(false);


  function openCooperationModal() {
    const modal = document.getElementById('cooperation-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }

  function openLoginModal() {
    const modal = document.getElementById('login-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }

  function openGetPrModal() {
    const modal = document.getElementById('getpr-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }

  // Функция для обновления изображения при наведении на ссылку
  // const handleMouseEnter = (image) => {
  //   setHoveredImage(image);
  // };

  // const handleMouseLeave = () => {
  //   setHoveredImage(defaultImage);
  // };

  return (
    <header id="header" className="fixed z-[99]">
      <div className="backdrop"></div>
      <div id="pc-right-nav-overflow" className={`fixed hidden overflow-auto md:block h-full w-[400px] z-[9999] right-0 top-0 bg-gray transition-[0.3] ${openNavPc ? 'translate-x-0 shadow-r' : 'translate-x-[100%]'}`}>
        <div className="p-10">
          <div className="flex justify-between">
            <a href="/">
              <img className="w-[140px]" src={logo} />
            </a>
            <XMarkIcon onClick={() => setOpenNavPc(!openNavPc)} className="cursor-pointer h-6 w-6 m-2 transform-none text-white" />
          </div>
          <div className="text-white mt-8">
            {/* ========== COL 3 START ========= */}
            <div className='rounded-xl overflow-hidden md:h-auto'>
              <div className='overflow-hidden transition-[0.3] h-auto'>
                {/* <div id='col' className='flex items-center justify-between'>
                  <h3 className='font-bold text-[20px]'>COMPANY</h3>
                  <svg className={`h-6 md:hidden w-6 transition-[0.3] cursor-pointer ${isExpandedMainLink3 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                  </svg>
                </div> */}
                <div className={`overflow-hidden transition-[0.3] ${isExpanded5 ? 'h-auto' : 'h-10'}`}>
                  <div className='flex gap-8 items-center justify-between'>
                    <h4 className='mb-3 mt-4 font-semibold text-[16px] 1'>EHOSS</h4>
                    <svg onClick={() => setIsExpanded5(!isExpanded5)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded5 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                    </svg>
                  </div>
                  <div>
                    <ul className='flex flex-col gap-2'>
                      <li className='text-[14px]'>
                        <a className='hover:text-red' href='/about'>
                          About company
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='hover:text-red' href='/laboratory-and-r-and-d'>
                            Laboratory and R&D
                          </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='hover:text-red' href='/esg-and-sustainability'>
                          ESG and Sustainability
                        </a>
                      </li>
                      {/* <li className='text-[14px]'>
                        <a className='hover:text-red' href='/ethics-and-compilance'>
                          Ethics & Compliance
                        </a>
                      </li> */}
                      <li className='text-[14px]'>
                        <a className='hover:text-red' href='/grants-and-awards'>
                          Grants and Awards
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`overflow-hidden transition-[0.3] ${isExpanded6 ? 'h-auto' : 'h-10'}`}>
                  <div className='flex gap-8 items-center justify-between'>
                    <h4 className='mb-3 hover:text-red mt-4 font-semibold text-[16px] 1'>
                      <a className='hover:text-red' href='/blog'>BLOG</a>
                    </h4>
                    <svg onClick={() => setIsExpanded6(!isExpanded6)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded6 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                    </svg>
                  </div>
                  <div>
                    <ul className='flex flex-col gap-2'>
                      <li className='text-[14px]'>
                        <a className='hover:text-red' href='/press-about-us'>
                          Media
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='hover:text-red' href='/events'>
                          Events
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='hover:text-red' href='/articles'>
                          Articles
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`overflow-hidden transition-[0.3] ${isExpanded4 ? 'h-auto' : 'h-10'}`}>
                  <div className='flex gap-8 items-center justify-between'>
                    <h4 className='mb-3 mt-4 font-semibold text-[16px] 1'>
                      PEOPLE
                    </h4>
                    <svg onClick={() => setIsExpanded4(!isExpanded4)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded4 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                    </svg>
                  </div>

                  <div>
                    <ul className='flex flex-col gap-2'>
                      <li className='text-[14px]'>
                        <a className='hover:text-red' href='/team'>
                          Team
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='text-lgray' href='/soon'>
                          Board
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='' href='/expert-advisors'>
                          Expert Advisors
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='text-lgray' href='/soon'>
                          Ambassadors
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='hover:text-red' href='/careers'>
                          Career
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='underline text-lgray' href='/soon'>
                          Job application
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='underline text-lgray' href='/soon'>
                          Internship Application
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* ========== COL 4 START ========= */}

            <div className='hidden mt-4 md:block border-t border-lgray overflow-hidden md:h-auto'>
              <div>
                <div className={`overflow-hidden transition-[0.3] ${isExpanded7 ? 'h-auto' : 'h-10'}`}>
                  {/* <div className='flex gap-8 items-center justify-between border-b border-gray mb-4'> */}
                  <div className='flex gap-8 items-center justify-between'>
                    <h4 className='mb-3 mt-4 font-semibold text-[16px]'>COOPERATION</h4>
                    <svg onClick={() => setIsExpanded7(!isExpanded7)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded7 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                    </svg>
                  </div>
                  <div>
                    <ul className='flex flex-col gap-2'>
                      <li className='text-[14px]'>
                        <a className='text-lgray' href='/soon'>
                          Distributors
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='text-lgray' href='/soon'>
                          Partners
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='' href='/business-relationships'>
                          Business Relationships
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='text-lgray' href='/soon'>
                          Educational Establishments
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='underline hover:text-red' onClick={openCooperationModal} href='#'>
                          Cooperation Request
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`overflow-hidden transition-[0.3] ${isExpanded8 ? 'h-auto' : 'h-10'}`}>
                  <div className='flex gap-8 items-center justify-between'>
                    <h4 className='mb-3 mt-4 font-semibold text-[16px] 1'>FOR INVESTORS</h4>
                    <svg onClick={() => setIsExpanded8(!isExpanded8)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded8 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                    </svg>
                  </div>
                  <div>
                    <ul className='flex flex-col gap-2'>
                      <li className='text-[14px]'>
                        <p onClick={openGetPrModal} className='underline cursor-pointer hover:text-red' >
                          Get Presentation
                        </p>
                      </li>
                      <li className='text-[14px]'>
                        <a className='underline text-lgray pointer-events-none' href='#'>
                          Reports
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='text-lgray' href='/soon'>
                          Investor Application
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='underline text-lgray pointer-events-none' href='#'>
                          Get Invest Deck
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`overflow-hidden transition-[0.3] ${isExpanded9 ? 'h-auto' : 'h-10'}`}>
                  <div className='flex gap-8 items-center justify-between'>
                    <h4 className='mb-3 mt-4 flex gap-2 items-center font-semibold text-[16px]'>
                      ACCOUNT
                    </h4>
                    <svg onClick={() => setIsExpanded9(!isExpanded9)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpanded9 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                    </svg>
                  </div>

                  <div>
                    <ul className='flex flex-col gap-2'>
                      <li className='text-[14px]'>
                        <a onClick={openLoginModal} className='text-lgray pointer-events-none' href='#'>
                          Authorised login
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='text-lgray' href='/soon'>
                          Support
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='text-lgray' href='/soon'>
                          Documentation
                        </a>
                      </li>
                      <li className='text-[14px]'>
                        <a className='text-lgray' href='/soon'>
                          Downloads
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`overflow-hidden transition-[0.3] h-auto`}>
                  <div className='flex gap-8 items-center justify-between'>
                    <h4 className='mb-3 mt-4 flex gap-2 items-center font-semibold text-[16px]'>
                      <a className="" href="/contacts">
                        CONTACTS
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`text-white overflow-x-hidden md:hidden overflow-auto pr-6 pt-6 pl-6 fixed z-[9999] w-full h-full bg-gray transition-[0.3] ${openNav ? 'block' : 'hidden'}`}>
        <div className="mb-16 flex items-center justify-between">
          <a href="/">
            <img src={logo} className="w-[140px]" />
          </a>
          <XMarkIcon onClick={() => setOpenNav(!openNav)} className="h-6 w-6 m-2 transform-none text-white" />
        </div>
        <div onClick={() => setIsExpandedMainLink(!isExpandedMainLink)} className={`overflow-hidden cursor-pointer transition-[0.3] ${isExpandedMainLink ? 'h-auto' : 'h-7'}`}>
          <div className='flex items-center justify-between'>
            <a href="/ecosystem" className='font-bold text-[20px]'>ECOSYSTEM</a>
            <svg className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpandedMainLink ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
            </svg>
          </div>

          <div>
            <h3 className="font-bold mt-4 mb-2">BIOMASS TECHNOLOGY</h3>
            <div className="mb-6">
              {/* <h4 className="text-[10px] opacity-50 mb-1">
                PREORDER:
              </h4> */}
              <ul className="flex flex-col gap-1">
                <li>
                  <a
                    className="hover:text-red md:text-[14px] text-[12px] flex justify-between items-center"
                    href="/reusing-platform"
                  // onMouseEnter={() => handleMouseEnter(prod2)}
                  // onMouseLeave={handleMouseLeave}
                  >
                    Reusing Platform
                    <div className='flex gap-2'>
                      <img className='w-4 h-4' src={cow} />
                      <img className='w-4 h-4' src={horse} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <h3 className="font-bold mb-2">ANIMAL CARE</h3>

            <div className="mb-3">
              {/* <h4 className="text-[10px] opacity-50 mb-1">
                PREORDER:
              </h4> */}
              <ul className="flex flex-col gap-1">
                <li>
                  <a
                    className="hover:text-red md:text-[14px] text-[12px] flex justify-between items-center"
                    href="/forage-platform"
                  // onMouseEnter={() => handleMouseEnter(prod1)}
                  // onMouseLeave={handleMouseLeave}
                  >
                    Forage Platform
                    <div className='flex gap-2'>

                      <img className='w-4 h-4' src={horse} />
                    </div>
                  </a>
                  <li>
                    <a
                      className="hover:text-red md:text-[14px] text-[12px] flex justify-between items-center"
                      href="/modular-stable"
                    >
                      Modular Stable
                      <div className='flex gap-2'>
                        <img className='w-4 h-4' src={horse} />
                      </div>
                    </a>
                  </li>
                </li>
              </ul>
            </div>
            <div className="mb-3">
              <h4 className="text-[10px] opacity-50 mb-1">
                Concept:
              </h4>
              <ul className="flex flex-col gap-1">
                <li>
                  <a
                    className="hover:text-red md:text-[14px] text-[12px] flex justify-between items-center"
                    href="/grain-platform"
                  >
                    Grain Platform
                    <div className='flex gap-2'>
                      <img className='w-4 h-4' src={horse} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    className="hover:text-red md:text-[14px] text-[12px] flex justify-between items-center"
                    href="/one-horse-platform"
                  >
                    One-Horse Platform
                    <div className='flex gap-2'>
                      <img className='w-4 h-4' src={horse} />
                    </div>
                  </a>
                </li>

                <li>
                  <a
                    className="hover:text-red md:text-[14px] text-[12px] flex justify-between items-center"
                    href="/monitoring-platform"
                  // onMouseEnter={() => handleMouseEnter(prod2)}
                  // onMouseLeave={handleMouseLeave}
                  >
                    Monitoring Platform
                    <div className='flex gap-2'>
                      <img className='w-4 h-4' src={cow} />
                      <img className='w-4 h-4' src={horse} />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    className="hover:text-red text-lgray md:text-[14px] text-[12px] flex justify-between items-center"
                    href="/soon"
                  // onMouseEnter={() => handleMouseEnter(prod2)}
                  // onMouseLeave={handleMouseLeave}
                  >
                    Environment Platform
                    <div className='flex gap-2'>
                      <img className='w-4 h-4' src={cow} />
                      <img className='w-4 h-4' src={horse} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div onClick={() => setIsExpandedMainLink2(!isExpandedMainLink2)} className={`overflow-hidden cursor-pointer mt-4 transition-[0.3] ${isExpandedMainLink2 ? 'h-auto' : 'h-7'}`}>
          <div className='flex items-center justify-between'>
            <h3 className='font-bold text-[20px]'>WHO NEEDS?</h3>
            <svg className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpandedMainLink2 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
            </svg>
          </div>

          <div className="mt-6">
            <div className={`overflow-hidden transition-[0.3] ${isExpanded3 ? 'h-auto' : 'h-10'}`}>
              <ul className='flex flex-col gap-2'>
                <li className='md:text-[14px] text-[12px] hover:text-red'>
                  <a className='' href='/farmers'>
                    Farmers
                  </a>
                </li>
                <li className='md:text-[14px] text-[12px] hover:text-red'>
                  <a href='/horse-owners'>
                    Horse Owners
                  </a>
                </li>
                <li className='md:text-[14px] text-[12px] hover:text-red'>
                  <a href='/equestrian-facilities'>
                    Equestrian Facilities
                  </a>
                </li>
                <li className='md:text-[14px] text-[12px] hover:text-red'>
                  <a href='/equine-producers'>
                    Equine Product Producers
                  </a>
                </li>
                <li className='md:text-[14px] text-[12px] hover:text-red'>
                  <a href='/veterinary'>
                    Veterinary
                  </a>
                </li>
                <li className='md:text-[14px] text-[12px] hover:text-red'>
                  <a href='/equestrians'>
                    Equestrians
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div onClick={() => setIsExpandedMainLink3(!isExpandedMainLink3)} className={`overflow-hidden cursor-pointer mt-4 transition-[0.3] ${isExpandedMainLink3 ? 'h-auto' : 'h-7'}`}>
          <div className='flex items-center justify-between'>
            <h3 className='font-bold text-[20px]'>COMPANY</h3>
            <svg className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpandedMainLink3 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
            </svg>
          </div>

          <div className="pl-2">
            <div>
              <div className={`overflow-hidden transition-[0.3] h-auto`}>
                <div className='flex gap-8 items-center justify-between'>
                  <h4 className='mb-3 mt-4 font-semibold text-[16px] 1'>EHOSS</h4>
                </div>
                <div>
                  <ul className='flex flex-col gap-2'>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='' href='/about'>
                        About company
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='hover:text-red' href='/laboratory-and-r-and-d'>
                            Laboratory and R&D
                        </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='' href='/esg-and-sustainability'>
                        ESG and Sustainability
                      </a>
                    </li>
                    {/* <li className='md:text-[14px] text-[12px]'>
                      <a className='' href='/ethics-and-compilance'>
                        Ethics & Compliance
                      </a>
                    </li> */}
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='' href='/grants-and-awards'>
                        Grants and Awards
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`overflow-hidden transition-[0.3] h-auto`}>
                <div className='flex gap-8 items-center justify-between'>
                  <h4 className='mb-3 hover:text-red mt-4 font-semibold text-[16px] 1'>
                    <a href='/blog'>BLOG</a>
                  </h4>
                </div>
                <div>
                  <ul className='flex flex-col gap-2'>
                    <li className='md:text-[14px] text-[12px]'>
                      <a href='/press-about-us'>
                        Media
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a href='/events'>
                        Events
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a href='/articles'>
                        Articles
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`overflow-hidden transition-[0.3] h-auto`}>
                <div className='flex gap-8 items-center justify-between'>
                  <h4 className='mb-3 mt-4 font-semibold text-[16px] 1'>
                    PEOPLE
                  </h4>
                </div>

                <div>
                  <ul className='flex flex-col gap-2'>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='hover:text-red' href='/team'>
                        Team
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='text-lgray' href='/soon'>
                        Board
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='' href='/expert-advisors'>
                        Expert Advisors
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='text-lgray' href='/soon'>
                        Ambassadors
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='' href='/careers'>
                        Career
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='underline text-lgray' href='/soon'>
                        Job application
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='underline text-lgray' href='/soon'>
                        Internship Application
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div onClick={() => setIsExpandedMainLink4(!isExpandedMainLink4)} className={`overflow-hidden cursor-pointer mt-4 transition-[0.3] ${isExpandedMainLink4 ? 'h-auto' : 'h-7'}`}>
          <div className='flex items-center justify-between'>
            <h3 className='font-bold text-[20px] uppercase'>Cooperation</h3>
            <svg className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpandedMainLink4 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
            </svg>
          </div>
          <div className="pl-2 pt-2">
            <div>
              <div className={`overflow-hidden transition-[0.3] h-auto`}>
                <div>
                  <ul className='flex flex-col gap-2'>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='text-lgray' href='/soon'>
                        Distributors
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='text-lgray' href='/soon'>
                        Partners
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='' href='/business-relationships'>
                        Business Relationships
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='text-lgray' href='/soon'>
                        Educational Establishments
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='underline' onClick={openCooperationModal} href='#'>
                        Cooperation Request
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div onClick={() => setIsExpandedMainLink5(!isExpandedMainLink5)} className={`overflow-hidden cursor-pointer mt-4 transition-[0.3] ${isExpandedMainLink5 ? 'h-auto' : 'h-7'}`}>
          <div className='flex items-center justify-between'>
            <h3 className='font-bold text-[20px] uppercase'>for investors</h3>
            <svg className={`h-6 w-6 transition-[0.3] cursor-pointer ${isExpandedMainLink5 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
            </svg>
          </div>

          <div className="pl-2 pt-2">
            <div>
              <div className={`overflow-hidden transition-[0.3] h-auto`}>
                <div>
                  <ul className='flex flex-col gap-2'>
                    <li className='md:text-[14px] text-[12px]'>
                      <p  onClick={openGetPrModal} className='underline cursor-pointer hover:text-red' >
                        Get Presentation
                      </p>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='underline text-lgray pointer-events-none' href='#'>
                        Reports
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='text-lgray' href='/soon'>
                        Investor Application
                      </a>
                    </li>
                    <li className='md:text-[14px] text-[12px]'>
                      <a className='underline text-lgray pointer-events-none' href='#'>
                        Get Invest Deck
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`overflow-hidden cursor-pointer mt-4 transition-[0.3] h-auto`}>
          <div className='flex items-center justify-between'>
            <h3 className='font-bold text-[20px] uppercase'>
              <a href='/contacts'>
                contacts
              </a>
            </h3>
          </div>
        </div>

        <div className={`overflow-hidden mt-4 mb-8 transition-[0.3] h-auto`}>
          <div className='flex items-center justify-between'>
            <h3 className='font-bold text-[20px] hover:text-red'> <a href="https://map.ehoss.com/" target="_blank">E-MAP</a></h3>
          </div>
        </div>
        <div className="mb-8">
          <a href="/reusing-platform" className="header-poster-link bg-[#1d1d1d] rounded-xl relative h-24 flex justify-between p-3 pr-6">
            <img src={prod2} className="h-40 object-contain -translate-y-12" />
            <div className="flex items-center justify-between gap-2">
              <p>
                Reusing Platform
              </p>
            </div>
          </a>
          <a href="/forage-platform" className="header-poster-link bg-[#1d1d1d] rounded-xl relative h-24 flex justify-between mt-10 p-3 pl-6">
            <div className="flex items-center justify-between gap-2">
              <p>
                Forage Platform
              </p>
            </div>
            <img src={prod1} className="h-32 object-contain -translate-y-12" />
          </a>
        </div>
      </div>


      <div id="main-nav" className={`container bg-gray rounded-[40px] relative`}>
        <div className="p-2 md:p-0 pl-0 pr-0 mt-4 flex items-center justify-between">
          <div className="flex gap-12 items-center">
            <a href="/">
              <img src={logo} className="w-[140px]" />
            </a>
            <div className="hidden md:block">
              <ul className="flex gap-6">
                <li onMouseEnter={() => { setOpenNavPc(false)}} className="nav-link p-4 hover:bg-lgray rounded-full">
                  <a className="text-white uppercase flex items-center gap-2" href="/ecosystem">
                    ecosystem
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                    </svg>
                  </a>
                  <div className="nav-link-drop overflow-hidden rounded-xl shadow-xl h-[460px] bg-gray top-6 absolute w-full left-0 z-[-1] p-10 text-white">
                    <div className="max-w-[350px]">
                      <h3 className="font-bold mt-4 mb-2">BIOMASS TECHNOLOGY</h3>
                      <div className="mb-6">
                        {/* <h4 className="text-[12px] opacity-50 mb-1">
                          PREORDER:
                        </h4> */}
                        <ul className="flex flex-col gap-1">
                          <li>
                            <a
                              className="hover:text-red justify-between flex gap-10 items-center"
                              href="/reusing-platform"
                            // onMouseEnter={() => handleMouseEnter(prod2)}
                            // onMouseLeave={handleMouseLeave}
                            >
                              Reusing Platform
                              <div className='flex gap-2'>
                                <img className='w-4 h-4' src={cow} />
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>

                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="max-w-[350px]">
                      <h3 className="font-bold mb-2">ANIMAL CARE</h3>
                      <div className="mb-3">
                        {/* <h4 className="text-[12px] opacity-50 mb-1">
                          BUY:
                        </h4> */}
                        {/* <ul className="flex flex-col gap-1">
                          
                        </ul> */}
                      </div>
                      <div className="mb-3">
                        {/* <h4 className="text-[12px] opacity-50 mb-1">
                          PREORDER:
                        </h4> */}
                        <ul className="flex flex-col gap-1">
                          <li>
                            <a
                              className="hover:text-red justify-between flex gap-10 items-center"
                              href="/forage-platform"
                            // onMouseEnter={() => handleMouseEnter(prod1)}
                            // onMouseLeave={handleMouseLeave}
                            >
                              Forage Platform
                              <div className='flex gap-2'>

                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              className="hover:text-red justify-between flex gap-10 items-center"
                              href="/modular-stable"
                            >
                              Modular Stable
                              <div className='flex gap-2'>

                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="mb-3">
                        <h4 className="text-[12px] opacity-50 mb-1">
                          Concept:
                        </h4>
                        <ul className="flex flex-col gap-1">
                          <li>
                            <a
                              className="hover:text-red justify-between flex gap-10 items-center"
                              href="/grain-platform"
                            >
                              Grain Platform
                              <div className='flex gap-2'>
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              className="hover:text-red justify-between flex gap-10 items-center"
                              href="/one-horse-platform"
                            >
                              One-Horse Platform
                              <div className='flex gap-2'>

                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li>

                          <li>
                            <a
                              className="hover:text-red justify-between flex gap-10 items-center"
                              href="/monitoring-platform"
                            // onMouseEnter={() => handleMouseEnter(prod2)}
                            // onMouseLeave={handleMouseLeave}
                            >
                              Monitoring Platform
                              <div className='flex gap-2'>
                                <img className='w-4 h-4' src={cow} />
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li>
                          <li>
                            <a
                              className="hover:text-red justify-between flex gap-10 items-center text-lgray"
                              href="/soon"
                            // onMouseEnter={() => handleMouseEnter(prod2)}
                            // onMouseLeave={handleMouseLeave}
                            >
                              Environment Platform
                              <div className='flex gap-2'>
                                <img className='w-4 h-4' src={cow} />
                                <img className='w-4 h-4' src={horse} />
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </li>
                <li onMouseEnter={() => { setOpenNavPc(false)}} className="nav-link p-4 hover:bg-lgray rounded-full">
                  <a href="javascript:void(0)" className="text-white uppercase flex items-center gap-2 cursor-pointer">
                    WHO NEEDS?
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                      <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                    </svg>
                  </a>
                  <div className="nav-link-drop flex gap-40 rounded-xl shadow-xl h-[350px] bg-gray top-8 absolute w-full left-0 z-[-1] p-10 text-white">

                    <div>
                      <h4 className='mb-3 mt-4 uppercase font-semibold hover:text-red text-[16px] 1'>
                        <a href='/audience'>For Animal Owners</a>
                      </h4>
                      <ul className="flex flex-col gap-3">
                        <li>
                          <a className="hover:text-red" href="/farmers">
                            Farmers
                          </a>
                        </li>
                        <li>
                          <a className="hover:text-red" href="/horse-owners">
                            Horse Owners
                          </a>
                        </li>

                        <li>
                          <a className="hover:text-red" href="/equestrian-facilities">
                            Equestrian Facilities
                          </a>
                        </li>

                        <li>
                          <a className="hover:text-red" href="/equine-producers">
                            Equine Product Producers
                          </a>
                        </li>
                        <li>
                          <a className="hover:text-red" href="/veterinary">
                            Veterinary
                          </a>
                        </li>
                        <li>
                          <a className="hover:text-red" href="/equestrians">
                            Equestrians
                          </a>
                        </li>

                      </ul>
                    </div>

                    <div>
                      <h4 className='mb-3 mt-4 font-semibold text-[16px] 1'>
                        <a href='/soon'>FOR BUSINESS
                        </a>
                      </h4>
                      <ul className="flex flex-col gap-3">
                        <li>
                          <a className="text-lgray hover:text-red" href="/soon">
                            Biogas Plant
                          </a>
                        </li>
                        <li>
                          <a className="hover:text-red text-lgray" href="/soon">
                            Agricultural Company
                          </a>
                        </li>
                        <li>
                          <a className="hover:text-red text-lgray" href="/soon">
                            Pellet Manufacturer
                          </a>
                        </li>
                        <li>
                          <a className="hover:text-red text-lgray" href="/soon">
                            Vertical Farming
                          </a>
                        </li>
                        <li>
                          <a className="hover:text-red text-lgray" href="/soon">
                            Insulation Manufacturer
                          </a>
                        </li>
                        <li>
                          <a className="hover:text-red text-lgray" href="/soon">
                            Substrate Manufacturer
                          </a>
                        </li>

                      </ul>
                    </div>

                  </div>
                </li>
                {/* <li className="nav-link p-4 hover:bg-lgray rounded-full">
                  <a className="text-white uppercase flex items-center gap-2" href="/ethics-and-compilance">
                    Ethics
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div className="hidden md:block">
              <a className="text-white hover:text-red cursor-pointer uppercase flex items-center gap-1" onClick={() => {
                window.location = 'https://map.ehoss.com/'
              }}>
                E-MAP
              </a>
            </div>
            <div className="hidden lg:block">
              <a className="text-lgray uppercase flex items-center gap-1" href="/soon">
                BIOMASS
              </a>
            </div>
            {/* <div>
              <a className="text-white uppercase flex items-center gap-1" href="#">
                EN
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                </svg>
              </a>
            </div> */}
            <div className="flex gap-2">
              <div className="bg-lgray rounded-full cursor-pointer pointer-events-none opacity-40">
                <a className=" transform-none" href="/admin">
                  <UserIcon className="h-6 w-6 m-2 transform-none text-white" />
                </a>
              </div>
              <div onClick={() => setOpenNav(!openNav)} className="bg-lgray md:hidden rounded-full cursor-pointer">
                <div className="h-6 w-6 m-2 text-white flex flex-col gap-2 items-center justify-center" >
                  <div className="h-[1px] w-6 bg-white"></div>
                  <div className="h-[1px] w-6 bg-white"></div>
                </div>
              </div>

              <div onClick={() => setOpenNavPc(!openNavPc)} id="pc-right-nav" className="bg-white hidden md:block rounded-full cursor-pointer">
                <div className="h-6 w-6 m-2 text-white flex flex-col gap-2 items-center justify-center" >
                  <div className="h-[1px] w-6 bg-red"></div>
                  <div className="h-[1px] w-6 bg-red"></div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>

    </header>
  );
}

export default Header;
