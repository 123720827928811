import Posts from "../../components/Posts/Posts";
import Footer from "../../container/footer/Footer";
import LineDot from "../../components/line-dot/LineDot";
import { Helmet } from "react-helmet";

import './blog.css'

function Blog(filter) {
  return (
    <>
      <Helmet>
        <title>EHOSS | Blog</title>
      </Helmet>
      <div className="container home">
        <div className="fixed z-[-10] left-0 w-24 h-[100vh]">
          <LineDot />
        </div>
        <Posts filter={filter} />
      </div>
      <Footer />
    </>

  );
}

export default Blog;
