import ReactFullpage from '@fullpage/react-fullpage';
import LineDot from '../../../components/line-dot/LineDot';
import Footer from '../../../container/footer/Footer';
import './equestrians.css'
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

import Line from '../../../components/line/Line';
import card1 from './../../audience/images/bg2.png'
import card2 from './../../audience/images/bg3.png'
import card3 from './../../audience/images/bg4.png'
import card4 from './../../audience/images/bg5.png'
import card5 from './../../audience/images/bg6.png'
import card7 from './../../audience/images/bg1.png'

function Equestrians() {
    const location = useLocation();
    return (
        <>
            <ReactFullpage
                licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
                scrollingSpeed={1000}
                navigation
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <Helmet>
                                <title>EHOSS | Equestrians</title>
                                <meta name="robots" content="index, follow" />
                                <meta name="keywords" content="Equine welfare, Horse Welfare, Equine Health Plan, feeding analysis software, equine management nutrition health & exercise" />
                                <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
                            </Helmet>
                            <section className='section audience6'>
                                <LineDot />
                                <div className='container'>
                                    <div className='pt-24 flex md:flex-row flex-col gap-8 h-[100vh]'>
                                        <div className='md:w-1/2 text-white'>
                                            <div className='flex flex-col  items-start gap-4'>
                                                <Breadcrumbs
                                                    items={[
                                                        { label: 'Who Needs?', url: '/' },
                                                        { label: 'For Animal Owners', url: '/audience' },
                                                        { label: 'Equestrian Facilities', url: '/equestrian-facilities' },
                                                    ]}
                                                />
                                                <h2 className='text-[36px] mt-12 font-medium'>
                                                    FOR THE PROFESSIONAL SPORTSMEN
                                                </h2>
                                                <p className='text-[14px]'>
                                                    Constant dynamic training that prepares horses for the sports events may demand a change in their ration. It should be highly concentrated and easy to digest. It is also essential to establish a daily schedule that balances training and rest
                                                </p>
                                                <p className='text-[14px] font-bold'>
                                                    The EHOSS ecosystem offers solutions for horses undergoing special training, addressing a range of issues that arise during their preparation and care. Namely:
                                                </p>
                                                <ul className='list flex flex-col gap-4 *:text-[14px] mb-6'>
                                                    <li>
                                                        <strong>Nutrition control: </strong>
                                                        you can monitor what, when and how much the horse ate via the <a className='underline font-medium cursor-pointer' href='/monitoring-platform'>Feed platform </a>
                                                    </li>
                                                    <li>
                                                        <strong>Regularly monitor the horse’s condition: </strong>
                                                        by using feeding programs and monitoring the environmental indicators from the stable, collected by a <a className='underline font-medium cursor-pointer' href='/monitoring-platform'>monitoring platform</a>
                                                    </li>
                                                    <li>
                                                        <strong>Record and analyse data: </strong>
                                                        information is kept on your personal account where you can monitor the dynamics of changes
                                                    </li>
                                                    <li>
                                                        <strong>Online consultations with specialists: </strong>
                                                        you can contact a specialist (the one you chose in the system) via your personal account, and provide them with selective information about a specific horse. The data is collected and stored in the EHOSS ecosystem
                                                    </li>
                                                    <li>
                                                        <strong>The possibility to adjust the horse's diet according to the training program: </strong>
                                                        according to the recommendations of the specialists/ your personal program or according to the programs available within the EHOSS ecosystem, depending on what the horse is being prepared for.
                                                    </li>
                                                    <li>
                                                        <strong>Quick access to the data of the EHOSS ecosystem, because the platforms are easy to use: </strong>
                                                        you can do all of the above using your personal account or a mobile application
                                                    </li>
                                                    <li>
                                                        <strong>Sufficient supply of purified and chopped hay: </strong>
                                                        you can pack the feed in advance and feed it to the horse when travelling , so the horse’s usual diet doesn't change.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className='section overflow-hidden'>
                <Line />
                <div className='container flex gap-6 flex-col md:flex-row md:items-center md:justify-between'>
                  <div className=' w-full md:h-[100vh] pt-24 text-white'>
                    <div>
                      <h2 className='text-[36px] font-medium uppercase'>
                        more about who needs
                      </h2>
                    </div>
                    <div className='w-full mt-12'>
                      <div className='grid gap-2 grid-cols-2 md:grid-cols-6 w-full'>
                        {/* =========== card 1 ============= */}
                        <a href='/farmers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>Farmers</h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card7} />
                        </a>
                        {/* =========== card 2 ============= */}
                        <a href='/horse-owners' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Horse Owners
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card1} />
                        </a>
                        {/* =========== card 3 ============= */}
                        <a href='/equestrian-facilities' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Equestrian Facilities
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card2} />
                        </a>
                        {/* =========== card 4 ============= */}
                        <a href='/equine-producers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Equine Product Producers
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card3} />
                        </a>
                        {/* =========== card 5 ============= */}
                        <a href='/veterinary' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] mt-4 font-semibold'>
                              Veterinary
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card4} />
                        </a>
                        {/* =========== card 6 ============= */}
                        <a href='/equestrians' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] mt-4 font-semibold'>
                              Equestrians
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card5} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                            <Footer />
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </>

    );
}

export default Equestrians;