import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
// import img1 from './images/bg1.jpg'
// import img2 from './images/bg2.png'
// import img3 from './images/bg3.jpg'
// import img4 from './images/bg3.png'
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import LineDot from '../../components/line-dot/LineDot';
import Line from '../../components/line/Line';
import Footer from '../../container/footer/Footer';
import './section.css'
import ModalImage from 'react-modal-image';

import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';
import { ArrowRightIcon, ArrowLeftIcon, LockClosedIcon, XMarkIcon } from '@heroicons/react/24/outline';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import slideImg1 from './images/test1.svg'
import slideImg1mb from './images/sec2mb1.svg'
import slideImg2 from './images/test2.svg'
import slideImg2mb from './images/sec2mb2.svg'
import slideImg3 from './images/test3.svg'
import slideImg3mb from './images/sec2mb3.svg'

import slider_poster from './slider_poster.png'
import slide1 from './slider/slide1.png'
import slide2 from './slider/slide2.png'
import slide3 from './slider/slide3.png'
import slide4 from './slider/slide4.png'
import slide5 from './slider/slide5.png'

import graph1 from './slider/1.svg'
import graph2 from './slider/2.svg'

import card1 from './images/card1.png'
import card2 from './images/card2.png'
import card3 from './images/card3.png'

import video from './mmt.mp4'

function ReusingPlatfrom() {
  const location = useLocation();
  const [slidesPerView, setSlidesPerView] = useState(calculateSlidesPerView());
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleAccordionClick = (index) => {
    if (expandedIndex === index) {
      // Если текущий аккордеон уже открыт, закрываем его
      setExpandedIndex(null);
    } else {
      // В противном случае открываем выбранный аккордеон
      setExpandedIndex(index);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setSlidesPerView(calculateSlidesPerView());
  };

  function calculateSlidesPerView() {
    if (window.innerWidth < 768) {
      return 1;
    } else if (window.innerWidth < 1280) {
      return 2.2;
    } else {
      return 3.2;
    }
  }

  function openRequestModal() {
    const modal = document.getElementById('request-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }




  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(70);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const intervalDuration = 35;
  const totalImages = 330;
  const isAnimatingRef = useRef(false);


  const [activeTab, setActiveTab] = useState('title1');

  const handleClick = (tab) => {
    setActiveTab(tab);
  };



  const loadImages = useCallback(async () => {

    const slideFolder = window.innerWidth < 830 ? 'slides830' : 'slides1920';

    const loadedImages = await Promise.all(
      Array.from({ length: totalImages }, (_, index) => {
        const paddedIndex = index.toString().padStart(4, '0');
        return `./${slideFolder}/${paddedIndex}.jpg`;
      }).map(async (src, index) => {
        const img = new Image();
        img.src = src;

        await new Promise((resolve) => {
          img.onload = () => {
            const percentage = ((index + 1) / totalImages) * 100;
            if (percentage >= loadingPercentage) {
              setLoadingPercentage(percentage);
            }
            resolve();
          };
        });

        return img;
      })
    );

    setLoadingPercentage(100);
    return loadedImages;
  }, [totalImages]);

  useEffect(() => {
    const fetchData = async () => {
      const loadedImages = await loadImages();
      setImages(loadedImages);
    };

    fetchData();
  }, [loadImages]);

  const moveToSlide = useCallback(
    (targetIndex) => {
      if (isAnimatingRef.current) {
        return;
      }

      isAnimatingRef.current = true;

      const start = currentIndex;
      const step = targetIndex > start ? 1 : -1;
      const indexesToAnimate = Array.from({ length: Math.abs(targetIndex - start) + 1 }, (_, index) => start + index * step);

      animateIndexes(indexesToAnimate);
    },
    [currentIndex]
  );

  const animateIndexes = useCallback(
    (indexesToAnimate) => {
      let currentIndex = indexesToAnimate[0];
      const intervalId = setInterval(() => {
        setCurrentIndex(currentIndex);

        if (currentIndex === indexesToAnimate[indexesToAnimate.length - 1]) {
          clearInterval(intervalId);
          isAnimatingRef.current = false;
        } else {
          currentIndex += indexesToAnimate[0] < indexesToAnimate[indexesToAnimate.length - 1] ? 1 : -1;
        }
      }, intervalDuration);
    },
    [intervalDuration]
  );

  moveToSlide(180);


  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const [modalOpen2, setModalOpen2] = useState(false);

  const openModal2 = () => {
    setModalOpen2(true);
  };

  const closeModal2 = () => {
    setModalOpen2(false);
  };



  return (
    <>


      {modalOpen && (
        <div className='fixed flex flex-col z-[9999] bg-gray w-full h-full p-4 md:p-40'>
          <div className='flex justify-end'>
            <div onClick={closeModal}>
              <XMarkIcon className='text-white md:w-12 cursor-pointer w-8 d:h-12 h-8' />
            </div>
          </div>
          <img className='w-full h-full' src={graph1} alt='Modal Image' />
        </div>
      )}

      {modalOpen2 && (
        <div className='fixed flex flex-col z-[9999] bg-gray w-full h-full p-4 md:p-40'>
          <div className='flex justify-end'>
            <div onClick={closeModal2}>
              <XMarkIcon className='text-white md:w-12 cursor-pointer w-8 d:h-12 h-8' />
            </div>
          </div>
          <img className='w-full h-full' src={graph2} alt='Modal Image' />
        </div>
      )}
      {/* 
                          <ModalImage
                            className='max-w-[400px]'
                            small={graph2}
                            large={graph2}
                            alt='MMT Stats'
                          /> */}
      <Helmet>
        <title>EHOSS | Reusing Platform: for recycling animal manure for biogas production </title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content="Sustainable and cost-effective manure disposal. Farms and stables get extra income. Biogas plants and agro holdings get constant supplies of advanced feedstock." />
        <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
      </Helmet>
      <ReactFullpage
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={1000}
        navigation
        anchors={
          [
            '',
            'for-manure-recycle',
            'benefits',
            'manure-management-technology',
            'footer'
          ]
        }
        render={({ state, fullpageApi }) => {
          return (
            <div>
              <div className='fixed w-full h-full z-[-9999]'>
                {images.length > 0 &&
                  <img
                    className='absolute inset-0 w-full h-[100vh] object-cover'
                    src={images[currentIndex].src}
                    alt={`Slide ${currentIndex + 1}`}
                  />
                }
              </div>
              <ReactFullpage.Wrapper>
                <Helmet>
                  <title>EHOSS | Reusing Platform: for recycling animal manure for biogas production </title>
                  <meta name="robots" content="index, follow" />
                  <meta name="description" content="Sustainable and cost-effective manure disposal. Farms and stables get extra income. Biogas plants and agro holdings get constant supplies of advanced feedstock." />
                  <meta name="robots" content="index, follow" />
                  <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
                </Helmet>
                <div className='section'>
                  <div className='z-[-20] md:z-10 fixed left-0 w-3 h-full'>
                    <LineDot />
                  </div>
                  <div className='container flex flex-wrap-reverse md:flex-nowrap flex-col md:flex-row'>
                    <div className='pb-20 md:order-1 order-2 pt-24 md:pt-24 flex flex-col items-start justify-between h-[100vh]'>
                      <div className='max-w-[600px]'>
                        <Breadcrumbs
                          items={[
                            { label: 'Ecosystem', url: '/ecosystem' },
                            { label: 'Biomass Technology', url: '/ecosystem' },
                            { label: 'Reusing Platform', url: '/reusing-platform' },
                          ]}
                        />
                      </div>
                      <div className='flex flex-col items-start'>
                        <div>
                          <h1 className='text-white uppercase mt-12 font-bold md:text-[38px]'>
                            Reusing Platform
                          </h1>
                          <p className='text-white mt-4'>
                            Manure management technology<br />
                            for recycling animal manure
                          </p>
                        </div>
                        <div className='hidden md:flex md:flex-row flex-col md:w-auto w-full justify-center mt-12 gap-4'>
                          <button onClick={openRequestModal} className='main-btn text-start whitespace-nowrap cursor-pointer'>
                            Leave a request
                          </button>
                          <a onClick={() => fullpageApi.moveTo(2)} className='main-btn text-start whitespace-nowrap two cursor-pointer hidden'>
                            learn more
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <section id="reusing-section" className="overflow-hidden section">
                  <Line />
                  <div className="container relative h-[100vh]">
                    <div className="pt-24">
                      <div className="flex md:flex-row justify-between items-center mb-4 md:mb-10">
                        <h2 className="text-[32px] font-semibold text-white text-center w-full">
                          REUSING PLATFORM: HOW IT WORKS?
                        </h2>
                      </div>
                    </div>
                    <div className='w-full flex justify-center items-center'>
                      <video src={video} poster={slider_poster} controls className="object-contain md:w-[80%] rounded-2xl h-full" />
                    </div>
                  </div>
                </section>

                <section id="reusing-section" className="overflow-hidden section">
                  <Line />
                  <div className="container relative h-[100vh]">

                    <div className="pt-24">
                      <div className="flex md:flex-row justify-between items-center mb-4 md:mb-10">
                        <h2 className="text-[32px] font-semibold text-white">
                          REUSING PLATFORM: HOW IT WORKS?
                        </h2>
                        {/* <div className='md:flex hidden z-20 items-center justify-center gap-4 mt-2'>
                          <div className={`custom-l cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                            <ArrowLeftIcon />
                          </div>
                          <div className={`custom-r cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                            <ArrowRightIcon />
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div id='section-pc' className='w-full pb-12 cursor-grab hidden md:block relative slider-shadow'>
                      <Swiper
                        modules={[Navigation, Mousewheel]}
                        spaceBetween={50}
                        slidesPerView={slidesPerView}
                        className={`${isBeginning ? '' : 'sw2'} ${isEnd ? '' : 'sw1'}`}
                        mousewheel={{ enabled: false }}
                        navigation={{
                          nextEl: '.custom-r',
                          prevEl: '.custom-l'
                        }}
                        onSlideChange={({ isBeginning, isEnd }) => {
                          setIsBeginning(isBeginning);
                          setIsEnd(isEnd);
                        }}
                        onSwiper={(swiper) => console.log(swiper)}
                      >
                        <SwiperSlide>
                          <div className=' text-white text-[14px] pointer-events-none'>
                            <div className="bg-[#1D1D1D] flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                              <img className="object-contain rounded-xl w-full h-full" src={slide1} alt="Main EHOSS logo" />
                            </div>
                            <h3 className='font-bold mb-2 text-[16px] md:text-[22px] mt-6'>
                              Parties Involved
                            </h3>
                            <div id='start-line' className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>
                            <p className=''>
                              Technology connect two sides where manure is accumulated and where it can be reused. Concerned sides can be divided into two groups
                            </p>
                            <p className='font-medium mt-4'>
                              Manure Suppliers:
                            </p>
                            <ul className='list'>
                              <li>
                                Cattle Farms
                              </li>
                              <li>
                                Horse Stables
                              </li>
                            </ul>
                            <p className='font-medium mt-4'>
                              Feedstock Consumers:
                            </p>
                            <ul className='list'>
                              <li>
                                Biogas Plants
                              </li>
                              <li>
                                Agricultural Holdings
                              </li>
                            </ul>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>
                            <div className="bg-[#1D1D1D] overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                              <img className="object-contain w-full h-full" src={slide2} alt="Main EHOSS logo" />
                            </div>
                            <h3 className='font-bold mb-2 text-[16px] md:text-[22px] mt-6'>
                              Elements
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>
                            <p className='text-[14px] mb-2'>
                              The technology consists of several elements.
                            </p>
                            <div className='grid md:grid-cols-2 gap-1 text-[13px]'>
                              <div className={`overflow-hidden bg-gray rounded-xl p-2 transition-[0.3] ${expandedIndex === 1 ? 'h-auto' : 'h-10'}`}>
                                <div className='flex gap-8 items-center justify-between'>
                                  <h4 className=' flex gap-2 items-center text-[13px]'>
                                    <strong>Regional Project</strong>
                                  </h4>
                                  <svg onClick={() => handleAccordionClick(1)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${expandedIndex === 1 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                                  </svg>
                                </div>
                                <div className='mt-3'>
                                  <p className='text-[12px]'>
                                    a geographical area of approximately 100 sq. km with the largest accumulation of manure waste and the presence of at least one big consumer.
                                  </p>
                                </div>
                              </div>
                              <div className={`overflow-hidden bg-gray rounded-xl p-2 transition-[0.3] ${expandedIndex === 2 ? 'h-auto' : 'h-10'}`}>
                                <div className='flex gap-8 items-center justify-between'>
                                  <h4 className=' flex gap-2 items-center text-[13px]'>
                                    <strong>Cloud Platforms</strong>
                                  </h4>
                                  <svg onClick={() => handleAccordionClick(2)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${expandedIndex === 2 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                                  </svg>
                                </div>
                                <div className='mt-3'>
                                  <p className='text-[12px]'>
                                    includes a mapping platform (showing biogas plants, farms and manure concentrations) and a trading platform of manure and feedstock on the marketplace. These two are forming data to analyze and improve the process.
                                  </p>
                                </div>
                              </div>
                              <div className={`overflow-hidden bg-gray rounded-xl p-2 transition-[0.3] ${expandedIndex === 3 ? 'h-auto' : 'h-10'}`}>
                                <div className='flex gap-8 items-center justify-between'>
                                  <h4 className=' flex gap-2 items-center text-[13px]'>
                                    <strong>ERP Platform</strong>
                                  </h4>
                                  <svg onClick={() => handleAccordionClick(3)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${expandedIndex === 3 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                                  </svg>
                                </div>
                                <div className='mt-3'>
                                  <p className='text-[12px]'>
                                    CRM, manage processes, dispatcher centre, information processing, preparation and circulation of documents inside a project, forming of reports.
                                  </p>
                                </div>
                              </div>
                              <div className={`overflow-hidden bg-gray rounded-xl p-2 transition-[0.3] ${expandedIndex === 4 ? 'h-auto' : 'h-10'}`}>
                                <div className='flex gap-8 items-center justify-between'>
                                  <h4 className=' flex gap-2 items-center text-[13px]'>
                                    <strong>Certification</strong>
                                  </h4>
                                  <svg onClick={() => handleAccordionClick(4)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${expandedIndex === 4 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                                  </svg>
                                </div>
                                <div className='mt-3'>
                                  <p className='text-[12px]'>
                                    quality collection and assurance, feedstock certification in accordance with RED II standards.
                                  </p>
                                </div>
                              </div>
                              <div className={`overflow-hidden bg-gray rounded-xl p-2 transition-[0.3] ${expandedIndex === 5 ? 'h-auto' : 'h-10'}`}>
                                <div className='flex gap-8 items-center justify-between'>
                                  <h4 className=' flex gap-2 items-center text-[13px]'>
                                    <strong>Equipment</strong>
                                  </h4>
                                  <svg onClick={() => handleAccordionClick(5)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${expandedIndex === 5 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                                  </svg>
                                </div>
                                <div className='mt-3'>
                                  <p className='text-[12px]'>
                                    provide mechanical processing manure into two fractions and its subsequent storage.
                                  </p>
                                </div>
                              </div>
                              <div className={`overflow-hidden bg-gray rounded-xl p-2 transition-[0.3] ${expandedIndex === 6 ? 'h-auto' : 'h-10'}`}>
                                <div className='flex gap-8 items-center justify-between'>
                                  <h4 className=' flex gap-2 items-center text-[13px]'>
                                    <strong>Logistics</strong>
                                  </h4>
                                  <svg onClick={() => handleAccordionClick(6)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${expandedIndex === 6 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                                  </svg>
                                </div>
                                <div className='mt-3'>
                                  <p className='text-[12px]'>
                                    timely transportation of containers when it is filled with feedstock due to inbuilt sensors by container and tank trucks.
                                  </p>
                                </div>
                              </div>

                            </div>
                          </div>
                        </SwiperSlide>
                        {/* <SwiperSlide>
                          <div className=' text-white  '>
                            <div className=" overflow-hidden w-full h-[180px] bg-[#262626] md:h-[260px] rounded-xl">
                              <video src='https://ehoss.com/videos/mmt.mp4' poster={slider_poster} controls className="object-contain w-full h-full" />
                            </div>
                            <h3 className='font-bold mb-2 text-[16px] md:text-[22px] mt-6'>
                              Project’s Steps & Cycle
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>
                            <ul className='list text-[14px] flex flex-col gap-2'>
                              <li>Data Check, Planning & Calculations, Logistics Plan</li>
                              <li>Equipment Production & Installation</li>
                              <li>Manure Loading Into Equipment, Manure Analysis, Manure Processing</li>
                              <li>Feedstock Certification </li>
                              <li>Feedstock Sale</li>
                              <li>Feedstock Transportation</li>
                              <li>Feedstock Reciept & Mutual Settlements</li>
                            </ul>
                          </div>
                        </SwiperSlide> */}
                        <SwiperSlide>
                          <div className=' text-white  '>
                            <div className="bg-[#1D1D1D] overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                              <img className="object-contain w-full h-full" src={slide4} alt="Main EHOSS logo" />
                            </div>
                            <h3 className='font-bold mb-2 text-[16px] md:text-[22px] mt-6'>
                              Quality & Certifications
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4"></div>
                            <p className='text-[14px]'>
                              To ensure the feedstock quality needed for biogas production, Reusing Platform provides several steps:
                            </p>
                            <ul className='list text-[14px] mt-2 flex flex-col gap-2'>
                              <li>Constant internal control, collection and storage of feedstock samples.</li>
                              <li>Certification of feedstock in accordance with regulations and requirements of RED II.</li>

                            </ul>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className=' text-white  '>
                            <div className="bg-[#1D1D1D] overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                              <img className="object-contain w-full h-full" src={slide5} alt="Main EHOSS logo" />
                            </div>


                            <h3 className='font-bold mb-2 text-[16px] md:text-[22px] mt-6'>
                              Versions
                            </h3>
                            <div className="w-6 h-6 rounded-full bg-[#1d1d1d] border-red border-[6px] mb-4 mt-4">

                            </div>
                            <p className='text-[14px]'>
                              The first version of technology works with horse stables and cattle farms. In the following versions, it is planned to expand to other livestock types of manure.
                            </p>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                      <div className='flex z-20 items-center justify-center gap-4 mt-2'>
                        <div className={`custom-l cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isBeginning ? 'opacity-50' : ''}`}>
                          <ArrowLeftIcon />
                        </div>
                        <div className={`custom-r cursor-pointer text-white p-2 w-12 h-12 rounded-full bg-lgray ${isEnd ? 'opacity-50' : ''}`}>
                          <ArrowRightIcon />
                        </div>
                      </div>
                    </div>
                    <div id='section-m' className='w-full pb-12 cursor-grab flex flex-col gap-16 md:hidden relative slider-shadow'>

                      <div className=' text-white text-[14px] pointer-events-none bg-gray p-4 rounded-xl'>
                        <div className="bg-[#1D1D1D] flex justify-center items-center w-full h-[180px] md:h-[260px] rounded-xl">
                          <img className="object-contain rounded-xl w-full h-full" src={slide1} alt="Main EHOSS logo" />
                        </div>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[22px] mt-6'>
                          Parties Involved
                        </h3>
                        <p className=''>
                          MMT connect two sides where manure is accumulated and where it can be reused. Concerned sides can be divided into two groups
                        </p>
                        <p className='font-medium mt-4'>
                          Manure Suppliers:
                        </p>
                        <ul className='list'>
                          <li>
                            Cattle Farms
                          </li>
                          <li>
                            Horse Stables
                          </li>
                        </ul>
                        <p className='font-medium mt-4'>
                          Feedstock Consumers:
                        </p>
                        <ul className='list'>
                          <li>
                            Biogas Plants
                          </li>
                          <li>
                            Agricultural Holdings
                          </li>
                        </ul>
                      </div>

                      <div className=' text-white  bg-gray p-4 rounded-xl'>
                        <div className="bg-[#1D1D1D] overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                          <img className="object-contain w-full h-full" src={slide2} alt="Main EHOSS logo" />
                        </div>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[22px] mt-6'>
                          Elements
                        </h3>
                        <p className='text-[14px] mb-2'>
                          The technology consists of several elements.
                        </p>
                        <div className='grid md:grid-cols-2 gap-1 text-[13px]'>
                          <div className={`overflow-hidden bg-gray rounded-xl p-2 transition-[0.3] ${expandedIndex === 1 ? 'h-auto' : 'h-10'}`}>
                            <div className='flex gap-8 items-center justify-between'>
                              <h4 className=' flex gap-2 items-center text-[13px]'>
                                <strong>Regional Project</strong>
                              </h4>
                              <svg onClick={() => handleAccordionClick(1)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${expandedIndex === 1 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                              </svg>
                            </div>
                            <div className='mt-3'>
                              <p className='text-[12px]'>
                                a geographical area of approximately 100 sq. km with the largest accumulation of manure waste and the presence of at least one big consumer.
                              </p>
                            </div>
                          </div>
                          <div className={`overflow-hidden bg-gray rounded-xl p-2 transition-[0.3] ${expandedIndex === 2 ? 'h-auto' : 'h-10'}`}>
                            <div className='flex gap-8 items-center justify-between'>
                              <h4 className=' flex gap-2 items-center text-[13px]'>
                                <strong>Cloud Platforms</strong>
                              </h4>
                              <svg onClick={() => handleAccordionClick(2)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${expandedIndex === 2 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                              </svg>
                            </div>
                            <div className='mt-3'>
                              <p className='text-[12px]'>
                                includes a mapping platform (showing biogas plants, farms and manure concentrations) and a trading platform of manure and feedstock on the marketplace. These two are forming data to analyze and improve the process.
                              </p>
                            </div>
                          </div>
                          <div className={`overflow-hidden bg-gray rounded-xl p-2 transition-[0.3] ${expandedIndex === 3 ? 'h-auto' : 'h-10'}`}>
                            <div className='flex gap-8 items-center justify-between'>
                              <h4 className=' flex gap-2 items-center text-[13px]'>
                                <strong>ERP Platform</strong>
                              </h4>
                              <svg onClick={() => handleAccordionClick(3)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${expandedIndex === 3 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                              </svg>
                            </div>
                            <div className='mt-3'>
                              <p className='text-[12px]'>
                                CRM, manage processes, dispatcher centre, information processing, preparation and circulation of documents inside a project, forming of reports.
                              </p>
                            </div>
                          </div>
                          <div className={`overflow-hidden bg-gray rounded-xl p-2 transition-[0.3] ${expandedIndex === 4 ? 'h-auto' : 'h-10'}`}>
                            <div className='flex gap-8 items-center justify-between'>
                              <h4 className=' flex gap-2 items-center text-[13px]'>
                                <strong>Certification</strong>
                              </h4>
                              <svg onClick={() => handleAccordionClick(4)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${expandedIndex === 4 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                              </svg>
                            </div>
                            <div className='mt-3'>
                              <p className='text-[12px]'>
                                quality collection and assurance, feedstock certification in accordance with RED II standards.
                              </p>
                            </div>
                          </div>
                          <div className={`overflow-hidden bg-gray rounded-xl p-2 transition-[0.3] ${expandedIndex === 5 ? 'h-auto' : 'h-10'}`}>
                            <div className='flex gap-8 items-center justify-between'>
                              <h4 className=' flex gap-2 items-center text-[13px]'>
                                <strong>Equipment</strong>
                              </h4>
                              <svg onClick={() => handleAccordionClick(5)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${expandedIndex === 5 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                              </svg>
                            </div>
                            <div className='mt-3'>
                              <p className='text-[12px]'>
                                provide mechanical processing manure into two fractions and its subsequent storage.
                              </p>
                            </div>
                          </div>
                          <div className={`overflow-hidden bg-gray rounded-xl p-2 transition-[0.3] ${expandedIndex === 6 ? 'h-auto' : 'h-10'}`}>
                            <div className='flex gap-8 items-center justify-between'>
                              <h4 className=' flex gap-2 items-center text-[13px]'>
                                <strong>Logistics</strong>
                              </h4>
                              <svg onClick={() => handleAccordionClick(6)} className={`h-6 w-6 transition-[0.3] cursor-pointer ${expandedIndex === 6 ? 'rotate-180' : 'h-10'}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M5.61578 5.9342L8.67578 8.9942L11.7358 5.9342L12.6758 6.88087L8.67578 10.8809L4.67578 6.88087L5.61578 5.9342Z" fill="#F20000" />
                              </svg>
                            </div>
                            <div className='mt-3'>
                              <p className='text-[12px]'>
                                timely transportation of containers when it is filled with feedstock due to inbuilt sensors by container and tank trucks.
                              </p>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className=' text-white  bg-gray p-4 rounded-xl'>
                        <div className=" overflow-hidden w-full h-[180px] bg-[#262626] md:h-[260px] rounded-xl">
                          {/* <img className="object-contain w-full h-full" src={slide3} alt="Main EHOSS logo" /> */}
                          <video src='https://ehoss.com/videos/mmt.mp4' poster={slider_poster} controls className="object-contain w-full h-full" />
                        </div>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[22px] mt-6'>
                          Project’s Steps & Cycle
                        </h3>
                        <ul className='list text-[14px] flex flex-col gap-2'>
                          <li>Data Check, Planning & Calculations, Logistics Plan</li>
                          <li>Equipment Production & Installation</li>
                          <li>Manure Loading Into Equipment, Manure Analysis, Manure Processing</li>
                          <li>Feedstock Certification </li>
                          <li>Feedstock Sale</li>
                          <li>Feedstock Transportation</li>
                          <li>Feedstock Reciept & Mutual Settlements</li>
                        </ul>
                      </div>

                      <div className=' text-white  bg-gray p-4 rounded-xl'>
                        <div className="bg-[#1D1D1D] overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                          <img className="object-contain w-full h-full" src={slide4} alt="Main EHOSS logo" />
                        </div>
                        <h3 className='font-bold mb-2 text-[16px] md:text-[22px] mt-6'>
                          Quality & Certifications
                        </h3>
                        <p className='text-[14px]'>
                          To ensure the feedstock quality needed for biogas production, MMT provides several steps:
                        </p>
                        <ul className='list text-[14px] mt-2 flex flex-col gap-2'>
                          <li>Constant internal control, collection and storage of feedstock samples.</li>
                          <li>Certification of feedstock in accordance with regulations and requirements of RED II.</li>

                        </ul>
                      </div>

                      <div className=' text-white  bg-gray p-4 rounded-xl'>
                        <div className="bg-[#1D1D1D] overflow-hidden w-full h-[180px] md:h-[260px] rounded-xl">
                          <img className="object-contain w-full h-full" src={slide5} alt="Main EHOSS logo" />
                        </div>


                        <h3 className='font-bold mb-2 text-[16px] md:text-[22px] mt-6'>
                          Versions
                        </h3>

                        <p className='text-[14px]'>
                          The first version of MMT works with horse stables and cattle farms. In the following versions, it is planned to expand to other livestock types of manure.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>

                <div className='section'>
                  <Line />
                  <div className='container flex flex-wrap-reverse md:flex-nowrap flex-col md:flex-row items-center'>
                    <div className='w-full pt-24 m:pt-0 md:pt-32 flex flex-col items-start justify-between md:h-[100vh]'>
                      <div className='text-white flex flex-col w-full items-center justify-center'>
                        <h3 className='text-white font-medium text-center text-[24px] md:text-[38px]'>
                          Benefits
                        </h3>
                        <span className='font-regular text-center text-[16px] mb-4'>
                          MONETIZE MANURE, <br className='md:hidden'></br>
                          PRESERVE THE PLANET
                        </span>
                        <div className='grid mt-4 mb-4 grid-cols-3 md:w-[600px] md:grid-cols-3 gap-2 p-1 bg-gray rounded-xl text-[12px] md:text-[16px]'>
                          <div
                            id='title1'
                            className={`text-center flex gap-1 items-center justify-center cursor-pointer p-3  rounded-xl bg-lgray ${activeTab === 'title1' ? 'bg-white text-black' : ''}`}
                            onClick={() => handleClick('title1')}
                          >
                            FOR SUPPLIERS
                          </div>
                          <div
                            id='title2'
                            className={`text-center flex gap-1 items-center justify-center cursor-pointer p-3  rounded-xl bg-lgray ${activeTab === 'title2' ? 'bg-white text-black' : ''}`}
                            onClick={() => handleClick('title2')}
                          >
                            FOR CONSUMERS
                          </div>
                          <div
                            id='title3'
                            className={`text-center flex gap-1 items-center justify-center cursor-pointer p-3  rounded-xl bg-lgray ${activeTab === 'title3' ? 'bg-white text-black' : ''}`}
                            onClick={() => handleClick('title3')}
                          >
                            FOR BOTH
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='md:absolute md:p-0 pb-12 w-full top-[200px] md:top-0 md:h-[100vh] flex justify-center items-center pointer-events-none overflow-hidden z-[-9999]'>
                    <div id='list1' className={`w-full ${activeTab === 'title1' ? 'block' : 'hidden'}`}>
                      <img className='w-full hidden md:block object-cover' src={slideImg1} />
                      <img className='w-full md:hidden block object-contain' src={slideImg1mb} />
                    </div>
                    <div id='list2' className={`w-full ${activeTab === 'title2' ? 'block' : 'hidden'}`}>
                      <img className='w-full hidden md:block object-cover' src={slideImg2} />
                      <img className='w-full md:hidden block object-contain' src={slideImg2mb} />
                    </div>
                    <div id='list3' className={`w-full ${activeTab === 'title3' ? 'block' : 'hidden'}`}>
                      <img className='w-full hidden md:block object-cover' src={slideImg3} />
                      <img className='w-full md:hidden block object-contain' src={slideImg3mb} />
                    </div>
                  </div>
                </div>
                <div className='section'>
                  <Line />
                  <div className='container'>
                    <div className='h-[100vh]'>
                      <div className='pt-24'>
                        <h2 className='md:text-center font-semibold text-[24px] md:text-[32px] text-white'>
                          MANAGEMENT AND STORAGE OF MANURE  <br></br>
                          AS A MEANS OF DECREASING GHGs EMISSIONS
                        </h2>
                      </div>
                      <div className='flex flex-col gap-4 justify-center items-center mt-12 text-white'>
                        <p>With Reusing Plarform we expect the following changes in CH4 and N2O emissions:</p>
                        <div className='flex md:flex-row flex-col mb-10 w-full max-w-5xl justify-center gap-8'>
                          <img
                            className='max-w-[400px] cursor-zoom-in'
                            src={graph1}
                            onClick={openModal}
                            alt='MMT Stats'
                          />
                          <img
                            className='max-w-[400px] cursor-zoom-in'
                            src={graph2}
                            onClick={openModal2}
                            alt='MMT Stats'
                          />
                        </div>
                        <p>
                          <strong>
                            WE ARE COMMITTED TO MAKING CHANGES
                          </strong>
                        </p>
                      </div>
                      <div>
                        <h2 className='text-[16px] font-semibold text-white mt-4 mb-2'>
                          SDG we are committed to:
                        </h2>
                      </div>
                      <div className=' grid md:grid-cols-3 gap-4 pb-12'>
                        <div className='flex text-white text-[14px] gap-2'>
                          <img className='w-[120px] object-contain' src={card1} alt='Card 1' />
                          <div>
                            <p className='md:text-[16px] text-[12px]'>
                              Due to the developing usage of manure as feedstock that in turn influences access to alternative energy sources.
                            </p>
                          </div>
                        </div>
                        <div className='flex text-white text-[14px] gap-2'>
                          <img className='w-[120px] object-contain' src={card2} alt='Card 1' />
                          <div>
                            <p className='md:text-[16px] text-[12px]'>
                              Innovation in manure disposal that makes farming more sustainable (from the side of manure utilization and using liquid fraction as organic fertiliser to replace usage of chemical ones).
                            </p>
                          </div>
                        </div>
                        <div className='flex text-white text-[14px] gap-2'>
                          <img className='w-[120px] object-contain' src={card3} alt='Card 1' />
                          <div>
                            <p className='md:text-[16px] text-[12px]'>
                              Reducing GHG emissions that occur during the traditional storage of manure and contributes to climate change and global warming.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Footer />
              </ReactFullpage.Wrapper>
            </div>
          );
        }}
      />
    </>

  );
}

export default ReusingPlatfrom;
