import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import "./App.scss";
import "./assets/fonts/fonts.css"
import Main from "./pages/main/Main";
import NotFound from "./pages/not-found/NotFound";
import "react-custom-cursors/dist/index.css";
import Header from "./container/header/Header";
import AdminLogin from "./admin/login/AdminLogin";
import AdminProfile from "./admin/profile/AdminProfile";
import AdminProfileSEO from "./admin/profile/AdminProfileSEO";
import AdminProfileUploads from "./admin/profile/AdminProfileUploads";
import AdminProfilePosts from "./admin/profile/AdminProfilePosts";
import AdminProfilePostsList from "./admin/profile/AdminProfilePostsList";

import Soon from './pages/soon/Soon'

import ForagePlatfrom from "./pages/forage-platform/ForagePlatform";
import ReusingPlatfrom from "./pages/reusing-platform/ReusingPlatform";
import OneHorsePlatform from "./pages/one-horse-platform/OneHorsePlatform";
import GrainPlatform from "./pages/grain-platform/GrainPlatform";
import ModularStable from "./pages/modular-stable/ModularStable";

// import Ethics from "./pages/ethics-and-compilance/Ethics";

import Blog from "./pages/blog/Blog";
import PostPage from './pages/postpage/postPage';
import Registration from "./pages/registration/Registration";

// FOOTER BOTTOM LINKS
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsOfUse from "./pages/terms-of-use/TermsOfUse";
import SalesAndRefund from "./pages/sales-and-refund/SalesAndRefund";
import Sitemap from "./pages/sitemap/Sitemap";
import Audience from "./pages/audience/Audience";
import Contacts from "./pages/contacts/Contacts";
import Careers from "./pages/careers/Careers";
import Vacancy from "./components/vacancy/Vacancy";
import About from "./pages/about/About";

// ================= FORMS ==================
import LeaveARequest from "./components/forms/leave-a-request/LeaveARequest";
import Cooperation from "./components/forms/cooperation/Сooperation";
import Cookies from "./components/cookies/Cookies";
import CookiesSetting from "./components/forms/cookies-setting/CookiesSetting";
import Login from "./components/forms/login/Login";
import Career from "./components/forms/career/Career";
import Environment from "./pages/environment/Environment";
import MonitoringPlatform from "./pages/monitoring-platform/MonitoringPlatform";
import ResearchAndDevelopment from "./pages/research-and-development/ResearchAndDevelopment";
import Contact from "./components/forms/contact/Contact";
import Order from "./components/forms/order/Order";
import Team from "./pages/team/Team";
import Welcome from "./pages/welcome/Welcome";
import Ecosystem from "./pages/ecosystem/Ecosystem";

import Farmers from "./pages/who-needs/farms/Farmers";
import HorseOwners from "./pages/who-needs/horse-owners/HorseOwners";
import EquestrianFacilities from "./pages/who-needs/equestrian-facilities/EquestrianFacilities";
import EquineProducers from "./pages/who-needs/equine-producers/EquineProducers";
import Veterinary from "./pages/who-needs/veterinary/Veterinary";
import Equestrians from "./pages/who-needs/equestrians/Equestrians";
import ExpertAdvisors from './pages/PEOPLE/expert-advisors/ExpertAdvisors'
import GetPr from "./components/forms/getpr/GetPr";

import BusinessRelationships from "./pages/COOPERATION/business-relationships/BusinessRelationships";
import GrantsAndAwards from "./pages/grants-and-awards/GrantsAndAwards";

import HeadScripts from "./utils/head";

import BlogMain from "./blog/Blog";
import PostPageMain from "./blog/PostPageMain";
function App() {

  const location = useLocation();
  const hideMenuPaths = ["/admin-login", "/panel", "/panel/uploads", "/panel/seo", "/panel/posts", "/panel/posts/list"];
  const shouldHideMenu = hideMenuPaths.includes(location.pathname);

  return (
    <>
      {!shouldHideMenu && <Header />}
      {/* FORMS */}
      <HeadScripts />
      <LeaveARequest />
      <GetPr />
      <Cookies />
      <CookiesSetting />
      <Cooperation />
      <Login />
      <Career />
      <Contact />
      <Order />
      <Routes>
        <Route path="/" element={<Main />} />

        <Route path="/blog" element={<BlogMain />} />
        <Route path="/blog/:link" element={<PostPageMain />} />

        <Route path="/welcome" element={<Welcome />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/panel" element={<AdminProfile />} />
        <Route path="/panel/uploads" element={<AdminProfileUploads />} />
        <Route path="/panel/seo" element={<AdminProfileSEO />} />
        <Route path="/panel/posts" element={<AdminProfilePosts />} />
        <Route path="/panel/posts/list" element={<AdminProfilePostsList />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/soon" element={<Soon />} />
        <Route path="/grants-and-awards" element={<GrantsAndAwards />} />
        <Route path="/business-relationships" element={<BusinessRelationships />} />
        {/* =========== who needs =========== */}
        <Route path="/audience" element={<Audience />} />
        <Route path="/farmers" element={<Farmers />} />
        <Route path="/horse-owners" element={<HorseOwners />} />
        <Route path="/equestrian-facilities" element={<EquestrianFacilities />} />
        <Route path="/equine-producers" element={<EquineProducers />} />
        <Route path="/veterinary" element={<Veterinary />} />
        <Route path="/equestrians" element={<Equestrians />} />
        <Route path="/about" element={<About />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/vacancy/:id" element={<Vacancy />} />
        <Route path="/team" element={<Team />} />
        <Route path="/expert-advisors" element={<ExpertAdvisors />} />
        {/* =========== PLATFORMS START =========== */}
        <Route path="/ecosystem" element={<Ecosystem />} />
        <Route path="/forage-platform" element={<ForagePlatfrom />} />
        <Route path="/reusing-platform" element={<ReusingPlatfrom />} />
        <Route path="/grain-platform" element={<GrainPlatform />} />
        <Route path="/one-horse-platform" element={<OneHorsePlatform />} />
        <Route path="/monitoring-platform" element={<MonitoringPlatform />} />
        <Route path="/modular-stable" element={<ModularStable />} />
        {/* =========== PLATFORMS END =========== */}

        {/* <Route path="/ethics-and-compilance" element={<Ethics />} /> */}
        <Route path="/esg-and-sustainability" element={<Environment />} />
        <Route path="/laboratory-and-r-and-d" element={<ResearchAndDevelopment />} />

        {/* =========== FOOTER BOTTOM LINKS START =========== */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/sales-and-refund" element={<SalesAndRefund />} />

        {/* =========== FOOTER BOTTOM LINKS END =========== */}
        <Route path="/blog-test" element={<Blog filter={null} />} />
        <Route path="/press-about-us" element={<Blog filter='Press about us' />} />
        <Route path="/articles" element={<Blog filter='Articles' />} />
        <Route path="/events" element={<Blog filter='Events' />} />
        <Route
          path="/*"
          element={
            <>
              <Navigate to="/not-found" />
            </>
          }
        />
        <Route path="/:postLink" element={<PostPage />} />
      </Routes>
    </>
  );
}


export default App;
