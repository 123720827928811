import post1 from "./../assets/images/post1/post1-1.png";

import post2 from "./../assets/images/post2/1.png";

import post3 from "./../assets/images/post3/main.png";
import post3_2 from "./../assets/images/post3/intro.mp4";

import { slider3, slider7, slider9, slider11, slider13, slider14, slider16, slider17 } from "./sliders";

import post4 from "./../assets/images/post4/1.jpg";

import post5 from "./../assets/images/post5/post5.png";

import post6 from "./../assets/images/post6/1.jpeg";
import post6_3 from "./../assets/images/post6/post6-3.png";

import post7 from "./../assets/images/post7/main.jpg";
import post7_1 from "./../assets/images/post7/contant.jpg";

import post8 from "./../assets/images/post8/1.png";
import post8_2 from "./../assets/images/post8/2.png";
import post8_3 from "./../assets/images/post8/3.png";

import post9main from "./../assets/images/post9/9680.jpg";
import post9img2 from "./../assets/images/post9/CHE_9748F.jpg";
import post9img3 from "./../assets/images/post9/CHE_9759F.jpg";
import post9img4 from "./../assets/images/post9/CHE_9729F.jpg";
import post9img5 from "./../assets/images/post9/CHE_9827F.jpg";
import post9img6 from "./../assets/images/post9/CHE_9693F.jpg";

import post10img1 from "./../assets/images/post10/greenhayinstable.png";
import post10img2 from "./../assets/images/post10/roundhaybalesinafield.png";
import post10img3 from "./../assets/images/post10/haybalestartingrot.png";
import post10img4 from "./../assets/images/post10/rottedhaybale.png";
import post10table from "./../assets/images/post10/table.png";


import post11img1 from "./../assets/images/post11/ehoss_00087.jpg";

import post12img1 from "./../assets/images/post12/1.jpg";
import post12img2 from "./../assets/images/post12/2.jpg";
import post12img3 from "./../assets/images/post12/3.jpg";
import post12img4 from "./../assets/images/post12/5.jpg";
import post12img5 from "./../assets/images/post12/6.jpg";
import post12img6 from "./../assets/images/post12/7.png";
import post12table from "./../assets/images/post12/4.svg";

import post13img1 from "./../assets/images/post13/ehossteam.jpg";

import post14img1 from "./../assets/images/post14/main_small_EHOS6092_LR.jpeg";




import post15img1 from './../assets/images/post15/small_EHOS1916.jpg'
import post15img2 from './../assets/images/post15/IMG_7301_LR.jpg'
import post15img3 from './../assets/images/post15/EHOS2118_LR.jpg'
import post15img4 from './../assets/images/post15/IMG_7289_LR.jpg'

import post15table from './../assets/images/post15/table.png'


import post16img1 from './../assets/images/post16/small_IMG_2403.jpg'
import post16img2 from './../assets/images/post16/small_IMG_2384.jpg'
import post16img3 from './../assets/images/post16/IMG_2845_1709.JPG'
import post16img4 from './../assets/images/post16/small_IMG_2390.jpg'
import post16img5 from './../assets/images/post16/slider/small_IMG_2400.jpg'

import post16img6 from './../assets/images/post16/small_IMG_2398.jpg'
import post16img7 from './../assets/images/post16/small_IMG_2402.jpg'
import post16img8 from './../assets/images/post16/IMG_1920_VSCO.JPG'

import post17img1 from './../assets/images/post17/EEPA.jpg'
import cert from './../assets/images/post17/CAT_5_EHOSS_Slovakia.pdf'

let post6videoUrl = "https://ehoss.com/videos/haystorage-200.mov";




{/* <a class='underline font-medium' href='https://mzv.sk/en/web/en' target='_blank'></a> */ }

const postsData = [
  {
    id: 17,
    post_group: {
      id: undefined,
    },
    seo_deskr: "EHOSS has placed 1st in the national competition for the European Enterprise Promotion Award 2024 and thus reached the EU round that will be held in terms of the SME Assembly which is a part of the European SME Week (ESW)",
    seo_tite: 'Slovakian National Winner of European Enterprise Promotion Award 2024',
    seo_keywords: '#EEPA2024 #SMEAssembly #SMEWeek2024 #SMEweek #biomass #circulareconomy',
    title: "Slovakian National Winner of EEPA 2024",
    minidesckr: "",
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img title='' alt='' src=${post17img1} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-text'>
                      We are thrilled to announce 
                      that <a class='underline font-medium' href='https://www.npc.sk/sk/aktuality/pozname-vitazov-sutaze-eepa-za-rok-2024/' target='_blank'>EHOSS has placed 1st in the national competition</a> for the <a class='underline font-medium' href='https://single-market-economy.ec.europa.eu/smes/learn-and-plan-entrepreneurship/european-enterprise-promotion-awards_en' target='_blank'>European Enterprise Promotion Award 2024</a> and thus reached the EU round that will be held in terms 
                       of the <a class='underline font-medium' href='https://single-market-economy.ec.europa.eu/smes/sme-strategy-and-sme-friendly-business-conditions/sme-assembly_en' target='_blank'>SME Assembly</a> which is a part  
                       of the <a class='underline font-medium' href='https://single-market-economy.ec.europa.eu/smes/sme-strategy-and-sme-friendly-business-conditions/european-sme-week_en' target='_blank'>European SME Week (ESW)</a>. The conference will be held on 18-20 November 2024 in Budapest, Hungary involving conjunction of the Hungarian Presidency of the Council of the European Union.
                    </p>
                     <p class='contant-text'>
                        The category in which we compete, «Supporting the sustainable transition», is our highest priority. We constantly insist on the importance of paying attention to the development of the intelligent use of biomass, which is embedded in the mission of our company. We hope we will find many like-minded people at this event for further productive development of the circular economy, climate neutrality, clean energy and resource efficiency.
                    </p>
                    <p class='contant-text'>
                      #EEPA2024 #SMEAssembly #SMEWeek2024 #SMEweek #biomass #circulareconomy
                    </p>
                  </div>
               

                </div>
                `,
    category: "Events",
    date: "15/08/2024",
    image: `${post17img1}`,
    slider: slider17,
  },
  {
    id: 16,
    post_group: {
      id: undefined
    },
    title:
      "EUBCE 2024: 32nd European Biomass Conference and Exhibition",
    minidesckr:
      "Participants from the EU and from many other countries...",
    seo_deskr: "Participants from the EU and from many other countries met to share and discuss developments and technologies for biomass and green energy, how to reach energy objectives established at COP28",
    seo_tite: 'EUBCE 2024: 32nd European Biomass Conference and Exhibition',
    seo_keywords: 'Biogas, Biomethane, sustainable farming, biomass, land use, bioenergy, renewable, carbon capture, BECCS, COP28, emissions ',
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img class='aspect-video' title='EUBCE 2024 main Auditorium at Chanot Convention and Exhibition Center in Marseille' alt='dark auditorium of a conference premises at Chanot Convention and Exhibition Center in Marseille' src=${post16img1} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-text'>
                      From the 24th to the 27th of July we took part in the <a class='underline font-medium' href='https://www.eubce.com/' target='_blank'>EUBCE’24: 32nd European Biomass Conference and Exhibition</a>
                      organized by <a class='underline font-medium' href='https://new.etaflorence.it/' target='_blank'>ETA Florence</a> and hosted at <a class='underline font-medium' href='https://www.marseille-chanot.com/en/' target='_blank'>Chanot Convention and Exhibition Center</a> in beautiful Marseille, France. Let's proceed to the main highlights that we found important in our view:
                    </p>
                  </div>
                </div>






                <div class='contant-wrapper'>
                  <div class=''>
                    <img title='BECCS presentation' alt='presentation at a conference ' src=${post16img2} />
                  </div>
                  <div class='' style='display: flex; flex-direction:column; justify-content: center;'">
                    <p class='contant-title'>
                      Land use
                    </p>
                    <p class='contant-text'>
                      One of the problems raised lots of times at the conference was the effective use of land resources. Currently, large areas of our planet are used for agricultural activities, necessary for feeding the population of our planet. As the population grows, these volumes will also grow. But with these territories, we significantly destroy the ecosystem of our planet and also have negative consequences, such as CO2 emissions and many other side effects. Agricultural activity, in the available sizes, already causes quite a lot of damage, and one should not think about its growth, but reduction and optimization. Junginger Martin, Professor of Bio-Based Economy at Utrecht University, even suggests that we need to eat more vegetables and less meat as animal farming not only occupies large areas but also has the highest level of pollution from its activity. So, when the question arises about land use for growing biomass, which is then used for the production of green energy, we understand, that we don’t have enough land to grow the needed volume of biomass for the EU's needs. This statement was also announced by Ana Luisa Fernando, a 
                      Researcher Scientist from <a class='underline font-medium' href='https://www.unl.pt/' target='_blank'>Universidade Nova de Lisboa</a>. In addition, Mrs. Fernando noted that these crops have an influence on soil conditions and have a high demand for water. So we have to be even more careful and think whether it will not bring more harm, and how exactly to make the use of these lands more efficient. In the context of this issue, it should be noted that livestock residues, agricultural residues, urban garbage and other biomass that are by-products of our life or production should be priority resources for the production of green energy. This is exactly what EHOSS proposes to do with the help of #advancingbiomasstechnologies, the first of which 
                      is <a class='underline font-medium' href='https://ehoss.com/reusing-platform' target='_blank'>Reusing Platform</a> designed for processing livestock residues, i.e., manure. Manure doesn’t need much land to become a useful element in biogas production.
                    </p>
                  </div>
              </div>



              <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>

              <div class='contant-left md:w-1/2' style='display: flex; flex-direction:column; justify-content: center;'">
                <p class='contant-title'>
                    Farmers
                </p>
                <p class='contant-text'>
                  Mr. <a class='underline font-medium' href='https://www.linkedin.com/in/ramakrishna-y-b-658b3485/' target='_blank'>Ramakrishna Y.B</a> from 
                  the Working group on Bio Fuels at 
                  the <a class='underline font-medium' href='https://mopng.gov.in/en' target='_blank'>Ministry of Petroleum and Natural Gas Govt of India</a>, said 
                  a very important thing, that farmers, who is actually the most important, are being ignored. They only have more rules to follow, complexity in work and not much support in doing it. Scientists and researchers are closed in their cabinets and there is a lack of information-sharing activities, that will educate farmers and actually all other people about biomass innovations, why is it important, how it can be implemented and what are actually the benefits of, in simple words. This is also confirmed by Thibault Cantat, Head of CEA's Circular Carbon Economy Programme. The raised question was: how do we actually educate waste utilization? And from the other side, we have to be more severe and efficient in the utilization of energy, be more responsible, making defuelization full-minded.
                  Our team at EHOSS really want to support farmers and first of all, plan to have events and information materials that will help understand all the related issues more easily and accessibly. And secondly, is to give them the opportunity to earn that these actions will be more interesting for them. Finally yet importantly in this topic question was raised by Paolo Frankl, 
                  from <a class='underline font-medium' href='https://www.iea.org/' target='_blank'>lEA's Renewable Energy Division</a>: why there is no conferences on sustainable farming? And this is a thing to be done. 
                </p>
              </div>
              <div class='contant-left md:w-1/2'>
                <img title='Potential of animal waste in Europe' alt='presentation slide on the topic of potential of animal waste in Europe' src=${post16img3} />
              </div>
            </div>


    



         


    
                <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>
                <div class='contant-left md:w-1/2 md:order-1 order-2'>
                  <img title='Keywords of EUBCE 2024' alt='keywords on a slide of a conference presentation' src=${post16img4} />
                </div>
                <div class='contant-left md:w-1/2 order-1' style='display: flex; flex-direction:column; justify-content: center;'">
                  <p class='contant-title'>
                    Biomass Mapping
                  </p>
                  <p class='contant-text'>
                    Baptiste Perrissin-Fabert 
                    from <a class='underline font-medium' href='https://www.ademe.fr/' target='_blank'>ADME</a> said 
                    that there is a need for biomass mapping of supply and demand. This is another proof, that we are moving in the right direction, 
                    as we already had started doing this. Our <a class='underline font-medium' href='https://map.ehoss.com/' target='_blank'>map</a> is designed to create a base of biomass and make it work as a trading platform, where everyone can see how much biomass is available and where exactly. In terms of biomass shortage in the EU, it must be treated wisely, and mapping where it is decreasing will help a lot with this issue. We usually say «Let’s recategorize waste into resources», but this time we also may say «Let’s recategorize waste into securities». 
                  </p>

                </div>
              </div>



              <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>

              <div class='contant-left md:w-1/2' style='display: flex; flex-direction:column; justify-content: center;'">
                <p class='contant-title'>
                   Supporting EU development strategies
                  </p>
                <p class='contant-text'>
                 Bioeconomy is in high demand of aims for next year, as Nicoló Giacomuzzi-Moore, Executive Director 
                 at <a class='underline font-medium' href='https://www.cbe.europa.eu' target='_blank'>Circular Bio-based Europe Joint Undertaking (CBE JU)</a> said. Taking into account all global green strategies, and plans to turn all transportation into electric transportation, there would be a high need not only for R&D and research work to be done but also a high need for engineering and manufacturing capacities. Also, another interesting point that was raised at the conference, was that there actually were not many representatives from the manufacturing side, and EHOSS was the one of them who was there. So from our side, we encourage other manufacturers to join the process and work on new technologies together. 
                </p>
              </div>
              <div class='contant-left md:w-1/2'>
                <img title='Biomethane as a key energy vector in decarbonizing energy systems ' alt='biomethane presentation on a conference ' src=${post16img5} />
              </div>
            </div>



            
            <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>
              <div class='contant-left md:w-1/2 md:order-1 order-2'>
                <img title='One among many other incredibly interesting presentations of EUBCE participant' alt='presentation slide on a conference' src=${post16img6} />
              </div>
              <div class='contant-left md:w-1/2 order-1' style='display: flex; flex-direction:column; justify-content: center;'">
                <p class='contant-title'>
                  Efficiency
                </p>
                <p class='contant-text'>
                  This question was raised in different meanings in the above extracts, but we want to highlight this one separately. As Lindsay Fitzgerald said: «multiple usages of biomass = efficiency», and we couldn’t agree more! This is what we follow in our work: reuse and recycle. In the first version 
                  of our <a class='underline font-medium' href='https://ehoss.com/reusing-platform'>Reusing Platform</a> technology, we support two steps: first, reuse manure as feedstock for biogas plants, and second, recycle digestate from biogas production as organic fertilizer. Let’s also take into account that phosphorus on the planet ends. Manure becoming even more valuable because of phosphorus content.
                </p>
              </div>
          </div>

               <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>

              <div class='contant-left md:w-1/2' style='display: flex; flex-direction:column; justify-content: center;'">
                <p class='contant-title'>
                   Tendencies
                  </p>
                <p class='contant-text'>
                 <a class='underline font-medium' href='https://www.linkedin.com/in/zhan-shi-243a53299?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'>Zhan Shi</a>, a PhD student from the University of Padova, made an impressive research on straw and manure usage for bioenergy production in one area of China. It shows interesting tendencies. The situation now is that straw is using much more than manure, but the deep analysis is predicting a shift from straw to manure and a 20-year forecast shows that it possibly will come to 1:1 usage. 
                </p>
              </div>
              <div class='contant-left md:w-1/2'>
                <img title='EUBCE 2024 participants' alt='presentation slide of a conference participants' src=${post16img7} />
              </div>
            </div>

    <div class='contant-wrapper '>

              <div>
                <p class='contant-title'>
                  Assessment
                </p>
                <p class='contant-text'>
                  Mr. Frédérik Jobert from Ecological Transition Planning said: «In France, everything is measured» and we are eager to work in France as that gives us so many possibilities to grow, but by now, we need to measure ourselves in all that we are doing and hope to collaborate with other interested parties to develop biomass usage.
                </p>
              </div>
          </div>

     <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>
              <div class='contant-left md:w-1/2 md:order-1 order-2'>
                <img title='EUBCE 2024 participants ' alt='participants of EUBCE 2024 conference' src=${post16img8} />
              </div>
              <div class='contant-left md:w-1/2 order-1' style='display: flex; flex-direction:column; justify-content: center;'">
                <p class='contant-title'>
                  CONCLUSION
                </p>
                <p class='contant-text'>
                  Despite all the pros and cons, the market needs and wants biomethane, and the demand is much higher than supply. Especially, taking into account the EU’s plan to turn all transport into electric in a plus/minus decade.
                  Technologies for bioenergy are developing, but there is still a lot of work that needs to be done. 
                  The dialogue between all the stakeholders needs improvements. 
                  Overall, it was an insightful and productive event, that proves that we are moving in the right direction and inspires us to think wider about biomass usage and technologies that can be used to generate green energy. 
                </p>
              </div>
          </div>
                `,
    category: "Events",
    date: "29/06/24",
    image: `${post16img1}`,
    slider: slider16,
  },
  {
    id: 15,
    post_group: {
      id: [10, 12]
    },
    title: "Hay classifications and quality characteristics",
    minidesckr: "Roughage for Horses: Part III",
    seo_deskr: "Hay is classified by botanical composition and moisture content, with standards for quality and harmful plants. Some hay types require caution due to toxicity.",
    seo_tite: 'Roughage for Horses: Hay Classifications and Quality, Part III',
    seo_keywords: 'hay types, hay classifications, hay quality',
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img title='What is the quality of hay that your horse consumes?' class='aspect-video' alt='horse eats hay' src=${post15img1} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-text'>
                      Depending on the botanical composition and growing conditions of the grass, hay is classified into the following types according to the current standard: legume (leguminous plants over 60%), grass (more than 60% grass plants and less than 20% leguminous plants), legume-grass (leguminous from 20 to 60%), natural meadows (grass, legume, grass-legume, etc.). The standard moisture content of hay is 17%. Three classes are defined for each type of hay. For legume hay of the 1st class, there should be no less than 90% leguminous plants, for the 2nd - 75%, and for the 3rd - 60%. The same amount of grass plants should be contained in grass hay depending on the class, and legume-grass hay should contain respectively 50, 35, and 20% leguminous plants (Table 3).
                    </p>
                    </div>
                </div>



                <div class='contant-wrapper'>
                  <div class='flex flex-col items-center md:mb-12 justify-center'>
                    <p class='contant-title md:text-center'>Table 3. Hay quality characteristics </p>
                    <img class='img-table max-w-2xl' src=${post15table} />
                  </div>
                </div>




                <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>
                <div class='contant-left md:w-1/2 md:order-1 order-2'>
                  <img title='Types of hay for horses' alt='hay in close-up ' src=${post15img2} />
                </div>
                <div class='contant-left md:w-1/2 order-1' style='display: flex; flex-direction:column; justify-content: center;'">
                  <p class='contant-text'>
                    If the hay does not meet at least one of the quality indicators, it is downgraded to a lower class or is classified as substandard. <br/>
                    Hay from seeded grasses should not contain harmful or poisonous plants, and for natural forage hay, Class 1 allows for no more than 0.5% of harmful plants, while Classes 2 and 3 allow for no more than 1 %.
                    Hay of Classes 1, 2, and 3 should be free from signs of mustiness, mold, and rot. All other hay that does not meet the established standards is classified as substandard or unfit for feeding. Some types of hay should be fed with caution.
                  </p>
                  <p class='contant-title'>
                  Oat-Vetch. 
                  </p>
                  <p class='contant-text'>
                    Vetch is not used for hay either in pure form or in mixtures. After seed formation, the plant's cyanogenic glycoside content increases, which is dangerous for animals. Prepared hay is recommended to be fed to adult cattle no more than 1/2-1/3 of the daily hay ration.
                  </p>
                  <p class='contant-title'>
                   Bird’s Foot Trefoil. 
                  </p>
                  <p class='contant-text'>
                    During the flowering phase, cyanogenic glycosides accumulate in the inflorescence. Cases of poisoning of sheep and goats with bird’s foot trefoil in the full flowering phase have been noted. It is necessary to mow it for hay before the beginning of flowering.
                  </p>
                </div>
              </div>



              <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>

              <div class='contant-left md:w-1/2' style='display: flex; flex-direction:column; justify-content: center;'">
                <p class='contant-text'>
                  Toxicity is caused by coumarin. The highest amount accumulates during the flowering phase. When using such hay, animals should be gradually accustomed to it, fed a daily hay ration, and given a break for 10-12 days after 2-3 weeks.
                </p>
                <p class='contant-title'>
                  Sorghum. <br/>
                  Meadow Vetchling Hay. 
                </p>
                <p class='contant-text'>
                 Meadow Vetchling is sown in a mixture with barley and mowed no later than the flowering phase. Feeding animals hay during the seed formation phase can cause a disease called lathyrism, it damages the brain and spinal cord.
                </p>
                <p class='contant-title'>
                 Buckwheat hay. 
                </p>
                <p class='contant-text'>
                 When fed, buckwheat hay causes phytophotodermatitis (non-pigmented areas of the skin are affected).
                </p>
                <p class='contant-text'>
                  Sorghum is harvested for hay at the yellow ripeness stage of the grain. Loses its poisonous properties when dried.
                </p>
                <p class='contant-title'>
                 Sudan grass hay.
                </p>
                <p class='contant-text'>
                  Sudan grass hay has a weakening effect on digestion processes. It is best to feed it to horses, cattle, and sheep in a mixture with alfalfa hay or corn silage.
                </p>
              </div>
              <div class='contant-left md:w-1/2'>
                <img src=${post15img3} />
              </div>
            </div>


    



              
              


    
                <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>
                <div class='contant-left md:w-1/2 md:order-1 order-2'>
                  <img alt='rotted round hay bale' src=${post15img4} />
                </div>
                <div class='contant-left md:w-1/2 order-1' style='display: flex; flex-direction:column; justify-content: center;'">
                  <p class='contant-text'>
                    Hay affected by mold or smuts should be shaken out and steamed before being fed to animals. In case of significant mold contamination, feeding such hay is not allowed.
                  </p>
                  <p class='contant-text'>
                    The organoleptic assessment of hay is done to study the following: colour, smell, stage of harvesting (based on the presence of inflorescences or seeds; on colour), baldness, moisture, dustiness, content of inedible impurities, and signs of spoilage. If the sample taken for analysis contains more than 10% of spoiled hay, such hay cannot be used for feed without the conclusion of veterinary medicine specialists.
                  </p>
                </div>
              </div>
          </div>

           




                `,
    category: "Articles",
    date: "11/06/24",
    image: `${post15img1}`,
  },
  {
    id: 14,
    post_group: {
      id: undefined,
    },
    seo_deskr: "At the end of May, #doers from all over the world gathered at Reflect Festival in Limassol, Cyprus to showcase its most innovative business models. ",
    seo_tite: 'EHOSS presented how manure can be recategorized from waste into resources. ',
    seo_keywords: 'startup, innovations, doers, engineering, technological developments, IT festival',
    title: "Reflect Festival",
    minidesckr: "Consistently advancing our goal: raising awareness about the potential of ...",
    content: `
                  <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img title='EHOSS on a Slovak Business Mission to Cyprus' alt='two men and a woman standing in front of Cyprus, Slovakia and EU flags' src=${post14img1} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-text'>
                     Consistently advancing our goal: raising awareness about the potential of manure in biogas production.
                    </p>
                    <p class='contant-text'>
                      Within the Slovak business mission to Cyprus, we also took part in the <a target='_blank' class='underline font-medium' href='https://reflectfest.com/'>Reflect Festival</a> that was held at Kolla in Limassol. The Festival is held every year on a beautiful island and gathers together #doers from all over the world.
                    </p>
                    <p class='contant-text'>
                      EHOSS’ stand was surprising for a lot of people: engineering and technological developments at the IT festival are an uncommon thing. Additionally, alongside typical branded merchandise, you see feedstock samples made from manure. Extravagant? Perhaps, but the innovative idea behind it is rushing and is a critical global need.
                    </p>
                    <p class='contant-text'>
                     We were glad to see all the guests of our booth and a big thanks to Martin Bezák, Ambassador of the Slovak Republic to the Republic of Cyprus, for the interest in the development of our technology.
                    </p>
                    <p class='contant-text'>
                     Our big thank to Dusan Duffek and his team for a great organizing and a great time!
                    </p>
                    <p class='contant-text font-bold'>
                      For more photos, <a href='/contacts' class='underline font-medium'>please write us</a> 
                    </p>
                  </div>
                </div>
              
                `,
    category: "Events",
    date: "30-31/05/2024",
    image: `${post14img1}`,
    slider: slider14,
  },
  {
    id: 13,
    post_group: {
      id: undefined,
    },
    seo_deskr: "Given Cyprus' 11 biogas plants and 6 more planned, EHOSS' cost-effective manure disposal technology is highly relevant.",
    seo_tite: 'EHOSS has been chosen to join Slovak business mission to Cyprus. ',
    seo_keywords: 'biogas, Cyprus, Slovakia, biomass, business relations',
    title:
      "Slovak Business Mission to Cyprus",
    minidesckr:
      "The Slovak Embassy in Cyprus in cooperation with the ...",
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img title='EHOSS on a Slovak Business Mission to Cyprus' alt='two men and a woman standing in front of Cyprus, Slovakia and EU flags' src=${post13img1} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-text'>
                     The <a class='underline font-medium' href='https://www.mzv.sk/nicosia' target='_blank'>Slovak Embassy in Cyprus</a> in cooperation with the <a class='underline font-medium' href='https://www.csbacy.com/' target='_blank'>Cyprus-Slovakia Business Association</a> organized a business mission to Cyprus, which took place on May 28-31, 2024.
                    </p>
                    <p class='contant-text'>
                      We are proud to announce that EHOSS was selected as one of the Slovak companies to participate in this business mission. Fruitful meeting have been held with representatives from Cypriot companies to foster business relations.
                    </p>
                    <p class='contant-text'>
                      Considering that the island has 11 biogas plants and 6 are about to open (according to open sources), EHOSS' technology that provides a continuous supply of feedstock at the same time making the whole process of manure disposal more cost-effective would be in a need.
                    </p>
                    <p class='contant-text'>
                     We also want to thank <a class='underline font-medium' href='https://mzv.sk/en/web/en' target='_blank'>Ministry of Foreign and European Affairs of the Slovak Republic</a>, <a class='underline font-medium' href='https://www.sario.sk/en' target='_blank'>Slovak Investment and Trade Development Agency (SARIO)</a> and the <a class='underline font-medium' href='https://www.limassolchamber.eu/' target='_blank'>Limassol Chamber of Commerce and Industry</a> for this invaluable opportunity and support of startups and innovative companies.
                    </p>

                    <p class='contant-text font-bold'>
                      Please, write us if you need more photos.
                    </p>
                  </div>
                </div>
              
                `,
    category: "Events",
    date: "29/05/2024",
    image: `${post13img1}`,
    slider: slider13,
  },
  {
    id: 12,
    post_group: {
      id: [10, 15]
    },
    title:
      "Harvesting Hay",
    minidesckr:
      "Roughage for Horses: Part II",
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img class='aspect-video' alt='green hay in stable' src=${post12img1} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-title'>
                      Harvesting chopped hay. 
                    </p>
                    <p class='contant-text'>
                    Chopped hay offers several advantages: it's more easily consumed by animals, distribution processes can be mechanized, and it can be mixed with other feeds. The optimal length for chopped hay is 8-10 cm.
                    </p>
                    <p class='contant-title'>
                      Harvesting baled hay. 
                    </p>
                    <p class='contant-text'>
                      Material with about 25% moisture content is gathered using a baler and formed into rectangular bales, approximately 25 kg each, which are tied with twine or wire. This significantly reduces nutrient loss during storage. Baled hay storage can accommodate 2-2.5 times more than loose hay. During sunny weather, bales are left in the field or stored under shelter arranged in pyramids for further drying. Artificial drying may be used to quicken this process. Bales dried to 17% moisture content are densely packed in hay storage for long-term storing. Those needing further drying are pressed less tightly.                  
                    </p>
                    </div>
                </div>






                <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>
                <div class='contant-left md:w-1/2 md:order-1 order-2'>
                  <img alt='rotted round hay bale' src=${post12img2} />
                </div>
                <div class='contant-left md:w-1/2 order-1' style='display: flex; flex-direction:column; justify-content: center;'">
                 
    
                  <p class='contant-text'>
                    Recently, the industry has introduced hay balers for rolling hay into bales. These balers form rolls weighing 500 kg and tie them with twine for easier transportation and storage. Storing hay in rolls as well as in bales is a primary method of feed preservation in developed countries.
                  </p>
                  <p class='contant-text'>
                    Sometimes hay is compressed into briquettes. By doing so we can combine the properties of chopped hay with the properties of baled hay. This eliminates the need for binding material and significantly reduces hay volume, improving transportation, unloading, storage, and feeding conditions. For briquetting, material from rollers is selected with a moisture content of 15-18%. If the moisture content is higher, the briquettes crumble after drying.
                  </p>
                  <p class='contant-text'>
                    One way to reduce nutrient loss and improve hay quality, given cheap energy sources, is through active ventilation drying. This method is also used when harvesting hay in cloudy weather. Moist material is harvested from the field at 45-55% moisture content and dried using specialized ventilation equipment. The ventilation period lasts 130-180 hours.
                  </p>
                </div>
    
              </div>



              <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>

              <div class='contant-left md:w-1/2' style='display: flex; flex-direction:column; justify-content: center;'">
                <p class='contant-text'>
                  Equipment for forced ventilation of harvested green material consists of a fan and an air distribution system, which delivers regular or heated air into special channels with perforated frames onto which the prepared material is placed.
                </p>
                <p class='contant-text'>
                  Air blowing from fans into a tunnel passing through a stack or a special hay tower is also utilized. In this case, the air distribution system must be properly sealed: air should only pass through openings in the system and through the hay. This equipment can be used to dry unchopped, chopped, and baled hay. Initially, fluffy, uncompressed material is stacked, no more than 2 m thick, and later, during the drying process, a new layer is added every 1.5-2 days until the stack reaches a height of 4-5 m. Ventilation is effective only with low humidity air supply. The drying process is significantly accelerated when using heated air for ventilation. For final drying the standard dryers with covers and installed fans are used.
                </p>
                <p class='contant-text'>
                  Haymaking ensures better preservation of nutrients if using active ventilation (table 2).
                </p>
              </div>
              <div class='contant-left md:w-1/2'>
                <img src=${post12img3} />
              </div>
            </div>


    



                <div class='contant-wrapper'>
                  <div class='flex flex-col items-center md:mb-12 justify-center'>
                    <p class='contant-title md:text-center'>Table 2. How different drying methods affect nutritiousness of 1 kg of alfalfa hay</p>
                    <img class='img-table max-w-2xl' src=${post12table} />
                  </div>
                </div>
              


    
                <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>
                <div class='contant-left md:w-1/2 md:order-1 order-2'>
                  <img alt='rotted round hay bale' src=${post12img4} />
                </div>
                <div class='contant-left md:w-1/2 order-1' style='display: flex; flex-direction:column; justify-content: center;'">
        
                  <p class='contant-text'>
                    Hay prepared with the usage of active ventilation is green in color, has a pleasant aroma, is rich in nutrients, and is also well consumed by animals. 
                  </p>
                  <p class='contant-text'>
                    This not only enhances the quality of the feed but also preserves the biological value of the protein. If alfalfa makes up 75% of the green mass, in alfalfa hay prepared with accelerated ventilation using heated air, it decreases to 70%, with active ventilation using regular air to 60-65%, and in field-dried hay to 45-50%.
                  </p>
                  <p class='contant-text'>
                    Recommendations have been developed for the preservation and enrichment of grain hay with nitrogen. Liquid ammonia (3% of the hay weight) is added to hay dried to 30% moisture content. Ammonia, dissolving in the water contained in the hay, partially binds, increasing the crude protein content in the hay. Liquid ammonia also partially disinfects the hay mass which is not completely dry yet preventing mold and thermophilic microflora development. If the treated hay begins to heat up after some time, it is treated again. For this purpose, 30-40 punctures are made along the length of the stack. Treatment with ammonia with subsequent ventilation is effective. In this case the mass continues to dry and the residual ammonia is ventilated out. However, this requires additional labor costs and strict adherence to safety rules.
                  </p>
                </div>
              </div>



              <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>

              <div class='contant-left md:w-1/2' style='display: flex; flex-direction:column; justify-content: center;'">

                <p class='contant-text'>
                  For better storage of high-moisture hay and increased nutritional value, chemical preservatives such as low-molecular-weight acid concentrate (LMWA) or propionic acid can be applied. They are applied at a rate of 1-1.5% of the feed mass. Special nozzles installed on pickers, used harvest loose or baled hay, are used. If propionic acid is evenly distributed hay can be stored for a long time even at 30-32% moisture content with minimal nutrient loss.
                </p>
                <p class='contant-text'>
                  However, it should be noted that propionic acid does not reduce but only inhibits the activity of mold fungi. Moreover, it evaporates quickly, has a pungent, harmful odor, and causes metal corrosion. Therefore, various substances (disinfectants, anticorrosive agents, odor neutralizers, etc.) are added to propionic acid.                
                </p>
              </div>
              <div class='contant-left md:w-1/2'>
                <img src=${post12img5} />
              </div>
            </div>



            
            <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>
            <div class='contant-left md:w-1/2 md:order-1 order-2'>
              <img alt='rotted round hay bale' src=${post12img6} />
            </div>
            <div class='contant-left md:w-1/2 order-1' style='display: flex; flex-direction:column; justify-content: center;'">
             

              <p class='contant-text'>
                Chemical substances are also used to accelerate the drying of grass. Before harvesting, the grass is sprayed with a weak solution of potassium bicarbonate (4-10 kg/ha). The product is dissolved in 200-400 liters of water. Special bars are installed on mowers, which bend the grass, allowing for more even spraying of plant stems. As a result, the mass drying is accelerated by one day. In this case, high-quality hay which animals willingly consume is obtained. This method increases hay yield to 8-10 tons/ha. The costs of processing are fully recouped. 
              </p>
              <p class='contant-text'>
                If there is no opportunity to ventilate hay on the farm and unfavorable weather conditions do not allow it to dry completely in the field, then hay with a moisture content of up to 25% is advisable to chop into pieces 5-10 cm long and store in trenches with a capacity of 300-600 tons, carefully compacted, covered with fresh grass and film.
              </p>
              <p class='contant-text'>
                For picking and chopping wet hay, silage combines are used. Such hay preserves leaves and inflorescences well and is readily consumed by cattle. Nutrient losses during storage are insignificant. However, at higher moisture content of the hay mass, fermentation processes similar to silage begin and the quality of the hay deteriorates.
              </p>
            </div>

          </div>

           




                `,
    category: "Articles",
    date: "27/02/24",
    image: `${post12img1}`,
  },
  {
    id: 11,
    post_group: {
      id: undefined,
    },
    title:
      "UN International Procurement Seminar",
    minidesckr:
      "The Czech Embassy in Vienna hosted International Procurement Seminar under the auspices of ...",
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img src=${post11img1} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-text'>
                      The Czech Embassy in Vienna hosted International Procurement Seminar under the auspices of the <a class='underline font-medium' href='https://www.un.org/' target='_blank'>United Nations</a> for businesses from Slovakia, the Czech Republic and Hungary with informative mission of tender participating possibilities.
                    </p>
                    <p class='contant-text'>
                    For two days, on 20-21 March, UN organisations spoke about their priorities for cooperation in projects in various fields around the world. Almost every meeting or speech of the UN representatives had key messages on renewable energy and the impact of technologies on the environment.

                    </p>
                    <p class='contant-text'>
                      EHOSS is presenting a fresh perspective on the potential of biomass, particularly within the biogas industry. Comprehensive solution includes expertise from technology to service, offering decentralized approaches tailored to the regions of biogas production, alongside efficient logistics for delivering feedstock directly to processing facilities. This integrated supply chain ensures a continuous flow of high-quality feedstock, crucial for producers.
                    </p>
                    <p class='contant-text'>
                      Additionally, Manure Management Technology creates a market for trading animal biomass, what provide additional income for farmers. while addressing the challenges of manure disposal and environmental concerns. We express gratitude to the organisers for facilitating potential collaborations with UN organizations on global projects.
                    </p>
                    <p class='contant-text font-bold'>
                      We truly appreciate assistance of all people we had a chance to communicate with, especially we would like to thank:
                    </p>
                    <ul class='list text-[14px] flex flex-col gap-2 mb-12'>
                      <li>Egbert von Mentzingen from <a class='underline font-bold' href='https://www.unido.org/' target='_blank'>UNIDO</a></li>
                      <li>Ingrid Antalkova from <a class='underline font-bold' href='https://www.fao.org/home/en/' target='_blank'>FAO</a></li>
                      <li>Julie Geoffrey from <a class='underline font-bold' href='https://www.osce.org/' target='_blank'>OSCE</a></li>
                      <li>Gurel Gurkan from <a class='underline font-bold' href='https://www.unops.org/' target='_blank'>UNOPS</a></li>
                      <li>Julie Havlova from <a class='underline font-bold' href='https://www.czechtrade.cz/' target='_blank'>CzechTrade</a></li>
                      <li>Sára Holly from <a class='underline font-bold' href='https://hepa.hu/en' target='_blank'>HEPA Hungarian Export Promotion Agency</a></li>
                      <li><a class='underline font-bold' href='https://www.sario.sk/en' target='_blank'>Slovak Investment and Trade Development Agency</a></li>
                      <li>Andrej Koleda Michaela Slimakova</li>
                      <li>Egon Zorad</li>
                      <li>Marek Kopanicky</li>
                      <li>Pocklan Marek</li>
                    </ul>
                  </div>
                </div>
               
                `,
    category: "Events",
    date: "20-21/03/24",
    image: `${post11img1}`,
    slider: slider11,
  },
  {
    id: 10,
    post_group: {
      id: [12, 15],
    },
    title:
      "Roughage for Horses",
    minidesckr:
      "Roughage for Horses: Part I",
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img class='aspect-video' alt='green hay in stable' src=${post10img1} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-text'>
                      Roughage includes various types of hay, straw, husks, grass meal, grass silage, as well as dry plant residues - flowerheads and stems of sunflower, corn stalks and stems, husks, hay chaff, twig feed is a valuable resource of the feed base for animal husbandry and is primarily used in the winter period. It is characterized by a high fiber content (19-20%) and a low water content (12-20%). The high fiber content helps to adjust the ration volume, improves digestion, intestinal peristalsis and promotes better secretion of digestive juices. Roughage is particularly important for ruminants as it facilitates intensive fermentation processes in the rumen due to the intense development of microorganisms which synthesize microbial protein, amino acids, B vitamins, and volatile fatty acids. Feeding hay to calves and lambs at an early age stimulates development of their rumens, allowing them to transition to plant-based feed at an earlier age. Hay is an essential feed in the diets of newly calved cows. Among all the roughage only hay contains vitamin D — a vitamin that is essential for metabolic regulation of minerals. It should be noted that for ruminants the degree of roughage grinding is of a crucial importance when normalizing digestion and metabolic processes. Feeding powdered roughage leads to digestive disorders and metabolic disturbances.
                    </p>
                  </div>
                </div>

                <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>
                  <div class='contant-left md:w-1/3'>
                    <video alt='black horse eats hay in a stable' class='post-video' src="https://ehoss.com/videos/horseeatshay.MOV" style="" controls />
                  </div>
                  <div class='contant-left md:w-2/3' style='display: flex; flex-direction:column; justify-content: center;'">
                    <p class='contant-text'>
                      Hay is prepared from legumes and grasses and their mixtures and is used in the feeding of horses, cows, breeding pigs, calves, sheep etc. Hay chaff is included in pigs diet. The straw of winter and spring cereals is used in the feeding of ruminants and horses, both — unchanged and after special preparation; it is also used to prepare silage from feed with a high moisture content. 
                    </p>
                    <p class='contant-text contant-text-acsent'>
                      Hay is one of the most valuable types of roughage for livestock.
                    </p>
                    <p class='contant-text'>
                      It is rich in vitamins, minerals and protein; contains flavoring substances that stimulate appetite; affects gut microbiome; normalizes rumen activity. However, in many farms the quality of hay is very low, and the loss of nutrients during harvesting exceeds 40%.
                    </p>
                    <p class='contant-text'>
                      To produce high-quality hay it’s important to harvest the grass in the early stages of plant vegetation when the level of digestible protein and vitamin content is the highest. In addition, early grazing enables a rich second harvest. So, to produce high-quality hay grass should be mowed no later than the budding phase of leguminous plants and heading of cereals begin. Harvesting should be complete at the beginning of flowering. Tender, green grasses retain their softness during drying and lose fewer delicate leaves.
                    </p>
                    <p class='contant-text'>
                      The highest yield of nutrients during haymaking is obtained by harvesting grass in the early stages of vegetation. Harvesting grass for hay in late stages of vegetation leads to a decrease in protein, amino acids, carotene, and sugar content (up to 20%) and an increase in fiber content. The digestibility of nutrients in such hay is reduced (chart 1).
                    </p>
                  </div>
                </div>
                <div class='contant-wrapper'>
                  <div class='flex flex-col items-center md:mb-12 justify-center'>
                    <p class='contant-title md:text-center'>Content and digestibility of nutrients in cereal hay depending on the vegetation phase, %</p>
                    <img class='img-table max-w-2xl' src=${post10table} />
                  </div>
                </div>
              <div class='contant-wrapper' style='margin-top: 20px; align-items: center;'>
                <div class='contant-right'>
                  <p class='contant-text contant-text-acsent'>Therefore, timely harvesting in the early stages of vegetation ensures the highest content of nutrients and their high digestibility. </p>
                  <p class='contant-text'>
                    To produce hay it’s needed to dry the harvested grass in the field or artificially, using special equipment, till a moisture content reaches the level of 15 - 17%. Its nutritional value depends on the botanical composition of plants, the stage of vegetation, growing conditions, harvesting, and storage. On average, the nutritional value of 1 kg of hay is 0.4-0.5 feed units, 40-80 g of digestible protein, 3-9 g of calcium, 1-4 g of phosphorus, and 10-35 mg of carotene. The fat content in hay is 1-2.5%, fiber 25-30%, and non-nitrogenous extractive substances 38-42%, vitamin D up to 400-500 IU.
                  </p>
                </div>
              </div>


              <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>

              <div class='contant-left md:w-1/2' style='display: flex; flex-direction:column; justify-content: center;'">
                <p class='contant-text'>
                  The essential part of preparing hay is drying during which water evaporates. There are three methods for evaporating water: air or sun drying in the field; field drying and forced ventilation drying by blowing through a specially arranged mass of atmospheric or heated air, and artificial high-temperature drying. The first two methods are used for haymaking while the third one is used to make grass meal and chaff.
                </p>
                <p class='contant-text'>
                  During hay preparation a significant amount of nutrients is lost due to breakage of leaves, inflorescences and shoots, physiological-biochemical processes during drying, washing out of soluble substances by rain etc.
                </p>
                <p class='contant-text'>
                  During this period, complex biochemical processes accompanied by the loss of nutrients occur in plants. In freshly mown grass cells continue to function under the condition of "starvation metabolism" which is characterized by usage of reserve carbohydrates and oxidizing them to CO2 and water. As a result, proteins partially degrade, carotene oxidizes, and the overall loss of organic matter reaches 1% per day. If 40-50% of water is lost, cells die. After their death, physiological-biochemical processes are replaced by biochemical ones (autolysis). Further decomposition of substances by autolysis (self-digestion) occurs under the unsystematic action of enzymes, and in the final stage of drying, oxidative decomposition of substances occurs.
                </p>
              </div>
              <div class='contant-left md:w-1/2'>
                <img src=${post10img2} />
              </div>
            </div>

            <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>
            <div class='contant-left md:w-1/2 md:order-1 order-2'>
              <img alt='rotted round hay bale' src=${post10img4} />
            </div>
            <div class='contant-left md:w-1/2 order-1' style='display: flex; flex-direction:column; justify-content: center;'">
              <p class='contant-text contant-text-acsent'>Total losses of nutrients during drying grass for hay in field conditions can reach up to 30-40% ...</p>

              <p class='contant-text'>
                ...due to biochemical processes, mechanical losses during mowing, stacking, and transportation. For carotene this number can reach up to 90%. If hay is prepared under bad weather conditions, losses can reach 50% and more. Grass getting wet because of dew or rain during autolysis can lead to microbiological processes development, soluble substances washing out; hay becomes moldy and blackens. Moisture and elevated temperatures can lead to fungi development (and thus reduce the content of water-soluble carbohydrates, starch, fat), and formation of toxic substances.
              </p>
              <p class='contant-text'>
                Drying speed depends on grass type, stage of development and external factors. If leguminous grasses and cereals are harvested at the same stage of development leguminous grasses dry slower. Alfalfa preserves water better than clover, and bromus dries faster than meadow fescue and timothy grass if under the same conditions. Young plants contain more water than the mature ones, leaves dry much faster than stems and during turning and other operations losses reach 30-50%. Significant losses of nutrients are observed in case of rain alternating with short periods of sunny weather.
              </p>
            </div>

          </div>

          <div class='contant-wrapper flex gap-4 items-start'>
            <p class='contant-text contant-text-acsent'>
            Haymaking technology consists of several operations: mowing grass, turning, stacking, picking, and forming dense cylindrical bales.
            </p>
          </div>
          
          <div class='contant-wrapper flex md:flex-row flex-col gap-4 items-start'>

          <div class='contant-left md:w-1/2' style='display: flex; flex-direction:column; justify-content: center;'">
            <p class='contant-text'>
              When harvesting legumes (clover, alfalfa, sainfoin, etc.) it is advisable to roll the mass during mowing and thus reduce drying time to half if the weather conditions are favourable. Rolling cereals is inefficient. In case of rainfall rolling is canceled due to chance of rapid loss of nutrients. It is most efficient to roll grasses using grooved rollers covered with rubber. Usage of metal rollers can lead to loss of leaves and flowers.
            </p>
            <p class='contant-text'>
              Withering of the mass comes next after rolling. To quicken this process, the grass is turned. After partial drying of the mown grass to a moisture content of 45-55% it is stacked into rolls for further drying. 
              During drying in rolls, under favorable weather conditions, the effect of sunlight on the grass weakens, reducing the destruction of carotene and reducing the losses of the most valuable leaves and flowers.
            </p>
            <p class='contant-text'>
              For further drying to a moisture content of 17-20% the withered mass with a moisture content of up to 30-35% is collected and formed into rolls with a collector-accumulator so it can be stored until feeding to animals.
            </p>
            <p class='contant-text'>
              When using hay with a moisture content of over 20% there is a risk of mold growth and self-heating. It is optimal to store hay in a shed or in hay barracks.
            </p>
          </div>
          <div class='contant-left md:w-1/2'>
            <img alt='round hay bale starting to rot' src=${post10img3} />
          </div>
        </div>

        <div class="btn6-wrp flex md:flex-row flex-col items-center mt-12">   
          <a target='_blank' class='main-btn text-black no-underline' href='https://ehoss.com/forage-platform'>
            Forage Platform
          </a>
        </div>


                `,
    category: "Articles",
    date: "27/02/24",
    image: `${post10img1}`,
  },
  {
    id: 9,
    post_group: {
      id: undefined,
    },
    title: "Slovak Industry VISION Day",
    minidesckr:
      "On November 23, 2023, in X-Bionic Sphere, Šamorín, Slovakia, our team took part in the Slovak Industry VISION Day held by Slovak Investment and Trade Development Agency (SARIO).",
    content: `
                <div class='contant-wrapper' style='align-items: center;'>
                  <div class='contant-left'>
                    <figure>
                        <img
                        src=${post9main}
                        alt="The posters on the back are in focus and the computer on the table not in focus standing on an exhibition.">
                      <figcaption>
                        EHOSS stand at Slovak Industry VISION Day.
                      </figcaption>
                    </figure>
                  </div>
                  <div class='contant-right'>
                    <p class='contant-text'>   
                      On November 23, 2023, in X-Bionic Sphere, Šamorín, Slovakia, our team took part in
                      the <a style="color: white;" target='_blank' href='https://www.slovakindustryvisionday.com/'>Slovak Industry VISION</a> Day held by <a target='_blank' style="color: white;" href='https://www.sario.sk/en'>Slovak Investment and Trade Development Agency (SARIO).</a>
                    </p>
                    <p class='contant-text'>
                      With Československá obchodní banka and Oberbank AG we discussed the prospects for mutual cooperation on the implementation of the leasing sales model of the <a target='_blank' style="color: white;" href='https://ehoss.com/reusing-platform'>Reusing Platform</a>. We plan that after the launch of sales, several options for purchasing will be available. 
                    </p>
                  </div>
                </div>



              <div class='contant-wrapper  flex md:flex-row flex-col gap-4' style='margin-top: 20px;justify-content: space-between; align-items: center;'>
              <div class='contant-right contant-left'>
              <figure>
                <img
                  src=${post9img2}
                  alt="Two men are sitting opposite each other at the table at a conference">
                <figcaption>
                  Mr. Arkadiusz Tos from the Embassy of the Republic of Poland and Mr. Viktor Kvachan from EHOSS
                </figcaption>
              </figure>
            </div>
              <div class='contant-left' style='display: flex; flex-direction:column; justify-content: center;'">
                    <p class='contant-text'>
                      At a meeting with the Embassy of the Republic of Poland, we discussed the prospects for the EHOSS technology for manure (<a target='_blank' style="color: white;" href='https://ehoss.com/reusing-platform'>Reusing Platform</a>) disposal to enter the Polish market. Poland is one of the most promising markets for our products. According to data from topagrar.pl, Poland ranks third in Europe in terms of the number of cattle and corresponding volumes of organic raw materials, which can be used by 194 Polish biogas producers. Moreover, one of the most authoritative certification centers KZR INiG System is located in Poland, which certifies raw materials at different stages in accordance with RED II Directive standards. 
                    </p>
                  </div>
                  
                </div>
              </div>



              <div class='contant-wrapper flex md:flex-row flex-col gap-4' style='align-items: center;'>
              <div class='contant-left md:order-2 md:w-1/2'>
                <figure>
                    <img
                    src=${post9img3}
                    alt="Two women are sitting opposite a man at the table at a conference.">
                  <figcaption>
                    Ms. Antonia Rackova Pariciova and Ms. Nina Erneker from ESG Klub with Mr. Viktor Kvachan from EHOSS.
                </figure>
              </div>
              <div class='contant-right md:order-1 md:w-1/2'>
                <p class='contant-text'>   
                  Green Talk invited EHOSS to join the local ESG Klub platform, which will gather together all companies related to green energy. Thanks to this meeting, we found like-minded people who, like us, believe in the potential of the domestic market of Slovakia and the power of proper networking. 
                </p>
              </div>
            </div>


                <div class='contant-wrapper'>
                  <div style='margin-top: 20px;'>
                    <p class='contant-text'>
                      With British Chamber of Commerce in SR, we discussed the prospects for sales of two key EHOSS products on the UK market (<a target='_blank' style="color: white;" href='https://ehoss.com/reusing-platform'>Reusing Platform</a> and <a target='_blank' style="color: white;" href='https://ehoss.com/forage-platform'>Forage Platform</a>). We received many useful contacts and advice on possible directions of development in the UK market. 
                    </p>
                  </div>
                </div>


                <div class='contant-wrapper flex md:flex-row flex-col gap-4' style='margin-top: 20px;justify-content: space-between; align-items: center;'>
                  <div class='contant-left md:order-2 md:w-1/2' style='display: flex; flex-direction:column; justify-content: center;'">
                    <p class='contant-text'>
                    The Embassy of the Kingdom of the Netherlands showed great interest in our company, which is of course mutual. Holland is one of the leading countries in the equestrian industry, which indicates the prospects for our further cooperation and entry into this market.
                    </p>
                  </div>
                  <div class='contant-right md:order-1 md:w-1/2'>
                    <figure>
                      <img
                        src=${post9img4}
                        alt="Two women are standing opposite a stand and a man explains what is on the table.">
                      <figcaption>
                        Ms. Martina Kurillová and Ms. Henriette van Notten from The Embassy of the Kingdom of the Netherlands with Mr. Viktor Kvachan from EHOSS.
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>


              <div class='contant-wrapper'>
                <div style='margin-top: 20px; margin-bottom: 20px;'>
                  <p class='contant-text'>
                    With the Austrian Business Agency, we discussed ways of business integration into the business ecosystem of Austria, our important neighbor and next market to come. 
                  </p>
                </div>
              </div>



              <div class='contant-wrapper flex md:flex-row flex-col gap-4' style='align-items: center;'>
              <div class='contant-left md:order-2 md:w-1/2'>
                <figure>
                    <img
                    src=${post9img5}
                    alt="Two men are sitting at the table at a conference.">
                  <figcaption>
                   Mr. Milan Haruštiak from Embassy of Canada / Trade Section with Mr. Viktor Kvachan from EHOSS.
                </figure>
              </div>
              <div class='contant-right md:order-1 md:w-1/2'>
                <p class='contant-text'>   
                With Embassy of Canada / Trade Section, we discussed prospects for entering the market outside of Europe. We can’t tell the date, but we are definitely going to join the Canadian market also.                 </p>
              </div>
            </div>



  <div class='contant-wrapper'>
                <div style='margin-top: 20px; margin-bottom: 20px;'>
                  <p class='contant-text'>
                 At the final meeting with Chorvátsko - Slovenská Obchodná Komora, we discussed the possibilities for Reusing Platform to enter the Croatian market as one of the most tempting neighboring markets.
              </p>
                </div>
              </div>


          
            <div class='contant-wrapper flex md:flex-row flex-col gap-4' style='margin-top: 20px;justify-content: space-between; align-items: center;'>
            <div class='contant-left md:order-2 md:w-1/2' style='display: flex; flex-direction:column; justify-content: center;'">
              
            
           
              
            <p class='contant-text'>
                Representatives from all countries and organizations have shown great interest in EHOSS's manure recycling technology and see great potential for their country. This giving us further confirmation that we are moving in the right direction to make our planet a better place.
              </p>
              <p class='contant-text'>
                Thanks to Mr. Tomás Kral, Ms. Hana Mozolová, Mr. Peter Gramblicka, Ms. Anna Balaj Vaclavova, Mr. Arkadiusz Tos, Ms. Antonia Rackova Pariciova, Ms. Nina Erneker, Ms. Miroslava Remenarova, Ms. Katarina Stulajterova, Ms. Henriette van Notten, Ms. Martina Kurillová, Ms. Dana Höller-Lipkova, Mr. Milan Haruštiak, Mr. Mario Gržinić and Mr. Jozef Jojo Krška.              
              </p>
            </div>
            <div class='contant-right md:order-1 md:w-1/2'>
              <figure>
                <img
                  src=${post9img6}
                  alt="Two women are standing opposite a stand and a man explains what is on the table.">
           
              </figure>
            </div>
          </div>
        </div>




                
                `,
    category: "Events",
    date: "23/11/2023",
    image: `${post9main}`,
    slider: slider9,
  },
  {
    id: 8,
    post_group: {
      id: undefined,
    },
    title:
      "How to Reduce Hay Waste with Chopped Hay: Maximizing Storage and Improving Equine Health",
    minidesckr:
      "One of the key issues in keeping horses is the quality of hay and the opportunity to manage the horse feeding process.",
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img src=${post8} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-title'>Introduction:</p>
                    <p class='contant-text'>
                      As horse owners and caretakers, it is our responsibility to ensure the well-being of these majestic animals. One crucial aspect of their care revolves around their dietary needs, specifically the provision of high-quality forage such as hay. However, traditional long-stem hay can be problematic in terms of waste, storage limitations, and suitability for horses with dental issues. This is where chopped hay comes into the picture, offering a range of advantages that address these concerns. In this article, we will explore the benefits of using chopped hay, highlighting its ability to reduce waste, maximize storage space, and provide easier chewing for horses with dental problems.                    
                    </p>
                  </div>
                </div>

              <div class='contant-wrapper'>
                <div class=''>
                  <p class='contant-title'>Reducing Waste:</p>
                  <p class='contant-text'>
                    Chopped hay is a game-changer when it comes to minimizing wastage. Horses tend to sort through long-stem hay, picking out the best parts and leaving the rest to be trampled. Using chopped hay it’s easier to make a hay mix, which has more uniform texture and discourages selective feeding. Moreover, it is easier for horses to chew chopped hay and to take it from a feeder or net. If the stems are chopped they don’t clung to one another, so hay doesn’t wind up on the ground. The amount of hay that is wasted is significantly reduced and savings reach up to 20% of the total volume of the bale. This not only helps to reduce hay waste, but also ensures that the horses get the most out of their feed.
                  </p>
                </div>
              </div>
                <div class='contant-wrapper' style='margin-top: 20px;justify-content: space-between;'>
                  <div class='contant-left' style='display: flex; flex-direction:column; justify-content: center;'">
                    <p class='contant-title'>Maximizing Storage Space:</p>
                    <p class='contant-text'>
                      Opting for chopped hay allows for denser packing, so it is possible to fit more hay into the same size feeder, which is especially beneficial during travels.
                    </p>

                  </div>
                  <div class='contant-right contant-left'>
                    <img src=${post8_2} />
                  </div>
                </div>




                <div class='contant-wrapper'>
                  <div class=''>
                    <p class='contant-title'>Improved Chewing for Horses with Dental Issues:</p>
                    <p class='contant-text'>
                      Dental problems can affect horses as they age, making it challenging for them to chew long-stem hay effectively. 
                      This can result in decreased feed intake, compromised digestion, and potential weight loss or malnutrition. 
                      Chopped hay offers a solution for such cases. Its shorter length and finer texture make it easier for horses 
                      with dental issues to chew and digest. By providing a more manageable forage option, chopped hay ensures that 
                      these horses receive the necessary nutrition without discomfort, promoting their overall health and well-being. 
                      <a class='' style="width: auto; color: white;" href='https://pubmed.ncbi.nlm.nih.gov/36877631/'>
                          [Viktoria Petz et al]
                      </a>
                      
                     
                     
                    </p>
                  </div>
                </div>



              <div class='contant-wrapper' style='margin-top: 20px; align-items: center;'>
                <div class='contant-left'>
         
                  <video class='post-video' src='https://ehoss.com/videos/post8video.mp4' style="" controls />
                </div>
                <div class='contant-right'>
                  <p class='contant-title'>Conclusion:</p>
                  <p class='contant-text'>
                    Chopped hay is a compelling choice for horse owners, offering various benefits that help reduce waste, 
                    optimize storage space, and improve equine health. By making hay more palatable and encouraging complete 
                    consumption, chopped hay saves resources and promotes better utilization. Additionally, it provides a 
                    viable solution for horses with dental issues, ensuring they receive the nutrition they need to stay healthy. 
                    As responsible caretakers, incorporating chopped hay into our equine feeding routines can lead to improved 
                    resource management and enhanced equine welfare. 

              
                      <a class='' style="width: auto; color: white;" href='https://www.vetres.org/articles/vetres/pdf/2002/06/01.pdf?access=ok'>
                        [Stephane Goncalves et al.]
                      </a>
                    
                  </p>
                </div>
              </div>

                        
              <div class='flex justify-center'>
                <a class='text-black mt-6 main-btn' href='/forage-platform'>
                  Forage Platform
                </a>
              </div>

                `,
    category: "Articles",
    date: "10/10/2023",
    image: `${post8}`,
  },
  {
    id: 7,
    post_group: {
      id: undefined,
    },
    title:
      "Exploring the UK market: our first trade show - BETA International",
    minidesckr:
      "In September 2023 EHOSS participated in its first trade show - BETA International, that took place at NAEC ...",
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img src=${post7_1} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-text'>
                    In September 2023 EHOSS participated in its first trade show - BETA International, that took place at NAEC Stoneleigh, Warwickshire, UK. That was the first introduction of EHOSS to the UK market. We got interesting feedback and companies for future collaborations. If you find our equipment useful for your business write us at <a style="font-weight: 600; text-decoration: underline; color: white;" href="mailto:welcome@ehoss.com">welcome@ehoss.com</a>  as we are always welcome for new interesting projects.
                    </p>
                  </div>
                </div>
               
                `,
    category: "Events",
    date: "10-11/09/23",
    image: `${post7}`,
    slider: slider7,
  },
  {
    id: 6,
    post_group: {
      id: undefined,
    },
    title: "Horse hay and technology or how to “uncook hay”",
    minidesckr:
      "I would say, the horse does not eat when it sleeps. Generally, the mass of hay for feeding a horse is about 2% of the horse’s weight...",
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img class='aspect-video' src=${post6} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-title'>The volume and price of hay.</p>
                    <p class='contant-text'>
                      I would say, the horse does not eat when it sleeps. Generally, the mass of hay for feeding a horse is about 2% of the horse’s weight and it is about 70% of food intake. The daily nutrition of hay is 10 kg for my horse. It is 300 kg of hay monthly.
                    </p>
                    <p class='contant-text'>
                      Let’s calculate hay consumption.The monthly cost of hay consumption is 40 euros in Slovakia for one horse. A small horse stable of 50 horses spends about 24,000 euros for hay yearly. That is 180 tons and 1800 m3 of hay.
                    </p>
                  </div>
                </div>

                <div class='contant-wrapper md:flex gap-4' style='margin-top: 20px;justify-content: space-between;'>
                  <div class='contant-left md:w-2/3' style='display: flex; flex-direction:column; justify-content: center;'">
                   
                    <p class='contant-title'>The storage of hay bales.</p>
                    <p class='contant-text'>
                      Of course, such a hay storage volume needs a lot of space. The hay bales warehouse is inside a huge hangar, mostly the hangar has no walls, just a roof. Sometimes, the outside layer of a bale becomes dark under the influence of the environment. The horse stable can lose about 20% of hay as the result of this environmental process. This mass of hay is unsuitable for horse feeding. Besides environmental factors like sun exposure and rainfall, the bale spaces are being compromised by the presence of rodents and the reproduction of bacteria etc. 
                    </p>
                    <p class='contant-text'>
                      The horse stable needs to build enclosed spaces with forced ventilation and air circulation for the quality storage of hay bales. But that is increased construction costs, and subsequent energy and service costs for this building.                    
                    </p>
                  </div>
                  <div class='contant-left md:w-1/3 video-container'>
                    <video class='post-video' src=${post6videoUrl} style="" controls />
                  </div>
                </div>

                <div class='contant-wrapper'>
                  <p class='contant-text'>
                    We take into account that we have already bought good-quality hay. And we sincerely hope that the quality of hay can never lose its nutritional properties. But as I mentioned above, we can not impact the warehouse process and the quality of the hay storage. Mostly we have to rent a horse stable as it is.                  
                  </p>
              </div>

              <div class='contant-wrapper' style='margin-top: 20px; align-items: center;'>
                <div class='contant-left'>
                  <img src=${post6_3} />
                </div>
                <div class='contant-right'>
                  <p class='contant-title'>The feeding of horses.</p>
                  <p class='contant-text'>
                    Each day the hay bales move to the area near the horse stables from the storage. Then part of hay is filled manually into the wheelbarrow by the stable hand. It takes quit a long time and it is not so easy to do it. But you also need to understand this process is not safe for humans because of the presence of dust. The next step the stable hand needs to do is to deliver a certain amount of hay to each stable. Mostly one wheelbarrow is enough hay for feeding 4 horses. So, if we have 40 horses in one stable it takes about 2 hours.                  </p>
                  <p class='contant-text'>
                    Today we have a few technologies for preparing hay before feeding. There are moisturized hay and steamed hay. Both technologies have already been used to partly change hay quality in recent decades. Moisturizing can be done in a special water tank or the hay can be watered directly on a wheelbarrow manually. Steaming is a more technological process that uses a steam generator in a special tank or a container. I call this process <strong>“to uncook hay”</strong>.                  
                  </p>
                </div>
              </div>

              <div class='contant-wrapper' style="flex-direction: column; gap: 0px !important;'">
                <p class='contant-text'>
                  The moisturized hay solves dryness and brittleness in overdried hay to some degree, but meanwhile it does not control the humidity level.              
                </p>
                <p class='contant-text'>
                  The steamed hay is intended to remove dust and microorganisms and to moisturize the hay. There is a “marketing” opinion for the steamed hay that a horse eats it better and it is easily digestible by the horse.     
                </p>
              </div>

              <div class='contant-wrapper'>
                <p class='contant-text contant-text-acsent'>
                  I have a lot of questions about steaming technology. The first is that it is too long of a process that takes about an hour to do it right. This is because we need to steam a huge volume of hay in a tank to avoid surface steaming.               
                </p>
              </div>

              <div class='contant-wrapper' style="flex-direction: column; gap: 0px !important;'">
                <p class='contant-text'>
                  While steaming the hay is in the same position in a tank. So much nutrients are lost at the point of contact of the steam and the hay. During the process “to uncook hay” a huge amount of vitamins, micronutrients and other type of nutrients are lost. The hay steaming is done without prior cleaning of the hay. So we uncook hay with mud, dust etc.               
                </p>
              </div>

              <div class='contant-wrapper'>
                <p class='contant-text contant-text-acsent'>
                  We brew a “borsch” with hay and harmful substances.              
                </p>
              </div>


              <div class='contant-wrapper' style="flex-direction: column; gap: 0px !important;'">
                <p class='contant-text'>
                  Another problem is that a portion for steaming in the tank is too small. It is complicated to clean hay this way for many horses at one moment. The horse cannot eat steamed hay in a short time. The hay is going to lose its quality over time since the hay is in open air.              
                </p>
                <p class='contant-text'>
                  In conclusion both processes, the moisturizing and the steaming, do not solve hay issues in the right way and are too complicated for stable hands. Steaming is not possible to implement for a whole stable of 50 horses. And finally, steaming equipment is not able to portion control during the day.     
                </p>
              </div>

              <div class="btn6-wrp flex md:flex-row flex-col items-center mt-12">

              
                <a target='_blank' class='main-btn text-black no-underline' href='https://ehoss.com/forage-platform'>
                  Forage Platform
                </a>
             
              </div>

                `,
    category: "Articles",
    date: "04/09/2023",
    image: `${post6}`,
  },
  {
    id: 5,
    post_group: {
      id: undefined,
    },
    title: "Horse Engineering: science and technology in horses life",
    minidesckr:
      "Forbes Slovensko about how we are developing Horse Engineering",
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img src=${post5} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-text'>
                      Our latest interview with Forbes Slovensko about how we are developing Horse Engineering 🚀
                    </p>

                    <p class='contant-text'>
                      We welcome partnerships with #horsetech companies, #horsestables, #farms, #biogas companies, #investors, and interested parties.
                    </p>

                    <p class='contant-text'>
                    You can read more in the article, which was created in cooperation with the creative team of #forbesmagazine.
                    </p>

                    <div class='flex md:flex-row flex-col gap-4 justify-center mt-8 mb-8'>
                      <a target='_blank' class='main-btn text-black' href='https://www.forbes.sk/ehoss-equine-engineering-connects-science-and-technology-in-the-world-of-horses/'>
                        Version EN
                      </a>
                      <a target='_blank' class='main-btn text-black' href='https://www.forbes.sk/ehoss-konske-inzinierstvo-prepaja-vedu-a-technologie-spate-so-svetom-koni/'>
                        Version SK
                      </a>
                    </div>


                    <p>
                       Photo (c) 2023, Forbes Slovensko, Marek Mucha
                    </p>
                  </div>
                </div>
                `,
    category: "Press about us",
    date: "11/08/2023",
    image: `${post5}`,
  },
  {
    id: 4,
    post_group: {
      id: undefined,
    },
    title: "Interview with Equestrian Trade News (ETN)",
    minidesckr:
      "ETN about our history, vision and upcoming participation at BETA International that will take place in September 2023",
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img src=${post4} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-text'>
                      Our interview with Equestrian Trade News (ETN) about our history, vision and upcoming participation at BETA International 2023 that will take place in Stoneleigh, UK this September.
                    </p>

                    <p class='contant-text'>
                      BETA is an international equestrian trade fair that gathers together producers and buyers to show up new products, inspire and business network development.
                    </p>

                    <p class='contant-text'>
                      Our stand 2-C15 at NAEC Stoneleigh (Stoneleigh Park, Warwickshire, UK) is open 10 and 11 of September from 09:30 till 17:00.
                    </p>


                

                    
                    <div class='flex justify-center'>
                      <a  target='_blank' class='text-black mt-6 main-btn' href='https://www.equestriantradenews.com/during-a-difficult-time-for-my-country-ukraine-dreams-have-come-true/'>
                        Read the full article
                      </a>
                    </div>

                  </div>
                </div>
              `,
    category: "Press about us",
    date: "19/07/2023",
    image: `${post4}`,
  },
  {
    id: 3,
    post_group: {
      id: undefined,
    },
    title: "Danube Equestrian Festival",
    minidesckr:
      "The Longines EEF Series jumping show Danube Equestrian Festival was held in June, 2023 at National Football Stadium…",
    content: ` <div>
        <div class='contant-wrapper md:flex gap-4'>
          <div class='contant-left md:w-1/3'>
            <video src=${post3_2} controls/>
          </div>
          <div class='contant-right md:w-2/3'>
            <p class='contant-text'>
              The CSIO Longines EEF Series 8 Series jumping show <a style="color: white;" href='https://csioslovakia.sk/en/'>Danube Equestrian Festival</a> was held in June, 2023 at National Football Stadium in Bratislava, Slovakia.
            </p>
            <p class='contant-text'>
              EHOSS toked part in it to introduce new products for horse life and understand the keen interest in our technology and other needs of the Horse Society.
            </p>
            <p class='contant-text'>
              We were honored to welcome guests from different countries and professions, especially we want to thank Ukraine’s ambassador in Slovakia for supporting our project.
            </p>
            <p class='contant-text'>
              The first <a href='https://ehoss.com/modular-stables' style="color: white;">Modular Stable</a> was presented in real. It is mobile and transportable construction making it easy and fast in installation. This kind of prefab horse stable solves horse keeping questions on offsite events such as fairs, jumping shows or any other of this type when a temporary stable is needed.
            </p>
            <p class='contant-text'>
              On the weekend days of festival, we held online and offline discussions on horse hay intake problems. 
              Watch our stream record <a target='_blank' style="color: white;" href="https://www.youtube.com/live/8y3JaUwzGAo?feature=share" style="color: white;">here</a>.
            </p>
            <div class='flex justify-start'>
              <a target='_blank' class='text-black mt-6 main-btn' href="https://www.youtube.com/live/8y3JaUwzGAo?feature=share">
                watch on YouTube
              </a>
            </div>
          </div>
        </div>


     
      </div>
      <p class='contant-title'>
        Gallery
      </p>
        
      </div>
    `,
    category: "Events",
    date: "08-11/06/2023",
    image: `${post3}`,
    slider: slider3,
  },
  {
    id: 2,
    post_group: {
      id: undefined,
    },
    title:
      "One of the most promising startups from Bratislava in 2023",
    minidesckr:
      "EU-Startups announced 10 Slovakian startups to watch over in 2023…",
    content: `
                <div class='contant-wrapper'>
                  <div class='contant-left'>
                    <img src=${post2} />
                  </div>
                  <div class='contant-right'>
                    <p class='contant-text'>
                      <a style="color: white;" href='https://www.eu-startups.com/'>EU-Startups.com</a>, the leading online magazine about
                      startups in Europe, announced 10 Slovakian 
                      startups to watch over in 2023. EHOSS was 
                      among this listing of young and promising 
                      from Bratislava’s startup ecosystem.
                    </p>

                  

                    <div class='flex justify-center'>
                      <a target='_blank' class='text-black mt-6 main-btn' href='https://www.eu-startups.com/2023/05/10-slovakian-startups-to-keep-an-eye-on-in-2023-and-beyond/'>
                        Read the full article
                      </a>
                    </div>
                   
                  </div>
                </div>
                `,
    category: "Press about us",
    date: "18/05/2023",
    image: `${post2}`,
  },
  {
    id: 1,
    post_group: {
      id: undefined,
    },
    title:
      "One of the most inventive farming companies and startups from Slovakia",
    minidesckr:
      "The passage presents the top ten most inventive farming companies and startups, showcasing their groundbreaking…",
    content: `<div class='contant-wrapper'>
    <div class='contant-left'>
    <img src=${post1} />
    </div><div class='contant-right'>
    <p class='contant-text'>
      The passage presents the top ten most inventive farming companies and startups, showcasing their groundbreaking efforts in the agricultural sector. All are based in the Slovak Republic and have proven to be exceptional companies that deserve attention. EHOSS was included in this list.
    </p>
    <p class='contant-text'>
    
    Futurology.life is a media that presents the most recent advancements and revolutionary discoveries that companies are doing within futuristic industries.
    </p>
    <div class='flex justify-center'>
    <a target='_blank' class='text-black mt-6 main-btn' href='https://futurology.life/10-most-innovative-slovakia-slovak-republic-based-farming-companies-startups/'>The full article is here</a>
    </div>
   
    </div></div>`,
    category: "Press about us",
    date: "19/09/2022",
    image: `${post1}`,
  },
];

export default postsData;
