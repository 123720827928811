import ReactFullpage from '@fullpage/react-fullpage';
import LineDot from '../../../components/line-dot/LineDot';
import Footer from '../../../container/footer/Footer';
import './farmers.css'
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

import Line from '../../../components/line/Line';
import card1 from './../../audience/images/bg2.png'
import card2 from './../../audience/images/bg3.png'
import card3 from './../../audience/images/bg4.png'
import card4 from './../../audience/images/bg5.png'
import card5 from './../../audience/images/bg6.png'
import card7 from './../../audience/images/bg1.png'

function Farmers() {
  const location = useLocation();
  
  return (
    <>
      <ReactFullpage
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={1000}
        navigation
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <Helmet>
                <title>EHOSS | For the farm owners</title>
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Equine welfare, Horse Welfare, Equine Health Plan, feeding analysis software, equine management nutrition health & exercise" />
                <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
              </Helmet>
              <section className='section audience1'>
                <LineDot />
                <div className='container'>
                  <div className='pt-24 flex md:flex-row flex-col gap-8 h-[100vh]'>
                    <div className='md:w-1/2  text-white'>
                      <Breadcrumbs
                        items={[
                          { label: 'Who Needs?', url: '/' },
                          { label: 'For Animal Owners', url: '/audience' },
                          { label: 'For the farm owners', url: '/farmers' },
                        ]}
                      />
                      <div className='flex flex-col mt-12 items-start gap-4'>
                        <h2 className='text-[36px] uppercase font-medium'>
                          For the farm owners
                        </h2>
                        <p className='text-[14px] font-bold uppercase'>
                          Let’s recategorise farm waste into resources.
                        </p>

                        <p className='text-[14px]'>
                          Several waste types from farms and stables can be reused as useful biomass for producing biofuels, organic fertiliser or used as material for construction. With our first biomass technology, we focus on manure type of waste for reuse in biogas production and as organic fertiliser on farms.
                        </p>

                        <p className='text-[14px]'>
                          These days, with government regulations and CAP policies (
                            <a className=' underline font-medium' target='_blank' rel="noreferrer" href='https://agriculture.ec.europa.eu/common-agricultural-policy_en#:~:text=The%20CAP%20is%20a%20partnership,and%20keeps%20rural%20areas%20vibrant.'>Common agricultural policy</a> and <a className=' underline font-medium' target='_blank' href='https://agriculture.ec.europa.eu/cap-my-country/cap-strategic-plans/slovakia_en'>CAP plan for Slovakia</a>), in the EU most manure volumes are reused as organic fertiliser. However, some amount is going to waste lagoons and storage areas. This wasted manure can be used more efficiently with the help of <a className=' underline font-medium' href="/reusing-platform">Manure Management Technology (MMT)</a>, which helps recycle it. 
                        </p>
                     
                        <p className='mt-6 font-bold uppercase'>MMT advantages for farms </p>
                        <ul className='list flex flex-col gap-4 *:text-[14px] mb-6'>
                          <li>
                            <strong>Better storage on the premises:</strong><br/>
                            as manure is divided into two fractions by the technology (solid and liquid), fractions are stored in specialized containers to prevent leak outs and emissions of toxic gases. This ensures a more sanitary and healthier environment. 
                          </li>
                          <li>
                            <strong>Management of the disposal process:</strong><br/>
                            all process is held by EHOSS team, farms need just to upload manure of needed condition to the equipment.
                          </li>
                          <li>
                            <strong>Additional income:</strong><br/>
                            technology processes manure into a feedstock that is bought by biogas plants giving farms extra income.
                          </li>
                        </ul>
                        <a style={{display: 'flex', gap: '4px'}} className='main-btn mb-16 text-black' href='/reusing-platform'>
                         <span className='block'>More about</span>
                         <span className='md:block hidden'>Manure Management Technology</span>
                          <span className='block md:hidden'>MMT</span> 
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className='section overflow-hidden'>
                <Line />
                <div className='container flex gap-6 flex-col md:flex-row md:items-center md:justify-between'>
                  <div className=' w-full md:h-[100vh] pt-24 text-white'>
                    <div>
                      <h2 className='text-[36px] font-medium uppercase'>
                        more about who needs
                      </h2>
                    </div>
                    <div className='w-full mt-12'>
                      <div className='grid gap-2 grid-cols-2 md:grid-cols-6 w-full'>
                        {/* =========== card 1 ============= */}
                        <a href='/farmers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>Farmers</h3>
                          </div>
                          <img alt='' className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card7} />
                        </a>
                        {/* =========== card 2 ============= */}
                        <a href='/horse-owners' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Horse Owners
                            </h3>
                          </div>
                          <img alt='' className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card1} />
                        </a>
                        {/* =========== card 3 ============= */}
                        <a href='/equestrian-facilities' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Equestrian Facilities
                            </h3>
                          </div>
                          <img alt='' className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card2} />
                        </a>
                        {/* =========== card 4 ============= */}
                        <a href='/equine-producers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Equine Product Producers
                            </h3>
                          </div>
                          <img alt='' className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card3} />
                        </a>
                        {/* =========== card 5 ============= */}
                        <a href='/veterinary' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] mt-4 font-semibold'>
                              Veterinary
                            </h3>
                          </div>
                          <img alt='' className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card4} />
                        </a>
                        {/* =========== card 6 ============= */}
                        <a href='/equestrians' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] mt-4 font-semibold'>
                              Equestrians
                            </h3>
                          </div>
                          <img alt='' className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card5} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </>

  );
}

export default Farmers;